import {
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import gql from "graphql-tag";
import {
  MapType,
  Mutation,
  Query,
  Subscription,
  ValueTypes,
  Zeus,
} from "./graphql-zeus";

export function useTypedLazyQuery<Q extends ValueTypes["Query"]>(
  query: Q,
  options?: QueryHookOptions<MapType<Query, Q>, Record<string, any>>
) {
  return useLazyQuery<MapType<Query, Q>>(gql(Zeus.query(query)), options);
}

export function useTypedQuery<Q extends ValueTypes["Query"]>(
  query: Q,
  options?: QueryHookOptions<MapType<Query, Q>, Record<string, any>>
) {
  return useQuery<MapType<Query, Q>>(gql(Zeus.query(query)), options);
}

export function useTypedMutation<Q extends ValueTypes["Mutation"]>(
  mutation: Q,
  options?: MutationHookOptions<MapType<Mutation, Q>, Record<string, any>>
) {
  return useMutation<MapType<Mutation, Q>>(
    gql(Zeus.mutation(mutation)),
    options
  );
}

export function useTypedSubscription<Q extends ValueTypes["Subscription"]>(
  subscription: Q,
  options?: SubscriptionHookOptions<
    MapType<Subscription, Q>,
    Record<string, any>
  >
) {
  return useSubscription<MapType<Subscription, Q>>(
    gql(Zeus.subscription(subscription)),
    options
  );
}

export function query<Q extends ValueTypes["Query"]>(q: Q) {
  return q;
}

export function mutation<Q extends ValueTypes["Mutation"]>(m: Q) {
  return m;
}
