/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["Address"]: AliasType<{
	id?:true,
	nickname?:true,
	flat_number?:true,
	address_line_1?:true,
	town?:true,
	postcode?:true,
	country?:true,
	phone_number?:true,
	instructions?:true,
	coordinates?:ValueTypes["GraphQLGeoJSONPoint"],
	imageKey?:true,
		__typename?: true
}>;
	["AddressCreateInput"]: {
	flat_number?:string,
	address_line_1:string,
	postcode:string,
	phone_number:string,
	instructions?:string,
	town?:string,
	country?:string,
	/** The coorindates of this address. Required only for customer and restaurant accounts */
	coordinates?:ValueTypes["JSON"]
};
	["AddressList"]: AliasType<{
	items?:ValueTypes["Address"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["AddressUpdateInput"]: {
	flat_number?:string,
	address_line_1?:string,
	postcode?:string,
	phone_number?:string,
	instructions?:string,
	town?:string,
	country?:string,
	/** The coordinates of this address. Required only for customer and restaurant accounts */
	coordinates?:ValueTypes["GraphQLGeoJSONPointInput"]
};
	["AdminPageView"]: AliasType<{
	url?:true,
	users?:ValueTypes["UserView"],
		__typename?: true
}>;
	["AggregateBasket"]: AliasType<{
	id?:true,
	isSingleDelivery?:true,
	allowSwitchSingleDelivery?:true,
	baskets?:ValueTypes["Basket"],
	feeExplanations?:ValueTypes["BasketFeeExplanation"],
	totalPrice?:ValueTypes["Price"],
	subtotal?:ValueTypes["Price"],
	fulfillmentTimes?:ValueTypes["FulfillmentDayTimes"],
	fulfillmentMethods?:true,
		__typename?: true
}>;
	["AggregateOrder"]: AliasType<{
	id?:true,
	status?:true,
	orders?:ValueTypes["Order"],
		__typename?: true
}>;
	["AnalyticsDTO"]: AliasType<{
	totalOrders?:true,
	totalRestaurants?:true,
	customers?:true,
		__typename?: true
}>;
	["AssignmentBias"]:AssignmentBias;
	["AuthDTO"]: AliasType<{
	user?:ValueTypes["User"],
	expiresIn?:true,
	accessToken?:true,
	refreshToken?:true,
		__typename?: true
}>;
	["AutoBookingSlot"]: AliasType<{
	id?:true,
	dayOfTheWeek?:true,
	startTime?:true,
	endTime?:true,
	guaranteedHourlyPay?:true,
	slotType?:true,
	maxRiders?:true,
	percentage?:true,
	autoIncreaseRiderPercentage?:true,
	carOnly?:true,
	configId?:true,
	config?:ValueTypes["AutoBookingSlotsConfig"],
		__typename?: true
}>;
	["AutoBookingSlotInput"]: {
	dayOfTheWeek:ValueTypes["DayOfWeek"],
	startTime:ValueTypes["HourMinuteTimeString"],
	endTime:ValueTypes["HourMinuteTimeString"],
	guaranteedHourlyPay:number,
	slotType:ValueTypes["BookingSlotType"],
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly?:boolean,
	configId:string
};
	["AutoBookingSlotsConfig"]: AliasType<{
	id?:true,
	name?:true,
	slots?:ValueTypes["AutoBookingSlot"],
	zones?:ValueTypes["Zone"],
		__typename?: true
}>;
	["AutoBookingSlotsConfigInput"]: {
	name:string
};
	["AutoBookingSlotsConfigList"]: AliasType<{
	items?:ValueTypes["AutoBookingSlotsConfig"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["B2BDeliveryJob"]: AliasType<{
	id?:true,
	created?:true,
	placed?:true,
	price?:true,
	distanceDuration?:ValueTypes["DistanceModel"],
	scheduledFor?:true,
	destination?:ValueTypes["Address"],
	origin?:ValueTypes["Address"],
	size?:true,
	placedById?:true,
	placedBy?:ValueTypes["RestaurantAdmin"],
	order?:ValueTypes["Order"],
		__typename?: true
}>;
	["B2BDeliveryJobList"]: AliasType<{
	items?:ValueTypes["B2BDeliveryJob"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["B2BDeliverySizes"]:B2BDeliverySizes;
	["B2BJobEstimateResponse"]: AliasType<{
	job?:ValueTypes["B2BDeliveryJob"],
	scheduledTimes?:ValueTypes["DeliveryTimes"],
	estimatedArrivalMins?:true,
	blockedReason?:true,
		__typename?: true
}>;
	["BarChart"]: AliasType<{
	id?:true,
	type?:true,
	series?:ValueTypes["Series"],
		__typename?: true
}>;
	["Basket"]: AliasType<{
	id?:true,
	modified?:true,
	checkedOutAt?:true,
	scheduledDelivery?:true,
	fulfillmentMethod?:true,
	targetDeliveryFromNow?:true,
	restaurantId?:true,
	sessionId?:true,
	totalPriceBeforeDiscount?:true,
	totalPrice?:true,
	riderTip?:true,
	allergyNotes?:true,
	tableNumber?:true,
	subtotal?:true,
	smallOrderFee?:true,
	deliveryFee?:true,
	location?:ValueTypes["GraphQLGeoJSONPoint"],
	distanceDuration?:true,
	alcohol?:true,
	numberOfItems?:true,
	weight?:true,
	instructions?:true,
	checkoutBlockedReason?:true,
	scheduledFor?:ValueTypes["DeliveryTimeArgs"],
	created?:true,
	paymentIntentSecret?:true,
	paymentIntentId?:true,
	/** Whether this basket has taken part in a successful order placement */
	complete?:true,
	donationTotal?:true,
	/** The items that have been added to this basket */
	items?:ValueTypes["OrderItem"],
	address?:ValueTypes["Address"],
	customer?:ValueTypes["Customer"],
	restaurant?:ValueTypes["Restaurant"],
	discount?:ValueTypes["Discount"],
	aggregateBasket?:ValueTypes["AggregateBasket"],
	donationView?:ValueTypes["BasketDonationView"],
	feeExplanations?:ValueTypes["BasketFeeExplanation"],
	fulfillmentMethods?:true,
	fulfillmentTimes?:ValueTypes["FulfillmentDayTimes"],
	reusables?:ValueTypes["BasketReusablesOptions"],
		__typename?: true
}>;
	["BasketAddDiscountInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:ValueTypes["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],
	scheduledFor?:ValueTypes["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	code:string
};
	["BasketAddItemInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:ValueTypes["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],
	scheduledFor?:ValueTypes["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	item:ValueTypes["BasketItemInput"]
};
	["BasketCheckOutInput"]: {
	sessionId:string,
	location?:ValueTypes["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],
	scheduledFor?:ValueTypes["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	tableNumber?:string
};
	["BasketDonationOptionView"]: AliasType<{
	id?:true,
	primaryText?:true,
	secondaryText?:true,
	isSelected?:true,
		__typename?: true
}>;
	["BasketDonationToggleView"]: AliasType<{
	id?:true,
	toggleText?:true,
	toggleFormattedValue?:true,
	toggled?:true,
		__typename?: true
}>;
	["BasketDonationView"]: AliasType<{
	campaign?:ValueTypes["ConsumerCharityCampaignView"],
	donationOptions?:ValueTypes["BasketDonationOptionView"],
	donationOptionsMutuallyExclusive?:true,
	donationToggleInfo?:ValueTypes["BasketDonationToggleView"],
		__typename?: true
}>;
	["BasketEditItemInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:ValueTypes["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],
	scheduledFor?:ValueTypes["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	itemId:string,
	quantity:number
};
	["BasketFeeExplanation"]: AliasType<{
	title?:true,
	explanation?:true,
	feeFormatted?:true,
	color?:true,
		__typename?: true
}>;
	/** Info to be sent with every basket request */
["BasketIdentifierInfo"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:ValueTypes["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],
	scheduledFor?:ValueTypes["DeliveryTimeInput"],
	useSynchronousPayments?:boolean
};
	["BasketIncrementDecrementItemInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:ValueTypes["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],
	scheduledFor?:ValueTypes["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	itemId:string
};
	["BasketItemInput"]: {
	id:string,
	/** How many of this exact combination of item and modifiers */
	quantity:number,
	modifierGroups?:ValueTypes["BasketModifierGroupInput"][]
};
	["BasketList"]: AliasType<{
	items?:ValueTypes["Basket"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["BasketModifierGroupInput"]: {
	id:string,
	modifierItems:ValueTypes["BasketItemInput"][]
};
	["BasketReusablesOptions"]: AliasType<{
	isEnabled?:true,
	enableText?:true,
	explanation?:true,
	disableText?:true,
	pickupScheduleTimes?:ValueTypes["TimeSlot"],
	pickupScheduledTime?:ValueTypes["TimeSlot"],
	imageKey?:true,
		__typename?: true
}>;
	["Booking"]: AliasType<{
	id?:true,
	createdAt?:true,
	riderId?:true,
	rider?:ValueTypes["Rider"],
	bookingSlotId?:true,
	bookingSlot?:ValueTypes["BookingSlot"],
	status?:true,
	attendancePercentage?:true,
	numOfferedOrders?:true,
	numAcceptedOrders?:true,
	riderEarnings?:true,
	sessionSpans?:true,
	lateCancellation?:true,
	stats?:ValueTypes["RiderSlotStats"],
		__typename?: true
}>;
	["BookingList"]: AliasType<{
	items?:ValueTypes["Booking"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["BookingSlot"]: AliasType<{
	id?:true,
	zoneId?:true,
	zone?:ValueTypes["Zone"],
	startTime?:true,
	endTime?:true,
	guaranteedHourlyPay?:true,
	slotType?:true,
	capHourlyPay?:true,
	carOnly?:true,
	maxRiders?:true,
	autoIncreaseRiderPercentage?:true,
	percentage?:true,
bookings?: [{	includeCancelled?:boolean},ValueTypes["Booking"]],
		__typename?: true
}>;
	["BookingSlotInput"]: {
	dayOfTheWeek:ValueTypes["DayOfWeek"],
	startTime:ValueTypes["HourMinuteTimeString"],
	endTime:ValueTypes["HourMinuteTimeString"],
	guaranteedHourlyPay:number,
	slotType:ValueTypes["BookingSlotType"],
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly?:boolean
};
	["BookingSlotList"]: AliasType<{
	items?:ValueTypes["BookingSlot"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["BookingSlotRiderView"]: AliasType<{
	id?:true,
	zoneId?:true,
	zone?:ValueTypes["Zone"],
	startTime?:true,
	endTime?:true,
	guaranteedHourlyPay?:true,
	slotType?:true,
	capHourlyPay?:true,
	carOnly?:true,
	maxRiders?:true,
	autoIncreaseRiderPercentage?:true,
	percentage?:true,
bookings?: [{	includeCancelled?:boolean},ValueTypes["Booking"]],
	hasBooked?:true,
	bookingId?:true,
		__typename?: true
}>;
	["BookingSlotRiderViewList"]: AliasType<{
	items?:ValueTypes["BookingSlotRiderView"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["BookingSlotType"]:BookingSlotType;
	["BookingStatus"]:BookingStatus;
	["Boost"]: AliasType<{
	id?:true,
	type?:true,
	start?:true,
	end?:true,
	multiplier?:true,
	fixedIncrease?:true,
	notified?:true,
	zoneId?:true,
	zone?:ValueTypes["Zone"],
		__typename?: true
}>;
	["BoostInput"]: {
	start:ValueTypes["Date"],
	end:ValueTypes["Date"],
	type:ValueTypes["BoostType"],
	multiplier:number,
	zoneId:string,
	fixedIncrease:number
};
	["BoostType"]:BoostType;
	["Brand"]: AliasType<{
	id?:true,
	slug?:true,
	name?:true,
	description?:true,
	logo?:ValueTypes["ImageAsset"],
	owners?:ValueTypes["BrandOwner"],
		__typename?: true
}>;
	["BrandList"]: AliasType<{
	items?:ValueTypes["Brand"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["BrandOwner"]: AliasType<{
	id?:true,
	created?:true,
		__typename?: true
}>;
	["CampaignMapMarker"]: AliasType<{
	id?:true,
	center?:true,
		__typename?: true
}>;
	["CampaignMapView"]: AliasType<{
	id?:true,
	features?:true,
	center?:true,
	markers?:ValueTypes["CampaignMapMarker"],
		__typename?: true
}>;
	["Charity"]: AliasType<{
	id?:true,
	name?:true,
	logo?:ValueTypes["ImageAsset"],
	logoId?:true,
	campaigns?:ValueTypes["CharityCampaign"],
		__typename?: true
}>;
	["CharityCampaign"]: AliasType<{
	id?:true,
	charityId?:true,
	startDate?:true,
	endDate?:true,
	goal?:true,
	progress?:true,
	title?:true,
	description?:true,
	goalPrefix?:true,
	goalSuffix?:true,
	costPerGoalUnit?:true,
	campaignLogo?:ValueTypes["ImageAsset"],
	campaignLogoId?:true,
	charity?:ValueTypes["Charity"],
		__typename?: true
}>;
	["CharityCampaignCreateArgs"]: {
	charityId:string,
	startDate:ValueTypes["Date"],
	endDate:ValueTypes["Date"],
	goal:number,
	title:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit:number,
	campaignLogoId?:string
};
	["CharityCampaignFinancials"]: AliasType<{
	id?:true,
	donationTotal?:true,
	campaign?:ValueTypes["CharityCampaign"],
		__typename?: true
}>;
	["CharityCampaignList"]: AliasType<{
	items?:ValueTypes["CharityCampaign"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["CharityCampaignUpdateArgs"]: {
	charityId?:string,
	startDate?:ValueTypes["Date"],
	endDate?:ValueTypes["Date"],
	goal?:number,
	title?:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit?:number,
	campaignLogoId?:string
};
	["CharityCreateArgs"]: {
	name?:string,
	logoId?:string
};
	["CharityFinancials"]: AliasType<{
	id?:true,
	donationTotal?:true,
	campaigns?:ValueTypes["CharityCampaignFinancials"],
	charity?:ValueTypes["Charity"],
		__typename?: true
}>;
	["CharityList"]: AliasType<{
	items?:ValueTypes["Charity"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["CharityUpdateArgs"]: {
	name?:string,
	logoId?:string
};
	/** All possible chart types */
["Chart"]: AliasType<{		["...on PieChart"] : ValueTypes["PieChart"],
		["...on LineChart"] : ValueTypes["LineChart"],
		["...on BarChart"] : ValueTypes["BarChart"],
		["...on TableChart"] : ValueTypes["TableChart"],
		["...on StatChart"] : ValueTypes["StatChart"]
		__typename?: true
}>;
	["ChartLayoutGroup"]: AliasType<{
	id?:true,
	title?:true,
	description?:true,
	charts?:ValueTypes["Chart"],
		__typename?: true
}>;
	["ChatMessage"]: AliasType<{
	id?:true,
	text?:true,
	sent?:true,
	read?:true,
	sender?:ValueTypes["MessageParticipant"],
	viewableBy?:true,
	order?:ValueTypes["Order"],
		__typename?: true
}>;
	["ChatMessageInput"]: {
	orderId:string,
	text:string,
	viewableBy?:ValueTypes["UserType"]
};
	["ChatMessageList"]: AliasType<{
	items?:ValueTypes["ChatMessage"],
	total?:true,
	hasMore?:true,
	unreadCount?:true,
		__typename?: true
}>;
	["City"]: AliasType<{
	id?:true,
	name?:true,
	zones?:ValueTypes["Zone"],
		__typename?: true
}>;
	["CityCreateInput"]: {
	name:string,
	zones:ValueTypes["ZoneCreateInput"][]
};
	["CommissionPlan"]: AliasType<{
	id?:true,
	slug?:true,
	description?:true,
	rungs?:ValueTypes["CommissionPlanRung"],
	partnerPlans?:ValueTypes["PartnerCommissionPlan"],
		__typename?: true
}>;
	["CommissionPlanList"]: AliasType<{
	items?:ValueTypes["CommissionPlan"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["CommissionPlanRung"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	fixedBase?:true,
	percentage?:true,
	percentageCollection?:true,
	percentageTableService?:true,
	deliveryFeePerKMDiff?:true,
	deliveryFeeBaseDiff?:true,
		__typename?: true
}>;
	/** Configuration for the ecoeats API */
["Configuration"]: AliasType<{
	/** Base URL for the CDN */
	cdnURL?:true,
	/** Base URL for image resizing proxy */
	imgProxyURL?:true,
	env?:true,
coreSettings?: [{	id:ValueTypes["CoreConfigurations"]},ValueTypes["CoreSettings"]],
		__typename?: true
}>;
	["ConsumerCharityCampaignView"]: AliasType<{
	charityId?:true,
	charityName?:true,
	charityLogo?:ValueTypes["ImageAsset"],
	id?:true,
	campaignTitle?:true,
	campaignLogo?:ValueTypes["ImageAsset"],
	startDate?:true,
	endDate?:true,
	goal?:true,
	progress?:true,
	goalFormatted?:true,
	progressFormatted?:true,
	percentageProgress?:true,
	description?:true,
	map?:ValueTypes["CampaignMapView"],
		__typename?: true
}>;
	["ConsumerSearchItemResult"]: AliasType<{
	relevance?:true,
	item?:ValueTypes["Item"],
		__typename?: true
}>;
	["ConsumerSearchPartnerResult"]: AliasType<{
	relevance?:true,
	item?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["ConsumerSearchResult"]: AliasType<{
	query?:true,
	location?:ValueTypes["UnifiedLocationType"],
	partners?:ValueTypes["ConsumerSearchPartnerResult"],
	items?:ValueTypes["ConsumerSearchItemResult"],
	tags?:ValueTypes["ConsumerSearchTagResult"],
		__typename?: true
}>;
	["ConsumerSearchTagResult"]: AliasType<{
	relevance?:true,
	item?:ValueTypes["PartnerTag"],
		__typename?: true
}>;
	["ContainerCode"]: AliasType<{
	id?:true,
	createdAt?:true,
		__typename?: true
}>;
	/** A set of coordinates. x, y */
["Coordinates"]:unknown;
	["CoreConfigurations"]:CoreConfigurations;
	["CoreSettings"]: AliasType<{
	id?:true,
	displayName?:true,
	enableMultiPartnerOrders?:true,
	url?:true,
	fulfillmentMethods?:true,
	fulfillmentTimeInterval?:true,
	theme?:ValueTypes["CoreSettingsTheme"],
		__typename?: true
}>;
	["CoreSettingsTheme"]: AliasType<{
	colors?:ValueTypes["CoreSettingsThemeColors"],
	font?:true,
		__typename?: true
}>;
	["CoreSettingsThemeColors"]: AliasType<{
	PRIMARY?:true,
	SECONDARY?:true,
	TEXT?:true,
	DARK?:true,
		__typename?: true
}>;
	["CreateIncidentInput"]: {
	severity:ValueTypes["IncidentSeverity"],
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:ValueTypes["UserType"],
	description:string
};
	["CreatePromotionInput"]: {
	start:ValueTypes["Date"],
	end:ValueTypes["Date"],
	name:string,
	title:string,
	zoneIds?:string[],
	color:string,
	body:string,
	targetType?:ValueTypes["PromotionTarget"],
	callToActionText?:string,
	target?:string
};
	["Customer"]: AliasType<{
	id?:true,
	name?:true,
	email?:true,
	created?:true,
	phoneNumber?:true,
	isPhoneNumberVerified?:true,
	emailConfirmationTokenIssued?:true,
	emailConfirmed?:true,
	roles?:true,
	profileImageKey?:true,
	marketing?:true,
	referralLink?:true,
	referredBy?:ValueTypes["Customer"],
	referrals?:ValueTypes["Customer"],
	stripeCustomerId?:true,
	addresses?:ValueTypes["Address"],
	orders?:ValueTypes["Order"],
	paymentMethods?:ValueTypes["PaymentMethod"],
	orderStatistics?:ValueTypes["CustomerOrderStatistics"],
	accountCredit?:true,
	vouchers?:ValueTypes["CustomerVoucher"],
	topups?:ValueTypes["CustomerWalletTopup"],
		__typename?: true
}>;
	["CustomerAnalytics"]: AliasType<{
	id?:true,
	layoutGroups?:ValueTypes["ChartLayoutGroup"],
		__typename?: true
}>;
	["CustomerList"]: AliasType<{
	items?:ValueTypes["Customer"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["CustomerOrderStatistics"]: AliasType<{
	metres?:true,
	emissionsSaved?:true,
	lastUpdated?:true,
		__typename?: true
}>;
	["CustomerReusables"]: AliasType<{
	id?:true,
	containersNotReturned?:true,
	containersClaimedReturned?:true,
	containersValidatedReturned?:true,
	containersScheduled?:true,
	containersScannedButNotScheduled?:true,
	maxContainersSchedulable?:true,
	totalDeposits?:true,
slots?: [{	count?:number,	addressId?:string},ValueTypes["ReusablesPickupScheduleTime"]],
	pickups?:ValueTypes["ReusablesPickup"],
	activePickup?:ValueTypes["ReusablesPickup"],
		__typename?: true
}>;
	["CustomerUpdateInput"]: {
	name?:string,
	phoneNumber?:string
};
	["CustomerVoucher"]: AliasType<{
	id?:true,
	created?:true,
	used?:true,
	discount?:ValueTypes["Discount"],
		__typename?: true
}>;
	["CustomerWalletTopup"]: AliasType<{
	id?:true,
	createdAt?:true,
	note?:true,
	amount?:true,
	paymentIntentId?:true,
	customer?:ValueTypes["Customer"],
		__typename?: true
}>;
	["CustomerWalletTopupList"]: AliasType<{
	items?:ValueTypes["CustomerWalletTopup"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	/** Date custom scalar type */
["Date"]:unknown;
	["DayOfWeek"]:DayOfWeek;
	["DeliveryFlightPlan"]: AliasType<{
	id?:true,
	createdAt?:true,
	completedAt?:true,
	route?:true,
	riderId?:true,
	legs?:ValueTypes["DeliveryFlightPlanLeg"],
	activeOffer?:ValueTypes["FlightPlanOffer"],
		__typename?: true
}>;
	["DeliveryFlightPlanLeg"]: AliasType<{
	id?:true,
	legOrder?:true,
	order?:ValueTypes["Order"],
	address?:ValueTypes["Address"],
		__typename?: true
}>;
	["DeliveryJobInput"]: {
	id?:string,
	scheduledFor?:ValueTypes["Date"],
	destinationId?:string,
	originId?:string,
	destination:ValueTypes["AddressCreateInput"],
	origin:ValueTypes["AddressCreateInput"],
	size:ValueTypes["B2BDeliverySizes"]
};
	["DeliveryTimeArgs"]: AliasType<{
	day?:true,
	time?:true,
		__typename?: true
}>;
	["DeliveryTimeDay"]:DeliveryTimeDay;
	["DeliveryTimeInput"]: {
	day:ValueTypes["DeliveryTimeDay"],
	time:ValueTypes["HourMinuteTimeString"]
};
	["DeliveryTimes"]: AliasType<{
	date?:true,
	slots?:true,
		__typename?: true
}>;
	["Discount"]: AliasType<{
	id?:true,
	/** Code that customer enters to redeem this discount. */
	code?:true,
	description?:true,
	/** The value of this discount. Only needed for PERCENTAGE and FIXED discounts. */
	value?:true,
	/** The minimum price of the basket before this discount can be applied */
	minimumTotalPrice?:true,
	type?:true,
	enabled?:true,
	validFrom?:true,
	validUntil?:true,
	maxUsesGlobally?:true,
	maxUsesPerCustomer?:true,
	referralAmount?:true,
orders?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["OrderList"]],
	riderReferral?:ValueTypes["RiderReferralDiscount"],
		__typename?: true
}>;
	["DiscountCreateInput"]: {
	code:string,
	description:string,
	validFrom:ValueTypes["Date"],
	validUntil:ValueTypes["Date"],
	type:ValueTypes["DiscountType"],
	value?:number,
	maxUsesGlobally:number,
	maxUsesPerCustomer:number,
	minimumTotalPrice:number,
	referralAmount?:number
};
	["DiscountList"]: AliasType<{
	items?:ValueTypes["Discount"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	/** The type of discount this is. */
["DiscountType"]:DiscountType;
	["DiscountUpdateInput"]: {
	description?:string,
	validFrom?:ValueTypes["Date"],
	validUntil?:ValueTypes["Date"],
	type?:ValueTypes["DiscountType"],
	value?:number,
	maxUsesGlobally?:number,
	maxUsesPerCustomer?:number,
	enabled?:boolean,
	referralAmount?:number
};
	["DistanceModel"]: AliasType<{
	metres?:true,
	seconds?:true,
		__typename?: true
}>;
	["DropBoxStatus"]:DropBoxStatus;
	/** A form that can represent any JSONSchema structured data. Designed for heavy data entry that does not fit with GraphQL */
["DynamicForm"]: AliasType<{
	/** A unique ID representing this forms template. */
	id?:true,
	/** The display type of the dynamic form */
	type?:true,
	title?:true,
	/** If provided, poll the API for a change in this form this often. */
	pollInterval?:true,
	canSubmit?:true,
	submitText?:true,
	description?:true,
	/** Values to populate the data structure represented by the Schema. */
	initialValues?:true,
	/** Always a valid JSONSchema object that can be represted as a form */
	schema?:true,
		__typename?: true
}>;
	["DynamicFormResult"]: AliasType<{
	success?:true,
	successMessage?:true,
	form?:ValueTypes["DynamicForm"],
		__typename?: true
}>;
	/** FORM displays as a form, TABLE as a table. Use initialValues as data. */
["DynamicFormType"]:DynamicFormType;
	["ExternalCompetitor"]: AliasType<{
	name?:true,
	id?:true,
	updated?:true,
	raw?:true,
		__typename?: true
}>;
	["ExternalCompetitorInput"]: {
	name:string,
	id:string
};
	["File"]: AliasType<{
	id?:true,
	createdAt?:true,
	type?:true,
	title?:true,
	size?:true,
	/** A link to the file, subject to change. */
	url?:true,
		__typename?: true
}>;
	["FileList"]: AliasType<{
	items?:ValueTypes["File"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["FinancialOverview"]: AliasType<{
	meta?:ValueTypes["FinancialOverviewArgsMeta"],
partners?: [{	restaurantIds?:string[]},ValueTypes["RestaurantFinancialsView"]],
	riders?:ValueTypes["SpecificRiderFinancials"],
	charities?:ValueTypes["CharityFinancials"],
		__typename?: true
}>;
	["FinancialOverviewArgsMeta"]: AliasType<{
	startDate?:true,
	endDate?:true,
	zoneIds?:true,
		__typename?: true
}>;
	["FlightPlanOffer"]: AliasType<{
	id?:true,
	offered?:true,
	changed?:true,
	outcome?:true,
	fee?:true,
	sneaky?:true,
	context?:true,
	legs?:ValueTypes["DeliveryFlightPlanLeg"],
	orders?:ValueTypes["Order"],
	riderId?:true,
	rider?:ValueTypes["Rider"],
	flightPlanId?:true,
	flightPlan?:ValueTypes["DeliveryFlightPlan"],
		__typename?: true
}>;
	["FlightPlanOfferList"]: AliasType<{
	items?:ValueTypes["FlightPlanOffer"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["FulfillmentDayTimes"]: AliasType<{
	day?:true,
	times?:true,
		__typename?: true
}>;
	["FulfillmentMethod"]:FulfillmentMethod;
	["GenericAuditView"]: AliasType<{
	id?:true,
	timestamp?:true,
	targetId?:true,
	from?:true,
	to?:true,
		__typename?: true
}>;
	["GraphQLGeoJSONLineString"]: AliasType<{
	type?:true,
	coordinates?:true,
		__typename?: true
}>;
	["GraphQLGeoJSONPoint"]: AliasType<{
	type?:true,
	coordinates?:true,
		__typename?: true
}>;
	["GraphQLGeoJSONPointI"]: {
	type?:string,
	coordinates:ValueTypes["Coordinates"]
};
	["GraphQLGeoJSONPointInput"]: {
	type?:string,
	coordinates:ValueTypes["Coordinates"]
};
	["GraphQLGeoJSONPolygon"]: AliasType<{
	type?:true,
	coordinates?:true,
		__typename?: true
}>;
	["GraphQLGeoJSONPolygonInput"]: {
	type?:string,
	coordinates:ValueTypes["Coordinates"][]
};
	["HomePage"]: AliasType<{
	meta?:ValueTypes["PartnerHomePageMeta"],
	promotions?:ValueTypes["HomepagePromotion"],
	selections?:ValueTypes["PartnerSelection"],
	partners?:ValueTypes["Restaurant"],
	tags?:ValueTypes["PartnerTag"],
	zone?:ValueTypes["Zone"],
	fulfillmentTimes?:ValueTypes["FulfillmentDayTimes"],
	itemGroups?:ValueTypes["PartnerHomePageItemGroup"],
		__typename?: true
}>;
	["HomepagePromotion"]: AliasType<{
	id?:true,
	body?:true,
	title?:true,
	color?:true,
	imageKey?:true,
	callToActionText?:true,
	target?:true,
	targetType?:true,
		__typename?: true
}>;
	/** HH:MM formatted time string */
["HourMinuteTimeString"]:unknown;
	["Hygiene"]: AliasType<{
	id?:true,
	updated?:true,
	rating?:true,
	authority?:true,
	authorityRatingId?:true,
	authorityRatingLink?:true,
	authorityRatingImage?:true,
	restaurant?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["HygieneAuthorities"]:HygieneAuthorities;
	["IdentityDocumentType"]:IdentityDocumentType;
	["ImageAsset"]: AliasType<{
	id?:true,
	created?:true,
	updated?:true,
	title?:true,
	key?:true,
	width?:true,
	height?:true,
	fileSize?:true,
	altText?:true,
	mimeType?:true,
url?: [{	width:number,	height?:number,	gravity?:string},true],
	uploadedBy?:ValueTypes["RestaurantAdmin"],
		__typename?: true
}>;
	["ImageAssetList"]: AliasType<{
	items?:ValueTypes["ImageAsset"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["Incident"]: AliasType<{
	id?:true,
	created?:true,
	number?:true,
	severity?:true,
	riderId?:true,
	orderId?:true,
	partnerId?:true,
	customerId?:true,
	atFault?:true,
	description?:true,
	customer?:ValueTypes["Customer"],
	order?:ValueTypes["Order"],
	rider?:ValueTypes["Rider"],
	partner?:ValueTypes["Restaurant"],
	/** Audit changes to the incident */
	audit?:ValueTypes["GenericAuditView"],
	images?:ValueTypes["ImageAsset"],
		__typename?: true
}>;
	["IncidentList"]: AliasType<{
	items?:ValueTypes["Incident"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["IncidentSeverity"]:IncidentSeverity;
	["Item"]: AliasType<{
	id?:true,
	isTemplate?:true,
	name?:true,
	description?:true,
	ingredients?:true,
	unitPrice?:true,
	/** The maximum number of this item that can be in a single order */
	maxPerOrder?:true,
	/** The emissions this item is responsible for, in grams of CO2 */
	emissions?:true,
	/** Whether this  */
	alcohol?:true,
	/** Whether this item is vegan or not */
	vegan?:true,
	/** Whether this item is vegetarian or not */
	vegetarian?:true,
	/** Weight of the item, in grams */
	weight?:true,
	/** A list of fulfillment methods this item cannot be ordered for */
	unavailableFor?:true,
	/** Whether this item takes a long time to prepare */
	slow?:true,
	/** Whether this item has been approved for use */
	approved?:true,
	tax?:true,
	/** How hot this item is when served */
	heat?:true,
	/** Whether this item is published or not */
	published?:true,
	/** Whether this item is popular with customers or not */
	popular?:true,
	/** A path to the image representing the header for this merchant */
	headerImageKey?:true,
	/** A hash that represents the headerImage */
	blurhash?:true,
	/** The order in which this item should be shown in the menu */
	sortOrder?:true,
	/** Whether this item is still available to order, i.e. not out of stock */
	available?:true,
	/** When this item was deleted */
	deletedAt?:true,
	categoryId?:true,
	/** The category to which this item belongs */
	category?:ValueTypes["MenuCategory"],
	/** The modifier groups this item has as options */
	modifiers?:ValueTypes["ModifierGroup"],
	optionFor?:ValueTypes["ModifierGroup"],
	containerTypeId?:true,
	price?:ValueTypes["Price"],
	headerImage?:true,
	squareImage?:true,
	menu?:ValueTypes["Menu"],
	containerType?:ValueTypes["ReusableContainerType"],
		__typename?: true
}>;
	["ItemCreateInput"]: {
	id?:string,
	name:string,
	description?:string,
	ingredients?:string,
	unitPrice:number,
	alcohol?:boolean,
	popular?:boolean,
	headerImageUpload?:ValueTypes["Upload"],
	sortOrder?:number,
	modifierIds:string[],
	approved?:boolean,
	available:boolean,
	published:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId:string,
	heat?:ValueTypes["ItemHeatType"],
	unavailableFor?:ValueTypes["FulfillmentMethod"][],
	containerTypeId?:string
};
	["ItemHeatType"]:ItemHeatType;
	["ItemList"]: AliasType<{
	items?:ValueTypes["Item"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ItemSales"]: AliasType<{
	item?:ValueTypes["Item"],
	numberSold?:true,
	totalValue?:true,
		__typename?: true
}>;
	["ItemUpdateAsRestaurantInput"]: {
	unitPrice?:number,
	alcohol?:boolean,
	popular?:boolean,
	sortOrder?:number,
	modifierIds?:string[],
	available?:boolean,
	published?:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId?:string,
	heat?:ValueTypes["ItemHeatType"],
	unavailableFor?:ValueTypes["FulfillmentMethod"][],
	containerTypeId?:string
};
	["ItemUpdateInput"]: {
	id?:string,
	name?:string,
	description?:string,
	ingredients?:string,
	unitPrice?:number,
	alcohol?:boolean,
	popular?:boolean,
	headerImageUpload?:ValueTypes["Upload"],
	sortOrder?:number,
	modifierIds?:string[],
	approved?:boolean,
	available?:boolean,
	published?:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId?:string,
	heat?:ValueTypes["ItemHeatType"],
	unavailableFor?:ValueTypes["FulfillmentMethod"][],
	containerTypeId?:string
};
	["ItemUpdateRequest"]: AliasType<{
	id?:true,
	created?:true,
	status?:true,
	name?:true,
	description?:true,
	imageKey?:true,
	item?:ValueTypes["Item"],
	partner?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["ItemUpdateRequestInput"]: {
	description?:string,
	name?:string,
	itemId:string,
	partnerId:string
};
	["ItemUpdateRequestList"]: AliasType<{
	items?:ValueTypes["ItemUpdateRequest"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ItemUpdateRequestStatus"]:ItemUpdateRequestStatus;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["Lease"]: AliasType<{
	id?:true,
	start?:true,
	end?:true,
	initialMileage?:true,
	weeklyMileageAllowance?:true,
	excessiveMileageCharge?:true,
	totalDeposit?:true,
	initialDeposit?:true,
	depositGraceWeeks?:true,
	numberOfDepositWeeks?:true,
	firstDaysFree?:true,
	pricePerDay?:true,
	vehicleId?:true,
	vehicle?:ValueTypes["LeaseVehicle"],
	riderId?:true,
	rider?:ValueTypes["Rider"],
		__typename?: true
}>;
	["LeaseList"]: AliasType<{
	items?:ValueTypes["LeaseView"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["LeasePayment"]: AliasType<{
	id?:true,
	created?:true,
	type?:true,
	dueAt?:true,
	paidAt?:true,
	leaseId?:true,
	lease?:ValueTypes["Lease"],
	value?:true,
	stripePaymentIntentId?:true,
	status?:true,
		__typename?: true
}>;
	["LeasePaymentStatus"]:LeasePaymentStatus;
	["LeaseVehicle"]: AliasType<{
	id?:true,
	created?:true,
	note?:true,
	registration?:true,
	status?:true,
	dvlaInfo?:true,
	leases?:ValueTypes["Lease"],
		__typename?: true
}>;
	["LeaseVehicleList"]: AliasType<{
	items?:ValueTypes["LeaseVehicle"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["LeaseVehicleStatus"]:LeaseVehicleStatus;
	["LeaseView"]: AliasType<{
	id?:true,
	start?:true,
	end?:true,
	initialMileage?:true,
	weeklyMileageAllowance?:true,
	excessiveMileageCharge?:true,
	totalDeposit?:true,
	initialDeposit?:true,
	depositGraceWeeks?:true,
	numberOfDepositWeeks?:true,
	firstDaysFree?:true,
	pricePerDay?:true,
	vehicleId?:true,
	vehicle?:ValueTypes["LeaseVehicle"],
	riderId?:true,
	rider?:ValueTypes["Rider"],
	totalPaid?:true,
	outstandingBalance?:true,
	outstandingDeposit?:true,
	nextDepositAmount?:true,
	nextDepositDate?:true,
	nextWeeklyAmount?:true,
	nextWeeklyDate?:true,
	lastDepositPayment?:true,
	lastWeeklyPayment?:true,
	payments?:ValueTypes["LeasePayment"],
		__typename?: true
}>;
	["LineChart"]: AliasType<{
	id?:true,
	type?:true,
	series?:ValueTypes["Series"],
		__typename?: true
}>;
	["LocationSearchResult"]: AliasType<{
	id?:true,
	text?:true,
	provider?:true,
	addressLine1?:true,
	postcode?:true,
	coordinates?:true,
		__typename?: true
}>;
	["LoginNumberPayload"]: {
	number:string,
	password:string,
	userType?:string
};
	["LoginOAuthProviders"]:LoginOAuthProviders;
	["LoginPayload"]: {
	email:string,
	password:string,
	userType?:string,
	twoFactorCode?:string
};
	["MarketingCampaign"]: AliasType<{
	id?:true,
	name?:true,
	status?:true,
	sends?:true,
	scheduledFor?:true,
		__typename?: true
}>;
	["MarketingCampaignList"]: AliasType<{
	items?:ValueTypes["MarketingCampaign"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["MarketingCampaignStatus"]:MarketingCampaignStatus;
	["Menu"]: AliasType<{
	id?:true,
	isTemplate?:true,
	name?:true,
	items?:ValueTypes["Item"],
	modifierGroups?:ValueTypes["ModifierGroup"],
	categories?:ValueTypes["MenuCategory"],
	restaurant?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	/** A menu category is a top-level listing of items that can be directly purchased */
["MenuCategory"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	sortOrder?:true,
	topLevel?:true,
	items?:ValueTypes["Item"],
	times?:ValueTypes["OpeningTime"],
	menu?:ValueTypes["Menu"],
		__typename?: true
}>;
	["MenuCategoryCreateInput"]: {
	id?:string,
	name:string,
	description?:string,
	sortOrder?:number,
	topLevel?:boolean,
	times?:ValueTypes["OpeningTimeInput"][]
};
	["MenuModifierGroupCreateInput"]: {
	id?:string,
	name:string,
	maxOptions:number,
	minOptions:number,
	maxPerOption?:number,
	instruction?:string,
	sortOrder?:number,
	optionIds:string[],
	priceStrategy:ValueTypes["PriceStrategy"],
	cascades:boolean,
	approved?:boolean
};
	["MerchantSite"]: AliasType<{
	id?:true,
	name?:true,
	description?:true,
	status?:true,
	domains?:ValueTypes["MerchantSiteDomain"],
	pages?:ValueTypes["MerchantSitePage"],
	screenshot?:ValueTypes["ImageAsset"],
		__typename?: true
}>;
	["MerchantSiteDomain"]: AliasType<{
	id?:true,
	createdAt?:true,
	domain?:true,
	isVerified?:true,
	dns?:true,
		__typename?: true
}>;
	["MerchantSiteList"]: AliasType<{
	items?:ValueTypes["MerchantSite"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["MerchantSitePage"]: AliasType<{
	id?:true,
	slug?:true,
	title?:true,
	layout?:true,
	screenshot?:ValueTypes["ImageAsset"],
	site?:ValueTypes["MerchantSite"],
		__typename?: true
}>;
	["MerchantSitePageCreateInput"]: {
	slug:string,
	title:string,
	layout:ValueTypes["JSON"],
	siteId:string
};
	["MerchantSitePageUpdateInput"]: {
	slug?:string,
	title?:string,
	layout?:string
};
	["MerchantSiteStatus"]:MerchantSiteStatus;
	["MessageParticipant"]: AliasType<{
	id?:true,
	type?:true,
	name?:true,
	email?:true,
	avatarUrl?:true,
		__typename?: true
}>;
	["ModifierGroup"]: AliasType<{
	id?:true,
	name?:true,
	instruction?:true,
	maxOptions?:true,
	minOptions?:true,
	/** Maximum selections of each individual option. */
	maxPerOption?:true,
	cascades?:true,
	sortOrder?:true,
	priceStrategy?:true,
	options?:ValueTypes["Item"],
	optionFor?:ValueTypes["Item"],
	menuId?:true,
		__typename?: true
}>;
	["Mutation"]: AliasType<{
submitDynamicForm?: [{	entityId?:string,	data:ValueTypes["JSON"],	id:string},ValueTypes["DynamicFormResult"]],
updateCustomer?: [{	customer:ValueTypes["CustomerUpdateInput"],	id:string},ValueTypes["Customer"]],
deleteCustomer?: [{	id:string},true],
addPaymentMethod?: [{	token:string},true],
addPaymentMethodWithCard?: [{	cardToken:string},true],
addVoucherCode?: [{	code:string},ValueTypes["Customer"]],
removePaymentMethod?: [{	id:string},true],
addAddress?: [{	customerId?:string,	address:ValueTypes["AddressCreateInput"]},ValueTypes["Address"]],
placeOrder?: [{	order:ValueTypes["OrderCreateInput"]},ValueTypes["PlaceOrderResponse"]],
updateOrder?: [{	data:ValueTypes["UpdateOrderInput"],	id:string},ValueTypes["Order"]],
rateOrder?: [{	rating:ValueTypes["OrderRatingInput"],	id:string},true],
OrderTipRider?: [{	amount:number,	id:string},ValueTypes["Order"]],
adjustOrder?: [{	data:ValueTypes["OrderAdjustInput"],	id:string},ValueTypes["Order"]],
acceptOrder?: [{	partnerId?:string,	id:string},ValueTypes["Order"]],
acceptOrderAsRestaurant?: [{	restaurantId?:string,	id:string},true],
completeOrderAsRestaurant?: [{	restaurantId?:string,	id:string},true],
rejectOrderAsRestaurant?: [{	rejectOrder:ValueTypes["OrderRejectionInput"]},true],
arriveForOwnOrder?: [{	id:string},true],
pickUpOwnOrder?: [{	id:string},true],
delayPickup?: [{	restaurantId?:string,	id:string},ValueTypes["Order"]],
blockPickup?: [{	id:string},ValueTypes["Order"]],
OrderRemoveRiderAsAdmin?: [{	riderId:string,	orderId:string},ValueTypes["Order"]],
OrderCompleteAsAdmin?: [{	id:string},ValueTypes["Order"]],
OrderBringForward?: [{	/** The number of minutes to bring forward the order by */
	amount?:number,	id:string},ValueTypes["Order"]],
rescheduleOrder?: [{	options:ValueTypes["OrderRescheduleInput"],	id:string},ValueTypes["Order"]],
resolveOrderAlert?: [{	id:string},ValueTypes["OrderAlert"]],
PurchaseWalletTopup?: [{	amount:number},ValueTypes["PurchaseCustomerWalletTopup"]],
setAvailability?: [{	available:boolean,	id:string},ValueTypes["Item"]],
setItemAvailability?: [{	restaurantId?:string,	published:boolean,	available:boolean,	id:string},ValueTypes["Item"]],
updateItemHeaderImage?: [{	image:ValueTypes["Upload"],	id:string},ValueTypes["Item"]],
removeItemHeaderImage?: [{	id:string},ValueTypes["Item"]],
updateItem?: [{	item:ValueTypes["ItemUpdateInput"],	id:string},ValueTypes["Item"]],
deleteItem?: [{	partnerId:string,	itemId:string},ValueTypes["Item"]],
updateItemAsRestaurant?: [{	item:ValueTypes["ItemUpdateAsRestaurantInput"],	restaurantId:string,	id:string},ValueTypes["Item"]],
basketApplyDiscount?: [{	input:ValueTypes["BasketAddDiscountInput"]},ValueTypes["Basket"]],
basketAddItem?: [{	input:ValueTypes["BasketAddItemInput"]},ValueTypes["Basket"]],
basketIncrementItem?: [{	input:ValueTypes["BasketIncrementDecrementItemInput"]},ValueTypes["Basket"]],
basketDecrementItem?: [{	input:ValueTypes["BasketIncrementDecrementItemInput"]},ValueTypes["Basket"]],
basketEditItem?: [{	input:ValueTypes["BasketEditItemInput"]},ValueTypes["Basket"]],
basketIncrementOrDecrementTip?: [{	increment:boolean,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
basketSetTip?: [{	tip:number,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
basketSetNotes?: [{	notes:string,	info:ValueTypes["BasketIdentifierInfo"]},true],
basketSetInstructions?: [{	instructions:string,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
basketCheckOut?: [{	input:ValueTypes["BasketCheckOutInput"]},ValueTypes["Basket"]],
basketSetSingleDelivery?: [{	singleDelivery:boolean,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
basketSetReusables?: [{	pickupTime?:ValueTypes["Date"],	enabled:boolean,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
basketDonationAdd?: [{	donationOptionId:string,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
basketDonationRemove?: [{	donationOptionId:string,	info:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
updateCharity?: [{	args:ValueTypes["CharityUpdateArgs"],	id:string},ValueTypes["Charity"]],
createCharity?: [{	args:ValueTypes["CharityCreateArgs"]},ValueTypes["Charity"]],
	customerCharityUIView?:ValueTypes["ConsumerCharityCampaignView"],
createCharityCampaign?: [{	args:ValueTypes["CharityCampaignCreateArgs"]},ValueTypes["CharityCampaign"]],
updateCharityCampaign?: [{	args:ValueTypes["CharityCampaignUpdateArgs"],	id:string},ValueTypes["CharityCampaign"]],
SwapContainerCode?: [{	to:string,	/** The code representing an existing container with a damaged label */
	from:string},ValueTypes["ReusableContainer"]],
ScanContainerAtPartner?: [{	image?:ValueTypes["Upload"],	shipmentId?:string,	partnerId:string,	/** The code from the container, in UUID or unparsed form */
	id:string},ValueTypes["ScanContainerAtPartnerResponse"]],
ScanContainer?: [{	id:string},ValueTypes["CustomerReusables"]],
CancelContainerPickup?: [{	id:string},ValueTypes["CustomerReusables"]],
UpdateContainerPickup?: [{	/** Add a picture to this pickup to help riders find the containers. */
	image?:ValueTypes["Upload"],	count:number,	addressId:string,	id:string},ValueTypes["ReusablesPickup"]],
BookContainerPickup?: [{	count:number,	addressId:string,	slot:string},ValueTypes["CustomerReusables"]],
AssignReusableShipment?: [{	washingLocationId:string,	id:string},ValueTypes["ReusableShipment"]],
CompleteReusableShipment?: [{	/** Place order even if shipment is not complete. */
	override?:boolean,	washingLocationId:string,	id:string},ValueTypes["ReusableShipment"]],
createRestaurant?: [{	restaurant:ValueTypes["RestaurantCreateInput"]},ValueTypes["Restaurant"]],
updateRestaurant?: [{	restaurant:ValueTypes["RestaurantUpdateInput"],	id:string},ValueTypes["Restaurant"]],
setFulfillmentMethods?: [{	fulfillmentMethods:ValueTypes["FulfillmentMethod"][],	restaurantId?:string},ValueTypes["Restaurant"]],
setAcceptsScheduledOrders?: [{	acceptsScheduledOrders:boolean,	restaurantId?:string},ValueTypes["Restaurant"]],
updateRestaurantHeaderImage?: [{	image:ValueTypes["Upload"],	id:string},ValueTypes["Restaurant"]],
setBusy?: [{	restaurantId?:string,	status:ValueTypes["RestaurantBusyStatus"]},ValueTypes["Restaurant"]],
setOnline?: [{	online:boolean},true],
setOwner?: [{	name:string,	email:string,	id:string},true],
markItemsUnavailable?: [{	items:ValueTypes["JSON"]},true],
createPartnerTag?: [{	tag:ValueTypes["PartnerTagInput"]},ValueTypes["PartnerTag"]],
updatePartnerTag?: [{	tag:ValueTypes["PartnerTagInput"],	id:string},ValueTypes["PartnerTag"]],
deletePartnerTag?: [{	id:string},true],
PartnerTabletSync?: [{	screenshot?:ValueTypes["Upload"],	partnerId?:string,	tablet:ValueTypes["PartnerTabletSyncArgs"]},ValueTypes["PartnerTablet"]],
PartnerTabletAction?: [{	payload?:ValueTypes["JSON"],	action:ValueTypes["PartnerTabletActionType"],	id:string},ValueTypes["PartnerTablet"]],
addItem?: [{	item:ValueTypes["ItemCreateInput"],	id:string},ValueTypes["Item"]],
addCategory?: [{	category:ValueTypes["MenuCategoryCreateInput"],	id:string},ValueTypes["Menu"]],
reorderCategory?: [{	itemIds:string[],	categoryId:string,	partnerId:string},ValueTypes["MenuCategory"]],
reorderModifierGroups?: [{	modifierGroupIds:string[],	partnerId:string},ValueTypes["Menu"]],
reorderCategories?: [{	categoryIds:string[],	partnerId:string},ValueTypes["Menu"]],
addModifierGroup?: [{	modifierGroup:ValueTypes["MenuModifierGroupCreateInput"],	id:string},ValueTypes["Menu"]],
importMenu?: [{	url:string,	id:string},true],
updateCategoryAsRestaurant?: [{	category:ValueTypes["UpdateMenuCategoryAsRestaurantInput"],	restaurantId:string,	id:string},ValueTypes["MenuCategory"]],
createItemUpdateRequest?: [{	image?:ValueTypes["Upload"],	item:ValueTypes["ItemUpdateRequestInput"]},ValueTypes["ItemUpdateRequest"]],
updateItemUpdateRequest?: [{	status:ValueTypes["ItemUpdateRequestStatus"],	id:string},ValueTypes["ItemUpdateRequest"]],
updateRestaurantAdmin?: [{	admin:ValueTypes["RestaurantAdminUpdateInput"],	id:string},ValueTypes["RestaurantAdmin"]],
createSuperAdmin?: [{	permissions?:ValueTypes["Permissions"][],	name?:string,	password:string,	email:string},ValueTypes["RestaurantAdmin"]],
updateTabletAccountPassword?: [{	password:string,	restaurantId:string},true],
createZone?: [{	zone:ValueTypes["ZoneCreateInput"]},ValueTypes["Zone"]],
boostZone?: [{	boost:ValueTypes["BoostInput"]},ValueTypes["Boost"]],
updateZone?: [{	zone:ValueTypes["ZoneUpdateInput"],	id:string},ValueTypes["Zone"]],
setActivePolygon?: [{	polygonId:string,	zoneId:string},ValueTypes["Zone"]],
endBoost?: [{	id:string},ValueTypes["Boost"]],
createZonePolygon?: [{	polygon:ValueTypes["ZonePolygonInput"]},ValueTypes["ZonePolygon"]],
createRider?: [{	rider:ValueTypes["RiderCreateInput"]},ValueTypes["Rider"]],
updateRiderLocation?: [{	rider:ValueTypes["RiderStatusUpdate"]},ValueTypes["Rider"]],
unthrottleRider?: [{	id:string},ValueTypes["Rider"]],
sendMessageToRider?: [{	message:string,	id:string},true],
updateRider?: [{	rider:ValueTypes["RiderUpdateInput"],	id:string},ValueTypes["Rider"]],
	requestNotification?:true,
endRiderOnlineSession?: [{	riderId:string},ValueTypes["RiderOnlineSession"]],
deleteRiderOnlineSession?: [{	id:string},true],
createRiderApplication?: [{	image:ValueTypes["Upload"],	documentType:ValueTypes["IdentityDocumentType"]},ValueTypes["RiderApplication"]],
deleteRiderApplication?: [{	id:string},true],
RiderLiveUIValidate?: [{	value:string,	validationId:string,	riderId:string},ValueTypes["RiderLiveView"]],
RiderLiveUIAction?: [{	orderId?:string,	action:ValueTypes["RiderUIActions"],	riderId:string},ValueTypes["RiderLiveView"]],
RiderLiveUISetting?: [{	value:ValueTypes["JSON"],	settingId:string,	riderId:string},ValueTypes["RiderLiveView"]],
PayLeasePayment?: [{	manual?:boolean,	id:string},ValueTypes["LeasePayment"]],
sendChatMessage?: [{	message:ValueTypes["ChatMessageInput"]},ValueTypes["ChatMessage"]],
login?: [{	login:ValueTypes["LoginPayload"]},ValueTypes["AuthDTO"]],
loginOAuth?: [{	/** Force authentication using this provider rather than reading the iss field. */
	provider?:ValueTypes["LoginOAuthProviders"],	code?:string,	token:string},ValueTypes["AuthDTO"]],
loginPhone?: [{	login:ValueTypes["LoginNumberPayload"]},ValueTypes["AuthDTO"]],
setupTwoFactorAuthentication?: [{	login:ValueTypes["LoginPayload"]},ValueTypes["TwoFactorSetup"]],
confirmTwoFactorAuthentication?: [{	token:string,	login:ValueTypes["LoginPayload"]},true],
refreshToken?: [{	refreshToken:string},ValueTypes["AuthDTO"]],
	logout?:true,
register?: [{	register:ValueTypes["RegisterPayload"]},ValueTypes["AuthDTO"]],
resetPasswordRequest?: [{	type:ValueTypes["UserType"],	email:string},true],
resetPasswordWithToken?: [{	password:string,	token:string},true],
requestPhoneNumberVerification?: [{	sessionId:string,	phoneNumber:string},true],
verifyPhoneNumberCode?: [{	code:string,	sessionId:string,	phoneNumber:string},true],
setFirebaseCloudMessagingToken?: [{	token:string},true],
	requestConfirmationEmail?:true,
confirmEmailWithToken?: [{	token:string},true],
UserUpdateProfilePicture?: [{	image?:ValueTypes["Upload"]},ValueTypes["User"]],
createDiscount?: [{	discount:ValueTypes["DiscountCreateInput"]},ValueTypes["Discount"]],
updateDiscount?: [{	discount:ValueTypes["DiscountUpdateInput"],	id:string},ValueTypes["Discount"]],
createRiderReferralDiscount?: [{	code:string,	riderId:string},ValueTypes["RiderReferralDiscount"]],
updateAddress?: [{	address:ValueTypes["AddressUpdateInput"],	id:string},ValueTypes["Address"]],
createAddress?: [{	address:ValueTypes["AddressCreateInput"]},ValueTypes["Address"]],
deleteAddress?: [{	id:string},true],
makeBooking?: [{	bookingSlotId:string},ValueTypes["Booking"]],
cancelBooking?: [{	id:string},ValueTypes["Booking"]],
updateBooking?: [{	updateArgs:ValueTypes["UpdateBookingArgs"],	bookingId:string},ValueTypes["Booking"]],
decideBooking?: [{	bookingId:string},ValueTypes["Booking"]],
createBookingSlot?: [{	slot:ValueTypes["BookingSlotInput"],	zoneId:string},ValueTypes["BookingSlot"]],
updateBookingSlot?: [{	slot:ValueTypes["UpdateBookingSlotArgs"],	id:string},ValueTypes["BookingSlot"]],
deleteBookingSlot?: [{	id:string},true],
	autoCreateBookingSlots?:true,
AutoBookingSlotsConfigList?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["AutoBookingSlotsConfigList"]],
createAutoBookingConfig?: [{	input:ValueTypes["AutoBookingSlotsConfigInput"]},ValueTypes["AutoBookingSlotsConfig"]],
deleteAutoBookingSlot?: [{	slotId:string,	configId:string},ValueTypes["AutoBookingSlotsConfig"]],
createAutoBookingSlot?: [{	input:ValueTypes["AutoBookingSlotInput"]},ValueTypes["AutoBookingSlot"]],
createCity?: [{	city:ValueTypes["CityCreateInput"]},ValueTypes["City"]],
exportOrders?: [{	restaurantId:string,	zoneIds?:string[],	restaurantIds?:string[],	startDate:ValueTypes["Date"],	endDate:ValueTypes["Date"]},true],
riderInvoice?: [{	id:string,	start:ValueTypes["Date"],	end:ValueTypes["Date"]},ValueTypes["RiderInvoice"]],
payRiderInvoice?: [{	manual?:boolean,	id:string},ValueTypes["RiderInvoice"]],
payRiderInvoices?: [{	end:ValueTypes["Date"],	start:ValueTypes["Date"]},ValueTypes["RiderInvoicesJob"]],
PartnerInvoiceForPeriod?: [{	partnerId:string,	start:ValueTypes["Date"],	end:ValueTypes["Date"]},ValueTypes["PartnerInvoice"]],
payPartnerInvoice?: [{	/** Has this invoice been paid by other means? */
	manual?:boolean,	id:string},ValueTypes["PartnerInvoice"]],
updatePartnerPaymentDetails?: [{	details:ValueTypes["UpdatePartnerPaymentDetailsInput"],	partnerId:string},ValueTypes["PartnerPaymentDetails"]],
createRiderAdjustment?: [{	adjustment:ValueTypes["RiderAdjustmentCreateInput"]},ValueTypes["RiderAdjustment"]],
updateRiderAdjustment?: [{	input:ValueTypes["RiderAdjustmentUpdateInput"],	id:string},ValueTypes["RiderAdjustment"]],
createPartnerAdjustment?: [{	adjustment:ValueTypes["PartnerAdjustmentCreateInput"]},ValueTypes["PartnerAdjustment"]],
updatePartnerAdjustment?: [{	input:ValueTypes["PartnerAdjustmentUpdateInput"],	id:string},ValueTypes["PartnerAdjustment"]],
uploadPromotionImage?: [{	image:ValueTypes["Upload"],	id:string},ValueTypes["Promotion"]],
createPromotion?: [{	promotion:ValueTypes["CreatePromotionInput"]},ValueTypes["Promotion"]],
updatePromotion?: [{	promotion:ValueTypes["UpdatePromotionInput"],	id:string},ValueTypes["Promotion"]],
estimateB2BJob?: [{	job:ValueTypes["DeliveryJobInput"]},ValueTypes["B2BJobEstimateResponse"]],
executeB2BJob?: [{	paymentMethodId:string,	id:string},ValueTypes["B2BDeliveryJob"]],
cancelB2BJob?: [{	id:string},ValueTypes["B2BDeliveryJob"]],
createIncident?: [{	incident:ValueTypes["CreateIncidentInput"]},ValueTypes["Incident"]],
updateIncident?: [{	incident:ValueTypes["UpdateIncidentInput"],	id:string},ValueTypes["Incident"]],
uploadImageAsset?: [{	title?:string,	altText?:string,	image:ValueTypes["Upload"]},ValueTypes["ImageAsset"]],
UploadVideoAsset?: [{	title?:string,	altText?:string,	mimeType:string,	size:number},ValueTypes["UploadMediaJob"]],
UploadMediaComplete?: [{	parts:ValueTypes["UploadMediaPart"][],	key:string,	uploadId:string},ValueTypes["VideoAsset"]],
AdminPageView?: [{	url:string},ValueTypes["AdminPageView"]],
createMerchantSitePage?: [{	page:ValueTypes["MerchantSitePageCreateInput"]},ValueTypes["MerchantSitePage"]],
updateMerchantSitePage?: [{	page:ValueTypes["MerchantSitePageUpdateInput"],	id:string},ValueTypes["MerchantSitePage"]],
createMerchantSiteDomain?: [{	domain:string,	siteId:string},ValueTypes["MerchantSiteDomain"]],
		__typename?: true
}>;
	["OAuthConfiguration"]: AliasType<{
	apple?:ValueTypes["OAuthConfigurationApple"],
	google?:ValueTypes["OAuthConfigurationGoogle"],
	facebook?:ValueTypes["OAuthConfigurationFacebook"],
		__typename?: true
}>;
	["OAuthConfigurationApple"]: AliasType<{
	clientId?:true,
	redirectURI?:true,
		__typename?: true
}>;
	["OAuthConfigurationFacebook"]: AliasType<{
	appId?:true,
		__typename?: true
}>;
	["OAuthConfigurationGoogle"]: AliasType<{
	clientId?:true,
	redirectURI?:true,
		__typename?: true
}>;
	["OpeningTime"]: AliasType<{
	id?:true,
	open?:true,
	close?:true,
	daysOfWeek?:true,
	restaurant?:ValueTypes["Restaurant"],
	menuCategory?:ValueTypes["MenuCategory"],
	zone?:ValueTypes["Zone"],
		__typename?: true
}>;
	["OpeningTimeInput"]: {
	id?:string,
	open:string,
	close:string,
	daysOfWeek?:string[]
};
	["Order"]: AliasType<{
	id?:true,
	/** A string to indentify the order at the restaurant */
	number?:true,
	fulfilledBy?:true,
	placedAt?:true,
	partnerAcknowlegedAt?:true,
	scheduledFor?:true,
	initialPickupAt?:true,
	pickupAt?:true,
	pickupDelayedByPartner?:true,
	pickedDelayedByAdminAt?:true,
	orderMarkedReadyAt?:true,
	status?:true,
	/** Whether this order has been viewed by the restaurant */
	opened?:true,
	allergyNotes?:true,
	internalNote?:true,
	riderEarnings?:true,
	baseRiderEarnings?:true,
	deliveryEstimate?:true,
	deliveryEstimateMax?:true,
	estimatedArrivalTime?:true,
	estimatedArrivalTimeDelta?:true,
	stripeFee?:true,
	fulfillmentMethod?:true,
	ourAdjustment?:true,
	ourAdjustmentJustification?:true,
	partnerAdjustment?:true,
	partnerAdjustmentJustification?:true,
	riderEarningsAdjustment?:true,
	riderEarningsAdjustmentJustification?:true,
	riderTipAdjustment?:true,
	riderTipAdjustmentJustification?:true,
	orderType?:true,
	/** The number of size points the items of this order sum to */
	sizePoints?:true,
	/** Number of child orders needed to complete this order */
	numberOfChildOrders?:true,
	/** The child orders for this object. Only populated when isSplit = true and orderType = PARENT */
	childOrders?:ValueTypes["Order"],
	/** The parent order for this order. Only populated when isSplit = true and orderType = CHILD */
	parentOrderId?:true,
	/** The parent order for this order. Only populated when isSplit = true and orderType = CHILD */
	parentOrder?:ValueTypes["Order"],
	customerLastRemindedToPickUp?:true,
	isBeingOfferedToRider?:true,
	lastOfferedToRider?:true,
	isRiderAtRestaurant?:true,
	riderArrivedAtRestaurantTime?:true,
	riderPickedUpTime?:true,
	riderArrivedAtCustomerTime?:true,
	riderDroppedOffTime?:true,
	pickupBlocked?:true,
	longWaitReportedAt?:true,
	riderRating?:true,
	partnerRating?:true,
	amountRefunded?:true,
	cancellationReason?:true,
	basket?:ValueTypes["Basket"],
	customer?:ValueTypes["Customer"],
	rider?:ValueTypes["Rider"],
	addressId?:true,
	address?:ValueTypes["Address"],
	restaurant?:ValueTypes["Restaurant"],
	offers?:ValueTypes["OrderOffer"],
	b2bDeliveryJob?:ValueTypes["B2BDeliveryJob"],
	b2bDeliveryJobId?:true,
	aggregateOrder?:ValueTypes["AggregateOrder"],
	unreadMessageCount?:true,
	canTipRider?:true,
	/** Information about the reusables used for this order. */
	reusables?:ValueTypes["ReusablesInfo"],
	/** A polyline representing the directions that either the rider or customer needs to follow. 
      This is not to be used for displaying to riders in app, but rather as a decorative element 
      to show customers when tracking the order. */
	directions?:true,
	reusablesPickupAggregate?:ValueTypes["ReusablesPickupAggregate"],
	riderReport?:ValueTypes["OrderRiderRating"],
	reusableShipment?:ValueTypes["ReusableShipment"],
		__typename?: true
}>;
	["OrderAdjustInput"]: {
	ourAdjustment:number,
	partnerAdjustment:number,
	riderEarningsAdjustment:number,
	riderTipAdjustment:number,
	ourAdjustmentJustification?:string,
	partnerAdjustmentJustification?:string,
	riderEarningsAdjustmentJustification?:string,
	riderTipAdjustmentJustification?:string
};
	["OrderAlert"]: AliasType<{
	id?:true,
	type?:true,
	created?:true,
	resolved?:true,
	resolvedBy?:ValueTypes["RestaurantAdmin"],
	riderId?:true,
	rider?:ValueTypes["Rider"],
	orderId?:true,
	order?:ValueTypes["Order"],
	zoneId?:true,
	zone?:ValueTypes["Zone"],
		__typename?: true
}>;
	["OrderAlertList"]: AliasType<{
	items?:ValueTypes["OrderAlert"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["OrderAlertType"]:OrderAlertType;
	["OrderCancellationReason"]:OrderCancellationReason;
	["OrderCreateInput"]: {
	/** The stripe payment method */
	paymentMethodId?:string,
	/** A tokenised payment method */
	paymentMethodToken?:string,
	/** The basket for the order */
	basketId:string
};
	["OrderEvent"]: AliasType<{
	id?:true,
	timestamp?:true,
	targetId?:true,
	from?:true,
	to?:true,
		__typename?: true
}>;
	["OrderEventList"]: AliasType<{
	items?:ValueTypes["OrderEvent"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["OrderFulfilledBy"]:OrderFulfilledBy;
	["Ordering"]:Ordering;
	/** An item in an order or a basket */
["OrderItem"]: AliasType<{
	/** A descriptor unique to this order item */
	id?:true,
	added?:true,
	/** Fields copied from the item, */
	name?:true,
	/** Fields copied from the item, */
	description?:true,
	quantity?:true,
	/** Unit price of of OrderItem including modifiers */
	unitPrice?:true,
	/** Unit weight of the menu item */
	unitWeight?:true,
	/** Total price including modifiers and quantity */
	totalPrice?:true,
	alcohol?:true,
	modifierItems?:ValueTypes["OrderItemModifier"],
	menuItemId?:true,
	basketId?:true,
	basket?:ValueTypes["Basket"],
		__typename?: true
}>;
	/** A modifier for an item in an order or basket. This should be flattend,
regardless of modifier groups appearing in the actual menu display */
["OrderItemModifier"]: AliasType<{
	id?:true,
	name?:true,
	quantity?:true,
	unitPrice?:true,
	/** Unit weight of the menu item */
	unitWeight?:true,
	menuItemId?:true,
	modifierGroupId?:true,
	/** The menu modifier group for which this is an order entry */
	modifierGroup?:ValueTypes["ModifierGroup"],
	orderItemId?:true,
	/** The parent OrderItem for which this is a modifier item of a modifier group */
	orderItem?:ValueTypes["OrderItem"],
		__typename?: true
}>;
	["OrderList"]: AliasType<{
	items?:ValueTypes["Order"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["OrderOffer"]: AliasType<{
	id?:true,
	offered?:true,
	changed?:true,
	outcome?:true,
	distance?:true,
	riderId?:true,
	rider?:ValueTypes["Rider"],
	orderId?:true,
	order?:ValueTypes["Order"],
		__typename?: true
}>;
	["OrderRatingInput"]: {
	riderRating:number,
	partnerRating:number
};
	["OrderRejectionInput"]: {
	restaurantId?:string,
	orderId:string,
	/** Items that are in the order but no longer in stock */
	outOfStockItems?:string[],
	reason?:ValueTypes["OrderCancellationReason"]
};
	["OrderRescheduleInput"]: {
	skipPrepTime:boolean,
	split:boolean,
	isPartnerResponsible:boolean,
	includeItems:string[]
};
	["OrderRiderRating"]: AliasType<{
	positive?:true,
	ratings?:true,
	note?:true,
	id?:true,
	order?:ValueTypes["Order"],
	rider?:ValueTypes["Rider"],
		__typename?: true
}>;
	["OrderRiderRatingList"]: AliasType<{
	items?:ValueTypes["OrderRiderRating"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["OrderRiderRatingType"]:OrderRiderRatingType;
	["OrderStatus"]:OrderStatus;
	["OrderType"]:OrderType;
	["PartnerAdjustment"]: AliasType<{
	id?:true,
	created?:true,
	forDate?:true,
	amount?:true,
	explanation?:true,
	partnerId?:true,
	partner?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["PartnerAdjustmentCreateInput"]: {
	forDate:ValueTypes["Date"],
	amount:number,
	explanation:string,
	partnerId:string
};
	["PartnerAdjustmentList"]: AliasType<{
	items?:ValueTypes["PartnerAdjustment"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["PartnerAdjustmentUpdateInput"]: {
	forDate?:ValueTypes["Date"],
	amount?:number,
	explanation?:string,
	partnerId?:string
};
	["PartnerAnalytics"]: AliasType<{
	id?:true,
	meta?:ValueTypes["PartnerAnalyticsMeta"],
	orderCount?:true,
	prepTimes?:ValueTypes["PartnerPrepTimeAnalytics"],
	waitTimes?:ValueTypes["PercentileValue"],
	layoutGroups?:ValueTypes["ChartLayoutGroup"],
		__typename?: true
}>;
	["PartnerAnalyticsMeta"]: AliasType<{
	id?:true,
	startDate?:true,
	dayOfWeek?:true,
	endDate?:true,
	timeRange?:true,
		__typename?: true
}>;
	["PartnerCommissionPlan"]: AliasType<{
	id?:true,
	created?:true,
	ended?:true,
	plan?:ValueTypes["CommissionPlan"],
	rung?:ValueTypes["CommissionPlanRung"],
	partner?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["PartnerHomePageItemGroup"]: AliasType<{
	id?:true,
	title?:true,
	items?:ValueTypes["Item"],
		__typename?: true
}>;
	["PartnerHomePageMeta"]: AliasType<{
	skip?:true,
	take?:true,
	lat?:true,
	lng?:true,
	zoneId?:true,
	zoneSlug?:true,
	/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:true,
	/** An optional query field to allow for user searches */
	query?:true,
	/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:true,
	tags?:true,
	sortBy?:true,
	order?:true,
	coreConfiguration?:true,
	time?:ValueTypes["DeliveryTimeArgs"],
		__typename?: true
}>;
	["PartnerInvoice"]: AliasType<{
	id?:true,
	/** Whether this invoice has been paid */
	paid?:true,
	value?:true,
	start?:true,
	end?:true,
	numberOfOrders?:true,
	sales?:true,
	adjustments?:true,
	commission?:true,
	purchases?:true,
	vat?:true,
	ref?:true,
	revolutTransactionId?:true,
	revolutTransactionLink?:true,
	partnerId?:true,
	partner?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["PartnerInvoiceList"]: AliasType<{
	items?:ValueTypes["PartnerInvoice"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["PartnerPaymentDetails"]: AliasType<{
	id?:true,
	accountNo?:true,
	sortCode?:true,
	isCompany?:true,
	individualName?:true,
	restaurant?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["PartnerPrepTimeAnalytics"]: AliasType<{
	percentile?:true,
	value?:true,
		__typename?: true
}>;
	["PartnerSelection"]: AliasType<{
	name?:true,
	partners?:ValueTypes["Restaurant"],
	type?:true,
		__typename?: true
}>;
	["PartnerSelectionType"]:PartnerSelectionType;
	["PartnerTablet"]: AliasType<{
	id?:true,
	created?:true,
	lastConnection?:true,
	serial?:true,
	model?:true,
	manufacturer?:true,
	battery?:true,
	release?:true,
	batteryState?:true,
	ram?:true,
	usedRam?:true,
	volume?:true,
	wifiNetwork?:true,
	bssid?:true,
	routerMake?:true,
	wifiStrength?:true,
	width?:true,
	height?:true,
	ip?:true,
	hostname?:true,
	isp?:true,
	location?:ValueTypes["GraphQLGeoJSONPoint"],
	partnerId?:true,
	partner?:ValueTypes["Restaurant"],
	webRTCOfferDescription?:true,
	webRTCOfferReceiveDescription?:true,
	webRTCOfferReceiveCandidates?:true,
	webRTCOfferCandidates?:true,
	screenshots?:ValueTypes["ImageAsset"],
		__typename?: true
}>;
	["PartnerTabletAction"]: AliasType<{
	id?:true,
	tabletId?:true,
	payload?:true,
		__typename?: true
}>;
	["PartnerTabletActionType"]:PartnerTabletActionType;
	["PartnerTabletList"]: AliasType<{
	items?:ValueTypes["PartnerTablet"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["PartnerTabletSyncArgs"]: {
	serial:string,
	model:string,
	manufacturer:string,
	battery?:number,
	release?:string,
	batteryState?:string,
	ram?:number,
	usedRam?:number,
	volume?:number,
	wifiNetwork?:string,
	bssid?:string,
	routerMake?:string,
	wifiStrength?:number,
	width?:number,
	height?:number,
	ip?:string,
	hostname?:string,
	isp?:string,
	location?:ValueTypes["GraphQLGeoJSONPointI"],
	webRTCOfferDescription?:ValueTypes["JSON"],
	webRTCOfferReceiveDescription?:ValueTypes["JSON"],
	webRTCOfferReceiveCandidates?:ValueTypes["JSON"],
	webRTCOfferCandidates?:ValueTypes["JSON"]
};
	/** A tag that describes something this partner offers, like Pizza, or Flowers. */
["PartnerTag"]: AliasType<{
	id?:true,
	name?:true,
	image?:true,
	type?:true,
	partners?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["PartnerTagInput"]: {
	name:string,
	image:string,
	type:ValueTypes["PartnerTagTypes"]
};
	/** Meta-type of a tag */
["PartnerTagTypes"]:PartnerTagTypes;
	["PaymentMethod"]: AliasType<{
	id?:true,
	stripeId?:true,
	last4?:true,
	brand?:true,
	exp_month?:true,
	exp_year?:true,
		__typename?: true
}>;
	["PercentileValue"]: AliasType<{
	percentile?:true,
	value?:true,
		__typename?: true
}>;
	["Permissions"]:Permissions;
	["PieChart"]: AliasType<{
	id?:true,
	type?:true,
	labels?:true,
	values?:true,
		__typename?: true
}>;
	["PlaceOrderResponse"]: AliasType<{
	id?:true,
	stripeNextAction?:true,
	stripeClientSecret?:true,
	order?:ValueTypes["Order"],
		__typename?: true
}>;
	["PresignedUploadURL"]: AliasType<{
	url?:true,
	method?:true,
	startBytes?:true,
	endBytes?:true,
		__typename?: true
}>;
	["Price"]: AliasType<{
	value?:true,
	display?:true,
		__typename?: true
}>;
	/** How to modify price based on selections */
["PriceStrategy"]:PriceStrategy;
	["Promotion"]: AliasType<{
	id?:true,
	created?:true,
	modified?:true,
	start?:true,
	end?:true,
	name?:true,
	title?:true,
	body?:true,
	color?:true,
	imageKey?:true,
	zones?:ValueTypes["Zone"],
	callToActionText?:true,
	enabled?:true,
	targetType?:true,
	target?:true,
		__typename?: true
}>;
	["PromotionList"]: AliasType<{
	items?:ValueTypes["Promotion"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["PromotionTarget"]:PromotionTarget;
	["PurchaseCustomerWalletTopup"]: AliasType<{
	id?:true,
	amount?:ValueTypes["Price"],
	paymentIntentId?:true,
	paymentIntentSecret?:true,
		__typename?: true
}>;
	["Query"]: AliasType<{
	Configuration?:ValueTypes["Configuration"],
DynamicForm?: [{	entityId?:string,	id:string},ValueTypes["DynamicForm"]],
CustomerList?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["CustomerList"]],
Customer?: [{	id:string},ValueTypes["Customer"]],
	stripeTokens?:ValueTypes["StripeTokens"],
order?: [{	isBasket?:boolean,	id:string},ValueTypes["Order"]],
OrderList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	customerId?:string,	status?:ValueTypes["OrderStatus"][],	fulfillmentMethods?:ValueTypes["FulfillmentMethod"][],	zoneId?:string,	restaurantId?:string,	riderId?:string,	start?:ValueTypes["Date"],	end?:ValueTypes["Date"],	/** Whether to collapse aggregate orders or not. If set to true, collapses
related aggregate orders only returning one of the set. */
	collapseAggregateOrders?:boolean},ValueTypes["OrderList"]],
FlightPlanOfferList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneId?:string,	orderId?:string,	riderId?:string,	includeCompleted:boolean},ValueTypes["FlightPlanOfferList"]],
OrderAlertList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	includeResolved:boolean,	zoneIds?:string[],	orderId?:string,	riderId?:string},ValueTypes["OrderAlertList"]],
OrderAlert?: [{	id:string},ValueTypes["OrderAlert"]],
OrderRiderRatingList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	ratings?:ValueTypes["OrderRiderRatingType"][]},ValueTypes["OrderRiderRatingList"]],
OrderEventList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	orderId?:string},ValueTypes["OrderEventList"]],
CustomerWalletTopupList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	customerId?:string},ValueTypes["CustomerWalletTopupList"]],
Item?: [{	id:string},ValueTypes["Item"]],
ItemList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	partnerId?:string,	approved?:boolean},ValueTypes["ItemList"]],
BasketList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneIds?:string[],	modifiedInLast?:number,	complete?:boolean},ValueTypes["BasketList"]],
Basket?: [{	id:string},ValueTypes["Basket"]],
basketForRestaurant?: [{	input:ValueTypes["BasketIdentifierInfo"]},ValueTypes["Basket"]],
Charity?: [{	id:string},ValueTypes["Charity"]],
CharityList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["CharityList"]],
	activeCharityCampaigns?:ValueTypes["CharityCampaign"],
CharityCampaign?: [{	id:string},ValueTypes["CharityCampaign"]],
CharityCampaignList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	charityId?:string,	minimumProgress?:number},ValueTypes["CharityCampaignList"]],
ConsumerCharityCampaign?: [{	id:string},ValueTypes["ConsumerCharityCampaignView"]],
ReusableDropBox?: [{	id:string},ValueTypes["ReusableDropBox"]],
ReusableDropBoxList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	coordinates?:ValueTypes["Coordinates"]},ValueTypes["ReusableDropBoxList"]],
ReusableContainer?: [{	id:string},ValueTypes["ReusableContainer"]],
ReusableContainerList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["ReusableContainerList"]],
ReusableContainerType?: [{	id:string},ValueTypes["ReusableContainerType"]],
ReusableContainerTypeList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["ReusableContainerTypeList"]],
ScannedContainerList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	washingLocationId?:string,	shipmentId?:string,	pickupId?:string,	customerId?:string,	containerId?:string},ValueTypes["ScannedContainerList"]],
WashingLocation?: [{	id:string},ValueTypes["WashingLocation"]],
WashingLocationList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	coordinates?:ValueTypes["Coordinates"]},ValueTypes["WashingLocationList"]],
ReusablesPickup?: [{	id:string},ValueTypes["ReusablesPickup"]],
ReusablesPickupList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	customerId?:string,	aggregateId?:string},ValueTypes["ReusablesPickupList"]],
CustomerReusables?: [{	id?:string},ValueTypes["CustomerReusables"]],
ReusableShipment?: [{	id:string},ValueTypes["ReusableShipment"]],
ReusableShipmentList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	partnerId?:string,	washingLocationId?:string},ValueTypes["ReusableShipmentList"]],
ReusablesPickupAggregate?: [{	id:string},ValueTypes["ReusablesPickupAggregate"]],
ReusablesPickupAggregateList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["ReusablesPickupAggregateList"]],
Restaurant?: [{	slug?:string,	id?:string},ValueTypes["Restaurant"]],
allRestaurants?: [{	skip?:number,	take?:number,	lat?:number,	lng?:number,	zoneId?:string,	zoneSlug?:string,	/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],	/** An optional query field to allow for user searches */
	query?:string,	/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:boolean,	tags?:string[],	sortBy?:string,	order?:string,	coreConfiguration?:ValueTypes["CoreConfigurations"]},ValueTypes["Restaurant"]],
RestaurantList?: [{	skip?:number,	take?:number,	lat?:number,	lng?:number,	zoneId?:string,	zoneSlug?:string,	/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],	/** An optional query field to allow for user searches */
	query?:string,	/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:boolean,	tags?:string[],	sortBy?:string,	order?:string,	coreConfiguration?:ValueTypes["CoreConfigurations"]},ValueTypes["RestaurantList"]],
restaurantHeartbeat?: [{	restaurantId?:string,	online?:boolean},true],
activeOrders?: [{	online?:boolean},ValueTypes["Order"]],
PartnerTag?: [{	id:string},ValueTypes["PartnerTag"]],
allPartnerTags?: [{	skip?:number,	take?:number},ValueTypes["PartnerTag"]],
PartnerTablet?: [{	id:string},ValueTypes["PartnerTablet"]],
PartnerTabletList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	partnerId?:string},ValueTypes["PartnerTabletList"]],
ConsumerSearch?: [{	location:ValueTypes["UnifiedLocationInput"],	query:string},ValueTypes["ConsumerSearchResult"]],
Menu?: [{	id:string},ValueTypes["Menu"]],
allMenus?: [{	skip?:number,	take?:number},ValueTypes["Menu"]],
ItemUpdateRequest?: [{	id:string},ValueTypes["ItemUpdateRequest"]],
ItemUpdateRequestList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	partnerId?:string,	itemId?:string,	statuses:ValueTypes["ItemUpdateRequestStatus"][]},ValueTypes["ItemUpdateRequestList"]],
RestaurantAdminList?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["RestaurantAdminList"]],
RestaurantAdmin?: [{	id:string},ValueTypes["RestaurantAdmin"]],
tabletForRestaurant?: [{	restaurantId:string},ValueTypes["RestaurantAdmin"]],
ZoneList?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["ZoneList"]],
zone?: [{	slug?:string,	id?:string},ValueTypes["Zone"]],
zones?: [{	skip?:number,	take?:number,	onboardingStatus?:ValueTypes["ZoneOnboardingStatus"][]},ValueTypes["Zone"]],
zoneAtPoint?: [{	lat:number,	lng:number},ValueTypes["Zone"]],
	/** Upcoming boosts for riders to view in their planner */
	UpcomingBoosts?:ValueTypes["Boost"],
ZoneEventList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneId:string,	fields?:string[]},ValueTypes["ZoneEventList"]],
ZonePolygonList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneId?:string},ValueTypes["ZonePolygonList"]],
CommissionPlanList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["CommissionPlanList"]],
CommissionPlan?: [{	id:string},ValueTypes["CommissionPlan"]],
Brand?: [{	id:string},ValueTypes["Brand"]],
BrandList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["BrandList"]],
PartnerHomepage?: [{	day:ValueTypes["DeliveryTimeDay"],	time:ValueTypes["HourMinuteTimeString"],	skip?:number,	take?:number,	lat?:number,	lng?:number,	zoneId?:string,	zoneSlug?:string,	/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],	/** An optional query field to allow for user searches */
	query?:string,	/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:boolean,	tags?:string[],	sortBy?:string,	order?:string,	coreConfiguration?:ValueTypes["CoreConfigurations"]},ValueTypes["HomePage"]],
Rider?: [{	id:string},ValueTypes["Rider"]],
RiderList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneId?:string,	activationStatus?:ValueTypes["RiderActivationStatus"],	online?:boolean},ValueTypes["RiderList"]],
validateVehicleRegistration?: [{	reg:string},ValueTypes["ValidateRegistrationResult"]],
riderOnlineSession?: [{	id:string},ValueTypes["RiderOnlineSession"]],
RiderOnlineSessionList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	riderId?:string},ValueTypes["RiderOnlineSessionList"]],
RiderLiveUI?: [{	riderId?:string},ValueTypes["RiderLiveView"]],
RiderLiveRoute?: [{	riderId:string},ValueTypes["RiderLiveRoute"]],
Lease?: [{	id:string},ValueTypes["LeaseView"]],
LeaseList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	riderId?:string,	vehicleId?:string,	active?:boolean},ValueTypes["LeaseList"]],
LeaseVehicle?: [{	id:string},ValueTypes["LeaseVehicle"]],
LeaseVehicleList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["LeaseVehicleList"]],
ChatMessageList?: [{	skip?:number,	take?:number,	orderId:string,	/** Are you reading these messages? */
	isReading?:boolean},ValueTypes["ChatMessageList"]],
	OAuthConfiguration?:ValueTypes["OAuthConfiguration"],
	me?:ValueTypes["UserUnionDTO"],
Discount?: [{	id:string},ValueTypes["Discount"]],
DiscountList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	validFrom:ValueTypes["Date"],	validUntil:ValueTypes["Date"]},ValueTypes["DiscountList"]],
RiderReferralDiscount?: [{	id:string},ValueTypes["RiderReferralDiscount"]],
RiderReferralDiscountList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	riderId?:string,	discountId?:string,	validFrom?:ValueTypes["Date"],	validUntil?:ValueTypes["Date"]},ValueTypes["RiderReferralDiscountList"]],
Address?: [{	id:string},ValueTypes["Address"]],
AddressList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["AddressList"]],
	allAddresses?:ValueTypes["Address"],
LocationSearch?: [{	/** A location hint, usually the users current location, that helps narrow down the results. */
	location?:ValueTypes["UnifiedLocationInput"],	query:string},ValueTypes["LocationSearchResult"]],
LocationSearchById?: [{	provider:string,	id:string},ValueTypes["LocationSearchResult"]],
BookingMetricsRank?: [{	riderId:string},true],
BookingSlot?: [{	id:string},ValueTypes["BookingSlot"]],
BookingSlotList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneId?:string,	riderId?:string,	start?:ValueTypes["Date"]},ValueTypes["BookingSlotList"]],
BookingSlotsAvailableForRider?: [{	riderId?:string,	zoneId:string,	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["BookingSlotRiderViewList"]],
countBookingsForSlot?: [{	id:string},true],
AutoBookingSlotConfig?: [{	id:string},ValueTypes["AutoBookingSlotsConfig"]],
allAnalytics?: [{	start:ValueTypes["Date"],	end:ValueTypes["Date"],	restaurantId?:string},ValueTypes["AnalyticsDTO"]],
PartnerAnalytics?: [{	id:string,	startDate:ValueTypes["Date"],	dayOfWeek?:number,	endDate:ValueTypes["Date"],	timeRange?:string[]},ValueTypes["PartnerAnalytics"]],
CustomerAnalytics?: [{	customerId:string},ValueTypes["CustomerAnalytics"]],
city?: [{	id:string},ValueTypes["City"]],
allCities?: [{	skip?:number,	take?:number},ValueTypes["City"]],
	Team?:ValueTypes["TeamMember"],
financialsForRestaurants?: [{	startDate:ValueTypes["Date"],	endDate:ValueTypes["Date"]},ValueTypes["RestaurantFinancialsView"]],
financialsForRestaurant?: [{	restaurantId:string,	zoneIds?:string[],	restaurantIds?:string[],	startDate:ValueTypes["Date"],	endDate:ValueTypes["Date"]},ValueTypes["RestaurantFinancialsView"]],
salesForRestaurant?: [{	restaurantId:string,	zoneIds?:string[],	restaurantIds?:string[],	startDate:ValueTypes["Date"],	endDate:ValueTypes["Date"]},ValueTypes["ItemSales"]],
financialsForRider?: [{	startDate:ValueTypes["Date"],	endDate:ValueTypes["Date"],	riderId?:string},ValueTypes["SpecificRiderFinancials"]],
RiderInvoice?: [{	id:string},ValueTypes["RiderInvoice"]],
allRiderInvoices?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	riderId?:string,	paid?:boolean,	startDate?:ValueTypes["Date"],	endDate?:ValueTypes["Date"]},ValueTypes["RiderInvoiceList"]],
PartnerInvoice?: [{	id:string},ValueTypes["PartnerInvoice"]],
downloadPartnerInvoice?: [{	id:string},true],
allPartnerInvoices?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	partnerId?:string,	paid?:boolean,	startDate?:ValueTypes["Date"],	endDate?:ValueTypes["Date"]},ValueTypes["PartnerInvoiceList"]],
PartnerPaymentDetails?: [{	partnerId:string},ValueTypes["PartnerPaymentDetails"]],
RiderAdjustment?: [{	id:string},ValueTypes["RiderAdjustment"]],
RiderAdjustmentList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	startDate?:ValueTypes["Date"],	riderId?:string},ValueTypes["RiderAdjustmentList"]],
PartnerAdjustment?: [{	id:string},ValueTypes["PartnerAdjustment"]],
PartnerAdjustmentList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	startDate?:ValueTypes["Date"],	partnerId?:string},ValueTypes["PartnerAdjustmentList"]],
FinancialOverview?: [{	startDate:ValueTypes["Date"],	endDate:ValueTypes["Date"],	zoneIds?:string[]},ValueTypes["FinancialOverview"]],
PromotionList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	zoneId?:string,	start:ValueTypes["Date"]},ValueTypes["PromotionList"]],
Promotion?: [{	id:string},ValueTypes["Promotion"]],
B2BDeliveryJob?: [{	id:string},ValueTypes["B2BDeliveryJob"]],
B2BDeliveryJobList?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["B2BDeliveryJobList"]],
IncidentList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	orderId?:string,	riderId?:string,	customerId?:string,	partnerId?:string},ValueTypes["IncidentList"]],
Incident?: [{	id:string},ValueTypes["Incident"]],
ImageAsset?: [{	id:string},ValueTypes["ImageAsset"]],
ImageAssetList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["ImageAssetList"]],
VideoAsset?: [{	id:string},ValueTypes["VideoAsset"]],
VideoAssetList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number},ValueTypes["VideoAssetList"]],
MarketingCampaignList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	startDate:ValueTypes["Date"]},ValueTypes["MarketingCampaignList"]],
MerchantSite?: [{	domain?:string,	id?:string},ValueTypes["MerchantSite"]],
MerchantSiteList?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	partnerId?:string,	ownerId?:string},ValueTypes["MerchantSiteList"]],
MerchantSitePage?: [{	url?:string,	id?:string},ValueTypes["MerchantSitePage"]],
FileList?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["FileList"]],
		__typename?: true
}>;
	["RegisterPayload"]: {
	email:string,
	name:string,
	password:string,
	phoneNumber?:string,
	marketing:boolean
};
	["Restaurant"]: AliasType<{
	id?:true,
	configuration?:true,
	name?:true,
	slug?:true,
	branch?:true,
	priceCategory?:true,
	blurhash?:true,
	headerImageKey?:true,
	ratingStars?:true,
	ratingCount?:true,
	phoneNumber?:true,
	allergyWarnings?:true,
	acceptsAllergyNotes?:true,
	autoAccept?:true,
	commission?:true,
	commissionCollection?:true,
	onboardingStatus?:true,
	fulfillmentMethods?:true,
	busyStatus?:true,
	online?:true,
	baseDeliveryTime?:true,
	basePrepOrderThreshold?:true,
	basePrepTime?:true,
	basePrepTimeDelta?:true,
	/** The amount to increase prep time by when restaurant is busy. */
	incrementPrepTime?:true,
	currentPrepTime?:true,
	/** Whether this partner accepts pre-orders (ie before they are open) */
	acceptsPreOrders?:true,
	/** Whether this partner accepts scheduled orders (ie when they are already open) */
	acceptsScheduledOrders?:true,
	deliveryTimes?:ValueTypes["FulfillmentDayTimes"],
	largeOrderSize?:true,
	minimumOrderSize?:true,
	minimumOrderValue?:true,
	deliveryTippingEnabled?:true,
	lastOnline?:true,
	minimumBasket?:true,
	assignmentBias?:true,
	exclusive?:true,
	maxDeliveryDistanceMetres?:true,
	isReusablesEnabled?:true,
	openingTimes?:ValueTypes["OpeningTime"],
	menuId?:true,
	menu?:ValueTypes["Menu"],
	zoneId?:true,
	zone?:ValueTypes["Zone"],
	owner?:ValueTypes["RestaurantAdmin"],
	hygiene?:ValueTypes["Hygiene"],
	hygieneId?:true,
	tags?:ValueTypes["PartnerTag"],
	commissionPlans?:ValueTypes["PartnerCommissionPlan"],
	activeCommissionPlan?:ValueTypes["PartnerCommissionPlan"],
	/** Fulfillment times for this partner */
	fulfillmentTimes?:ValueTypes["FulfillmentDayTimes"],
	/** The current delivery time (in minutes) */
	currentDeliveryTime?:true,
	/** The current delivery time delta */
	currentDeliveryTimeDelta?:true,
	distance?:true,
	/** The display header image */
	headerImage?:true,
	/** Whether this restaurant is open or not */
	open?:true,
	/** Whether this restaurant is on vacation or not */
	isOnVacation?:true,
	/** Whether this restaurant is coming soon */
	comingSoon?:true,
	external?:ValueTypes["ExternalCompetitor"],
	invoices?:ValueTypes["PartnerInvoice"],
	address?:ValueTypes["Address"],
	brand?:ValueTypes["Brand"],
	headerVideo?:ValueTypes["VideoAsset"],
	washingLocation?:ValueTypes["WashingLocation"],
	nextOpeningTime?:true,
	description?:true,
		__typename?: true
}>;
	["RestaurantAdmin"]: AliasType<{
	id?:true,
	name?:true,
	email?:true,
	created?:true,
	phoneNumber?:true,
	isPhoneNumberVerified?:true,
	emailConfirmationTokenIssued?:true,
	emailConfirmed?:true,
	roles?:true,
	profileImageKey?:true,
	/** The restaurant that this user administers */
	administeredRestaurant?:ValueTypes["Restaurant"],
	/** The restaurants that this user administers */
	restaurants?:ValueTypes["Restaurant"],
	linkedCustomerAccount?:ValueTypes["Customer"],
	b2bJobs?:ValueTypes["B2BDeliveryJob"],
		__typename?: true
}>;
	["RestaurantAdminList"]: AliasType<{
	items?:ValueTypes["RestaurantAdmin"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RestaurantAdminUpdateInput"]: {
	roles?:ValueTypes["Permissions"][]
};
	["RestaurantBusyStatus"]:RestaurantBusyStatus;
	["RestaurantCreateInput"]: {
	name:string,
	address:ValueTypes["AddressCreateInput"],
	description:string,
	phoneNumber:string,
	zoneId:string,
	notes?:string,
	ownerEmail:string,
	ownerName:string
};
	["RestaurantFinancialsView"]: AliasType<{
	restaurantId?:true,
	restaurantSalesTotal?:true,
	deliveryFeeTotal?:true,
	/** The total price taken off of the order from discounts */
	discountsTotal?:true,
	accountCreditTotal?:true,
	smallOrderFeeTotal?:true,
	overallTotal?:true,
	riderEarnings?:true,
	riderTips?:true,
	numberOfOrders?:true,
	commissionPercent?:true,
	taxPercent?:true,
	commissionBeforeAddedTax?:true,
	taxFromCommission?:true,
	commissionTotal?:true,
	toPayRestaurant?:true,
	ourProfit?:true,
	amountRefunded?:true,
	adjustmentsTotal?:true,
	stripeFeeTotal?:true,
	donationToRestaurantTotal?:true,
	donationToCharityTotal?:true,
	purchasesFromUsTotal?:true,
	restaurant?:ValueTypes["Restaurant"],
		__typename?: true
}>;
	["RestaurantList"]: AliasType<{
	items?:ValueTypes["Restaurant"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RestaurantOnboardingStatus"]:RestaurantOnboardingStatus;
	/** Updates to the restaurant */
["RestaurantUpdateInput"]: {
	name?:string,
	description?:string,
	onboardingStatus?:ValueTypes["RestaurantOnboardingStatus"],
	openingTimes?:ValueTypes["OpeningTimeInput"][],
	busyStatus?:ValueTypes["RestaurantBusyStatus"],
	fulfillmentMethods?:ValueTypes["FulfillmentMethod"][],
	exclusive?:boolean,
	basePrepTime?:number,
	incrementPrepTime?:number,
	baseDeliveryTime?:number,
	ratingStars?:number,
	ratingCount?:number,
	commission?:number,
	commissionCollection?:number,
	minimumBasket?:number,
	hygieneRatingId?:string,
	allergyWarnings?:string,
	phoneNumber?:string,
	tags?:string[],
	external?:ValueTypes["ExternalCompetitorInput"],
	acceptsScheduledOrders?:boolean,
	autoAccept?:boolean,
	commissionPlanId?:string,
	commissionPlanRungId?:string,
	configuration?:ValueTypes["CoreConfigurations"],
	brandId?:string,
	headerVideoId?:string,
	isReusablesEnabled?:boolean
};
	["ReusableContainer"]: AliasType<{
	id?:true,
	createdAt?:true,
	type?:ValueTypes["ReusableContainerType"],
	uris?:true,
	aliases?:ValueTypes["ContainerCode"],
		__typename?: true
}>;
	["ReusableContainerList"]: AliasType<{
	items?:ValueTypes["ReusableContainer"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ReusableContainerType"]: AliasType<{
	id?:true,
	name?:true,
	depth?:true,
	length?:true,
	width?:true,
	unitPrice?:true,
	image?:ValueTypes["ImageAsset"],
	/** Whether ecoeats has stock of these containers */
	inStock?:true,
		__typename?: true
}>;
	["ReusableContainerTypeList"]: AliasType<{
	items?:ValueTypes["ReusableContainerType"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ReusableDropBox"]: AliasType<{
	id?:true,
	name?:true,
	notes?:true,
	status?:true,
	depth?:true,
	length?:true,
	width?:true,
	address?:ValueTypes["Address"],
		__typename?: true
}>;
	["ReusableDropBoxList"]: AliasType<{
	items?:ValueTypes["ReusableDropBox"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ReusablePickupTracking"]: AliasType<{
	/** The current position of the rider */
	coordinates?:true,
	/** The earliest we expect the rider to arrive */
	etaEarliest?:true,
	/** The latest we expect the rider to arrive */
	etaLatest?:true,
	/** How many stops away the rider is */
	stopsAway?:true,
		__typename?: true
}>;
	["ReusableShipment"]: AliasType<{
	id?:true,
	createdAt?:true,
	status?:true,
	scheduledFor?:true,
	estimatedArrivalTime?:true,
	washingLocation?:ValueTypes["WashingLocation"],
	partner?:ValueTypes["Restaurant"],
	containers?:ValueTypes["ShipmentContainer"],
	orders?:ValueTypes["Order"],
	activeOrder?:ValueTypes["Order"],
	canScheduleOrder?:true,
		__typename?: true
}>;
	["ReusableShipmentList"]: AliasType<{
	items?:ValueTypes["ReusableShipment"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ReusableShipmentStatus"]:ReusableShipmentStatus;
	["ReusablesInfo"]: AliasType<{
	id?:true,
	deposit?:true,
	fee?:true,
	predictionCount?:true,
	partnerCount?:true,
	riderCount?:true,
	customerCount?:true,
		__typename?: true
}>;
	["ReusablesPickup"]: AliasType<{
	id?:true,
	type?:true,
	status?:true,
	refundedDeposit?:true,
	pickupTimeEarliest?:true,
	pickupTimeLatest?:true,
	customerImageKey?:true,
	customerCount?:true,
	riderCount?:true,
	address?:ValueTypes["Address"],
	customer?:ValueTypes["Customer"],
	aggregate?:ValueTypes["ReusablesPickupAggregate"],
	canReschedule?:true,
	formattedQuantity?:true,
	tracking?:ValueTypes["ReusablePickupTracking"],
		__typename?: true
}>;
	["ReusablesPickupAggregate"]: AliasType<{
	id?:true,
	createdAt?:true,
	pickups?:ValueTypes["ReusablesPickup"],
	washingLocation?:ValueTypes["WashingLocation"],
	orders?:ValueTypes["Order"],
		__typename?: true
}>;
	["ReusablesPickupAggregateList"]: AliasType<{
	items?:ValueTypes["ReusablesPickupAggregate"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ReusablesPickupList"]: AliasType<{
	items?:ValueTypes["ReusablesPickup"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ReusablesPickupScheduleTime"]: AliasType<{
	slot?:ValueTypes["TimeSlot"],
	depositReturn?:ValueTypes["Price"],
		__typename?: true
}>;
	["ReusablesPickupStatus"]:ReusablesPickupStatus;
	["ReusablesPickupType"]:ReusablesPickupType;
	["Rider"]: AliasType<{
	id?:true,
	name?:true,
	email?:true,
	created?:true,
	phoneNumber?:true,
	isPhoneNumberVerified?:true,
	emailConfirmationTokenIssued?:true,
	emailConfirmed?:true,
	roles?:true,
	profileImageKey?:true,
	application?:ValueTypes["RiderApplication"],
	lastOnline?:true,
	online?:true,
	throttledUntil?:true,
	position?:ValueTypes["GraphQLGeoJSONPoint"],
	note?:true,
	activationStatus?:true,
	vehicle?:true,
	carRegistration?:true,
	activeFlightPlan?:ValueTypes["DeliveryFlightPlan"],
	zone?:ValueTypes["Zone"],
orders?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["Order"]],
	invoices?:ValueTypes["RiderInvoice"],
bookings?: [{	skip?:number,	take?:number,	sortBy:string,	order:ValueTypes["Ordering"],	query?:string},ValueTypes["BookingList"]],
	vehicleDetails?:ValueTypes["RiderVehicleDetails"],
	activeBooking?:ValueTypes["Booking"],
	accountNo?:true,
	sortCode?:true,
	stripeSetupIntentId?:true,
	statistics?:ValueTypes["RiderStatistics"],
	offers?:ValueTypes["OrderOffer"],
	address?:ValueTypes["Address"],
	shippingAddress?:ValueTypes["Address"],
		__typename?: true
}>;
	["RiderActivationStatus"]:RiderActivationStatus;
	["RiderAddressType"]:RiderAddressType;
	["RiderAddressView"]: AliasType<{
	id?:true,
	title?:true,
	type?:true,
	formatted?:true,
	instructions?:true,
	phoneNumber?:true,
	coordinates?:true,
	instructionImageKey?:true,
		__typename?: true
}>;
	["RiderAdjustment"]: AliasType<{
	id?:true,
	created?:true,
	forDate?:true,
	amount?:true,
	explanation?:true,
	riderId?:true,
	rider?:ValueTypes["Rider"],
		__typename?: true
}>;
	["RiderAdjustmentCreateInput"]: {
	forDate:ValueTypes["Date"],
	amount:number,
	explanation:string,
	riderId:string
};
	["RiderAdjustmentList"]: AliasType<{
	items?:ValueTypes["RiderAdjustment"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RiderAdjustmentUpdateInput"]: {
	forDate?:ValueTypes["Date"],
	amount?:number,
	explanation?:string,
	riderId?:string
};
	["RiderApplication"]: AliasType<{
	id?:true,
	created?:true,
	updated?:true,
	documentType?:true,
	compareFaces?:true,
	rekognitionLabels?:true,
	imageUrl?:true,
		__typename?: true
}>;
	["RiderCreateInput"]: {
	zoneId:string,
	name:string,
	email:string,
	password:string,
	accountNo?:string,
	sortCode?:string,
	phoneNumber:string,
	address:ValueTypes["AddressCreateInput"],
	vehicle:ValueTypes["RiderVehicle"],
	carRegistration?:string,
	sessionId:string,
	code:string
};
	["RiderInvoice"]: AliasType<{
	id?:true,
	/** Whether this invoice has been paid */
	paid?:true,
	value?:true,
	start?:true,
	end?:true,
	orders?:true,
	bookingExtra?:true,
	referralExtra?:true,
	leaseDeduction?:true,
	tips?:true,
	earnings?:true,
	adjustments?:true,
	revolutTransactionId?:true,
	revolutTransactionLink?:true,
	riderId?:true,
	rider?:ValueTypes["Rider"],
	url?:true,
		__typename?: true
}>;
	["RiderInvoiceList"]: AliasType<{
	items?:ValueTypes["RiderInvoice"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RiderInvoicesJob"]: AliasType<{
	id?:true,
	count?:true,
		__typename?: true
}>;
	["RiderJobView"]: AliasType<{
	feeFormatted?:true,
	feeModifiedExplanation?:true,
	orders?:ValueTypes["RiderOrderView"],
	origin?:ValueTypes["RiderAddressView"],
	currentDestination?:ValueTypes["RiderAddressView"],
	directions?:ValueTypes["RiderLiveRoute"],
		__typename?: true
}>;
	["RiderList"]: AliasType<{
	items?:ValueTypes["Rider"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RiderLiveRoute"]: AliasType<{
	id?:true,
	steps?:ValueTypes["RiderLiveRouteStep"],
	geometry?:true,
	distance?:true,
	duration?:true,
		__typename?: true
}>;
	["RiderLiveRouteStep"]: AliasType<{
	id?:true,
	geometry?:true,
		__typename?: true
}>;
	["RiderLiveView"]: AliasType<{
	id?:true,
	action?:ValueTypes["RiderUIAction"],
	layoutType?:true,
	banners?:ValueTypes["RiderUIBanner"],
	job?:ValueTypes["RiderJobView"],
	support?:ValueTypes["RiderSupportView"],
	offer?:ValueTypes["RiderOfferView"],
	settings?:ValueTypes["RiderSettingsView"],
		__typename?: true
}>;
	["RiderOfferView"]: AliasType<{
	id?:true,
	offeredAt?:true,
	offeredUntil?:true,
	feeFormatted?:true,
	origins?:ValueTypes["RiderAddressView"],
	destinations?:ValueTypes["RiderAddressView"],
		__typename?: true
}>;
	["RiderOnlineSession"]: AliasType<{
	id?:true,
	riderId?:true,
	rider?:ValueTypes["Rider"],
	updated?:true,
	start?:true,
	end?:true,
	path?:ValueTypes["GraphQLGeoJSONLineString"],
	initialPoint?:ValueTypes["GraphQLGeoJSONPoint"],
		__typename?: true
}>;
	["RiderOnlineSessionList"]: AliasType<{
	items?:ValueTypes["RiderOnlineSession"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RiderOrderItemView"]: AliasType<{
	id?:true,
	name?:true,
	quantity?:true,
		__typename?: true
}>;
	["RiderOrderSupportView"]: AliasType<{
	actions?:ValueTypes["RiderUIAction"],
		__typename?: true
}>;
	["RiderOrderView"]: AliasType<{
	id?:true,
	number?:true,
	isReadyToCollect?:true,
	estimatedReadyAt?:true,
	tipFormatted?:true,
	numberOfItems?:true,
	items?:ValueTypes["RiderOrderItemView"],
	origin?:ValueTypes["RiderAddressView"],
	destination?:ValueTypes["RiderAddressView"],
	support?:ValueTypes["RiderOrderSupportView"],
		__typename?: true
}>;
	["RiderReferralDiscount"]: AliasType<{
	id?:true,
	riderId?:true,
	discountId?:true,
	associationDate?:true,
	discount?:ValueTypes["Discount"],
	rider?:ValueTypes["Rider"],
		__typename?: true
}>;
	["RiderReferralDiscountList"]: AliasType<{
	items?:ValueTypes["RiderReferralDiscount"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["RiderSettingsView"]: AliasType<{
	id?:true,
	initialValue?:true,
	schema?:true,
		__typename?: true
}>;
	["RiderSlotStats"]: AliasType<{
	id?:true,
	riderId?:true,
	bookingId?:true,
	bookingSlotId?:true,
	slotType?:true,
	slotStart?:true,
	slotEnd?:true,
	ordersAccepted?:true,
	ordersOffered?:true,
	maxMissedOrders?:true,
	sessionSpans?:true,
	attended?:true,
	onlinePercentage?:true,
	minAttendancePercentage?:true,
	guaranteedRiderEarnings?:true,
	orderRiderEarnings?:true,
	riderOwedExtra?:true,
		__typename?: true
}>;
	["RiderStatistics"]: AliasType<{
	riderId?:true,
	acceptedOrders?:true,
	rejectedOrders?:true,
	timedoutOrders?:true,
	averageRating?:true,
		__typename?: true
}>;
	["RiderStatusUpdate"]: {
	location?:ValueTypes["GraphQLGeoJSONPointInput"],
	online:boolean,
	device?:string,
	os?:string
};
	["RiderSupportView"]: AliasType<{
	supportURL?:true,
	supportPhoneNumber?:true,
		__typename?: true
}>;
	["RiderUIAction"]: AliasType<{
	id?:true,
	maxDistanceInMetres?:true,
	allowMaxDistanceOverride?:true,
	validationPrompt?:ValueTypes["RiderUIActionValidationPrompt"],
	areYouSure?:true,
	orderId?:true,
	disabled?:true,
	color?:true,
	title?:true,
	explanation?:true,
	text?:true,
	extraPayload?:true,
		__typename?: true
}>;
	["RiderUIActionColour"]:RiderUIActionColour;
	["RiderUIActions"]:RiderUIActions;
	["RiderUIActionValidationPrompt"]: AliasType<{
	id?:true,
	title?:true,
	canBeTemporarilyDismissed?:true,
	validateAge?:ValueTypes["RiderUIAgeValidation"],
	validateReusables?:ValueTypes["RiderUIReusablesCountValidation"],
	rateOrderPrompt?:ValueTypes["RiderUIRateOrderValidation"],
	buttons?:ValueTypes["RiderUIButton"],
		__typename?: true
}>;
	["RiderUIAgeValidation"]: AliasType<{
	customerDOBShouldBeLessThanOrEqualTo?:true,
		__typename?: true
}>;
	["RiderUIBanner"]: AliasType<{
	id?:true,
	isDismissible?:true,
	title?:true,
	message?:true,
	color?:true,
		__typename?: true
}>;
	["RiderUIButton"]: AliasType<{
	type?:true,
	text?:true,
	color?:true,
		__typename?: true
}>;
	["RiderUIButtonType"]:RiderUIButtonType;
	["RiderUILayoutType"]:RiderUILayoutType;
	["RiderUIOption"]: AliasType<{
	value?:true,
	label?:true,
		__typename?: true
}>;
	["RiderUIRateOrderValidation"]: AliasType<{
	options?:ValueTypes["RiderUIOption"],
		__typename?: true
}>;
	["RiderUIReusablesCountValidation"]: AliasType<{
	customerCountWas?:true,
		__typename?: true
}>;
	["RiderUpdateInput"]: {
	name?:string,
	activationStatus?:ValueTypes["RiderActivationStatus"],
	vehicle?:ValueTypes["RiderVehicle"],
	phoneNumber?:string,
	zoneId?:string,
	note?:string,
	accountNo?:string,
	sortCode?:string,
	carRegistration?:string,
	shippingAddressId?:string
};
	["RiderVehicle"]:RiderVehicle;
	["RiderVehicleDetails"]: AliasType<{
	id?:true,
	updated?:true,
	details?:true,
	rider?:ValueTypes["Rider"],
		__typename?: true
}>;
	["ScanContainerAtPartnerResponse"]: AliasType<{
	success?:true,
	recognisedContainer?:ValueTypes["ReusableContainer"],
		__typename?: true
}>;
	["ScannedContainer"]: AliasType<{
	id?:true,
	containerId?:true,
	scannedAt?:true,
	imageKey?:true,
	pickupId?:true,
	customerId?:true,
	washingLocationId?:true,
	shipmentContainerId?:true,
	container?:ValueTypes["ReusableContainer"],
	washingLocation?:ValueTypes["WashingLocation"],
		__typename?: true
}>;
	["ScannedContainerList"]: AliasType<{
	items?:ValueTypes["ScannedContainer"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["Series"]: AliasType<{
	label?:true,
	x_values?:true,
	y_values?:true,
		__typename?: true
}>;
	["ShipmentContainer"]: AliasType<{
	quantity?:true,
	containerType?:ValueTypes["ReusableContainerType"],
	containersScannedSoFar?:true,
		__typename?: true
}>;
	["SpecificRiderFinancials"]: AliasType<{
	startDate?:true,
	endDate?:true,
	id?:true,
	riderEarningsSum?:true,
	adjustments?:true,
	extraFromSlots?:true,
	extraFromReferrals?:true,
	riderTips?:true,
	totalPayout?:true,
	numberOfOrders?:true,
	bookingStats?:ValueTypes["RiderSlotStats"],
	rider?:ValueTypes["Rider"],
		__typename?: true
}>;
	["StatChart"]: AliasType<{
	id?:true,
	type?:true,
	label?:true,
	value?:true,
		__typename?: true
}>;
	["StripeTokens"]: AliasType<{
	addPaymentMethod?:true,
	payOnce?:true,
		__typename?: true
}>;
	["Subscription"]: AliasType<{
LiveOrders?: [{	customerId?:string,	status?:ValueTypes["OrderStatus"][],	zoneId?:string,	restaurantId?:string,	riderId?:string},ValueTypes["Order"]],
OrderAlerts?: [{	sortBy:string,	order:ValueTypes["Ordering"],	query?:string,	skip?:number,	take?:number,	includeResolved:boolean,	zoneIds?:string[],	orderId?:string,	riderId?:string},ValueTypes["OrderAlert"]],
PartnerTabletActions?: [{	partnerId:string,	tabletId:string},ValueTypes["PartnerTabletAction"]],
AdminPageViews?: [{	url:string},ValueTypes["AdminPageView"]],
		__typename?: true
}>;
	["TableChart"]: AliasType<{
	id?:true,
	type?:true,
	title?:true,
	columns?:ValueTypes["TableColumn"],
	data?:ValueTypes["TableRow"],
		__typename?: true
}>;
	["TableColumn"]: AliasType<{
	id?:true,
	title?:true,
		__typename?: true
}>;
	["TableRecord"]: AliasType<{
	dataIndex?:true,
	formatted?:true,
	value?:true,
		__typename?: true
}>;
	["TableRow"]: AliasType<{
	id?:true,
	records?:ValueTypes["TableRecord"],
		__typename?: true
}>;
	["TeamMember"]: AliasType<{
	id?:true,
	name?:true,
	title?:true,
	description?:true,
	imageKey?:true,
		__typename?: true
}>;
	["TimeSlot"]: AliasType<{
	start?:true,
	end?:true,
		__typename?: true
}>;
	["TwoFactorSetup"]: AliasType<{
	uri?:true,
		__typename?: true
}>;
	["UIThemeColour"]:UIThemeColour;
	["UnifiedLocationInput"]: {
	latLng?:ValueTypes["GraphQLGeoJSONPointInput"],
	zoneId?:string,
	geohash?:string
};
	["UnifiedLocationType"]: AliasType<{
	latLng?:ValueTypes["GraphQLGeoJSONPoint"],
	zoneId?:true,
	geohash?:true,
		__typename?: true
}>;
	["UpdateBookingArgs"]: {
	status?:ValueTypes["BookingStatus"]
};
	["UpdateBookingSlotArgs"]: {
	startTime?:ValueTypes["Date"],
	endTime?:ValueTypes["Date"],
	guaranteedHourlyPay?:number,
	slotType?:ValueTypes["BookingSlotType"],
	capHourlyPay?:boolean,
	carOnly?:boolean,
	maxRiders?:number,
	autoIncreaseRiderPercentage?:boolean,
	percentage?:number
};
	["UpdateIncidentInput"]: {
	severity:ValueTypes["IncidentSeverity"],
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:ValueTypes["UserType"],
	description:string,
	imageIds:string[]
};
	["UpdateMenuCategoryAsRestaurantInput"]: {
	times?:ValueTypes["OpeningTimeInput"][],
	topLevel?:boolean
};
	["UpdateOrderInput"]: {
	internalNote?:string,
	addressId?:string,
	partnerReusablesCount?:number
};
	["UpdatePartnerPaymentDetailsInput"]: {
	accountNo:string,
	sortCode:string,
	isCompany?:boolean,
	individualName?:string
};
	["UpdatePromotionInput"]: {
	start?:ValueTypes["Date"],
	end?:ValueTypes["Date"],
	name?:string,
	title?:string,
	zoneIds?:string[],
	enabled?:boolean,
	body?:string,
	color?:string,
	targetType?:ValueTypes["PromotionTarget"],
	callToActionText?:string,
	target?:string
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:unknown;
	["UploadMediaJob"]: AliasType<{
	id?:true,
	uploadId?:true,
	key?:true,
	urls?:ValueTypes["PresignedUploadURL"],
		__typename?: true
}>;
	["UploadMediaPart"]: {
	etag:string,
	partNumber:number
};
	["User"]: AliasType<{
	id?:true,
	name?:true,
	email?:true,
	created?:true,
	phoneNumber?:true,
	isPhoneNumberVerified?:true,
	emailConfirmationTokenIssued?:true,
	emailConfirmed?:true,
	roles?:true,
	profileImageKey?:true,
		__typename?: true
}>;
	/** The type of this user */
["UserType"]:UserType;
	["UserUnionDTO"]: AliasType<{		["...on Customer"] : ValueTypes["Customer"],
		["...on RestaurantAdmin"] : ValueTypes["RestaurantAdmin"],
		["...on Rider"] : ValueTypes["Rider"]
		__typename?: true
}>;
	["UserView"]: AliasType<{
	id?:true,
	type?:true,
	name?:true,
	email?:true,
	avatarUrl?:true,
		__typename?: true
}>;
	["ValidateRegistrationResult"]: AliasType<{
	canRide?:true,
	reason?:true,
	registration?:true,
		__typename?: true
}>;
	["VideoAsset"]: AliasType<{
	id?:true,
	created?:true,
	updated?:true,
	title?:true,
	key?:true,
	width?:true,
	height?:true,
	fileSize?:true,
	altText?:true,
	mimeType?:true,
	uploadedBy?:ValueTypes["RestaurantAdmin"],
	url?:true,
		__typename?: true
}>;
	["VideoAssetList"]: AliasType<{
	items?:ValueTypes["VideoAsset"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["WashingLocation"]: AliasType<{
	id?:true,
	name?:true,
	notes?:true,
	status?:true,
	hasUnattendedDropoff?:true,
	address?:ValueTypes["Address"],
	partner?:ValueTypes["Restaurant"],
	/** The current list of ingested containers not scheduled */
	currentIngestedContainers?:ValueTypes["ScannedContainer"],
	/** A list of shipments that this washing location could fulfill. */
	availableShipments?:ValueTypes["ReusableShipment"],
		__typename?: true
}>;
	["WashingLocationList"]: AliasType<{
	items?:ValueTypes["WashingLocation"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["Zone"]: AliasType<{
	id?:true,
	name?:true,
	slug?:true,
	polygon?:ValueTypes["GraphQLGeoJSONPolygon"],
	baseDeliveryFee?:true,
	feePerKM?:true,
	baseRiderEarnings?:true,
	riderEarningsPerKM?:true,
	timeZone?:true,
	onboardingStatus?:true,
	busyWeight?:true,
	busyStatus?:true,
	minDeliveryDistanceMetres?:true,
	standardDeliveryDistanceMetres?:true,
	currentDeliveryDistanceMetres?:true,
	hardLimitDeliveryDistanceMetres?:true,
	autoBookingSlotConfigId?:true,
	autoBookingSlotConfig?:ValueTypes["AutoBookingSlotsConfig"],
	weather?:ValueTypes["ZoneWeatherData"],
	riders?:ValueTypes["Rider"],
restaurants?: [{	skip?:number,	take?:number,	lat?:number,	lng?:number,	zoneId?:string,	zoneSlug?:string,	/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:ValueTypes["FulfillmentMethod"],	/** An optional query field to allow for user searches */
	query?:string,	/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:boolean,	tags?:string[],	sortBy?:string,	order?:string,	coreConfiguration?:ValueTypes["CoreConfigurations"]},ValueTypes["Restaurant"]],
	city?:ValueTypes["City"],
	boosts?:ValueTypes["Boost"],
	bookingSlots?:ValueTypes["BookingSlot"],
	currentBoost?:ValueTypes["Boost"],
	openingTimes?:ValueTypes["OpeningTime"],
	activePolygonId?:true,
	liveView?:ValueTypes["ZoneLiveView"],
		__typename?: true
}>;
	["ZoneBusyStatus"]:ZoneBusyStatus;
	["ZoneCreateInput"]: {
	name:string,
	polygon:ValueTypes["GraphQLGeoJSONPolygonInput"],
	cityId?:string,
	feePerKM?:number,
	baseDeliveryFee?:number
};
	["ZoneEvent"]: AliasType<{
	id?:true,
	timestamp?:true,
	operation?:true,
	zoneId?:true,
	from?:true,
	to?:true,
		__typename?: true
}>;
	["ZoneEventList"]: AliasType<{
	items?:ValueTypes["ZoneEvent"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ZoneList"]: AliasType<{
	items?:ValueTypes["Zone"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ZoneLiveView"]: AliasType<{
	activeRiders?:true,
	unassignedRiders?:true,
	riderAcceptRate?:true,
	ridersFreeSoon?:true,
		__typename?: true
}>;
	["ZoneOnboardingStatus"]:ZoneOnboardingStatus;
	["ZonePolygon"]: AliasType<{
	id?:true,
	created?:true,
	updated?:true,
	maxCycleTime?:true,
	maxDriveTime?:true,
	polygon?:ValueTypes["GraphQLGeoJSONPolygon"],
		__typename?: true
}>;
	["ZonePolygonInput"]: {
	zoneId:string,
	polygon:ValueTypes["GraphQLGeoJSONPolygonInput"]
};
	["ZonePolygonList"]: AliasType<{
	items?:ValueTypes["ZonePolygon"],
	total?:true,
	hasMore?:true,
		__typename?: true
}>;
	["ZoneUpdateInput"]: {
	feePerKM?:number,
	baseDeliveryFee?:number,
	riderEarningsPerKM?:number,
	baseRiderEarnings?:number,
	polygon?:ValueTypes["GraphQLGeoJSONPolygonInput"],
	onboardingStatus?:ValueTypes["ZoneOnboardingStatus"],
	busyStatus?:ValueTypes["ZoneBusyStatus"],
	autoBookingSlotConfigId?:string,
	openingTimes?:ValueTypes["OpeningTimeInput"][],
	minDeliveryDistanceMetres?:number,
	standardDeliveryDistanceMetres?:number,
	currentDeliveryDistanceMetres?:number,
	hardLimitDeliveryDistanceMetres?:number
};
	["ZoneWeatherData"]: AliasType<{
	updated?:true,
	result?:true,
		__typename?: true
}>
  }

export type PartialObjects = {
    ["Address"]: {
		__typename?: "Address";
			id?:string,
			nickname?:string,
			flat_number?:string,
			address_line_1?:string,
			town?:string,
			postcode?:string,
			country?:string,
			phone_number?:string,
			instructions?:string,
			coordinates?:PartialObjects["GraphQLGeoJSONPoint"],
			imageKey?:string
	},
	["AddressCreateInput"]: {
	flat_number?:string,
	address_line_1:string,
	postcode:string,
	phone_number:string,
	instructions?:string,
	town?:string,
	country?:string,
	/** The coorindates of this address. Required only for customer and restaurant accounts */
	coordinates?:PartialObjects["JSON"]
},
	["AddressList"]: {
		__typename?: "AddressList";
			items?:PartialObjects["Address"][],
			total?:number,
			hasMore?:boolean
	},
	["AddressUpdateInput"]: {
	flat_number?:string,
	address_line_1?:string,
	postcode?:string,
	phone_number?:string,
	instructions?:string,
	town?:string,
	country?:string,
	/** The coordinates of this address. Required only for customer and restaurant accounts */
	coordinates?:PartialObjects["GraphQLGeoJSONPointInput"]
},
	["AdminPageView"]: {
		__typename?: "AdminPageView";
			url?:string,
			users?:PartialObjects["UserView"][]
	},
	["AggregateBasket"]: {
		__typename?: "AggregateBasket";
			id?:string,
			isSingleDelivery?:boolean,
			allowSwitchSingleDelivery?:boolean,
			baskets?:PartialObjects["Basket"][],
			feeExplanations?:PartialObjects["BasketFeeExplanation"][],
			totalPrice?:PartialObjects["Price"],
			subtotal?:PartialObjects["Price"],
			fulfillmentTimes?:PartialObjects["FulfillmentDayTimes"][],
			fulfillmentMethods?:PartialObjects["FulfillmentMethod"][]
	},
	["AggregateOrder"]: {
		__typename?: "AggregateOrder";
			id?:string,
			status?:PartialObjects["OrderStatus"],
			orders?:PartialObjects["Order"][]
	},
	["AnalyticsDTO"]: {
		__typename?: "AnalyticsDTO";
			totalOrders?:number,
			totalRestaurants?:number,
			customers?:number
	},
	["AssignmentBias"]:AssignmentBias,
	["AuthDTO"]: {
		__typename?: "AuthDTO";
			user?:PartialObjects["User"],
			expiresIn?:string,
			accessToken?:string,
			refreshToken?:string
	},
	["AutoBookingSlot"]: {
		__typename?: "AutoBookingSlot";
			id?:string,
			dayOfTheWeek?:PartialObjects["DayOfWeek"],
			startTime?:PartialObjects["HourMinuteTimeString"],
			endTime?:PartialObjects["HourMinuteTimeString"],
			guaranteedHourlyPay?:number,
			slotType?:PartialObjects["BookingSlotType"],
			maxRiders?:number,
			percentage?:number,
			autoIncreaseRiderPercentage?:boolean,
			carOnly?:boolean,
			configId?:string,
			config?:PartialObjects["AutoBookingSlotsConfig"]
	},
	["AutoBookingSlotInput"]: {
	dayOfTheWeek:PartialObjects["DayOfWeek"],
	startTime:PartialObjects["HourMinuteTimeString"],
	endTime:PartialObjects["HourMinuteTimeString"],
	guaranteedHourlyPay:number,
	slotType:PartialObjects["BookingSlotType"],
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly?:boolean,
	configId:string
},
	["AutoBookingSlotsConfig"]: {
		__typename?: "AutoBookingSlotsConfig";
			id?:string,
			name?:string,
			slots?:PartialObjects["AutoBookingSlot"][],
			zones?:PartialObjects["Zone"][]
	},
	["AutoBookingSlotsConfigInput"]: {
	name:string
},
	["AutoBookingSlotsConfigList"]: {
		__typename?: "AutoBookingSlotsConfigList";
			items?:PartialObjects["AutoBookingSlotsConfig"][],
			total?:number,
			hasMore?:boolean
	},
	["B2BDeliveryJob"]: {
		__typename?: "B2BDeliveryJob";
			id?:string,
			created?:PartialObjects["Date"],
			placed?:PartialObjects["Date"],
			price?:number,
			distanceDuration?:PartialObjects["DistanceModel"],
			scheduledFor?:PartialObjects["Date"],
			destination?:PartialObjects["Address"],
			origin?:PartialObjects["Address"],
			size?:PartialObjects["B2BDeliverySizes"],
			placedById?:string,
			placedBy?:PartialObjects["RestaurantAdmin"],
			order?:PartialObjects["Order"]
	},
	["B2BDeliveryJobList"]: {
		__typename?: "B2BDeliveryJobList";
			items?:PartialObjects["B2BDeliveryJob"][],
			total?:number,
			hasMore?:boolean
	},
	["B2BDeliverySizes"]:B2BDeliverySizes,
	["B2BJobEstimateResponse"]: {
		__typename?: "B2BJobEstimateResponse";
			job?:PartialObjects["B2BDeliveryJob"],
			scheduledTimes?:PartialObjects["DeliveryTimes"][],
			estimatedArrivalMins?:number,
			blockedReason?:string
	},
	["BarChart"]: {
		__typename?: "BarChart";
			id?:string,
			type?:string,
			series?:PartialObjects["Series"][]
	},
	["Basket"]: {
		__typename?: "Basket";
			id?:string,
			modified?:PartialObjects["Date"],
			checkedOutAt?:PartialObjects["Date"],
			scheduledDelivery?:string,
			fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
			targetDeliveryFromNow?:number,
			restaurantId?:string,
			sessionId?:string,
			totalPriceBeforeDiscount?:number,
			totalPrice?:number,
			riderTip?:number,
			allergyNotes?:string,
			tableNumber?:string,
			subtotal?:number,
			smallOrderFee?:number,
			deliveryFee?:number,
			location?:PartialObjects["GraphQLGeoJSONPoint"],
			distanceDuration?:PartialObjects["JSON"],
			alcohol?:boolean,
			numberOfItems?:number,
			weight?:number,
			instructions?:string,
			checkoutBlockedReason?:string,
			scheduledFor?:PartialObjects["DeliveryTimeArgs"],
			created?:PartialObjects["Date"],
			paymentIntentSecret?:string,
			paymentIntentId?:string,
			/** Whether this basket has taken part in a successful order placement */
	complete?:boolean,
			donationTotal?:number,
			/** The items that have been added to this basket */
	items?:PartialObjects["OrderItem"][],
			address?:PartialObjects["Address"],
			customer?:PartialObjects["Customer"],
			restaurant?:PartialObjects["Restaurant"],
			discount?:PartialObjects["Discount"],
			aggregateBasket?:PartialObjects["AggregateBasket"],
			donationView?:PartialObjects["BasketDonationView"],
			feeExplanations?:PartialObjects["BasketFeeExplanation"][],
			fulfillmentMethods?:PartialObjects["FulfillmentMethod"][],
			fulfillmentTimes?:PartialObjects["FulfillmentDayTimes"][],
			reusables?:PartialObjects["BasketReusablesOptions"]
	},
	["BasketAddDiscountInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:PartialObjects["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
	scheduledFor?:PartialObjects["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	code:string
},
	["BasketAddItemInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:PartialObjects["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
	scheduledFor?:PartialObjects["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	item:PartialObjects["BasketItemInput"]
},
	["BasketCheckOutInput"]: {
	sessionId:string,
	location?:PartialObjects["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
	scheduledFor?:PartialObjects["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	tableNumber?:string
},
	["BasketDonationOptionView"]: {
		__typename?: "BasketDonationOptionView";
			id?:string,
			primaryText?:string,
			secondaryText?:string,
			isSelected?:boolean
	},
	["BasketDonationToggleView"]: {
		__typename?: "BasketDonationToggleView";
			id?:string,
			toggleText?:string,
			toggleFormattedValue?:string,
			toggled?:boolean
	},
	["BasketDonationView"]: {
		__typename?: "BasketDonationView";
			campaign?:PartialObjects["ConsumerCharityCampaignView"],
			donationOptions?:PartialObjects["BasketDonationOptionView"][],
			donationOptionsMutuallyExclusive?:boolean,
			donationToggleInfo?:PartialObjects["BasketDonationToggleView"]
	},
	["BasketEditItemInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:PartialObjects["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
	scheduledFor?:PartialObjects["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	itemId:string,
	quantity:number
},
	["BasketFeeExplanation"]: {
		__typename?: "BasketFeeExplanation";
			title?:string,
			explanation?:string,
			feeFormatted?:string,
			color?:PartialObjects["UIThemeColour"]
	},
	/** Info to be sent with every basket request */
["BasketIdentifierInfo"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:PartialObjects["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
	scheduledFor?:PartialObjects["DeliveryTimeInput"],
	useSynchronousPayments?:boolean
},
	["BasketIncrementDecrementItemInput"]: {
	/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:PartialObjects["JSON"],
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
	scheduledFor?:PartialObjects["DeliveryTimeInput"],
	useSynchronousPayments?:boolean,
	itemId:string
},
	["BasketItemInput"]: {
	id:string,
	/** How many of this exact combination of item and modifiers */
	quantity:number,
	modifierGroups?:PartialObjects["BasketModifierGroupInput"][]
},
	["BasketList"]: {
		__typename?: "BasketList";
			items?:PartialObjects["Basket"][],
			total?:number,
			hasMore?:boolean
	},
	["BasketModifierGroupInput"]: {
	id:string,
	modifierItems:PartialObjects["BasketItemInput"][]
},
	["BasketReusablesOptions"]: {
		__typename?: "BasketReusablesOptions";
			isEnabled?:boolean,
			enableText?:string,
			explanation?:string,
			disableText?:string,
			pickupScheduleTimes?:PartialObjects["TimeSlot"][],
			pickupScheduledTime?:PartialObjects["TimeSlot"],
			imageKey?:string
	},
	["Booking"]: {
		__typename?: "Booking";
			id?:string,
			createdAt?:PartialObjects["Date"],
			riderId?:string,
			rider?:PartialObjects["Rider"],
			bookingSlotId?:string,
			bookingSlot?:PartialObjects["BookingSlot"],
			status?:PartialObjects["BookingStatus"],
			attendancePercentage?:number,
			numOfferedOrders?:number,
			numAcceptedOrders?:number,
			riderEarnings?:number,
			sessionSpans?:string,
			lateCancellation?:boolean,
			stats?:PartialObjects["RiderSlotStats"]
	},
	["BookingList"]: {
		__typename?: "BookingList";
			items?:PartialObjects["Booking"][],
			total?:number,
			hasMore?:boolean
	},
	["BookingSlot"]: {
		__typename?: "BookingSlot";
			id?:string,
			zoneId?:string,
			zone?:PartialObjects["Zone"],
			startTime?:PartialObjects["Date"],
			endTime?:PartialObjects["Date"],
			guaranteedHourlyPay?:number,
			slotType?:PartialObjects["BookingSlotType"],
			capHourlyPay?:boolean,
			carOnly?:boolean,
			maxRiders?:number,
			autoIncreaseRiderPercentage?:boolean,
			percentage?:number,
			bookings?:PartialObjects["Booking"][]
	},
	["BookingSlotInput"]: {
	dayOfTheWeek:PartialObjects["DayOfWeek"],
	startTime:PartialObjects["HourMinuteTimeString"],
	endTime:PartialObjects["HourMinuteTimeString"],
	guaranteedHourlyPay:number,
	slotType:PartialObjects["BookingSlotType"],
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly?:boolean
},
	["BookingSlotList"]: {
		__typename?: "BookingSlotList";
			items?:PartialObjects["BookingSlot"][],
			total?:number,
			hasMore?:boolean
	},
	["BookingSlotRiderView"]: {
		__typename?: "BookingSlotRiderView";
			id?:string,
			zoneId?:string,
			zone?:PartialObjects["Zone"],
			startTime?:PartialObjects["Date"],
			endTime?:PartialObjects["Date"],
			guaranteedHourlyPay?:number,
			slotType?:PartialObjects["BookingSlotType"],
			capHourlyPay?:boolean,
			carOnly?:boolean,
			maxRiders?:number,
			autoIncreaseRiderPercentage?:boolean,
			percentage?:number,
			bookings?:PartialObjects["Booking"][],
			hasBooked?:boolean,
			bookingId?:string
	},
	["BookingSlotRiderViewList"]: {
		__typename?: "BookingSlotRiderViewList";
			items?:PartialObjects["BookingSlotRiderView"][],
			total?:number,
			hasMore?:boolean
	},
	["BookingSlotType"]:BookingSlotType,
	["BookingStatus"]:BookingStatus,
	["Boost"]: {
		__typename?: "Boost";
			id?:string,
			type?:PartialObjects["BoostType"],
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			multiplier?:number,
			fixedIncrease?:number,
			notified?:boolean,
			zoneId?:string,
			zone?:PartialObjects["Zone"]
	},
	["BoostInput"]: {
	start:PartialObjects["Date"],
	end:PartialObjects["Date"],
	type:PartialObjects["BoostType"],
	multiplier:number,
	zoneId:string,
	fixedIncrease:number
},
	["BoostType"]:BoostType,
	["Brand"]: {
		__typename?: "Brand";
			id?:string,
			slug?:string,
			name?:string,
			description?:string,
			logo?:PartialObjects["ImageAsset"],
			owners?:PartialObjects["BrandOwner"]
	},
	["BrandList"]: {
		__typename?: "BrandList";
			items?:PartialObjects["Brand"][],
			total?:number,
			hasMore?:boolean
	},
	["BrandOwner"]: {
		__typename?: "BrandOwner";
			id?:string,
			created?:PartialObjects["Date"]
	},
	["CampaignMapMarker"]: {
		__typename?: "CampaignMapMarker";
			id?:string,
			center?:PartialObjects["Coordinates"]
	},
	["CampaignMapView"]: {
		__typename?: "CampaignMapView";
			id?:string,
			features?:PartialObjects["JSON"],
			center?:PartialObjects["Coordinates"],
			markers?:PartialObjects["CampaignMapMarker"][]
	},
	["Charity"]: {
		__typename?: "Charity";
			id?:string,
			name?:string,
			logo?:PartialObjects["ImageAsset"],
			logoId?:string,
			campaigns?:PartialObjects["CharityCampaign"][]
	},
	["CharityCampaign"]: {
		__typename?: "CharityCampaign";
			id?:string,
			charityId?:string,
			startDate?:PartialObjects["Date"],
			endDate?:PartialObjects["Date"],
			goal?:number,
			progress?:number,
			title?:string,
			description?:string,
			goalPrefix?:string,
			goalSuffix?:string,
			costPerGoalUnit?:number,
			campaignLogo?:PartialObjects["ImageAsset"],
			campaignLogoId?:string,
			charity?:PartialObjects["Charity"]
	},
	["CharityCampaignCreateArgs"]: {
	charityId:string,
	startDate:PartialObjects["Date"],
	endDate:PartialObjects["Date"],
	goal:number,
	title:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit:number,
	campaignLogoId?:string
},
	["CharityCampaignFinancials"]: {
		__typename?: "CharityCampaignFinancials";
			id?:string,
			donationTotal?:number,
			campaign?:PartialObjects["CharityCampaign"]
	},
	["CharityCampaignList"]: {
		__typename?: "CharityCampaignList";
			items?:PartialObjects["CharityCampaign"][],
			total?:number,
			hasMore?:boolean
	},
	["CharityCampaignUpdateArgs"]: {
	charityId?:string,
	startDate?:PartialObjects["Date"],
	endDate?:PartialObjects["Date"],
	goal?:number,
	title?:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit?:number,
	campaignLogoId?:string
},
	["CharityCreateArgs"]: {
	name?:string,
	logoId?:string
},
	["CharityFinancials"]: {
		__typename?: "CharityFinancials";
			id?:string,
			donationTotal?:number,
			campaigns?:PartialObjects["CharityCampaignFinancials"][],
			charity?:PartialObjects["Charity"]
	},
	["CharityList"]: {
		__typename?: "CharityList";
			items?:PartialObjects["Charity"][],
			total?:number,
			hasMore?:boolean
	},
	["CharityUpdateArgs"]: {
	name?:string,
	logoId?:string
},
	/** All possible chart types */
["Chart"]: PartialObjects["PieChart"] | PartialObjects["LineChart"] | PartialObjects["BarChart"] | PartialObjects["TableChart"] | PartialObjects["StatChart"],
	["ChartLayoutGroup"]: {
		__typename?: "ChartLayoutGroup";
			id?:string,
			title?:string,
			description?:string,
			charts?:PartialObjects["Chart"][]
	},
	["ChatMessage"]: {
		__typename?: "ChatMessage";
			id?:string,
			text?:string,
			sent?:PartialObjects["Date"],
			read?:boolean,
			sender?:PartialObjects["MessageParticipant"],
			viewableBy?:PartialObjects["UserType"],
			order?:PartialObjects["Order"]
	},
	["ChatMessageInput"]: {
	orderId:string,
	text:string,
	viewableBy?:PartialObjects["UserType"]
},
	["ChatMessageList"]: {
		__typename?: "ChatMessageList";
			items?:PartialObjects["ChatMessage"][],
			total?:number,
			hasMore?:boolean,
			unreadCount?:number
	},
	["City"]: {
		__typename?: "City";
			id?:string,
			name?:string,
			zones?:PartialObjects["Zone"][]
	},
	["CityCreateInput"]: {
	name:string,
	zones:PartialObjects["ZoneCreateInput"][]
},
	["CommissionPlan"]: {
		__typename?: "CommissionPlan";
			id?:string,
			slug?:string,
			description?:string,
			rungs?:PartialObjects["CommissionPlanRung"][],
			partnerPlans?:PartialObjects["PartnerCommissionPlan"][]
	},
	["CommissionPlanList"]: {
		__typename?: "CommissionPlanList";
			items?:PartialObjects["CommissionPlan"][],
			total?:number,
			hasMore?:boolean
	},
	["CommissionPlanRung"]: {
		__typename?: "CommissionPlanRung";
			id?:string,
			name?:string,
			description?:string,
			fixedBase?:number,
			percentage?:number,
			percentageCollection?:number,
			percentageTableService?:number,
			deliveryFeePerKMDiff?:number,
			deliveryFeeBaseDiff?:number
	},
	/** Configuration for the ecoeats API */
["Configuration"]: {
		__typename?: "Configuration";
			/** Base URL for the CDN */
	cdnURL?:string,
			/** Base URL for image resizing proxy */
	imgProxyURL?:string,
			env?:string,
			/** Branding settings for the current context */
	coreSettings?:PartialObjects["CoreSettings"]
	},
	["ConsumerCharityCampaignView"]: {
		__typename?: "ConsumerCharityCampaignView";
			charityId?:string,
			charityName?:string,
			charityLogo?:PartialObjects["ImageAsset"],
			id?:string,
			campaignTitle?:string,
			campaignLogo?:PartialObjects["ImageAsset"],
			startDate?:PartialObjects["Date"],
			endDate?:PartialObjects["Date"],
			goal?:number,
			progress?:number,
			goalFormatted?:string,
			progressFormatted?:string,
			percentageProgress?:number,
			description?:string,
			map?:PartialObjects["CampaignMapView"]
	},
	["ConsumerSearchItemResult"]: {
		__typename?: "ConsumerSearchItemResult";
			relevance?:number,
			item?:PartialObjects["Item"]
	},
	["ConsumerSearchPartnerResult"]: {
		__typename?: "ConsumerSearchPartnerResult";
			relevance?:number,
			item?:PartialObjects["Restaurant"]
	},
	["ConsumerSearchResult"]: {
		__typename?: "ConsumerSearchResult";
			query?:string,
			location?:PartialObjects["UnifiedLocationType"],
			partners?:PartialObjects["ConsumerSearchPartnerResult"][],
			items?:PartialObjects["ConsumerSearchItemResult"][],
			tags?:PartialObjects["ConsumerSearchTagResult"][]
	},
	["ConsumerSearchTagResult"]: {
		__typename?: "ConsumerSearchTagResult";
			relevance?:number,
			item?:PartialObjects["PartnerTag"]
	},
	["ContainerCode"]: {
		__typename?: "ContainerCode";
			id?:string,
			createdAt?:PartialObjects["Date"]
	},
	/** A set of coordinates. x, y */
["Coordinates"]:any,
	["CoreConfigurations"]:CoreConfigurations,
	["CoreSettings"]: {
		__typename?: "CoreSettings";
			id?:PartialObjects["CoreConfigurations"],
			displayName?:string,
			enableMultiPartnerOrders?:boolean,
			url?:string,
			fulfillmentMethods?:PartialObjects["FulfillmentMethod"][],
			fulfillmentTimeInterval?:number,
			theme?:PartialObjects["CoreSettingsTheme"]
	},
	["CoreSettingsTheme"]: {
		__typename?: "CoreSettingsTheme";
			colors?:PartialObjects["CoreSettingsThemeColors"],
			font?:string
	},
	["CoreSettingsThemeColors"]: {
		__typename?: "CoreSettingsThemeColors";
			PRIMARY?:string,
			SECONDARY?:string,
			TEXT?:string,
			DARK?:string
	},
	["CreateIncidentInput"]: {
	severity:PartialObjects["IncidentSeverity"],
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:PartialObjects["UserType"],
	description:string
},
	["CreatePromotionInput"]: {
	start:PartialObjects["Date"],
	end:PartialObjects["Date"],
	name:string,
	title:string,
	zoneIds?:string[],
	color:string,
	body:string,
	targetType?:PartialObjects["PromotionTarget"],
	callToActionText?:string,
	target?:string
},
	["Customer"]: {
		__typename?: "Customer";
			id?:string,
			name?:string,
			email?:string,
			created?:PartialObjects["Date"],
			phoneNumber?:string,
			isPhoneNumberVerified?:boolean,
			emailConfirmationTokenIssued?:PartialObjects["Date"],
			emailConfirmed?:boolean,
			roles?:string[],
			profileImageKey?:string,
			marketing?:boolean,
			referralLink?:string,
			referredBy?:PartialObjects["Customer"],
			referrals?:PartialObjects["Customer"][],
			stripeCustomerId?:string,
			addresses?:PartialObjects["Address"][],
			orders?:PartialObjects["Order"][],
			paymentMethods?:PartialObjects["PaymentMethod"][],
			orderStatistics?:PartialObjects["CustomerOrderStatistics"],
			accountCredit?:number,
			vouchers?:PartialObjects["CustomerVoucher"][],
			topups?:PartialObjects["CustomerWalletTopup"][]
	},
	["CustomerAnalytics"]: {
		__typename?: "CustomerAnalytics";
			id?:string,
			layoutGroups?:PartialObjects["ChartLayoutGroup"][]
	},
	["CustomerList"]: {
		__typename?: "CustomerList";
			items?:PartialObjects["Customer"][],
			total?:number,
			hasMore?:boolean
	},
	["CustomerOrderStatistics"]: {
		__typename?: "CustomerOrderStatistics";
			metres?:number,
			emissionsSaved?:number,
			lastUpdated?:PartialObjects["Date"]
	},
	["CustomerReusables"]: {
		__typename?: "CustomerReusables";
			id?:string,
			containersNotReturned?:number,
			containersClaimedReturned?:number,
			containersValidatedReturned?:number,
			containersScheduled?:number,
			containersScannedButNotScheduled?:number,
			maxContainersSchedulable?:number,
			totalDeposits?:number,
			slots?:PartialObjects["ReusablesPickupScheduleTime"][],
			pickups?:PartialObjects["ReusablesPickup"][],
			activePickup?:PartialObjects["ReusablesPickup"]
	},
	["CustomerUpdateInput"]: {
	name?:string,
	phoneNumber?:string
},
	["CustomerVoucher"]: {
		__typename?: "CustomerVoucher";
			id?:string,
			created?:PartialObjects["Date"],
			used?:boolean,
			discount?:PartialObjects["Discount"]
	},
	["CustomerWalletTopup"]: {
		__typename?: "CustomerWalletTopup";
			id?:string,
			createdAt?:PartialObjects["Date"],
			note?:string,
			amount?:number,
			paymentIntentId?:string,
			customer?:PartialObjects["Customer"]
	},
	["CustomerWalletTopupList"]: {
		__typename?: "CustomerWalletTopupList";
			items?:PartialObjects["CustomerWalletTopup"][],
			total?:number,
			hasMore?:boolean
	},
	/** Date custom scalar type */
["Date"]:any,
	["DayOfWeek"]:DayOfWeek,
	["DeliveryFlightPlan"]: {
		__typename?: "DeliveryFlightPlan";
			id?:string,
			createdAt?:PartialObjects["Date"],
			completedAt?:PartialObjects["Date"],
			route?:PartialObjects["JSON"],
			riderId?:string,
			legs?:PartialObjects["DeliveryFlightPlanLeg"][],
			activeOffer?:PartialObjects["FlightPlanOffer"]
	},
	["DeliveryFlightPlanLeg"]: {
		__typename?: "DeliveryFlightPlanLeg";
			id?:string,
			legOrder?:number,
			order?:PartialObjects["Order"],
			address?:PartialObjects["Address"]
	},
	["DeliveryJobInput"]: {
	id?:string,
	scheduledFor?:PartialObjects["Date"],
	destinationId?:string,
	originId?:string,
	destination:PartialObjects["AddressCreateInput"],
	origin:PartialObjects["AddressCreateInput"],
	size:PartialObjects["B2BDeliverySizes"]
},
	["DeliveryTimeArgs"]: {
		__typename?: "DeliveryTimeArgs";
			day?:PartialObjects["DeliveryTimeDay"],
			time?:PartialObjects["HourMinuteTimeString"]
	},
	["DeliveryTimeDay"]:DeliveryTimeDay,
	["DeliveryTimeInput"]: {
	day:PartialObjects["DeliveryTimeDay"],
	time:PartialObjects["HourMinuteTimeString"]
},
	["DeliveryTimes"]: {
		__typename?: "DeliveryTimes";
			date?:string,
			slots?:PartialObjects["Date"][]
	},
	["Discount"]: {
		__typename?: "Discount";
			id?:string,
			/** Code that customer enters to redeem this discount. */
	code?:string,
			description?:string,
			/** The value of this discount. Only needed for PERCENTAGE and FIXED discounts. */
	value?:number,
			/** The minimum price of the basket before this discount can be applied */
	minimumTotalPrice?:number,
			type?:PartialObjects["DiscountType"],
			enabled?:boolean,
			validFrom?:PartialObjects["Date"],
			validUntil?:PartialObjects["Date"],
			maxUsesGlobally?:number,
			maxUsesPerCustomer?:number,
			referralAmount?:number,
			orders?:PartialObjects["OrderList"],
			riderReferral?:PartialObjects["RiderReferralDiscount"]
	},
	["DiscountCreateInput"]: {
	code:string,
	description:string,
	validFrom:PartialObjects["Date"],
	validUntil:PartialObjects["Date"],
	type:PartialObjects["DiscountType"],
	value?:number,
	maxUsesGlobally:number,
	maxUsesPerCustomer:number,
	minimumTotalPrice:number,
	referralAmount?:number
},
	["DiscountList"]: {
		__typename?: "DiscountList";
			items?:PartialObjects["Discount"][],
			total?:number,
			hasMore?:boolean
	},
	/** The type of discount this is. */
["DiscountType"]:DiscountType,
	["DiscountUpdateInput"]: {
	description?:string,
	validFrom?:PartialObjects["Date"],
	validUntil?:PartialObjects["Date"],
	type?:PartialObjects["DiscountType"],
	value?:number,
	maxUsesGlobally?:number,
	maxUsesPerCustomer?:number,
	enabled?:boolean,
	referralAmount?:number
},
	["DistanceModel"]: {
		__typename?: "DistanceModel";
			metres?:number,
			seconds?:number
	},
	["DropBoxStatus"]:DropBoxStatus,
	/** A form that can represent any JSONSchema structured data. Designed for heavy data entry that does not fit with GraphQL */
["DynamicForm"]: {
		__typename?: "DynamicForm";
			/** A unique ID representing this forms template. */
	id?:string,
			/** The display type of the dynamic form */
	type?:PartialObjects["DynamicFormType"],
			title?:string,
			/** If provided, poll the API for a change in this form this often. */
	pollInterval?:number,
			canSubmit?:boolean,
			submitText?:string,
			description?:string,
			/** Values to populate the data structure represented by the Schema. */
	initialValues?:PartialObjects["JSON"],
			/** Always a valid JSONSchema object that can be represted as a form */
	schema?:PartialObjects["JSON"]
	},
	["DynamicFormResult"]: {
		__typename?: "DynamicFormResult";
			success?:boolean,
			successMessage?:string,
			form?:PartialObjects["DynamicForm"]
	},
	/** FORM displays as a form, TABLE as a table. Use initialValues as data. */
["DynamicFormType"]:DynamicFormType,
	["ExternalCompetitor"]: {
		__typename?: "ExternalCompetitor";
			name?:string,
			id?:string,
			updated?:PartialObjects["Date"],
			raw?:PartialObjects["JSON"]
	},
	["ExternalCompetitorInput"]: {
	name:string,
	id:string
},
	["File"]: {
		__typename?: "File";
			id?:string,
			createdAt?:PartialObjects["Date"],
			type?:string,
			title?:string,
			size?:number,
			/** A link to the file, subject to change. */
	url?:string
	},
	["FileList"]: {
		__typename?: "FileList";
			items?:PartialObjects["File"][],
			total?:number,
			hasMore?:boolean
	},
	["FinancialOverview"]: {
		__typename?: "FinancialOverview";
			meta?:PartialObjects["FinancialOverviewArgsMeta"],
			partners?:PartialObjects["RestaurantFinancialsView"][],
			riders?:PartialObjects["SpecificRiderFinancials"][],
			charities?:PartialObjects["CharityFinancials"][]
	},
	["FinancialOverviewArgsMeta"]: {
		__typename?: "FinancialOverviewArgsMeta";
			startDate?:PartialObjects["Date"],
			endDate?:PartialObjects["Date"],
			zoneIds?:string[]
	},
	["FlightPlanOffer"]: {
		__typename?: "FlightPlanOffer";
			id?:string,
			offered?:PartialObjects["Date"],
			changed?:PartialObjects["Date"],
			outcome?:string,
			fee?:number,
			sneaky?:boolean,
			context?:PartialObjects["JSON"],
			legs?:PartialObjects["DeliveryFlightPlanLeg"][],
			orders?:PartialObjects["Order"][],
			riderId?:string,
			rider?:PartialObjects["Rider"],
			flightPlanId?:string,
			flightPlan?:PartialObjects["DeliveryFlightPlan"]
	},
	["FlightPlanOfferList"]: {
		__typename?: "FlightPlanOfferList";
			items?:PartialObjects["FlightPlanOffer"][],
			total?:number,
			hasMore?:boolean
	},
	["FulfillmentDayTimes"]: {
		__typename?: "FulfillmentDayTimes";
			day?:PartialObjects["DeliveryTimeDay"],
			times?:PartialObjects["HourMinuteTimeString"][]
	},
	["FulfillmentMethod"]:FulfillmentMethod,
	["GenericAuditView"]: {
		__typename?: "GenericAuditView";
			id?:string,
			timestamp?:PartialObjects["Date"],
			targetId?:string,
			from?:PartialObjects["JSON"],
			to?:PartialObjects["JSON"]
	},
	["GraphQLGeoJSONLineString"]: {
		__typename?: "GraphQLGeoJSONLineString";
			type?:string,
			coordinates?:PartialObjects["Coordinates"][]
	},
	["GraphQLGeoJSONPoint"]: {
		__typename?: "GraphQLGeoJSONPoint";
			type?:string,
			coordinates?:PartialObjects["Coordinates"]
	},
	["GraphQLGeoJSONPointI"]: {
	type?:string,
	coordinates:PartialObjects["Coordinates"]
},
	["GraphQLGeoJSONPointInput"]: {
	type?:string,
	coordinates:PartialObjects["Coordinates"]
},
	["GraphQLGeoJSONPolygon"]: {
		__typename?: "GraphQLGeoJSONPolygon";
			type?:string,
			coordinates?:PartialObjects["Coordinates"][]
	},
	["GraphQLGeoJSONPolygonInput"]: {
	type?:string,
	coordinates:PartialObjects["Coordinates"][]
},
	["HomePage"]: {
		__typename?: "HomePage";
			meta?:PartialObjects["PartnerHomePageMeta"],
			promotions?:PartialObjects["HomepagePromotion"][],
			selections?:PartialObjects["PartnerSelection"][],
			partners?:PartialObjects["Restaurant"][],
			tags?:PartialObjects["PartnerTag"][],
			zone?:PartialObjects["Zone"],
			fulfillmentTimes?:PartialObjects["FulfillmentDayTimes"][],
			itemGroups?:PartialObjects["PartnerHomePageItemGroup"][]
	},
	["HomepagePromotion"]: {
		__typename?: "HomepagePromotion";
			id?:string,
			body?:string,
			title?:string,
			color?:string,
			imageKey?:string,
			callToActionText?:string,
			target?:string,
			targetType?:PartialObjects["PromotionTarget"]
	},
	/** HH:MM formatted time string */
["HourMinuteTimeString"]:any,
	["Hygiene"]: {
		__typename?: "Hygiene";
			id?:string,
			updated?:PartialObjects["Date"],
			rating?:string,
			authority?:PartialObjects["HygieneAuthorities"],
			authorityRatingId?:string,
			authorityRatingLink?:string,
			authorityRatingImage?:string,
			restaurant?:PartialObjects["Restaurant"]
	},
	["HygieneAuthorities"]:HygieneAuthorities,
	["IdentityDocumentType"]:IdentityDocumentType,
	["ImageAsset"]: {
		__typename?: "ImageAsset";
			id?:string,
			created?:PartialObjects["Date"],
			updated?:PartialObjects["Date"],
			title?:string,
			key?:string,
			width?:number,
			height?:number,
			fileSize?:number,
			altText?:string,
			mimeType?:string,
			url?:string,
			uploadedBy?:PartialObjects["RestaurantAdmin"]
	},
	["ImageAssetList"]: {
		__typename?: "ImageAssetList";
			items?:PartialObjects["ImageAsset"][],
			total?:number,
			hasMore?:boolean
	},
	["Incident"]: {
		__typename?: "Incident";
			id?:string,
			created?:PartialObjects["Date"],
			number?:number,
			severity?:PartialObjects["IncidentSeverity"],
			riderId?:string,
			orderId?:string,
			partnerId?:string,
			customerId?:string,
			atFault?:PartialObjects["UserType"],
			description?:string,
			customer?:PartialObjects["Customer"],
			order?:PartialObjects["Order"],
			rider?:PartialObjects["Rider"],
			partner?:PartialObjects["Restaurant"],
			/** Audit changes to the incident */
	audit?:PartialObjects["GenericAuditView"][],
			images?:PartialObjects["ImageAsset"][]
	},
	["IncidentList"]: {
		__typename?: "IncidentList";
			items?:PartialObjects["Incident"][],
			total?:number,
			hasMore?:boolean
	},
	["IncidentSeverity"]:IncidentSeverity,
	["Item"]: {
		__typename?: "Item";
			id?:string,
			isTemplate?:boolean,
			name?:string,
			description?:string,
			ingredients?:string,
			unitPrice?:number,
			/** The maximum number of this item that can be in a single order */
	maxPerOrder?:number,
			/** The emissions this item is responsible for, in grams of CO2 */
	emissions?:number,
			/** Whether this  */
	alcohol?:boolean,
			/** Whether this item is vegan or not */
	vegan?:boolean,
			/** Whether this item is vegetarian or not */
	vegetarian?:boolean,
			/** Weight of the item, in grams */
	weight?:number,
			/** A list of fulfillment methods this item cannot be ordered for */
	unavailableFor?:PartialObjects["FulfillmentMethod"][],
			/** Whether this item takes a long time to prepare */
	slow?:boolean,
			/** Whether this item has been approved for use */
	approved?:boolean,
			tax?:number,
			/** How hot this item is when served */
	heat?:PartialObjects["ItemHeatType"],
			/** Whether this item is published or not */
	published?:boolean,
			/** Whether this item is popular with customers or not */
	popular?:boolean,
			/** A path to the image representing the header for this merchant */
	headerImageKey?:string,
			/** A hash that represents the headerImage */
	blurhash?:string,
			/** The order in which this item should be shown in the menu */
	sortOrder?:number,
			/** Whether this item is still available to order, i.e. not out of stock */
	available?:boolean,
			/** When this item was deleted */
	deletedAt?:PartialObjects["Date"],
			categoryId?:string,
			/** The category to which this item belongs */
	category?:PartialObjects["MenuCategory"],
			/** The modifier groups this item has as options */
	modifiers?:PartialObjects["ModifierGroup"][],
			optionFor?:PartialObjects["ModifierGroup"][],
			containerTypeId?:string,
			price?:PartialObjects["Price"],
			headerImage?:string,
			squareImage?:string,
			menu?:PartialObjects["Menu"],
			containerType?:PartialObjects["ReusableContainerType"]
	},
	["ItemCreateInput"]: {
	id?:string,
	name:string,
	description?:string,
	ingredients?:string,
	unitPrice:number,
	alcohol?:boolean,
	popular?:boolean,
	headerImageUpload?:PartialObjects["Upload"],
	sortOrder?:number,
	modifierIds:string[],
	approved?:boolean,
	available:boolean,
	published:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId:string,
	heat?:PartialObjects["ItemHeatType"],
	unavailableFor?:PartialObjects["FulfillmentMethod"][],
	containerTypeId?:string
},
	["ItemHeatType"]:ItemHeatType,
	["ItemList"]: {
		__typename?: "ItemList";
			items?:PartialObjects["Item"][],
			total?:number,
			hasMore?:boolean
	},
	["ItemSales"]: {
		__typename?: "ItemSales";
			item?:PartialObjects["Item"],
			numberSold?:number,
			totalValue?:number
	},
	["ItemUpdateAsRestaurantInput"]: {
	unitPrice?:number,
	alcohol?:boolean,
	popular?:boolean,
	sortOrder?:number,
	modifierIds?:string[],
	available?:boolean,
	published?:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId?:string,
	heat?:PartialObjects["ItemHeatType"],
	unavailableFor?:PartialObjects["FulfillmentMethod"][],
	containerTypeId?:string
},
	["ItemUpdateInput"]: {
	id?:string,
	name?:string,
	description?:string,
	ingredients?:string,
	unitPrice?:number,
	alcohol?:boolean,
	popular?:boolean,
	headerImageUpload?:PartialObjects["Upload"],
	sortOrder?:number,
	modifierIds?:string[],
	approved?:boolean,
	available?:boolean,
	published?:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId?:string,
	heat?:PartialObjects["ItemHeatType"],
	unavailableFor?:PartialObjects["FulfillmentMethod"][],
	containerTypeId?:string
},
	["ItemUpdateRequest"]: {
		__typename?: "ItemUpdateRequest";
			id?:string,
			created?:PartialObjects["Date"],
			status?:PartialObjects["ItemUpdateRequestStatus"],
			name?:string,
			description?:string,
			imageKey?:string,
			item?:PartialObjects["Item"],
			partner?:PartialObjects["Restaurant"]
	},
	["ItemUpdateRequestInput"]: {
	description?:string,
	name?:string,
	itemId:string,
	partnerId:string
},
	["ItemUpdateRequestList"]: {
		__typename?: "ItemUpdateRequestList";
			items?:PartialObjects["ItemUpdateRequest"][],
			total?:number,
			hasMore?:boolean
	},
	["ItemUpdateRequestStatus"]:ItemUpdateRequestStatus,
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any,
	["Lease"]: {
		__typename?: "Lease";
			id?:string,
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			initialMileage?:number,
			weeklyMileageAllowance?:number,
			excessiveMileageCharge?:number,
			totalDeposit?:number,
			initialDeposit?:number,
			depositGraceWeeks?:number,
			numberOfDepositWeeks?:number,
			firstDaysFree?:number,
			pricePerDay?:number,
			vehicleId?:string,
			vehicle?:PartialObjects["LeaseVehicle"],
			riderId?:string,
			rider?:PartialObjects["Rider"]
	},
	["LeaseList"]: {
		__typename?: "LeaseList";
			items?:PartialObjects["LeaseView"][],
			total?:number,
			hasMore?:boolean
	},
	["LeasePayment"]: {
		__typename?: "LeasePayment";
			id?:string,
			created?:PartialObjects["Date"],
			type?:string,
			dueAt?:PartialObjects["Date"],
			paidAt?:PartialObjects["Date"],
			leaseId?:string,
			lease?:PartialObjects["Lease"],
			value?:number,
			stripePaymentIntentId?:string,
			status?:PartialObjects["LeasePaymentStatus"]
	},
	["LeasePaymentStatus"]:LeasePaymentStatus,
	["LeaseVehicle"]: {
		__typename?: "LeaseVehicle";
			id?:string,
			created?:PartialObjects["Date"],
			note?:string,
			registration?:string,
			status?:PartialObjects["LeaseVehicleStatus"],
			dvlaInfo?:PartialObjects["JSON"],
			leases?:PartialObjects["Lease"][]
	},
	["LeaseVehicleList"]: {
		__typename?: "LeaseVehicleList";
			items?:PartialObjects["LeaseVehicle"][],
			total?:number,
			hasMore?:boolean
	},
	["LeaseVehicleStatus"]:LeaseVehicleStatus,
	["LeaseView"]: {
		__typename?: "LeaseView";
			id?:string,
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			initialMileage?:number,
			weeklyMileageAllowance?:number,
			excessiveMileageCharge?:number,
			totalDeposit?:number,
			initialDeposit?:number,
			depositGraceWeeks?:number,
			numberOfDepositWeeks?:number,
			firstDaysFree?:number,
			pricePerDay?:number,
			vehicleId?:string,
			vehicle?:PartialObjects["LeaseVehicle"],
			riderId?:string,
			rider?:PartialObjects["Rider"],
			totalPaid?:number,
			outstandingBalance?:number,
			outstandingDeposit?:number,
			nextDepositAmount?:number,
			nextDepositDate?:PartialObjects["Date"],
			nextWeeklyAmount?:number,
			nextWeeklyDate?:PartialObjects["Date"],
			lastDepositPayment?:PartialObjects["Date"],
			lastWeeklyPayment?:PartialObjects["Date"],
			payments?:PartialObjects["LeasePayment"][]
	},
	["LineChart"]: {
		__typename?: "LineChart";
			id?:string,
			type?:string,
			series?:PartialObjects["Series"][]
	},
	["LocationSearchResult"]: {
		__typename?: "LocationSearchResult";
			id?:string,
			text?:string,
			provider?:string,
			addressLine1?:string,
			postcode?:string,
			coordinates?:PartialObjects["Coordinates"]
	},
	["LoginNumberPayload"]: {
	number:string,
	password:string,
	userType?:string
},
	["LoginOAuthProviders"]:LoginOAuthProviders,
	["LoginPayload"]: {
	email:string,
	password:string,
	userType?:string,
	twoFactorCode?:string
},
	["MarketingCampaign"]: {
		__typename?: "MarketingCampaign";
			id?:string,
			name?:string,
			status?:PartialObjects["MarketingCampaignStatus"],
			sends?:number,
			scheduledFor?:PartialObjects["Date"]
	},
	["MarketingCampaignList"]: {
		__typename?: "MarketingCampaignList";
			items?:PartialObjects["MarketingCampaign"][],
			total?:number,
			hasMore?:boolean
	},
	["MarketingCampaignStatus"]:MarketingCampaignStatus,
	["Menu"]: {
		__typename?: "Menu";
			id?:string,
			isTemplate?:boolean,
			name?:string,
			items?:PartialObjects["Item"][],
			modifierGroups?:PartialObjects["ModifierGroup"][],
			categories?:PartialObjects["MenuCategory"][],
			restaurant?:PartialObjects["Restaurant"]
	},
	/** A menu category is a top-level listing of items that can be directly purchased */
["MenuCategory"]: {
		__typename?: "MenuCategory";
			id?:string,
			name?:string,
			description?:string,
			sortOrder?:number,
			topLevel?:boolean,
			items?:PartialObjects["Item"][],
			times?:PartialObjects["OpeningTime"][],
			menu?:PartialObjects["Menu"]
	},
	["MenuCategoryCreateInput"]: {
	id?:string,
	name:string,
	description?:string,
	sortOrder?:number,
	topLevel?:boolean,
	times?:PartialObjects["OpeningTimeInput"][]
},
	["MenuModifierGroupCreateInput"]: {
	id?:string,
	name:string,
	maxOptions:number,
	minOptions:number,
	maxPerOption?:number,
	instruction?:string,
	sortOrder?:number,
	optionIds:string[],
	priceStrategy:PartialObjects["PriceStrategy"],
	cascades:boolean,
	approved?:boolean
},
	["MerchantSite"]: {
		__typename?: "MerchantSite";
			id?:string,
			name?:string,
			description?:string,
			status?:PartialObjects["MerchantSiteStatus"],
			domains?:PartialObjects["MerchantSiteDomain"][],
			pages?:PartialObjects["MerchantSitePage"][],
			screenshot?:PartialObjects["ImageAsset"]
	},
	["MerchantSiteDomain"]: {
		__typename?: "MerchantSiteDomain";
			id?:string,
			createdAt?:PartialObjects["Date"],
			domain?:string,
			isVerified?:boolean,
			dns?:string
	},
	["MerchantSiteList"]: {
		__typename?: "MerchantSiteList";
			items?:PartialObjects["MerchantSite"][],
			total?:number,
			hasMore?:boolean
	},
	["MerchantSitePage"]: {
		__typename?: "MerchantSitePage";
			id?:string,
			slug?:string,
			title?:string,
			layout?:PartialObjects["JSON"],
			screenshot?:PartialObjects["ImageAsset"],
			site?:PartialObjects["MerchantSite"]
	},
	["MerchantSitePageCreateInput"]: {
	slug:string,
	title:string,
	layout:PartialObjects["JSON"],
	siteId:string
},
	["MerchantSitePageUpdateInput"]: {
	slug?:string,
	title?:string,
	layout?:string
},
	["MerchantSiteStatus"]:MerchantSiteStatus,
	["MessageParticipant"]: {
		__typename?: "MessageParticipant";
			id?:string,
			type?:PartialObjects["UserType"],
			name?:string,
			email?:string,
			avatarUrl?:string
	},
	["ModifierGroup"]: {
		__typename?: "ModifierGroup";
			id?:string,
			name?:string,
			instruction?:string,
			maxOptions?:number,
			minOptions?:number,
			/** Maximum selections of each individual option. */
	maxPerOption?:number,
			cascades?:boolean,
			sortOrder?:number,
			priceStrategy?:PartialObjects["PriceStrategy"],
			options?:PartialObjects["Item"][],
			optionFor?:PartialObjects["Item"][],
			menuId?:string
	},
	["Mutation"]: {
		__typename?: "Mutation";
			submitDynamicForm?:PartialObjects["DynamicFormResult"],
			updateCustomer?:PartialObjects["Customer"],
			deleteCustomer?:boolean,
			addPaymentMethod?:boolean,
			addPaymentMethodWithCard?:boolean,
			addVoucherCode?:PartialObjects["Customer"],
			removePaymentMethod?:boolean,
			addAddress?:PartialObjects["Address"],
			placeOrder?:PartialObjects["PlaceOrderResponse"],
			updateOrder?:PartialObjects["Order"],
			rateOrder?:boolean,
			/** Tip your rider after the delivery is complete. */
	OrderTipRider?:PartialObjects["Order"],
			adjustOrder?:PartialObjects["Order"],
			/** Accept this order and begin preparing it. */
	acceptOrder?:PartialObjects["Order"],
			acceptOrderAsRestaurant?:boolean,
			completeOrderAsRestaurant?:boolean,
			rejectOrderAsRestaurant?:boolean,
			arriveForOwnOrder?:boolean,
			pickUpOwnOrder?:boolean,
			/** Delay the pickup of this order by 10 minutes. */
	delayPickup?:PartialObjects["Order"],
			/** Prevent this order from being assigned to any rider. Invisible to anyone else. */
	blockPickup?:PartialObjects["Order"],
			/** Remove the assigned rider from this order. Also rejects any pending offers if they are relevant. */
	OrderRemoveRiderAsAdmin?:PartialObjects["Order"],
			/** Marks this order as complete, regardless of its current status. */
	OrderCompleteAsAdmin?:PartialObjects["Order"],
			/** Bring the pickup time forward for this order. Can encourage the algorithm to prioritise it over others. */
	OrderBringForward?:PartialObjects["Order"],
			rescheduleOrder?:PartialObjects["Order"],
			resolveOrderAlert?:PartialObjects["OrderAlert"],
			PurchaseWalletTopup?:PartialObjects["PurchaseCustomerWalletTopup"],
			/** Mark this item as no longer available */
	setAvailability?:PartialObjects["Item"],
			setItemAvailability?:PartialObjects["Item"],
			updateItemHeaderImage?:PartialObjects["Item"],
			removeItemHeaderImage?:PartialObjects["Item"],
			updateItem?:PartialObjects["Item"],
			deleteItem?:PartialObjects["Item"],
			updateItemAsRestaurant?:PartialObjects["Item"],
			basketApplyDiscount?:PartialObjects["Basket"],
			/** Add an item to the basket */
	basketAddItem?:PartialObjects["Basket"],
			/** Increment an item in the basket */
	basketIncrementItem?:PartialObjects["Basket"],
			/** Decrement an item in the basket */
	basketDecrementItem?:PartialObjects["Basket"],
			/** Edit an item in the basket */
	basketEditItem?:PartialObjects["Basket"],
			/** Update tip for the basket */
	basketIncrementOrDecrementTip?:PartialObjects["Basket"],
			/** Set the rider tip for the basket */
	basketSetTip?:PartialObjects["Basket"],
			/** Set the rider tip for the basket */
	basketSetNotes?:boolean,
			basketSetInstructions?:PartialObjects["Basket"],
			/** Generates a payment intent for the basket, allowing the customer to start client-side payment flow */
	basketCheckOut?:PartialObjects["Basket"],
			basketSetSingleDelivery?:PartialObjects["Basket"],
			basketSetReusables?:PartialObjects["Basket"],
			/** Add a donation to the basket */
	basketDonationAdd?:PartialObjects["Basket"],
			/** Remove a donation from the basket */
	basketDonationRemove?:PartialObjects["Basket"],
			updateCharity?:PartialObjects["Charity"],
			createCharity?:PartialObjects["Charity"],
			customerCharityUIView?:PartialObjects["ConsumerCharityCampaignView"],
			createCharityCampaign?:PartialObjects["CharityCampaign"],
			updateCharityCampaign?:PartialObjects["CharityCampaign"],
			/** Swap a containers code for another. The existing code will continue to function. */
	SwapContainerCode?:PartialObjects["ReusableContainer"],
			ScanContainerAtPartner?:PartialObjects["ScanContainerAtPartnerResponse"],
			ScanContainer?:PartialObjects["CustomerReusables"],
			CancelContainerPickup?:PartialObjects["CustomerReusables"],
			UpdateContainerPickup?:PartialObjects["ReusablesPickup"],
			BookContainerPickup?:PartialObjects["CustomerReusables"],
			/** Assign a shipment to be fulfilled at the given washing location */
	AssignReusableShipment?:PartialObjects["ReusableShipment"],
			/** Mark a shipment as complete and schedule the order for it */
	CompleteReusableShipment?:PartialObjects["ReusableShipment"],
			createRestaurant?:PartialObjects["Restaurant"],
			updateRestaurant?:PartialObjects["Restaurant"],
			setFulfillmentMethods?:PartialObjects["Restaurant"],
			setAcceptsScheduledOrders?:PartialObjects["Restaurant"],
			updateRestaurantHeaderImage?:PartialObjects["Restaurant"],
			setBusy?:PartialObjects["Restaurant"],
			setOnline?:boolean,
			setOwner?:boolean,
			markItemsUnavailable?:boolean,
			createPartnerTag?:PartialObjects["PartnerTag"],
			updatePartnerTag?:PartialObjects["PartnerTag"],
			deletePartnerTag?:boolean,
			PartnerTabletSync?:PartialObjects["PartnerTablet"],
			PartnerTabletAction?:PartialObjects["PartnerTablet"],
			addItem?:PartialObjects["Item"][],
			addCategory?:PartialObjects["Menu"],
			reorderCategory?:PartialObjects["MenuCategory"],
			reorderModifierGroups?:PartialObjects["Menu"],
			reorderCategories?:PartialObjects["Menu"],
			addModifierGroup?:PartialObjects["Menu"],
			importMenu?:boolean,
			updateCategoryAsRestaurant?:PartialObjects["MenuCategory"],
			createItemUpdateRequest?:PartialObjects["ItemUpdateRequest"],
			updateItemUpdateRequest?:PartialObjects["ItemUpdateRequest"],
			updateRestaurantAdmin?:PartialObjects["RestaurantAdmin"],
			createSuperAdmin?:PartialObjects["RestaurantAdmin"],
			updateTabletAccountPassword?:boolean,
			createZone?:PartialObjects["Zone"],
			boostZone?:PartialObjects["Boost"],
			updateZone?:PartialObjects["Zone"],
			setActivePolygon?:PartialObjects["Zone"],
			endBoost?:PartialObjects["Boost"],
			createZonePolygon?:PartialObjects["ZonePolygon"],
			createRider?:PartialObjects["Rider"],
			updateRiderLocation?:PartialObjects["Rider"],
			unthrottleRider?:PartialObjects["Rider"],
			sendMessageToRider?:boolean,
			updateRider?:PartialObjects["Rider"],
			requestNotification?:boolean,
			endRiderOnlineSession?:PartialObjects["RiderOnlineSession"],
			deleteRiderOnlineSession?:boolean,
			createRiderApplication?:PartialObjects["RiderApplication"],
			deleteRiderApplication?:boolean,
			RiderLiveUIValidate?:PartialObjects["RiderLiveView"],
			RiderLiveUIAction?:PartialObjects["RiderLiveView"],
			RiderLiveUISetting?:PartialObjects["RiderLiveView"],
			PayLeasePayment?:PartialObjects["LeasePayment"],
			sendChatMessage?:PartialObjects["ChatMessage"],
			login?:PartialObjects["AuthDTO"],
			/** Exchange an OAuth token for ecoeats auth tokens */
	loginOAuth?:PartialObjects["AuthDTO"],
			loginPhone?:PartialObjects["AuthDTO"],
			setupTwoFactorAuthentication?:PartialObjects["TwoFactorSetup"],
			confirmTwoFactorAuthentication?:boolean,
			refreshToken?:PartialObjects["AuthDTO"],
			logout?:boolean,
			register?:PartialObjects["AuthDTO"],
			resetPasswordRequest?:boolean,
			resetPasswordWithToken?:boolean,
			requestPhoneNumberVerification?:boolean,
			verifyPhoneNumberCode?:boolean,
			setFirebaseCloudMessagingToken?:boolean,
			requestConfirmationEmail?:boolean,
			confirmEmailWithToken?:boolean,
			UserUpdateProfilePicture?:PartialObjects["User"],
			createDiscount?:PartialObjects["Discount"],
			updateDiscount?:PartialObjects["Discount"],
			createRiderReferralDiscount?:PartialObjects["RiderReferralDiscount"],
			updateAddress?:PartialObjects["Address"],
			createAddress?:PartialObjects["Address"],
			deleteAddress?:boolean,
			makeBooking?:PartialObjects["Booking"],
			cancelBooking?:PartialObjects["Booking"],
			updateBooking?:PartialObjects["Booking"],
			decideBooking?:PartialObjects["Booking"],
			createBookingSlot?:PartialObjects["BookingSlot"],
			updateBookingSlot?:PartialObjects["BookingSlot"],
			deleteBookingSlot?:boolean,
			autoCreateBookingSlots?:boolean,
			AutoBookingSlotsConfigList?:PartialObjects["AutoBookingSlotsConfigList"],
			createAutoBookingConfig?:PartialObjects["AutoBookingSlotsConfig"],
			deleteAutoBookingSlot?:PartialObjects["AutoBookingSlotsConfig"],
			createAutoBookingSlot?:PartialObjects["AutoBookingSlot"],
			createCity?:PartialObjects["City"],
			exportOrders?:string,
			/** Finds the invoice for the arguments, creates one if it does not exist */
	riderInvoice?:PartialObjects["RiderInvoice"],
			payRiderInvoice?:PartialObjects["RiderInvoice"],
			payRiderInvoices?:PartialObjects["RiderInvoicesJob"],
			PartnerInvoiceForPeriod?:PartialObjects["PartnerInvoice"],
			payPartnerInvoice?:PartialObjects["PartnerInvoice"],
			updatePartnerPaymentDetails?:PartialObjects["PartnerPaymentDetails"],
			createRiderAdjustment?:PartialObjects["RiderAdjustment"],
			updateRiderAdjustment?:PartialObjects["RiderAdjustment"],
			createPartnerAdjustment?:PartialObjects["PartnerAdjustment"],
			updatePartnerAdjustment?:PartialObjects["PartnerAdjustment"],
			uploadPromotionImage?:PartialObjects["Promotion"],
			createPromotion?:PartialObjects["Promotion"],
			updatePromotion?:PartialObjects["Promotion"],
			estimateB2BJob?:PartialObjects["B2BJobEstimateResponse"],
			executeB2BJob?:PartialObjects["B2BDeliveryJob"],
			cancelB2BJob?:PartialObjects["B2BDeliveryJob"],
			createIncident?:PartialObjects["Incident"],
			updateIncident?:PartialObjects["Incident"],
			uploadImageAsset?:PartialObjects["ImageAsset"],
			UploadVideoAsset?:PartialObjects["UploadMediaJob"],
			UploadMediaComplete?:PartialObjects["VideoAsset"],
			AdminPageView?:PartialObjects["AdminPageView"],
			createMerchantSitePage?:PartialObjects["MerchantSitePage"],
			updateMerchantSitePage?:PartialObjects["MerchantSitePage"],
			createMerchantSiteDomain?:PartialObjects["MerchantSiteDomain"]
	},
	["OAuthConfiguration"]: {
		__typename?: "OAuthConfiguration";
			apple?:PartialObjects["OAuthConfigurationApple"],
			google?:PartialObjects["OAuthConfigurationGoogle"],
			facebook?:PartialObjects["OAuthConfigurationFacebook"]
	},
	["OAuthConfigurationApple"]: {
		__typename?: "OAuthConfigurationApple";
			clientId?:string,
			redirectURI?:string
	},
	["OAuthConfigurationFacebook"]: {
		__typename?: "OAuthConfigurationFacebook";
			appId?:string
	},
	["OAuthConfigurationGoogle"]: {
		__typename?: "OAuthConfigurationGoogle";
			clientId?:string,
			redirectURI?:string
	},
	["OpeningTime"]: {
		__typename?: "OpeningTime";
			id?:string,
			open?:string,
			close?:string,
			daysOfWeek?:PartialObjects["DayOfWeek"][],
			restaurant?:PartialObjects["Restaurant"],
			menuCategory?:PartialObjects["MenuCategory"],
			zone?:PartialObjects["Zone"]
	},
	["OpeningTimeInput"]: {
	id?:string,
	open:string,
	close:string,
	daysOfWeek?:string[]
},
	["Order"]: {
		__typename?: "Order";
			id?:string,
			/** A string to indentify the order at the restaurant */
	number?:string,
			fulfilledBy?:PartialObjects["OrderFulfilledBy"],
			placedAt?:PartialObjects["Date"],
			partnerAcknowlegedAt?:PartialObjects["Date"],
			scheduledFor?:PartialObjects["Date"],
			initialPickupAt?:PartialObjects["Date"],
			pickupAt?:PartialObjects["Date"],
			pickupDelayedByPartner?:boolean,
			pickedDelayedByAdminAt?:PartialObjects["Date"],
			orderMarkedReadyAt?:PartialObjects["Date"],
			status?:string,
			/** Whether this order has been viewed by the restaurant */
	opened?:boolean,
			allergyNotes?:string,
			internalNote?:string,
			riderEarnings?:number,
			baseRiderEarnings?:number,
			deliveryEstimate?:number,
			deliveryEstimateMax?:number,
			estimatedArrivalTime?:PartialObjects["Date"],
			estimatedArrivalTimeDelta?:number,
			stripeFee?:number,
			fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
			ourAdjustment?:number,
			ourAdjustmentJustification?:string,
			partnerAdjustment?:number,
			partnerAdjustmentJustification?:string,
			riderEarningsAdjustment?:number,
			riderEarningsAdjustmentJustification?:string,
			riderTipAdjustment?:number,
			riderTipAdjustmentJustification?:string,
			orderType?:PartialObjects["OrderType"],
			/** The number of size points the items of this order sum to */
	sizePoints?:number,
			/** Number of child orders needed to complete this order */
	numberOfChildOrders?:number,
			/** The child orders for this object. Only populated when isSplit = true and orderType = PARENT */
	childOrders?:PartialObjects["Order"][],
			/** The parent order for this order. Only populated when isSplit = true and orderType = CHILD */
	parentOrderId?:string,
			/** The parent order for this order. Only populated when isSplit = true and orderType = CHILD */
	parentOrder?:PartialObjects["Order"],
			customerLastRemindedToPickUp?:PartialObjects["Date"],
			isBeingOfferedToRider?:boolean,
			lastOfferedToRider?:PartialObjects["Date"],
			isRiderAtRestaurant?:boolean,
			riderArrivedAtRestaurantTime?:PartialObjects["Date"],
			riderPickedUpTime?:PartialObjects["Date"],
			riderArrivedAtCustomerTime?:PartialObjects["Date"],
			riderDroppedOffTime?:PartialObjects["Date"],
			pickupBlocked?:boolean,
			longWaitReportedAt?:PartialObjects["Date"],
			riderRating?:number,
			partnerRating?:number,
			amountRefunded?:number,
			cancellationReason?:PartialObjects["OrderCancellationReason"],
			basket?:PartialObjects["Basket"],
			customer?:PartialObjects["Customer"],
			rider?:PartialObjects["Rider"],
			addressId?:string,
			address?:PartialObjects["Address"],
			restaurant?:PartialObjects["Restaurant"],
			offers?:PartialObjects["OrderOffer"][],
			b2bDeliveryJob?:PartialObjects["B2BDeliveryJob"],
			b2bDeliveryJobId?:string,
			aggregateOrder?:PartialObjects["AggregateOrder"],
			unreadMessageCount?:number,
			canTipRider?:boolean,
			/** Information about the reusables used for this order. */
	reusables?:PartialObjects["ReusablesInfo"],
			/** A polyline representing the directions that either the rider or customer needs to follow. 
      This is not to be used for displaying to riders in app, but rather as a decorative element 
      to show customers when tracking the order. */
	directions?:string,
			reusablesPickupAggregate?:PartialObjects["ReusablesPickupAggregate"],
			riderReport?:PartialObjects["OrderRiderRating"],
			reusableShipment?:PartialObjects["ReusableShipment"]
	},
	["OrderAdjustInput"]: {
	ourAdjustment:number,
	partnerAdjustment:number,
	riderEarningsAdjustment:number,
	riderTipAdjustment:number,
	ourAdjustmentJustification?:string,
	partnerAdjustmentJustification?:string,
	riderEarningsAdjustmentJustification?:string,
	riderTipAdjustmentJustification?:string
},
	["OrderAlert"]: {
		__typename?: "OrderAlert";
			id?:string,
			type?:PartialObjects["OrderAlertType"],
			created?:PartialObjects["Date"],
			resolved?:PartialObjects["Date"],
			resolvedBy?:PartialObjects["RestaurantAdmin"],
			riderId?:string,
			rider?:PartialObjects["Rider"],
			orderId?:string,
			order?:PartialObjects["Order"],
			zoneId?:string,
			zone?:PartialObjects["Zone"]
	},
	["OrderAlertList"]: {
		__typename?: "OrderAlertList";
			items?:PartialObjects["OrderAlert"][],
			total?:number,
			hasMore?:boolean
	},
	["OrderAlertType"]:OrderAlertType,
	["OrderCancellationReason"]:OrderCancellationReason,
	["OrderCreateInput"]: {
	/** The stripe payment method */
	paymentMethodId?:string,
	/** A tokenised payment method */
	paymentMethodToken?:string,
	/** The basket for the order */
	basketId:string
},
	["OrderEvent"]: {
		__typename?: "OrderEvent";
			id?:string,
			timestamp?:PartialObjects["Date"],
			targetId?:string,
			from?:PartialObjects["JSON"],
			to?:PartialObjects["JSON"]
	},
	["OrderEventList"]: {
		__typename?: "OrderEventList";
			items?:PartialObjects["OrderEvent"][],
			total?:number,
			hasMore?:boolean
	},
	["OrderFulfilledBy"]:OrderFulfilledBy,
	["Ordering"]:Ordering,
	/** An item in an order or a basket */
["OrderItem"]: {
		__typename?: "OrderItem";
			/** A descriptor unique to this order item */
	id?:string,
			added?:PartialObjects["Date"],
			/** Fields copied from the item, */
	name?:string,
			/** Fields copied from the item, */
	description?:string,
			quantity?:number,
			/** Unit price of of OrderItem including modifiers */
	unitPrice?:number,
			/** Unit weight of the menu item */
	unitWeight?:number,
			/** Total price including modifiers and quantity */
	totalPrice?:number,
			alcohol?:boolean,
			modifierItems?:PartialObjects["OrderItemModifier"][],
			menuItemId?:string,
			basketId?:string,
			basket?:PartialObjects["Basket"]
	},
	/** A modifier for an item in an order or basket. This should be flattend,
regardless of modifier groups appearing in the actual menu display */
["OrderItemModifier"]: {
		__typename?: "OrderItemModifier";
			id?:string,
			name?:string,
			quantity?:number,
			unitPrice?:number,
			/** Unit weight of the menu item */
	unitWeight?:number,
			menuItemId?:string,
			modifierGroupId?:string,
			/** The menu modifier group for which this is an order entry */
	modifierGroup?:PartialObjects["ModifierGroup"],
			orderItemId?:string,
			/** The parent OrderItem for which this is a modifier item of a modifier group */
	orderItem?:PartialObjects["OrderItem"]
	},
	["OrderList"]: {
		__typename?: "OrderList";
			items?:PartialObjects["Order"][],
			total?:number,
			hasMore?:boolean
	},
	["OrderOffer"]: {
		__typename?: "OrderOffer";
			id?:string,
			offered?:PartialObjects["Date"],
			changed?:PartialObjects["Date"],
			outcome?:string,
			distance?:number,
			riderId?:string,
			rider?:PartialObjects["Rider"],
			orderId?:string,
			order?:PartialObjects["Order"]
	},
	["OrderRatingInput"]: {
	riderRating:number,
	partnerRating:number
},
	["OrderRejectionInput"]: {
	restaurantId?:string,
	orderId:string,
	/** Items that are in the order but no longer in stock */
	outOfStockItems?:string[],
	reason?:PartialObjects["OrderCancellationReason"]
},
	["OrderRescheduleInput"]: {
	skipPrepTime:boolean,
	split:boolean,
	isPartnerResponsible:boolean,
	includeItems:string[]
},
	["OrderRiderRating"]: {
		__typename?: "OrderRiderRating";
			positive?:boolean,
			ratings?:string[],
			note?:string,
			id?:string,
			order?:PartialObjects["Order"],
			rider?:PartialObjects["Rider"]
	},
	["OrderRiderRatingList"]: {
		__typename?: "OrderRiderRatingList";
			items?:PartialObjects["OrderRiderRating"][],
			total?:number,
			hasMore?:boolean
	},
	["OrderRiderRatingType"]:OrderRiderRatingType,
	["OrderStatus"]:OrderStatus,
	["OrderType"]:OrderType,
	["PartnerAdjustment"]: {
		__typename?: "PartnerAdjustment";
			id?:string,
			created?:PartialObjects["Date"],
			forDate?:PartialObjects["Date"],
			amount?:number,
			explanation?:string,
			partnerId?:string,
			partner?:PartialObjects["Restaurant"]
	},
	["PartnerAdjustmentCreateInput"]: {
	forDate:PartialObjects["Date"],
	amount:number,
	explanation:string,
	partnerId:string
},
	["PartnerAdjustmentList"]: {
		__typename?: "PartnerAdjustmentList";
			items?:PartialObjects["PartnerAdjustment"][],
			total?:number,
			hasMore?:boolean
	},
	["PartnerAdjustmentUpdateInput"]: {
	forDate?:PartialObjects["Date"],
	amount?:number,
	explanation?:string,
	partnerId?:string
},
	["PartnerAnalytics"]: {
		__typename?: "PartnerAnalytics";
			id?:string,
			meta?:PartialObjects["PartnerAnalyticsMeta"],
			orderCount?:number,
			prepTimes?:PartialObjects["PartnerPrepTimeAnalytics"][],
			waitTimes?:PartialObjects["PercentileValue"][],
			layoutGroups?:PartialObjects["ChartLayoutGroup"][]
	},
	["PartnerAnalyticsMeta"]: {
		__typename?: "PartnerAnalyticsMeta";
			id?:string,
			startDate?:PartialObjects["Date"],
			dayOfWeek?:number,
			endDate?:PartialObjects["Date"],
			timeRange?:string[]
	},
	["PartnerCommissionPlan"]: {
		__typename?: "PartnerCommissionPlan";
			id?:string,
			created?:PartialObjects["Date"],
			ended?:PartialObjects["Date"],
			plan?:PartialObjects["CommissionPlan"],
			rung?:PartialObjects["CommissionPlanRung"],
			partner?:PartialObjects["Restaurant"]
	},
	["PartnerHomePageItemGroup"]: {
		__typename?: "PartnerHomePageItemGroup";
			id?:string,
			title?:string,
			items?:PartialObjects["Item"][]
	},
	["PartnerHomePageMeta"]: {
		__typename?: "PartnerHomePageMeta";
			skip?:number,
			take?:number,
			lat?:number,
			lng?:number,
			zoneId?:string,
			zoneSlug?:string,
			/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:PartialObjects["FulfillmentMethod"],
			/** An optional query field to allow for user searches */
	query?:string,
			/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:boolean,
			tags?:string[],
			sortBy?:string,
			order?:string,
			coreConfiguration?:PartialObjects["CoreConfigurations"],
			time?:PartialObjects["DeliveryTimeArgs"]
	},
	["PartnerInvoice"]: {
		__typename?: "PartnerInvoice";
			id?:string,
			/** Whether this invoice has been paid */
	paid?:boolean,
			value?:number,
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			numberOfOrders?:number,
			sales?:number,
			adjustments?:number,
			commission?:number,
			purchases?:number,
			vat?:number,
			ref?:string,
			revolutTransactionId?:string,
			revolutTransactionLink?:string,
			partnerId?:string,
			partner?:PartialObjects["Restaurant"]
	},
	["PartnerInvoiceList"]: {
		__typename?: "PartnerInvoiceList";
			items?:PartialObjects["PartnerInvoice"][],
			total?:number,
			hasMore?:boolean
	},
	["PartnerPaymentDetails"]: {
		__typename?: "PartnerPaymentDetails";
			id?:string,
			accountNo?:string,
			sortCode?:string,
			isCompany?:boolean,
			individualName?:string,
			restaurant?:PartialObjects["Restaurant"]
	},
	["PartnerPrepTimeAnalytics"]: {
		__typename?: "PartnerPrepTimeAnalytics";
			percentile?:string,
			value?:number
	},
	["PartnerSelection"]: {
		__typename?: "PartnerSelection";
			name?:string,
			partners?:PartialObjects["Restaurant"][],
			type?:PartialObjects["PartnerSelectionType"]
	},
	["PartnerSelectionType"]:PartnerSelectionType,
	["PartnerTablet"]: {
		__typename?: "PartnerTablet";
			id?:string,
			created?:PartialObjects["Date"],
			lastConnection?:PartialObjects["Date"],
			serial?:string,
			model?:string,
			manufacturer?:string,
			battery?:number,
			release?:string,
			batteryState?:string,
			ram?:number,
			usedRam?:number,
			volume?:number,
			wifiNetwork?:string,
			bssid?:string,
			routerMake?:string,
			wifiStrength?:number,
			width?:number,
			height?:number,
			ip?:string,
			hostname?:string,
			isp?:string,
			location?:PartialObjects["GraphQLGeoJSONPoint"],
			partnerId?:string,
			partner?:PartialObjects["Restaurant"],
			webRTCOfferDescription?:PartialObjects["JSON"],
			webRTCOfferReceiveDescription?:PartialObjects["JSON"],
			webRTCOfferReceiveCandidates?:PartialObjects["JSON"],
			webRTCOfferCandidates?:PartialObjects["JSON"],
			screenshots?:PartialObjects["ImageAsset"][]
	},
	["PartnerTabletAction"]: {
		__typename?: "PartnerTabletAction";
			id?:PartialObjects["PartnerTabletActionType"],
			tabletId?:string,
			payload?:PartialObjects["JSON"]
	},
	["PartnerTabletActionType"]:PartnerTabletActionType,
	["PartnerTabletList"]: {
		__typename?: "PartnerTabletList";
			items?:PartialObjects["PartnerTablet"][],
			total?:number,
			hasMore?:boolean
	},
	["PartnerTabletSyncArgs"]: {
	serial:string,
	model:string,
	manufacturer:string,
	battery?:number,
	release?:string,
	batteryState?:string,
	ram?:number,
	usedRam?:number,
	volume?:number,
	wifiNetwork?:string,
	bssid?:string,
	routerMake?:string,
	wifiStrength?:number,
	width?:number,
	height?:number,
	ip?:string,
	hostname?:string,
	isp?:string,
	location?:PartialObjects["GraphQLGeoJSONPointI"],
	webRTCOfferDescription?:PartialObjects["JSON"],
	webRTCOfferReceiveDescription?:PartialObjects["JSON"],
	webRTCOfferReceiveCandidates?:PartialObjects["JSON"],
	webRTCOfferCandidates?:PartialObjects["JSON"]
},
	/** A tag that describes something this partner offers, like Pizza, or Flowers. */
["PartnerTag"]: {
		__typename?: "PartnerTag";
			id?:string,
			name?:string,
			image?:string,
			type?:PartialObjects["PartnerTagTypes"],
			partners?:PartialObjects["Restaurant"][]
	},
	["PartnerTagInput"]: {
	name:string,
	image:string,
	type:PartialObjects["PartnerTagTypes"]
},
	/** Meta-type of a tag */
["PartnerTagTypes"]:PartnerTagTypes,
	["PaymentMethod"]: {
		__typename?: "PaymentMethod";
			id?:string,
			stripeId?:string,
			last4?:string,
			brand?:string,
			exp_month?:number,
			exp_year?:number
	},
	["PercentileValue"]: {
		__typename?: "PercentileValue";
			percentile?:string,
			value?:number
	},
	["Permissions"]:Permissions,
	["PieChart"]: {
		__typename?: "PieChart";
			id?:string,
			type?:string,
			labels?:string[],
			values?:number[]
	},
	["PlaceOrderResponse"]: {
		__typename?: "PlaceOrderResponse";
			id?:string,
			stripeNextAction?:PartialObjects["JSON"],
			stripeClientSecret?:string,
			order?:PartialObjects["Order"]
	},
	["PresignedUploadURL"]: {
		__typename?: "PresignedUploadURL";
			url?:string,
			method?:string,
			startBytes?:number,
			endBytes?:number
	},
	["Price"]: {
		__typename?: "Price";
			value?:number,
			display?:string
	},
	/** How to modify price based on selections */
["PriceStrategy"]:PriceStrategy,
	["Promotion"]: {
		__typename?: "Promotion";
			id?:string,
			created?:PartialObjects["Date"],
			modified?:PartialObjects["Date"],
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			name?:string,
			title?:string,
			body?:string,
			color?:string,
			imageKey?:string,
			zones?:PartialObjects["Zone"][],
			callToActionText?:string,
			enabled?:boolean,
			targetType?:PartialObjects["PromotionTarget"],
			target?:string
	},
	["PromotionList"]: {
		__typename?: "PromotionList";
			items?:PartialObjects["Promotion"][],
			total?:number,
			hasMore?:boolean
	},
	["PromotionTarget"]:PromotionTarget,
	["PurchaseCustomerWalletTopup"]: {
		__typename?: "PurchaseCustomerWalletTopup";
			id?:string,
			amount?:PartialObjects["Price"],
			paymentIntentId?:string,
			paymentIntentSecret?:string
	},
	["Query"]: {
		__typename?: "Query";
			Configuration?:PartialObjects["Configuration"],
			DynamicForm?:PartialObjects["DynamicForm"],
			CustomerList?:PartialObjects["CustomerList"],
			Customer?:PartialObjects["Customer"],
			stripeTokens?:PartialObjects["StripeTokens"],
			order?:PartialObjects["Order"],
			OrderList?:PartialObjects["OrderList"],
			FlightPlanOfferList?:PartialObjects["FlightPlanOfferList"],
			OrderAlertList?:PartialObjects["OrderAlertList"],
			OrderAlert?:PartialObjects["OrderAlert"],
			OrderRiderRatingList?:PartialObjects["OrderRiderRatingList"],
			OrderEventList?:PartialObjects["OrderEventList"],
			CustomerWalletTopupList?:PartialObjects["CustomerWalletTopupList"],
			Item?:PartialObjects["Item"],
			ItemList?:PartialObjects["ItemList"],
			BasketList?:PartialObjects["BasketList"],
			Basket?:PartialObjects["Basket"],
			/** Find basket for current restaurant */
	basketForRestaurant?:PartialObjects["Basket"],
			Charity?:PartialObjects["Charity"],
			CharityList?:PartialObjects["CharityList"],
			activeCharityCampaigns?:PartialObjects["CharityCampaign"],
			CharityCampaign?:PartialObjects["CharityCampaign"],
			CharityCampaignList?:PartialObjects["CharityCampaignList"],
			ConsumerCharityCampaign?:PartialObjects["ConsumerCharityCampaignView"],
			ReusableDropBox?:PartialObjects["ReusableDropBox"],
			ReusableDropBoxList?:PartialObjects["ReusableDropBoxList"],
			ReusableContainer?:PartialObjects["ReusableContainer"],
			ReusableContainerList?:PartialObjects["ReusableContainerList"],
			ReusableContainerType?:PartialObjects["ReusableContainerType"],
			ReusableContainerTypeList?:PartialObjects["ReusableContainerTypeList"],
			ScannedContainerList?:PartialObjects["ScannedContainerList"],
			WashingLocation?:PartialObjects["WashingLocation"],
			WashingLocationList?:PartialObjects["WashingLocationList"],
			ReusablesPickup?:PartialObjects["ReusablesPickup"],
			ReusablesPickupList?:PartialObjects["ReusablesPickupList"],
			CustomerReusables?:PartialObjects["CustomerReusables"],
			ReusableShipment?:PartialObjects["ReusableShipment"],
			ReusableShipmentList?:PartialObjects["ReusableShipmentList"],
			ReusablesPickupAggregate?:PartialObjects["ReusablesPickupAggregate"],
			ReusablesPickupAggregateList?:PartialObjects["ReusablesPickupAggregateList"],
			Restaurant?:PartialObjects["Restaurant"],
			allRestaurants?:PartialObjects["Restaurant"][],
			RestaurantList?:PartialObjects["RestaurantList"],
			restaurantHeartbeat?:boolean,
			activeOrders?:PartialObjects["Order"][],
			PartnerTag?:PartialObjects["PartnerTag"],
			allPartnerTags?:PartialObjects["PartnerTag"][],
			PartnerTablet?:PartialObjects["PartnerTablet"],
			PartnerTabletList?:PartialObjects["PartnerTabletList"],
			ConsumerSearch?:PartialObjects["ConsumerSearchResult"],
			Menu?:PartialObjects["Menu"],
			allMenus?:PartialObjects["Menu"][],
			ItemUpdateRequest?:PartialObjects["ItemUpdateRequest"],
			ItemUpdateRequestList?:PartialObjects["ItemUpdateRequestList"],
			RestaurantAdminList?:PartialObjects["RestaurantAdminList"],
			RestaurantAdmin?:PartialObjects["RestaurantAdmin"],
			tabletForRestaurant?:PartialObjects["RestaurantAdmin"],
			ZoneList?:PartialObjects["ZoneList"],
			zone?:PartialObjects["Zone"],
			zones?:PartialObjects["Zone"][],
			/** Find the zone at the given point */
	zoneAtPoint?:PartialObjects["Zone"],
			/** Upcoming boosts for riders to view in their planner */
	UpcomingBoosts?:PartialObjects["Boost"][],
			ZoneEventList?:PartialObjects["ZoneEventList"],
			ZonePolygonList?:PartialObjects["ZonePolygonList"],
			CommissionPlanList?:PartialObjects["CommissionPlanList"],
			CommissionPlan?:PartialObjects["CommissionPlan"],
			Brand?:PartialObjects["Brand"],
			BrandList?:PartialObjects["BrandList"],
			PartnerHomepage?:PartialObjects["HomePage"],
			Rider?:PartialObjects["Rider"],
			RiderList?:PartialObjects["RiderList"],
			validateVehicleRegistration?:PartialObjects["ValidateRegistrationResult"],
			riderOnlineSession?:PartialObjects["RiderOnlineSession"],
			RiderOnlineSessionList?:PartialObjects["RiderOnlineSessionList"],
			RiderLiveUI?:PartialObjects["RiderLiveView"],
			RiderLiveRoute?:PartialObjects["RiderLiveRoute"],
			Lease?:PartialObjects["LeaseView"],
			LeaseList?:PartialObjects["LeaseList"],
			LeaseVehicle?:PartialObjects["LeaseVehicle"],
			LeaseVehicleList?:PartialObjects["LeaseVehicleList"],
			ChatMessageList?:PartialObjects["ChatMessageList"],
			OAuthConfiguration?:PartialObjects["OAuthConfiguration"],
			me?:PartialObjects["UserUnionDTO"],
			Discount?:PartialObjects["Discount"],
			DiscountList?:PartialObjects["DiscountList"],
			RiderReferralDiscount?:PartialObjects["RiderReferralDiscount"],
			RiderReferralDiscountList?:PartialObjects["RiderReferralDiscountList"],
			Address?:PartialObjects["Address"],
			AddressList?:PartialObjects["AddressList"],
			allAddresses?:PartialObjects["Address"][],
			LocationSearch?:PartialObjects["LocationSearchResult"][],
			LocationSearchById?:PartialObjects["LocationSearchResult"],
			BookingMetricsRank?:PartialObjects["JSON"],
			BookingSlot?:PartialObjects["BookingSlot"],
			BookingSlotList?:PartialObjects["BookingSlotList"],
			BookingSlotsAvailableForRider?:PartialObjects["BookingSlotRiderViewList"],
			countBookingsForSlot?:number,
			AutoBookingSlotConfig?:PartialObjects["AutoBookingSlotsConfig"],
			allAnalytics?:PartialObjects["AnalyticsDTO"],
			PartnerAnalytics?:PartialObjects["PartnerAnalytics"],
			CustomerAnalytics?:PartialObjects["CustomerAnalytics"],
			city?:PartialObjects["City"],
			allCities?:PartialObjects["City"][],
			Team?:PartialObjects["TeamMember"][],
			financialsForRestaurants?:PartialObjects["RestaurantFinancialsView"][],
			financialsForRestaurant?:PartialObjects["RestaurantFinancialsView"],
			salesForRestaurant?:PartialObjects["ItemSales"][],
			financialsForRider?:PartialObjects["SpecificRiderFinancials"],
			RiderInvoice?:PartialObjects["RiderInvoice"],
			allRiderInvoices?:PartialObjects["RiderInvoiceList"],
			PartnerInvoice?:PartialObjects["PartnerInvoice"],
			/** Get a signed URL for the partner invoice */
	downloadPartnerInvoice?:string,
			allPartnerInvoices?:PartialObjects["PartnerInvoiceList"],
			PartnerPaymentDetails?:PartialObjects["PartnerPaymentDetails"],
			RiderAdjustment?:PartialObjects["RiderAdjustment"],
			RiderAdjustmentList?:PartialObjects["RiderAdjustmentList"],
			PartnerAdjustment?:PartialObjects["PartnerAdjustment"],
			PartnerAdjustmentList?:PartialObjects["PartnerAdjustmentList"],
			FinancialOverview?:PartialObjects["FinancialOverview"],
			PromotionList?:PartialObjects["PromotionList"],
			Promotion?:PartialObjects["Promotion"],
			B2BDeliveryJob?:PartialObjects["B2BDeliveryJob"],
			B2BDeliveryJobList?:PartialObjects["B2BDeliveryJobList"],
			IncidentList?:PartialObjects["IncidentList"],
			Incident?:PartialObjects["Incident"],
			ImageAsset?:PartialObjects["ImageAsset"],
			ImageAssetList?:PartialObjects["ImageAssetList"],
			VideoAsset?:PartialObjects["VideoAsset"],
			VideoAssetList?:PartialObjects["VideoAssetList"],
			MarketingCampaignList?:PartialObjects["MarketingCampaignList"],
			MerchantSite?:PartialObjects["MerchantSite"],
			MerchantSiteList?:PartialObjects["MerchantSiteList"],
			MerchantSitePage?:PartialObjects["MerchantSitePage"],
			/** Retrieve a list of all files stored. */
	FileList?:PartialObjects["FileList"]
	},
	["RegisterPayload"]: {
	email:string,
	name:string,
	password:string,
	phoneNumber?:string,
	marketing:boolean
},
	["Restaurant"]: {
		__typename?: "Restaurant";
			id?:string,
			configuration?:PartialObjects["CoreConfigurations"],
			name?:string,
			slug?:string,
			branch?:string,
			priceCategory?:string,
			blurhash?:string,
			headerImageKey?:string,
			ratingStars?:number,
			ratingCount?:number,
			phoneNumber?:string,
			allergyWarnings?:string,
			acceptsAllergyNotes?:boolean,
			autoAccept?:boolean,
			commission?:number,
			commissionCollection?:number,
			onboardingStatus?:PartialObjects["RestaurantOnboardingStatus"],
			fulfillmentMethods?:PartialObjects["FulfillmentMethod"][],
			busyStatus?:PartialObjects["RestaurantBusyStatus"],
			online?:boolean,
			baseDeliveryTime?:number,
			basePrepOrderThreshold?:number,
			basePrepTime?:number,
			basePrepTimeDelta?:number,
			/** The amount to increase prep time by when restaurant is busy. */
	incrementPrepTime?:number,
			currentPrepTime?:number,
			/** Whether this partner accepts pre-orders (ie before they are open) */
	acceptsPreOrders?:boolean,
			/** Whether this partner accepts scheduled orders (ie when they are already open) */
	acceptsScheduledOrders?:boolean,
			deliveryTimes?:PartialObjects["FulfillmentDayTimes"][],
			largeOrderSize?:number,
			minimumOrderSize?:number,
			minimumOrderValue?:PartialObjects["JSON"],
			deliveryTippingEnabled?:boolean,
			lastOnline?:PartialObjects["Date"],
			minimumBasket?:number,
			assignmentBias?:PartialObjects["AssignmentBias"],
			exclusive?:boolean,
			maxDeliveryDistanceMetres?:number,
			isReusablesEnabled?:boolean,
			openingTimes?:PartialObjects["OpeningTime"][],
			menuId?:string,
			menu?:PartialObjects["Menu"],
			zoneId?:string,
			zone?:PartialObjects["Zone"],
			owner?:PartialObjects["RestaurantAdmin"],
			hygiene?:PartialObjects["Hygiene"],
			hygieneId?:string,
			tags?:PartialObjects["PartnerTag"][],
			commissionPlans?:PartialObjects["PartnerCommissionPlan"][],
			activeCommissionPlan?:PartialObjects["PartnerCommissionPlan"],
			/** Fulfillment times for this partner */
	fulfillmentTimes?:PartialObjects["FulfillmentDayTimes"][],
			/** The current delivery time (in minutes) */
	currentDeliveryTime?:number,
			/** The current delivery time delta */
	currentDeliveryTimeDelta?:number,
			distance?:string,
			/** The display header image */
	headerImage?:string,
			/** Whether this restaurant is open or not */
	open?:boolean,
			/** Whether this restaurant is on vacation or not */
	isOnVacation?:boolean,
			/** Whether this restaurant is coming soon */
	comingSoon?:boolean,
			external?:PartialObjects["ExternalCompetitor"],
			invoices?:PartialObjects["PartnerInvoice"][],
			address?:PartialObjects["Address"],
			brand?:PartialObjects["Brand"],
			headerVideo?:PartialObjects["VideoAsset"],
			washingLocation?:PartialObjects["WashingLocation"],
			nextOpeningTime?:PartialObjects["Date"],
			description?:string
	},
	["RestaurantAdmin"]: {
		__typename?: "RestaurantAdmin";
			id?:string,
			name?:string,
			email?:string,
			created?:PartialObjects["Date"],
			phoneNumber?:string,
			isPhoneNumberVerified?:boolean,
			emailConfirmationTokenIssued?:PartialObjects["Date"],
			emailConfirmed?:boolean,
			roles?:string[],
			profileImageKey?:string,
			/** The restaurant that this user administers */
	administeredRestaurant?:PartialObjects["Restaurant"],
			/** The restaurants that this user administers */
	restaurants?:PartialObjects["Restaurant"][],
			linkedCustomerAccount?:PartialObjects["Customer"],
			b2bJobs?:PartialObjects["B2BDeliveryJob"][]
	},
	["RestaurantAdminList"]: {
		__typename?: "RestaurantAdminList";
			items?:PartialObjects["RestaurantAdmin"][],
			total?:number,
			hasMore?:boolean
	},
	["RestaurantAdminUpdateInput"]: {
	roles?:PartialObjects["Permissions"][]
},
	["RestaurantBusyStatus"]:RestaurantBusyStatus,
	["RestaurantCreateInput"]: {
	name:string,
	address:PartialObjects["AddressCreateInput"],
	description:string,
	phoneNumber:string,
	zoneId:string,
	notes?:string,
	ownerEmail:string,
	ownerName:string
},
	["RestaurantFinancialsView"]: {
		__typename?: "RestaurantFinancialsView";
			restaurantId?:string,
			restaurantSalesTotal?:number,
			deliveryFeeTotal?:number,
			/** The total price taken off of the order from discounts */
	discountsTotal?:number,
			accountCreditTotal?:number,
			smallOrderFeeTotal?:number,
			overallTotal?:number,
			riderEarnings?:number,
			riderTips?:number,
			numberOfOrders?:number,
			commissionPercent?:number,
			taxPercent?:number,
			commissionBeforeAddedTax?:number,
			taxFromCommission?:number,
			commissionTotal?:number,
			toPayRestaurant?:number,
			ourProfit?:number,
			amountRefunded?:number,
			adjustmentsTotal?:number,
			stripeFeeTotal?:number,
			donationToRestaurantTotal?:number,
			donationToCharityTotal?:number,
			purchasesFromUsTotal?:number,
			restaurant?:PartialObjects["Restaurant"]
	},
	["RestaurantList"]: {
		__typename?: "RestaurantList";
			items?:PartialObjects["Restaurant"][],
			total?:number,
			hasMore?:boolean
	},
	["RestaurantOnboardingStatus"]:RestaurantOnboardingStatus,
	/** Updates to the restaurant */
["RestaurantUpdateInput"]: {
	name?:string,
	description?:string,
	onboardingStatus?:PartialObjects["RestaurantOnboardingStatus"],
	openingTimes?:PartialObjects["OpeningTimeInput"][],
	busyStatus?:PartialObjects["RestaurantBusyStatus"],
	fulfillmentMethods?:PartialObjects["FulfillmentMethod"][],
	exclusive?:boolean,
	basePrepTime?:number,
	incrementPrepTime?:number,
	baseDeliveryTime?:number,
	ratingStars?:number,
	ratingCount?:number,
	commission?:number,
	commissionCollection?:number,
	minimumBasket?:number,
	hygieneRatingId?:string,
	allergyWarnings?:string,
	phoneNumber?:string,
	tags?:string[],
	external?:PartialObjects["ExternalCompetitorInput"],
	acceptsScheduledOrders?:boolean,
	autoAccept?:boolean,
	commissionPlanId?:string,
	commissionPlanRungId?:string,
	configuration?:PartialObjects["CoreConfigurations"],
	brandId?:string,
	headerVideoId?:string,
	isReusablesEnabled?:boolean
},
	["ReusableContainer"]: {
		__typename?: "ReusableContainer";
			id?:string,
			createdAt?:PartialObjects["Date"],
			type?:PartialObjects["ReusableContainerType"],
			uris?:string[],
			aliases?:PartialObjects["ContainerCode"][]
	},
	["ReusableContainerList"]: {
		__typename?: "ReusableContainerList";
			items?:PartialObjects["ReusableContainer"][],
			total?:number,
			hasMore?:boolean
	},
	["ReusableContainerType"]: {
		__typename?: "ReusableContainerType";
			id?:string,
			name?:string,
			depth?:number,
			length?:number,
			width?:number,
			unitPrice?:number,
			image?:PartialObjects["ImageAsset"],
			/** Whether ecoeats has stock of these containers */
	inStock?:boolean
	},
	["ReusableContainerTypeList"]: {
		__typename?: "ReusableContainerTypeList";
			items?:PartialObjects["ReusableContainerType"][],
			total?:number,
			hasMore?:boolean
	},
	["ReusableDropBox"]: {
		__typename?: "ReusableDropBox";
			id?:string,
			name?:string,
			notes?:string,
			status?:PartialObjects["DropBoxStatus"],
			depth?:number,
			length?:number,
			width?:number,
			address?:PartialObjects["Address"]
	},
	["ReusableDropBoxList"]: {
		__typename?: "ReusableDropBoxList";
			items?:PartialObjects["ReusableDropBox"][],
			total?:number,
			hasMore?:boolean
	},
	["ReusablePickupTracking"]: {
		__typename?: "ReusablePickupTracking";
			/** The current position of the rider */
	coordinates?:PartialObjects["Coordinates"],
			/** The earliest we expect the rider to arrive */
	etaEarliest?:PartialObjects["Date"],
			/** The latest we expect the rider to arrive */
	etaLatest?:PartialObjects["Date"],
			/** How many stops away the rider is */
	stopsAway?:number
	},
	["ReusableShipment"]: {
		__typename?: "ReusableShipment";
			id?:string,
			createdAt?:PartialObjects["Date"],
			status?:PartialObjects["ReusableShipmentStatus"],
			scheduledFor?:PartialObjects["Date"],
			estimatedArrivalTime?:PartialObjects["Date"],
			washingLocation?:PartialObjects["WashingLocation"],
			partner?:PartialObjects["Restaurant"],
			containers?:PartialObjects["ShipmentContainer"][],
			orders?:PartialObjects["Order"][],
			activeOrder?:PartialObjects["Order"],
			canScheduleOrder?:boolean
	},
	["ReusableShipmentList"]: {
		__typename?: "ReusableShipmentList";
			items?:PartialObjects["ReusableShipment"][],
			total?:number,
			hasMore?:boolean
	},
	["ReusableShipmentStatus"]:ReusableShipmentStatus,
	["ReusablesInfo"]: {
		__typename?: "ReusablesInfo";
			id?:string,
			deposit?:number,
			fee?:number,
			predictionCount?:number,
			partnerCount?:number,
			riderCount?:number,
			customerCount?:number
	},
	["ReusablesPickup"]: {
		__typename?: "ReusablesPickup";
			id?:string,
			type?:PartialObjects["ReusablesPickupType"],
			status?:PartialObjects["ReusablesPickupStatus"],
			refundedDeposit?:number,
			pickupTimeEarliest?:PartialObjects["Date"],
			pickupTimeLatest?:PartialObjects["Date"],
			customerImageKey?:string,
			customerCount?:number,
			riderCount?:number,
			address?:PartialObjects["Address"],
			customer?:PartialObjects["Customer"],
			aggregate?:PartialObjects["ReusablesPickupAggregate"],
			canReschedule?:boolean,
			formattedQuantity?:string,
			tracking?:PartialObjects["ReusablePickupTracking"]
	},
	["ReusablesPickupAggregate"]: {
		__typename?: "ReusablesPickupAggregate";
			id?:string,
			createdAt?:PartialObjects["Date"],
			pickups?:PartialObjects["ReusablesPickup"][],
			washingLocation?:PartialObjects["WashingLocation"],
			orders?:PartialObjects["Order"][]
	},
	["ReusablesPickupAggregateList"]: {
		__typename?: "ReusablesPickupAggregateList";
			items?:PartialObjects["ReusablesPickupAggregate"][],
			total?:number,
			hasMore?:boolean
	},
	["ReusablesPickupList"]: {
		__typename?: "ReusablesPickupList";
			items?:PartialObjects["ReusablesPickup"][],
			total?:number,
			hasMore?:boolean
	},
	["ReusablesPickupScheduleTime"]: {
		__typename?: "ReusablesPickupScheduleTime";
			slot?:PartialObjects["TimeSlot"],
			depositReturn?:PartialObjects["Price"]
	},
	["ReusablesPickupStatus"]:ReusablesPickupStatus,
	["ReusablesPickupType"]:ReusablesPickupType,
	["Rider"]: {
		__typename?: "Rider";
			id?:string,
			name?:string,
			email?:string,
			created?:PartialObjects["Date"],
			phoneNumber?:string,
			isPhoneNumberVerified?:boolean,
			emailConfirmationTokenIssued?:PartialObjects["Date"],
			emailConfirmed?:boolean,
			roles?:string[],
			profileImageKey?:string,
			application?:PartialObjects["RiderApplication"],
			lastOnline?:PartialObjects["Date"],
			online?:boolean,
			throttledUntil?:PartialObjects["Date"],
			position?:PartialObjects["GraphQLGeoJSONPoint"],
			note?:string,
			activationStatus?:string,
			vehicle?:PartialObjects["RiderVehicle"],
			carRegistration?:string,
			activeFlightPlan?:PartialObjects["DeliveryFlightPlan"],
			zone?:PartialObjects["Zone"],
			orders?:PartialObjects["Order"][],
			invoices?:PartialObjects["RiderInvoice"][],
			bookings?:PartialObjects["BookingList"],
			vehicleDetails?:PartialObjects["RiderVehicleDetails"],
			activeBooking?:PartialObjects["Booking"],
			accountNo?:string,
			sortCode?:string,
			stripeSetupIntentId?:string,
			statistics?:PartialObjects["RiderStatistics"],
			offers?:PartialObjects["OrderOffer"][],
			address?:PartialObjects["Address"],
			shippingAddress?:PartialObjects["Address"]
	},
	["RiderActivationStatus"]:RiderActivationStatus,
	["RiderAddressType"]:RiderAddressType,
	["RiderAddressView"]: {
		__typename?: "RiderAddressView";
			id?:string,
			title?:string,
			type?:PartialObjects["RiderAddressType"],
			formatted?:string,
			instructions?:string,
			phoneNumber?:string,
			coordinates?:PartialObjects["Coordinates"],
			instructionImageKey?:string
	},
	["RiderAdjustment"]: {
		__typename?: "RiderAdjustment";
			id?:string,
			created?:PartialObjects["Date"],
			forDate?:PartialObjects["Date"],
			amount?:number,
			explanation?:string,
			riderId?:string,
			rider?:PartialObjects["Rider"]
	},
	["RiderAdjustmentCreateInput"]: {
	forDate:PartialObjects["Date"],
	amount:number,
	explanation:string,
	riderId:string
},
	["RiderAdjustmentList"]: {
		__typename?: "RiderAdjustmentList";
			items?:PartialObjects["RiderAdjustment"][],
			total?:number,
			hasMore?:boolean
	},
	["RiderAdjustmentUpdateInput"]: {
	forDate?:PartialObjects["Date"],
	amount?:number,
	explanation?:string,
	riderId?:string
},
	["RiderApplication"]: {
		__typename?: "RiderApplication";
			id?:string,
			created?:PartialObjects["Date"],
			updated?:PartialObjects["Date"],
			documentType?:PartialObjects["IdentityDocumentType"],
			compareFaces?:PartialObjects["JSON"],
			rekognitionLabels?:PartialObjects["JSON"],
			imageUrl?:string
	},
	["RiderCreateInput"]: {
	zoneId:string,
	name:string,
	email:string,
	password:string,
	accountNo?:string,
	sortCode?:string,
	phoneNumber:string,
	address:PartialObjects["AddressCreateInput"],
	vehicle:PartialObjects["RiderVehicle"],
	carRegistration?:string,
	sessionId:string,
	code:string
},
	["RiderInvoice"]: {
		__typename?: "RiderInvoice";
			id?:string,
			/** Whether this invoice has been paid */
	paid?:boolean,
			value?:number,
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			orders?:number,
			bookingExtra?:number,
			referralExtra?:number,
			leaseDeduction?:number,
			tips?:number,
			earnings?:number,
			adjustments?:number,
			revolutTransactionId?:string,
			revolutTransactionLink?:string,
			riderId?:string,
			rider?:PartialObjects["Rider"],
			url?:string
	},
	["RiderInvoiceList"]: {
		__typename?: "RiderInvoiceList";
			items?:PartialObjects["RiderInvoice"][],
			total?:number,
			hasMore?:boolean
	},
	["RiderInvoicesJob"]: {
		__typename?: "RiderInvoicesJob";
			id?:string,
			count?:number
	},
	["RiderJobView"]: {
		__typename?: "RiderJobView";
			feeFormatted?:string,
			feeModifiedExplanation?:string,
			orders?:PartialObjects["RiderOrderView"][],
			origin?:PartialObjects["RiderAddressView"],
			currentDestination?:PartialObjects["RiderAddressView"],
			directions?:PartialObjects["RiderLiveRoute"]
	},
	["RiderList"]: {
		__typename?: "RiderList";
			items?:PartialObjects["Rider"][],
			total?:number,
			hasMore?:boolean
	},
	["RiderLiveRoute"]: {
		__typename?: "RiderLiveRoute";
			id?:string,
			steps?:PartialObjects["RiderLiveRouteStep"][],
			geometry?:string,
			distance?:number,
			duration?:number
	},
	["RiderLiveRouteStep"]: {
		__typename?: "RiderLiveRouteStep";
			id?:string,
			geometry?:string
	},
	["RiderLiveView"]: {
		__typename?: "RiderLiveView";
			id?:string,
			action?:PartialObjects["RiderUIAction"],
			layoutType?:PartialObjects["RiderUILayoutType"],
			banners?:PartialObjects["RiderUIBanner"][],
			job?:PartialObjects["RiderJobView"],
			support?:PartialObjects["RiderSupportView"],
			offer?:PartialObjects["RiderOfferView"],
			settings?:PartialObjects["RiderSettingsView"][]
	},
	["RiderOfferView"]: {
		__typename?: "RiderOfferView";
			id?:string,
			offeredAt?:PartialObjects["Date"],
			offeredUntil?:PartialObjects["Date"],
			feeFormatted?:string,
			origins?:PartialObjects["RiderAddressView"][],
			destinations?:PartialObjects["RiderAddressView"][]
	},
	["RiderOnlineSession"]: {
		__typename?: "RiderOnlineSession";
			id?:string,
			riderId?:string,
			rider?:PartialObjects["Rider"],
			updated?:PartialObjects["Date"],
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"],
			path?:PartialObjects["GraphQLGeoJSONLineString"],
			initialPoint?:PartialObjects["GraphQLGeoJSONPoint"]
	},
	["RiderOnlineSessionList"]: {
		__typename?: "RiderOnlineSessionList";
			items?:PartialObjects["RiderOnlineSession"][],
			total?:number,
			hasMore?:boolean
	},
	["RiderOrderItemView"]: {
		__typename?: "RiderOrderItemView";
			id?:string,
			name?:string,
			quantity?:number
	},
	["RiderOrderSupportView"]: {
		__typename?: "RiderOrderSupportView";
			actions?:PartialObjects["RiderUIAction"][]
	},
	["RiderOrderView"]: {
		__typename?: "RiderOrderView";
			id?:string,
			number?:string,
			isReadyToCollect?:boolean,
			estimatedReadyAt?:PartialObjects["Date"],
			tipFormatted?:string,
			numberOfItems?:number,
			items?:PartialObjects["RiderOrderItemView"][],
			origin?:PartialObjects["RiderAddressView"],
			destination?:PartialObjects["RiderAddressView"],
			support?:PartialObjects["RiderOrderSupportView"]
	},
	["RiderReferralDiscount"]: {
		__typename?: "RiderReferralDiscount";
			id?:string,
			riderId?:string,
			discountId?:string,
			associationDate?:PartialObjects["Date"],
			discount?:PartialObjects["Discount"],
			rider?:PartialObjects["Rider"]
	},
	["RiderReferralDiscountList"]: {
		__typename?: "RiderReferralDiscountList";
			items?:PartialObjects["RiderReferralDiscount"][],
			total?:number,
			hasMore?:boolean
	},
	["RiderSettingsView"]: {
		__typename?: "RiderSettingsView";
			id?:string,
			initialValue?:PartialObjects["JSON"],
			schema?:PartialObjects["JSON"]
	},
	["RiderSlotStats"]: {
		__typename?: "RiderSlotStats";
			id?:string,
			riderId?:string,
			bookingId?:string,
			bookingSlotId?:string,
			slotType?:PartialObjects["BookingSlotType"],
			slotStart?:PartialObjects["Date"],
			slotEnd?:PartialObjects["Date"],
			ordersAccepted?:number,
			ordersOffered?:number,
			maxMissedOrders?:number,
			sessionSpans?:string[],
			attended?:PartialObjects["BookingStatus"],
			onlinePercentage?:number,
			minAttendancePercentage?:number,
			guaranteedRiderEarnings?:number,
			orderRiderEarnings?:number,
			riderOwedExtra?:number
	},
	["RiderStatistics"]: {
		__typename?: "RiderStatistics";
			riderId?:string,
			acceptedOrders?:number,
			rejectedOrders?:number,
			timedoutOrders?:number,
			averageRating?:number
	},
	["RiderStatusUpdate"]: {
	location?:PartialObjects["GraphQLGeoJSONPointInput"],
	online:boolean,
	device?:string,
	os?:string
},
	["RiderSupportView"]: {
		__typename?: "RiderSupportView";
			supportURL?:string,
			supportPhoneNumber?:string
	},
	["RiderUIAction"]: {
		__typename?: "RiderUIAction";
			id?:PartialObjects["RiderUIActions"],
			maxDistanceInMetres?:number,
			allowMaxDistanceOverride?:boolean,
			validationPrompt?:PartialObjects["RiderUIActionValidationPrompt"],
			areYouSure?:string,
			orderId?:string,
			disabled?:boolean,
			color?:PartialObjects["RiderUIActionColour"],
			title?:string,
			explanation?:string,
			text?:string,
			extraPayload?:PartialObjects["JSON"]
	},
	["RiderUIActionColour"]:RiderUIActionColour,
	["RiderUIActions"]:RiderUIActions,
	["RiderUIActionValidationPrompt"]: {
		__typename?: "RiderUIActionValidationPrompt";
			id?:string,
			title?:string,
			canBeTemporarilyDismissed?:boolean,
			validateAge?:PartialObjects["RiderUIAgeValidation"],
			validateReusables?:PartialObjects["RiderUIReusablesCountValidation"],
			rateOrderPrompt?:PartialObjects["RiderUIRateOrderValidation"],
			buttons?:PartialObjects["RiderUIButton"][]
	},
	["RiderUIAgeValidation"]: {
		__typename?: "RiderUIAgeValidation";
			customerDOBShouldBeLessThanOrEqualTo?:PartialObjects["Date"]
	},
	["RiderUIBanner"]: {
		__typename?: "RiderUIBanner";
			id?:string,
			isDismissible?:boolean,
			title?:string,
			message?:string,
			color?:PartialObjects["RiderUIActionColour"]
	},
	["RiderUIButton"]: {
		__typename?: "RiderUIButton";
			type?:PartialObjects["RiderUIButtonType"],
			text?:string,
			color?:PartialObjects["RiderUIActionColour"]
	},
	["RiderUIButtonType"]:RiderUIButtonType,
	["RiderUILayoutType"]:RiderUILayoutType,
	["RiderUIOption"]: {
		__typename?: "RiderUIOption";
			value?:string,
			label?:string
	},
	["RiderUIRateOrderValidation"]: {
		__typename?: "RiderUIRateOrderValidation";
			options?:PartialObjects["RiderUIOption"][]
	},
	["RiderUIReusablesCountValidation"]: {
		__typename?: "RiderUIReusablesCountValidation";
			customerCountWas?:number
	},
	["RiderUpdateInput"]: {
	name?:string,
	activationStatus?:PartialObjects["RiderActivationStatus"],
	vehicle?:PartialObjects["RiderVehicle"],
	phoneNumber?:string,
	zoneId?:string,
	note?:string,
	accountNo?:string,
	sortCode?:string,
	carRegistration?:string,
	shippingAddressId?:string
},
	["RiderVehicle"]:RiderVehicle,
	["RiderVehicleDetails"]: {
		__typename?: "RiderVehicleDetails";
			id?:string,
			updated?:PartialObjects["Date"],
			details?:PartialObjects["JSON"],
			rider?:PartialObjects["Rider"]
	},
	["ScanContainerAtPartnerResponse"]: {
		__typename?: "ScanContainerAtPartnerResponse";
			success?:boolean,
			recognisedContainer?:PartialObjects["ReusableContainer"]
	},
	["ScannedContainer"]: {
		__typename?: "ScannedContainer";
			id?:number,
			containerId?:string,
			scannedAt?:PartialObjects["Date"],
			imageKey?:string,
			pickupId?:string,
			customerId?:string,
			washingLocationId?:string,
			shipmentContainerId?:string,
			container?:PartialObjects["ReusableContainer"],
			washingLocation?:PartialObjects["WashingLocation"]
	},
	["ScannedContainerList"]: {
		__typename?: "ScannedContainerList";
			items?:PartialObjects["ScannedContainer"][],
			total?:number,
			hasMore?:boolean
	},
	["Series"]: {
		__typename?: "Series";
			label?:string,
			x_values?:string[],
			y_values?:number[]
	},
	["ShipmentContainer"]: {
		__typename?: "ShipmentContainer";
			quantity?:number,
			containerType?:PartialObjects["ReusableContainerType"],
			containersScannedSoFar?:number
	},
	["SpecificRiderFinancials"]: {
		__typename?: "SpecificRiderFinancials";
			startDate?:PartialObjects["Date"],
			endDate?:PartialObjects["Date"],
			id?:string,
			riderEarningsSum?:number,
			adjustments?:number,
			extraFromSlots?:number,
			extraFromReferrals?:number,
			riderTips?:number,
			totalPayout?:number,
			numberOfOrders?:number,
			bookingStats?:PartialObjects["RiderSlotStats"][],
			rider?:PartialObjects["Rider"]
	},
	["StatChart"]: {
		__typename?: "StatChart";
			id?:string,
			type?:string,
			label?:string,
			value?:string
	},
	["StripeTokens"]: {
		__typename?: "StripeTokens";
			addPaymentMethod?:string,
			payOnce?:string
	},
	["Subscription"]: {
		__typename?: "Subscription";
			LiveOrders?:PartialObjects["Order"][],
			OrderAlerts?:PartialObjects["OrderAlert"],
			PartnerTabletActions?:PartialObjects["PartnerTabletAction"],
			AdminPageViews?:PartialObjects["AdminPageView"]
	},
	["TableChart"]: {
		__typename?: "TableChart";
			id?:string,
			type?:string,
			title?:string,
			columns?:PartialObjects["TableColumn"][],
			data?:PartialObjects["TableRow"][]
	},
	["TableColumn"]: {
		__typename?: "TableColumn";
			id?:string,
			title?:string
	},
	["TableRecord"]: {
		__typename?: "TableRecord";
			dataIndex?:string,
			formatted?:string,
			value?:PartialObjects["JSON"]
	},
	["TableRow"]: {
		__typename?: "TableRow";
			id?:string,
			records?:PartialObjects["TableRecord"][]
	},
	["TeamMember"]: {
		__typename?: "TeamMember";
			id?:string,
			name?:string,
			title?:string,
			description?:string,
			imageKey?:string
	},
	["TimeSlot"]: {
		__typename?: "TimeSlot";
			start?:PartialObjects["Date"],
			end?:PartialObjects["Date"]
	},
	["TwoFactorSetup"]: {
		__typename?: "TwoFactorSetup";
			uri?:string
	},
	["UIThemeColour"]:UIThemeColour,
	["UnifiedLocationInput"]: {
	latLng?:PartialObjects["GraphQLGeoJSONPointInput"],
	zoneId?:string,
	geohash?:string
},
	["UnifiedLocationType"]: {
		__typename?: "UnifiedLocationType";
			latLng?:PartialObjects["GraphQLGeoJSONPoint"],
			zoneId?:string,
			geohash?:string
	},
	["UpdateBookingArgs"]: {
	status?:PartialObjects["BookingStatus"]
},
	["UpdateBookingSlotArgs"]: {
	startTime?:PartialObjects["Date"],
	endTime?:PartialObjects["Date"],
	guaranteedHourlyPay?:number,
	slotType?:PartialObjects["BookingSlotType"],
	capHourlyPay?:boolean,
	carOnly?:boolean,
	maxRiders?:number,
	autoIncreaseRiderPercentage?:boolean,
	percentage?:number
},
	["UpdateIncidentInput"]: {
	severity:PartialObjects["IncidentSeverity"],
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:PartialObjects["UserType"],
	description:string,
	imageIds:string[]
},
	["UpdateMenuCategoryAsRestaurantInput"]: {
	times?:PartialObjects["OpeningTimeInput"][],
	topLevel?:boolean
},
	["UpdateOrderInput"]: {
	internalNote?:string,
	addressId?:string,
	partnerReusablesCount?:number
},
	["UpdatePartnerPaymentDetailsInput"]: {
	accountNo:string,
	sortCode:string,
	isCompany?:boolean,
	individualName?:string
},
	["UpdatePromotionInput"]: {
	start?:PartialObjects["Date"],
	end?:PartialObjects["Date"],
	name?:string,
	title?:string,
	zoneIds?:string[],
	enabled?:boolean,
	body?:string,
	color?:string,
	targetType?:PartialObjects["PromotionTarget"],
	callToActionText?:string,
	target?:string
},
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:any,
	["UploadMediaJob"]: {
		__typename?: "UploadMediaJob";
			id?:string,
			uploadId?:string,
			key?:string,
			urls?:PartialObjects["PresignedUploadURL"][]
	},
	["UploadMediaPart"]: {
	etag:string,
	partNumber:number
},
	["User"]: {
		__typename?: "User";
			id?:string,
			name?:string,
			email?:string,
			created?:PartialObjects["Date"],
			phoneNumber?:string,
			isPhoneNumberVerified?:boolean,
			emailConfirmationTokenIssued?:PartialObjects["Date"],
			emailConfirmed?:boolean,
			roles?:string[],
			profileImageKey?:string
	},
	/** The type of this user */
["UserType"]:UserType,
	["UserUnionDTO"]: PartialObjects["Customer"] | PartialObjects["RestaurantAdmin"] | PartialObjects["Rider"],
	["UserView"]: {
		__typename?: "UserView";
			id?:string,
			type?:PartialObjects["UserType"],
			name?:string,
			email?:string,
			avatarUrl?:string
	},
	["ValidateRegistrationResult"]: {
		__typename?: "ValidateRegistrationResult";
			canRide?:boolean,
			reason?:string,
			registration?:string
	},
	["VideoAsset"]: {
		__typename?: "VideoAsset";
			id?:string,
			created?:PartialObjects["Date"],
			updated?:PartialObjects["Date"],
			title?:string,
			key?:string,
			width?:number,
			height?:number,
			fileSize?:number,
			altText?:string,
			mimeType?:string,
			uploadedBy?:PartialObjects["RestaurantAdmin"],
			url?:string
	},
	["VideoAssetList"]: {
		__typename?: "VideoAssetList";
			items?:PartialObjects["VideoAsset"][],
			total?:number,
			hasMore?:boolean
	},
	["WashingLocation"]: {
		__typename?: "WashingLocation";
			id?:string,
			name?:string,
			notes?:string,
			status?:string,
			hasUnattendedDropoff?:boolean,
			address?:PartialObjects["Address"],
			partner?:PartialObjects["Restaurant"],
			/** The current list of ingested containers not scheduled */
	currentIngestedContainers?:PartialObjects["ScannedContainer"][],
			/** A list of shipments that this washing location could fulfill. */
	availableShipments?:PartialObjects["ReusableShipment"][]
	},
	["WashingLocationList"]: {
		__typename?: "WashingLocationList";
			items?:PartialObjects["WashingLocation"][],
			total?:number,
			hasMore?:boolean
	},
	["Zone"]: {
		__typename?: "Zone";
			id?:string,
			name?:string,
			slug?:string,
			polygon?:PartialObjects["GraphQLGeoJSONPolygon"],
			baseDeliveryFee?:number,
			feePerKM?:number,
			baseRiderEarnings?:number,
			riderEarningsPerKM?:number,
			timeZone?:string,
			onboardingStatus?:PartialObjects["ZoneOnboardingStatus"],
			busyWeight?:number,
			busyStatus?:PartialObjects["ZoneBusyStatus"],
			minDeliveryDistanceMetres?:number,
			standardDeliveryDistanceMetres?:number,
			currentDeliveryDistanceMetres?:number,
			hardLimitDeliveryDistanceMetres?:number,
			autoBookingSlotConfigId?:string,
			autoBookingSlotConfig?:PartialObjects["AutoBookingSlotsConfig"],
			weather?:PartialObjects["ZoneWeatherData"],
			riders?:PartialObjects["Rider"][],
			restaurants?:PartialObjects["Restaurant"][],
			city?:PartialObjects["City"],
			boosts?:PartialObjects["Boost"][],
			bookingSlots?:PartialObjects["BookingSlot"][],
			currentBoost?:PartialObjects["Boost"],
			openingTimes?:PartialObjects["OpeningTime"][],
			activePolygonId?:string,
			liveView?:PartialObjects["ZoneLiveView"]
	},
	["ZoneBusyStatus"]:ZoneBusyStatus,
	["ZoneCreateInput"]: {
	name:string,
	polygon:PartialObjects["GraphQLGeoJSONPolygonInput"],
	cityId?:string,
	feePerKM?:number,
	baseDeliveryFee?:number
},
	["ZoneEvent"]: {
		__typename?: "ZoneEvent";
			id?:string,
			timestamp?:PartialObjects["Date"],
			operation?:string,
			zoneId?:string,
			from?:PartialObjects["JSON"],
			to?:PartialObjects["JSON"]
	},
	["ZoneEventList"]: {
		__typename?: "ZoneEventList";
			items?:PartialObjects["ZoneEvent"][],
			total?:number,
			hasMore?:boolean
	},
	["ZoneList"]: {
		__typename?: "ZoneList";
			items?:PartialObjects["Zone"][],
			total?:number,
			hasMore?:boolean
	},
	["ZoneLiveView"]: {
		__typename?: "ZoneLiveView";
			activeRiders?:number,
			unassignedRiders?:number,
			riderAcceptRate?:number,
			ridersFreeSoon?:number
	},
	["ZoneOnboardingStatus"]:ZoneOnboardingStatus,
	["ZonePolygon"]: {
		__typename?: "ZonePolygon";
			id?:string,
			created?:PartialObjects["Date"],
			updated?:PartialObjects["Date"],
			maxCycleTime?:number,
			maxDriveTime?:number,
			polygon?:PartialObjects["GraphQLGeoJSONPolygon"]
	},
	["ZonePolygonInput"]: {
	zoneId:string,
	polygon:PartialObjects["GraphQLGeoJSONPolygonInput"]
},
	["ZonePolygonList"]: {
		__typename?: "ZonePolygonList";
			items?:PartialObjects["ZonePolygon"][],
			total?:number,
			hasMore?:boolean
	},
	["ZoneUpdateInput"]: {
	feePerKM?:number,
	baseDeliveryFee?:number,
	riderEarningsPerKM?:number,
	baseRiderEarnings?:number,
	polygon?:PartialObjects["GraphQLGeoJSONPolygonInput"],
	onboardingStatus?:PartialObjects["ZoneOnboardingStatus"],
	busyStatus?:PartialObjects["ZoneBusyStatus"],
	autoBookingSlotConfigId?:string,
	openingTimes?:PartialObjects["OpeningTimeInput"][],
	minDeliveryDistanceMetres?:number,
	standardDeliveryDistanceMetres?:number,
	currentDeliveryDistanceMetres?:number,
	hardLimitDeliveryDistanceMetres?:number
},
	["ZoneWeatherData"]: {
		__typename?: "ZoneWeatherData";
			updated?:PartialObjects["Date"],
			result?:PartialObjects["JSON"]
	}
  }



export type Address = {
	__typename?: "Address",
	id:string,
	nickname?:string,
	flat_number?:string,
	address_line_1:string,
	town?:string,
	postcode:string,
	country:string,
	phone_number:string,
	instructions?:string,
	coordinates?:GraphQLGeoJSONPoint,
	imageKey?:string
}

export type AddressCreateInput = {
		flat_number?:string,
	address_line_1:string,
	postcode:string,
	phone_number:string,
	instructions?:string,
	town?:string,
	country?:string,
	/** The coorindates of this address. Required only for customer and restaurant accounts */
	coordinates?:JSON
}

export type AddressList = {
	__typename?: "AddressList",
	items:Address[],
	total:number,
	hasMore:boolean
}

export type AddressUpdateInput = {
		flat_number?:string,
	address_line_1?:string,
	postcode?:string,
	phone_number?:string,
	instructions?:string,
	town?:string,
	country?:string,
	/** The coordinates of this address. Required only for customer and restaurant accounts */
	coordinates?:GraphQLGeoJSONPointInput
}

export type AdminPageView = {
	__typename?: "AdminPageView",
	url:string,
	users:UserView[]
}

export type AggregateBasket = {
	__typename?: "AggregateBasket",
	id:string,
	isSingleDelivery:boolean,
	allowSwitchSingleDelivery:boolean,
	baskets:Basket[],
	feeExplanations:BasketFeeExplanation[],
	totalPrice:Price,
	subtotal:Price,
	fulfillmentTimes:FulfillmentDayTimes[],
	fulfillmentMethods:FulfillmentMethod[]
}

export type AggregateOrder = {
	__typename?: "AggregateOrder",
	id:string,
	status:OrderStatus,
	orders:Order[]
}

export type AnalyticsDTO = {
	__typename?: "AnalyticsDTO",
	totalOrders:number,
	totalRestaurants:number,
	customers:number
}

export enum AssignmentBias {
	BIKE = "BIKE",
	CAR = "CAR",
	NONE = "NONE"
}

export type AuthDTO = {
	__typename?: "AuthDTO",
	user:User,
	expiresIn:string,
	accessToken:string,
	refreshToken?:string
}

export type AutoBookingSlot = {
	__typename?: "AutoBookingSlot",
	id:string,
	dayOfTheWeek:DayOfWeek,
	startTime:HourMinuteTimeString,
	endTime:HourMinuteTimeString,
	guaranteedHourlyPay:number,
	slotType:BookingSlotType,
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly:boolean,
	configId:string,
	config:AutoBookingSlotsConfig
}

export type AutoBookingSlotInput = {
		dayOfTheWeek:DayOfWeek,
	startTime:HourMinuteTimeString,
	endTime:HourMinuteTimeString,
	guaranteedHourlyPay:number,
	slotType:BookingSlotType,
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly?:boolean,
	configId:string
}

export type AutoBookingSlotsConfig = {
	__typename?: "AutoBookingSlotsConfig",
	id:string,
	name?:string,
	slots:AutoBookingSlot[],
	zones:Zone[]
}

export type AutoBookingSlotsConfigInput = {
		name:string
}

export type AutoBookingSlotsConfigList = {
	__typename?: "AutoBookingSlotsConfigList",
	items:AutoBookingSlotsConfig[],
	total:number,
	hasMore:boolean
}

export type B2BDeliveryJob = {
	__typename?: "B2BDeliveryJob",
	id:string,
	created:Date,
	placed?:Date,
	price:number,
	distanceDuration:DistanceModel,
	scheduledFor?:Date,
	destination:Address,
	origin:Address,
	size:B2BDeliverySizes,
	placedById?:string,
	placedBy?:RestaurantAdmin,
	order:Order
}

export type B2BDeliveryJobList = {
	__typename?: "B2BDeliveryJobList",
	items:B2BDeliveryJob[],
	total:number,
	hasMore:boolean
}

export enum B2BDeliverySizes {
	LETTER = "LETTER",
	SMALL_PARCEL = "SMALL_PARCEL",
	MEDIUM_PARCEL = "MEDIUM_PARCEL",
	LARGE_PARCEL = "LARGE_PARCEL",
	HUGE_PARCEL = "HUGE_PARCEL"
}

export type B2BJobEstimateResponse = {
	__typename?: "B2BJobEstimateResponse",
	job:B2BDeliveryJob,
	scheduledTimes:DeliveryTimes[],
	estimatedArrivalMins:number,
	blockedReason?:string
}

export type BarChart = {
	__typename?: "BarChart",
	id:string,
	type:string,
	series:Series[]
}

export type Basket = {
	__typename?: "Basket",
	id:string,
	modified:Date,
	checkedOutAt?:Date,
	scheduledDelivery:string,
	fulfillmentMethod:FulfillmentMethod,
	targetDeliveryFromNow:number,
	restaurantId:string,
	sessionId:string,
	totalPriceBeforeDiscount:number,
	totalPrice:number,
	riderTip:number,
	allergyNotes?:string,
	tableNumber?:string,
	subtotal:number,
	smallOrderFee:number,
	deliveryFee:number,
	location?:GraphQLGeoJSONPoint,
	distanceDuration?:JSON,
	alcohol:boolean,
	numberOfItems:number,
	weight:number,
	instructions?:string,
	checkoutBlockedReason?:string,
	scheduledFor?:DeliveryTimeArgs,
	created:Date,
	paymentIntentSecret?:string,
	paymentIntentId?:string,
	/** Whether this basket has taken part in a successful order placement */
	complete:boolean,
	donationTotal:number,
	/** The items that have been added to this basket */
	items:OrderItem[],
	address?:Address,
	customer?:Customer,
	restaurant:Restaurant,
	discount?:Discount,
	aggregateBasket:AggregateBasket,
	donationView?:BasketDonationView,
	feeExplanations:BasketFeeExplanation[],
	fulfillmentMethods:FulfillmentMethod[],
	fulfillmentTimes:FulfillmentDayTimes[],
	reusables?:BasketReusablesOptions
}

export type BasketAddDiscountInput = {
		/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:JSON,
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:FulfillmentMethod,
	scheduledFor?:DeliveryTimeInput,
	useSynchronousPayments?:boolean,
	code:string
}

export type BasketAddItemInput = {
		/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:JSON,
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:FulfillmentMethod,
	scheduledFor?:DeliveryTimeInput,
	useSynchronousPayments?:boolean,
	item:BasketItemInput
}

export type BasketCheckOutInput = {
		sessionId:string,
	location?:JSON,
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:FulfillmentMethod,
	scheduledFor?:DeliveryTimeInput,
	useSynchronousPayments?:boolean,
	tableNumber?:string
}

export type BasketDonationOptionView = {
	__typename?: "BasketDonationOptionView",
	id:string,
	primaryText:string,
	secondaryText?:string,
	isSelected:boolean
}

export type BasketDonationToggleView = {
	__typename?: "BasketDonationToggleView",
	id:string,
	toggleText:string,
	toggleFormattedValue:string,
	toggled:boolean
}

export type BasketDonationView = {
	__typename?: "BasketDonationView",
	campaign:ConsumerCharityCampaignView,
	donationOptions?:BasketDonationOptionView[],
	donationOptionsMutuallyExclusive:boolean,
	donationToggleInfo?:BasketDonationToggleView
}

export type BasketEditItemInput = {
		/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:JSON,
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:FulfillmentMethod,
	scheduledFor?:DeliveryTimeInput,
	useSynchronousPayments?:boolean,
	itemId:string,
	quantity:number
}

export type BasketFeeExplanation = {
	__typename?: "BasketFeeExplanation",
	title:string,
	explanation?:string,
	feeFormatted:string,
	color?:UIThemeColour
}

/** Info to be sent with every basket request */
export type BasketIdentifierInfo = {
		/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:JSON,
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:FulfillmentMethod,
	scheduledFor?:DeliveryTimeInput,
	useSynchronousPayments?:boolean
}

export type BasketIncrementDecrementItemInput = {
		/** Behaves as an identifier allowing the user to create baskets without authentication */
	sessionId?:string,
	location?:JSON,
	/** The restaurant for which this item is meant */
	restaurantId:string,
	addressId?:string,
	fulfillmentMethod?:FulfillmentMethod,
	scheduledFor?:DeliveryTimeInput,
	useSynchronousPayments?:boolean,
	itemId:string
}

export type BasketItemInput = {
		id:string,
	/** How many of this exact combination of item and modifiers */
	quantity:number,
	modifierGroups?:BasketModifierGroupInput[]
}

export type BasketList = {
	__typename?: "BasketList",
	items:Basket[],
	total:number,
	hasMore:boolean
}

export type BasketModifierGroupInput = {
		id:string,
	modifierItems:BasketItemInput[]
}

export type BasketReusablesOptions = {
	__typename?: "BasketReusablesOptions",
	isEnabled:boolean,
	enableText:string,
	explanation:string,
	disableText:string,
	pickupScheduleTimes:TimeSlot[],
	pickupScheduledTime?:TimeSlot,
	imageKey?:string
}

export type Booking = {
	__typename?: "Booking",
	id:string,
	createdAt:Date,
	riderId:string,
	rider:Rider,
	bookingSlotId:string,
	bookingSlot:BookingSlot,
	status:BookingStatus,
	attendancePercentage:number,
	numOfferedOrders:number,
	numAcceptedOrders:number,
	riderEarnings:number,
	sessionSpans:string,
	lateCancellation?:boolean,
	stats:RiderSlotStats
}

export type BookingList = {
	__typename?: "BookingList",
	items:Booking[],
	total:number,
	hasMore:boolean
}

export type BookingSlot = {
	__typename?: "BookingSlot",
	id:string,
	zoneId:string,
	zone:Zone,
	startTime:Date,
	endTime:Date,
	guaranteedHourlyPay:number,
	slotType:BookingSlotType,
	capHourlyPay:boolean,
	carOnly:boolean,
	maxRiders:number,
	autoIncreaseRiderPercentage:boolean,
	percentage:number,
	bookings:Booking[]
}

export type BookingSlotInput = {
		dayOfTheWeek:DayOfWeek,
	startTime:HourMinuteTimeString,
	endTime:HourMinuteTimeString,
	guaranteedHourlyPay:number,
	slotType:BookingSlotType,
	maxRiders:number,
	percentage:number,
	autoIncreaseRiderPercentage:boolean,
	carOnly?:boolean
}

export type BookingSlotList = {
	__typename?: "BookingSlotList",
	items:BookingSlot[],
	total:number,
	hasMore:boolean
}

export type BookingSlotRiderView = {
	__typename?: "BookingSlotRiderView",
	id:string,
	zoneId:string,
	zone:Zone,
	startTime:Date,
	endTime:Date,
	guaranteedHourlyPay:number,
	slotType:BookingSlotType,
	capHourlyPay:boolean,
	carOnly:boolean,
	maxRiders:number,
	autoIncreaseRiderPercentage:boolean,
	percentage:number,
	bookings:Booking[],
	hasBooked:boolean,
	bookingId?:string
}

export type BookingSlotRiderViewList = {
	__typename?: "BookingSlotRiderViewList",
	items:BookingSlotRiderView[],
	total:number,
	hasMore:boolean
}

export enum BookingSlotType {
	STANDARD = "STANDARD",
	GOLDEN = "GOLDEN"
}

export enum BookingStatus {
	PENDING = "PENDING",
	CANCELLED = "CANCELLED",
	ATTENDED = "ATTENDED",
	NOT_ATTENDED = "NOT_ATTENDED",
	PARTIALLY_ATTENDED = "PARTIALLY_ATTENDED"
}

export type Boost = {
	__typename?: "Boost",
	id:string,
	type:BoostType,
	start:Date,
	end:Date,
	multiplier:number,
	fixedIncrease:number,
	notified:boolean,
	zoneId:string,
	zone:Zone
}

export type BoostInput = {
		start:Date,
	end:Date,
	type:BoostType,
	multiplier:number,
	zoneId:string,
	fixedIncrease:number
}

export enum BoostType {
	AUTOMATIC = "AUTOMATIC",
	SCHEDULED = "SCHEDULED",
	MANUAL = "MANUAL"
}

export type Brand = {
	__typename?: "Brand",
	id:string,
	slug:string,
	name:string,
	description:string,
	logo:ImageAsset,
	owners:BrandOwner
}

export type BrandList = {
	__typename?: "BrandList",
	items:Brand[],
	total:number,
	hasMore:boolean
}

export type BrandOwner = {
	__typename?: "BrandOwner",
	id:string,
	created:Date
}

export type CampaignMapMarker = {
	__typename?: "CampaignMapMarker",
	id:string,
	center:Coordinates
}

export type CampaignMapView = {
	__typename?: "CampaignMapView",
	id:string,
	features:JSON,
	center:Coordinates,
	markers:CampaignMapMarker[]
}

export type Charity = {
	__typename?: "Charity",
	id:string,
	name:string,
	logo:ImageAsset,
	logoId:string,
	campaigns:CharityCampaign[]
}

export type CharityCampaign = {
	__typename?: "CharityCampaign",
	id:string,
	charityId:string,
	startDate:Date,
	endDate:Date,
	goal:number,
	progress:number,
	title:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit:number,
	campaignLogo?:ImageAsset,
	campaignLogoId?:string,
	charity:Charity
}

export type CharityCampaignCreateArgs = {
		charityId:string,
	startDate:Date,
	endDate:Date,
	goal:number,
	title:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit:number,
	campaignLogoId?:string
}

export type CharityCampaignFinancials = {
	__typename?: "CharityCampaignFinancials",
	id:string,
	donationTotal:number,
	campaign:CharityCampaign
}

export type CharityCampaignList = {
	__typename?: "CharityCampaignList",
	items:CharityCampaign[],
	total:number,
	hasMore:boolean
}

export type CharityCampaignUpdateArgs = {
		charityId?:string,
	startDate?:Date,
	endDate?:Date,
	goal?:number,
	title?:string,
	description?:string,
	goalPrefix?:string,
	goalSuffix?:string,
	costPerGoalUnit?:number,
	campaignLogoId?:string
}

export type CharityCreateArgs = {
		name?:string,
	logoId?:string
}

export type CharityFinancials = {
	__typename?: "CharityFinancials",
	id:string,
	donationTotal:number,
	campaigns:CharityCampaignFinancials[],
	charity:Charity
}

export type CharityList = {
	__typename?: "CharityList",
	items:Charity[],
	total:number,
	hasMore:boolean
}

export type CharityUpdateArgs = {
		name?:string,
	logoId?:string
}

/** All possible chart types */
export type Chart = {
	__union:PieChart | LineChart | BarChart | TableChart | StatChart;
	__resolve:{
		['...on PieChart']: PieChart;
		['...on LineChart']: LineChart;
		['...on BarChart']: BarChart;
		['...on TableChart']: TableChart;
		['...on StatChart']: StatChart;
	}
}

export type ChartLayoutGroup = {
	__typename?: "ChartLayoutGroup",
	id:string,
	title:string,
	description?:string,
	charts:Chart[]
}

export type ChatMessage = {
	__typename?: "ChatMessage",
	id:string,
	text:string,
	sent:Date,
	read:boolean,
	sender:MessageParticipant,
	viewableBy?:UserType,
	order:Order
}

export type ChatMessageInput = {
		orderId:string,
	text:string,
	viewableBy?:UserType
}

export type ChatMessageList = {
	__typename?: "ChatMessageList",
	items:ChatMessage[],
	total:number,
	hasMore:boolean,
	unreadCount:number
}

export type City = {
	__typename?: "City",
	id:string,
	name:string,
	zones:Zone[]
}

export type CityCreateInput = {
		name:string,
	zones:ZoneCreateInput[]
}

export type CommissionPlan = {
	__typename?: "CommissionPlan",
	id:string,
	slug:string,
	description?:string,
	rungs:CommissionPlanRung[],
	partnerPlans:PartnerCommissionPlan[]
}

export type CommissionPlanList = {
	__typename?: "CommissionPlanList",
	items:CommissionPlan[],
	total:number,
	hasMore:boolean
}

export type CommissionPlanRung = {
	__typename?: "CommissionPlanRung",
	id:string,
	name:string,
	description?:string,
	fixedBase:number,
	percentage:number,
	percentageCollection:number,
	percentageTableService:number,
	deliveryFeePerKMDiff:number,
	deliveryFeeBaseDiff:number
}

/** Configuration for the ecoeats API */
export type Configuration = {
	__typename?: "Configuration",
	/** Base URL for the CDN */
	cdnURL:string,
	/** Base URL for image resizing proxy */
	imgProxyURL:string,
	env:string,
	/** Branding settings for the current context */
	coreSettings:CoreSettings
}

export type ConsumerCharityCampaignView = {
	__typename?: "ConsumerCharityCampaignView",
	charityId:string,
	charityName:string,
	charityLogo:ImageAsset,
	id:string,
	campaignTitle:string,
	campaignLogo?:ImageAsset,
	startDate:Date,
	endDate:Date,
	goal:number,
	progress:number,
	goalFormatted:string,
	progressFormatted:string,
	percentageProgress:number,
	description?:string,
	map?:CampaignMapView
}

export type ConsumerSearchItemResult = {
	__typename?: "ConsumerSearchItemResult",
	relevance:number,
	item:Item
}

export type ConsumerSearchPartnerResult = {
	__typename?: "ConsumerSearchPartnerResult",
	relevance:number,
	item:Restaurant
}

export type ConsumerSearchResult = {
	__typename?: "ConsumerSearchResult",
	query:string,
	location:UnifiedLocationType,
	partners:ConsumerSearchPartnerResult[],
	items:ConsumerSearchItemResult[],
	tags:ConsumerSearchTagResult[]
}

export type ConsumerSearchTagResult = {
	__typename?: "ConsumerSearchTagResult",
	relevance:number,
	item:PartnerTag
}

export type ContainerCode = {
	__typename?: "ContainerCode",
	id:string,
	createdAt:Date
}

/** A set of coordinates. x, y */
export type Coordinates = any

export enum CoreConfigurations {
	ECOEATS = "ECOEATS",
	ZESH = "ZESH",
	INTERNAL = "INTERNAL",
	ECORIDER = "ECORIDER"
}

export type CoreSettings = {
	__typename?: "CoreSettings",
	id:CoreConfigurations,
	displayName:string,
	enableMultiPartnerOrders:boolean,
	url:string,
	fulfillmentMethods:FulfillmentMethod[],
	fulfillmentTimeInterval:number,
	theme:CoreSettingsTheme
}

export type CoreSettingsTheme = {
	__typename?: "CoreSettingsTheme",
	colors:CoreSettingsThemeColors,
	font?:string
}

export type CoreSettingsThemeColors = {
	__typename?: "CoreSettingsThemeColors",
	PRIMARY:string,
	SECONDARY:string,
	TEXT:string,
	DARK:string
}

export type CreateIncidentInput = {
		severity:IncidentSeverity,
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:UserType,
	description:string
}

export type CreatePromotionInput = {
		start:Date,
	end:Date,
	name:string,
	title:string,
	zoneIds?:string[],
	color:string,
	body:string,
	targetType?:PromotionTarget,
	callToActionText?:string,
	target?:string
}

export type Customer = {
	__typename?: "Customer",
	id:string,
	name:string,
	email:string,
	created:Date,
	phoneNumber?:string,
	isPhoneNumberVerified:boolean,
	emailConfirmationTokenIssued?:Date,
	emailConfirmed:boolean,
	roles:string[],
	profileImageKey?:string,
	marketing:boolean,
	referralLink?:string,
	referredBy:Customer,
	referrals:Customer[],
	stripeCustomerId?:string,
	addresses:Address[],
	orders:Order[],
	paymentMethods:PaymentMethod[],
	orderStatistics?:CustomerOrderStatistics,
	accountCredit:number,
	vouchers:CustomerVoucher[],
	topups:CustomerWalletTopup[]
}

export type CustomerAnalytics = {
	__typename?: "CustomerAnalytics",
	id:string,
	layoutGroups:ChartLayoutGroup[]
}

export type CustomerList = {
	__typename?: "CustomerList",
	items:Customer[],
	total:number,
	hasMore:boolean
}

export type CustomerOrderStatistics = {
	__typename?: "CustomerOrderStatistics",
	metres?:number,
	emissionsSaved?:number,
	lastUpdated?:Date
}

export type CustomerReusables = {
	__typename?: "CustomerReusables",
	id:string,
	containersNotReturned:number,
	containersClaimedReturned:number,
	containersValidatedReturned:number,
	containersScheduled:number,
	containersScannedButNotScheduled:number,
	maxContainersSchedulable:number,
	totalDeposits:number,
	slots:ReusablesPickupScheduleTime[],
	pickups:ReusablesPickup[],
	activePickup?:ReusablesPickup
}

export type CustomerUpdateInput = {
		name?:string,
	phoneNumber?:string
}

export type CustomerVoucher = {
	__typename?: "CustomerVoucher",
	id:string,
	created:Date,
	used:boolean,
	discount:Discount
}

export type CustomerWalletTopup = {
	__typename?: "CustomerWalletTopup",
	id:string,
	createdAt:Date,
	note?:string,
	amount:number,
	paymentIntentId?:string,
	customer:Customer
}

export type CustomerWalletTopupList = {
	__typename?: "CustomerWalletTopupList",
	items:CustomerWalletTopup[],
	total:number,
	hasMore:boolean
}

/** Date custom scalar type */
export type Date = any

export enum DayOfWeek {
	MONDAY = "MONDAY",
	TUESDAY = "TUESDAY",
	WEDNESDAY = "WEDNESDAY",
	THURSDAY = "THURSDAY",
	FRIDAY = "FRIDAY",
	SATURDAY = "SATURDAY",
	SUNDAY = "SUNDAY"
}

export type DeliveryFlightPlan = {
	__typename?: "DeliveryFlightPlan",
	id:string,
	createdAt:Date,
	completedAt:Date,
	route:JSON,
	riderId:string,
	legs:DeliveryFlightPlanLeg[],
	activeOffer?:FlightPlanOffer
}

export type DeliveryFlightPlanLeg = {
	__typename?: "DeliveryFlightPlanLeg",
	id:string,
	legOrder:number,
	order:Order,
	address?:Address
}

export type DeliveryJobInput = {
		id?:string,
	scheduledFor?:Date,
	destinationId?:string,
	originId?:string,
	destination:AddressCreateInput,
	origin:AddressCreateInput,
	size:B2BDeliverySizes
}

export type DeliveryTimeArgs = {
	__typename?: "DeliveryTimeArgs",
	day:DeliveryTimeDay,
	time:HourMinuteTimeString
}

export enum DeliveryTimeDay {
	TODAY = "TODAY",
	TOMORROW = "TOMORROW"
}

export type DeliveryTimeInput = {
		day:DeliveryTimeDay,
	time:HourMinuteTimeString
}

export type DeliveryTimes = {
	__typename?: "DeliveryTimes",
	date:string,
	slots:Date[]
}

export type Discount = {
	__typename?: "Discount",
	id:string,
	/** Code that customer enters to redeem this discount. */
	code:string,
	description?:string,
	/** The value of this discount. Only needed for PERCENTAGE and FIXED discounts. */
	value?:number,
	/** The minimum price of the basket before this discount can be applied */
	minimumTotalPrice:number,
	type:DiscountType,
	enabled:boolean,
	validFrom:Date,
	validUntil:Date,
	maxUsesGlobally:number,
	maxUsesPerCustomer:number,
	referralAmount?:number,
	orders:OrderList,
	riderReferral?:RiderReferralDiscount
}

export type DiscountCreateInput = {
		code:string,
	description:string,
	validFrom:Date,
	validUntil:Date,
	type:DiscountType,
	value?:number,
	maxUsesGlobally:number,
	maxUsesPerCustomer:number,
	minimumTotalPrice:number,
	referralAmount?:number
}

export type DiscountList = {
	__typename?: "DiscountList",
	items:Discount[],
	total:number,
	hasMore:boolean
}

/** The type of discount this is. */
export enum DiscountType {
	PERCENTAGE = "PERCENTAGE",
	FIXED = "FIXED",
	FREE_DELIVERY = "FREE_DELIVERY"
}

export type DiscountUpdateInput = {
		description?:string,
	validFrom?:Date,
	validUntil?:Date,
	type?:DiscountType,
	value?:number,
	maxUsesGlobally?:number,
	maxUsesPerCustomer?:number,
	enabled?:boolean,
	referralAmount?:number
}

export type DistanceModel = {
	__typename?: "DistanceModel",
	metres:number,
	seconds:number
}

export enum DropBoxStatus {
	READY = "READY",
	UNAVAILABLE = "UNAVAILABLE"
}

/** A form that can represent any JSONSchema structured data. Designed for heavy data entry that does not fit with GraphQL */
export type DynamicForm = {
	__typename?: "DynamicForm",
	/** A unique ID representing this forms template. */
	id:string,
	/** The display type of the dynamic form */
	type:DynamicFormType,
	title:string,
	/** If provided, poll the API for a change in this form this often. */
	pollInterval?:number,
	canSubmit:boolean,
	submitText:string,
	description?:string,
	/** Values to populate the data structure represented by the Schema. */
	initialValues?:JSON,
	/** Always a valid JSONSchema object that can be represted as a form */
	schema:JSON
}

export type DynamicFormResult = {
	__typename?: "DynamicFormResult",
	success:boolean,
	successMessage?:string,
	form:DynamicForm
}

/** FORM displays as a form, TABLE as a table. Use initialValues as data. */
export enum DynamicFormType {
	FORM = "FORM",
	TABLE = "TABLE"
}

export type ExternalCompetitor = {
	__typename?: "ExternalCompetitor",
	name:string,
	id:string,
	updated:Date,
	raw?:JSON
}

export type ExternalCompetitorInput = {
		name:string,
	id:string
}

export type File = {
	__typename?: "File",
	id:string,
	createdAt:Date,
	type:string,
	title:string,
	size:number,
	/** A link to the file, subject to change. */
	url:string
}

export type FileList = {
	__typename?: "FileList",
	items:File[],
	total:number,
	hasMore:boolean
}

export type FinancialOverview = {
	__typename?: "FinancialOverview",
	meta:FinancialOverviewArgsMeta,
	partners:RestaurantFinancialsView[],
	riders:SpecificRiderFinancials[],
	charities:CharityFinancials[]
}

export type FinancialOverviewArgsMeta = {
	__typename?: "FinancialOverviewArgsMeta",
	startDate:Date,
	endDate:Date,
	zoneIds?:string[]
}

export type FlightPlanOffer = {
	__typename?: "FlightPlanOffer",
	id:string,
	offered:Date,
	changed:Date,
	outcome:string,
	fee:number,
	sneaky:boolean,
	context?:JSON,
	legs:DeliveryFlightPlanLeg[],
	orders:Order[],
	riderId:string,
	rider:Rider,
	flightPlanId:string,
	flightPlan:DeliveryFlightPlan
}

export type FlightPlanOfferList = {
	__typename?: "FlightPlanOfferList",
	items:FlightPlanOffer[],
	total:number,
	hasMore:boolean
}

export type FulfillmentDayTimes = {
	__typename?: "FulfillmentDayTimes",
	day:DeliveryTimeDay,
	times:HourMinuteTimeString[]
}

export enum FulfillmentMethod {
	DELIVERY = "DELIVERY",
	COLLECTION = "COLLECTION",
	TABLE_SERVICE = "TABLE_SERVICE"
}

export type GenericAuditView = {
	__typename?: "GenericAuditView",
	id:string,
	timestamp:Date,
	targetId:string,
	from:JSON,
	to:JSON
}

export type GraphQLGeoJSONLineString = {
	__typename?: "GraphQLGeoJSONLineString",
	type:string,
	coordinates:Coordinates[]
}

export type GraphQLGeoJSONPoint = {
	__typename?: "GraphQLGeoJSONPoint",
	type:string,
	coordinates:Coordinates
}

export type GraphQLGeoJSONPointI = {
		type?:string,
	coordinates:Coordinates
}

export type GraphQLGeoJSONPointInput = {
		type?:string,
	coordinates:Coordinates
}

export type GraphQLGeoJSONPolygon = {
	__typename?: "GraphQLGeoJSONPolygon",
	type:string,
	coordinates:Coordinates[]
}

export type GraphQLGeoJSONPolygonInput = {
		type?:string,
	coordinates:Coordinates[]
}

export type HomePage = {
	__typename?: "HomePage",
	meta:PartnerHomePageMeta,
	promotions:HomepagePromotion[],
	selections:PartnerSelection[],
	partners:Restaurant[],
	tags:PartnerTag[],
	zone:Zone,
	fulfillmentTimes:FulfillmentDayTimes[],
	itemGroups:PartnerHomePageItemGroup[]
}

export type HomepagePromotion = {
	__typename?: "HomepagePromotion",
	id:string,
	body:string,
	title:string,
	color?:string,
	imageKey?:string,
	callToActionText?:string,
	target?:string,
	targetType?:PromotionTarget
}

/** HH:MM formatted time string */
export type HourMinuteTimeString = any

export type Hygiene = {
	__typename?: "Hygiene",
	id:string,
	updated:Date,
	rating?:string,
	authority:HygieneAuthorities,
	authorityRatingId:string,
	authorityRatingLink?:string,
	authorityRatingImage?:string,
	restaurant:Restaurant
}

export enum HygieneAuthorities {
	FSA = "FSA"
}

export enum IdentityDocumentType {
	PASSPORT = "PASSPORT",
	DRIVERS_LICENSE = "DRIVERS_LICENSE",
	BIOMETRIC_PERMIT_NUMBER = "BIOMETRIC_PERMIT_NUMBER",
	BIOMETRIC_CARD_NUMBER = "BIOMETRIC_CARD_NUMBER"
}

export type ImageAsset = {
	__typename?: "ImageAsset",
	id:string,
	created:Date,
	updated:Date,
	title:string,
	key:string,
	width:number,
	height:number,
	fileSize:number,
	altText?:string,
	mimeType:string,
	url:string,
	uploadedBy?:RestaurantAdmin
}

export type ImageAssetList = {
	__typename?: "ImageAssetList",
	items:ImageAsset[],
	total:number,
	hasMore:boolean
}

export type Incident = {
	__typename?: "Incident",
	id:string,
	created:Date,
	number:number,
	severity:IncidentSeverity,
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:UserType,
	description:string,
	customer?:Customer,
	order?:Order,
	rider?:Rider,
	partner?:Restaurant,
	/** Audit changes to the incident */
	audit:GenericAuditView[],
	images:ImageAsset[]
}

export type IncidentList = {
	__typename?: "IncidentList",
	items:Incident[],
	total:number,
	hasMore:boolean
}

export enum IncidentSeverity {
	MAJOR = "MAJOR",
	MINOR = "MINOR",
	MODERATE = "MODERATE"
}

export type Item = {
	__typename?: "Item",
	id:string,
	isTemplate:boolean,
	name:string,
	description?:string,
	ingredients?:string,
	unitPrice:number,
	/** The maximum number of this item that can be in a single order */
	maxPerOrder?:number,
	/** The emissions this item is responsible for, in grams of CO2 */
	emissions?:number,
	/** Whether this  */
	alcohol:boolean,
	/** Whether this item is vegan or not */
	vegan?:boolean,
	/** Whether this item is vegetarian or not */
	vegetarian?:boolean,
	/** Weight of the item, in grams */
	weight:number,
	/** A list of fulfillment methods this item cannot be ordered for */
	unavailableFor:FulfillmentMethod[],
	/** Whether this item takes a long time to prepare */
	slow:boolean,
	/** Whether this item has been approved for use */
	approved:boolean,
	tax:number,
	/** How hot this item is when served */
	heat?:ItemHeatType,
	/** Whether this item is published or not */
	published:boolean,
	/** Whether this item is popular with customers or not */
	popular:boolean,
	/** A path to the image representing the header for this merchant */
	headerImageKey?:string,
	/** A hash that represents the headerImage */
	blurhash?:string,
	/** The order in which this item should be shown in the menu */
	sortOrder:number,
	/** Whether this item is still available to order, i.e. not out of stock */
	available:boolean,
	/** When this item was deleted */
	deletedAt?:Date,
	categoryId:string,
	/** The category to which this item belongs */
	category:MenuCategory,
	/** The modifier groups this item has as options */
	modifiers:ModifierGroup[],
	optionFor:ModifierGroup[],
	containerTypeId?:string,
	price:Price,
	headerImage?:string,
	squareImage?:string,
	menu:Menu,
	containerType?:ReusableContainerType
}

export type ItemCreateInput = {
		id?:string,
	name:string,
	description?:string,
	ingredients?:string,
	unitPrice:number,
	alcohol?:boolean,
	popular?:boolean,
	headerImageUpload?:Upload,
	sortOrder?:number,
	modifierIds:string[],
	approved?:boolean,
	available:boolean,
	published:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId:string,
	heat?:ItemHeatType,
	unavailableFor?:FulfillmentMethod[],
	containerTypeId?:string
}

export enum ItemHeatType {
	HOT = "HOT",
	COLD = "COLD"
}

export type ItemList = {
	__typename?: "ItemList",
	items:Item[],
	total:number,
	hasMore:boolean
}

export type ItemSales = {
	__typename?: "ItemSales",
	item:Item,
	numberSold:number,
	totalValue:number
}

export type ItemUpdateAsRestaurantInput = {
		unitPrice?:number,
	alcohol?:boolean,
	popular?:boolean,
	sortOrder?:number,
	modifierIds?:string[],
	available?:boolean,
	published?:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId?:string,
	heat?:ItemHeatType,
	unavailableFor?:FulfillmentMethod[],
	containerTypeId?:string
}

export type ItemUpdateInput = {
		id?:string,
	name?:string,
	description?:string,
	ingredients?:string,
	unitPrice?:number,
	alcohol?:boolean,
	popular?:boolean,
	headerImageUpload?:Upload,
	sortOrder?:number,
	modifierIds?:string[],
	approved?:boolean,
	available?:boolean,
	published?:boolean,
	vegan?:boolean,
	vegetarian?:boolean,
	weight?:number,
	tax?:number,
	/** The category to which this item belongs */
	categoryId?:string,
	heat?:ItemHeatType,
	unavailableFor?:FulfillmentMethod[],
	containerTypeId?:string
}

export type ItemUpdateRequest = {
	__typename?: "ItemUpdateRequest",
	id:string,
	created:Date,
	status:ItemUpdateRequestStatus,
	name?:string,
	description?:string,
	imageKey?:string,
	item:Item,
	partner:Restaurant
}

export type ItemUpdateRequestInput = {
		description?:string,
	name?:string,
	itemId:string,
	partnerId:string
}

export type ItemUpdateRequestList = {
	__typename?: "ItemUpdateRequestList",
	items:ItemUpdateRequest[],
	total:number,
	hasMore:boolean
}

export enum ItemUpdateRequestStatus {
	PENDING = "PENDING",
	REJECTED = "REJECTED",
	APPROVED = "APPROVED"
}

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type JSON = any

export type Lease = {
	__typename?: "Lease",
	id:string,
	start:Date,
	end?:Date,
	initialMileage:number,
	weeklyMileageAllowance:number,
	excessiveMileageCharge:number,
	totalDeposit:number,
	initialDeposit:number,
	depositGraceWeeks:number,
	numberOfDepositWeeks:number,
	firstDaysFree:number,
	pricePerDay:number,
	vehicleId:string,
	vehicle:LeaseVehicle,
	riderId:string,
	rider:Rider
}

export type LeaseList = {
	__typename?: "LeaseList",
	items:LeaseView[],
	total:number,
	hasMore:boolean
}

export type LeasePayment = {
	__typename?: "LeasePayment",
	id:string,
	created:Date,
	type:string,
	dueAt:Date,
	paidAt?:Date,
	leaseId:string,
	lease:Lease,
	value:number,
	stripePaymentIntentId?:string,
	status:LeasePaymentStatus
}

export enum LeasePaymentStatus {
	DUE = "DUE",
	PAID = "PAID"
}

export type LeaseVehicle = {
	__typename?: "LeaseVehicle",
	id:string,
	created:Date,
	note?:string,
	registration:string,
	status:LeaseVehicleStatus,
	dvlaInfo:JSON,
	leases:Lease[]
}

export type LeaseVehicleList = {
	__typename?: "LeaseVehicleList",
	items:LeaseVehicle[],
	total:number,
	hasMore:boolean
}

export enum LeaseVehicleStatus {
	PURCHASED = "PURCHASED",
	READY = "READY",
	MAINTENANCE = "MAINTENANCE"
}

export type LeaseView = {
	__typename?: "LeaseView",
	id:string,
	start:Date,
	end?:Date,
	initialMileage:number,
	weeklyMileageAllowance:number,
	excessiveMileageCharge:number,
	totalDeposit:number,
	initialDeposit:number,
	depositGraceWeeks:number,
	numberOfDepositWeeks:number,
	firstDaysFree:number,
	pricePerDay:number,
	vehicleId:string,
	vehicle:LeaseVehicle,
	riderId:string,
	rider:Rider,
	totalPaid:number,
	outstandingBalance:number,
	outstandingDeposit:number,
	nextDepositAmount:number,
	nextDepositDate?:Date,
	nextWeeklyAmount:number,
	nextWeeklyDate?:Date,
	lastDepositPayment?:Date,
	lastWeeklyPayment?:Date,
	payments:LeasePayment[]
}

export type LineChart = {
	__typename?: "LineChart",
	id:string,
	type:string,
	series:Series[]
}

export type LocationSearchResult = {
	__typename?: "LocationSearchResult",
	id:string,
	text:string,
	provider:string,
	addressLine1?:string,
	postcode?:string,
	coordinates?:Coordinates
}

export type LoginNumberPayload = {
		number:string,
	password:string,
	userType?:string
}

export enum LoginOAuthProviders {
	APPLE = "APPLE",
	GOOGLE = "GOOGLE",
	FACEBOOK = "FACEBOOK"
}

export type LoginPayload = {
		email:string,
	password:string,
	userType?:string,
	twoFactorCode?:string
}

export type MarketingCampaign = {
	__typename?: "MarketingCampaign",
	id:string,
	name:string,
	status:MarketingCampaignStatus,
	sends:number,
	scheduledFor:Date
}

export type MarketingCampaignList = {
	__typename?: "MarketingCampaignList",
	items:MarketingCampaign[],
	total:number,
	hasMore:boolean
}

export enum MarketingCampaignStatus {
	SCHEDULED = "SCHEDULED",
	IN_PROGRESS = "IN_PROGRESS",
	COMPLETE = "COMPLETE"
}

export type Menu = {
	__typename?: "Menu",
	id:string,
	isTemplate:boolean,
	name:string,
	items:Item[],
	modifierGroups:ModifierGroup[],
	categories:MenuCategory[],
	restaurant:Restaurant
}

/** A menu category is a top-level listing of items that can be directly purchased */
export type MenuCategory = {
	__typename?: "MenuCategory",
	id:string,
	name:string,
	description?:string,
	sortOrder:number,
	topLevel:boolean,
	items:Item[],
	times:OpeningTime[],
	menu:Menu
}

export type MenuCategoryCreateInput = {
		id?:string,
	name:string,
	description?:string,
	sortOrder?:number,
	topLevel?:boolean,
	times?:OpeningTimeInput[]
}

export type MenuModifierGroupCreateInput = {
		id?:string,
	name:string,
	maxOptions:number,
	minOptions:number,
	maxPerOption?:number,
	instruction?:string,
	sortOrder?:number,
	optionIds:string[],
	priceStrategy:PriceStrategy,
	cascades:boolean,
	approved?:boolean
}

export type MerchantSite = {
	__typename?: "MerchantSite",
	id:string,
	name:string,
	description?:string,
	status:MerchantSiteStatus,
	domains:MerchantSiteDomain[],
	pages:MerchantSitePage[],
	screenshot?:ImageAsset
}

export type MerchantSiteDomain = {
	__typename?: "MerchantSiteDomain",
	id:string,
	createdAt:Date,
	domain:string,
	isVerified:boolean,
	dns:string
}

export type MerchantSiteList = {
	__typename?: "MerchantSiteList",
	items:MerchantSite[],
	total:number,
	hasMore:boolean
}

export type MerchantSitePage = {
	__typename?: "MerchantSitePage",
	id:string,
	slug:string,
	title:string,
	layout:JSON,
	screenshot?:ImageAsset,
	site:MerchantSite
}

export type MerchantSitePageCreateInput = {
		slug:string,
	title:string,
	layout:JSON,
	siteId:string
}

export type MerchantSitePageUpdateInput = {
		slug?:string,
	title?:string,
	layout?:string
}

export enum MerchantSiteStatus {
	LIVE = "LIVE",
	DISABLED = "DISABLED"
}

export type MessageParticipant = {
	__typename?: "MessageParticipant",
	id:string,
	type:UserType,
	name:string,
	email:string,
	avatarUrl:string
}

export type ModifierGroup = {
	__typename?: "ModifierGroup",
	id:string,
	name:string,
	instruction?:string,
	maxOptions:number,
	minOptions:number,
	/** Maximum selections of each individual option. */
	maxPerOption?:number,
	cascades:boolean,
	sortOrder:number,
	priceStrategy:PriceStrategy,
	options:Item[],
	optionFor:Item[],
	menuId:string
}

export type Mutation = {
	__typename?: "Mutation",
	submitDynamicForm:DynamicFormResult,
	updateCustomer:Customer,
	deleteCustomer:boolean,
	addPaymentMethod:boolean,
	addPaymentMethodWithCard:boolean,
	addVoucherCode:Customer,
	removePaymentMethod:boolean,
	addAddress:Address,
	placeOrder:PlaceOrderResponse,
	updateOrder:Order,
	rateOrder:boolean,
	/** Tip your rider after the delivery is complete. */
	OrderTipRider:Order,
	adjustOrder:Order,
	/** Accept this order and begin preparing it. */
	acceptOrder:Order,
	acceptOrderAsRestaurant:boolean,
	completeOrderAsRestaurant:boolean,
	rejectOrderAsRestaurant:boolean,
	arriveForOwnOrder:boolean,
	pickUpOwnOrder:boolean,
	/** Delay the pickup of this order by 10 minutes. */
	delayPickup:Order,
	/** Prevent this order from being assigned to any rider. Invisible to anyone else. */
	blockPickup:Order,
	/** Remove the assigned rider from this order. Also rejects any pending offers if they are relevant. */
	OrderRemoveRiderAsAdmin:Order,
	/** Marks this order as complete, regardless of its current status. */
	OrderCompleteAsAdmin:Order,
	/** Bring the pickup time forward for this order. Can encourage the algorithm to prioritise it over others. */
	OrderBringForward:Order,
	rescheduleOrder:Order,
	resolveOrderAlert:OrderAlert,
	PurchaseWalletTopup:PurchaseCustomerWalletTopup,
	/** Mark this item as no longer available */
	setAvailability:Item,
	setItemAvailability:Item,
	updateItemHeaderImage:Item,
	removeItemHeaderImage:Item,
	updateItem:Item,
	deleteItem:Item,
	updateItemAsRestaurant:Item,
	basketApplyDiscount:Basket,
	/** Add an item to the basket */
	basketAddItem:Basket,
	/** Increment an item in the basket */
	basketIncrementItem:Basket,
	/** Decrement an item in the basket */
	basketDecrementItem:Basket,
	/** Edit an item in the basket */
	basketEditItem:Basket,
	/** Update tip for the basket */
	basketIncrementOrDecrementTip:Basket,
	/** Set the rider tip for the basket */
	basketSetTip:Basket,
	/** Set the rider tip for the basket */
	basketSetNotes:boolean,
	basketSetInstructions:Basket,
	/** Generates a payment intent for the basket, allowing the customer to start client-side payment flow */
	basketCheckOut:Basket,
	basketSetSingleDelivery:Basket,
	basketSetReusables:Basket,
	/** Add a donation to the basket */
	basketDonationAdd:Basket,
	/** Remove a donation from the basket */
	basketDonationRemove:Basket,
	updateCharity:Charity,
	createCharity:Charity,
	customerCharityUIView:ConsumerCharityCampaignView,
	createCharityCampaign:CharityCampaign,
	updateCharityCampaign:CharityCampaign,
	/** Swap a containers code for another. The existing code will continue to function. */
	SwapContainerCode:ReusableContainer,
	ScanContainerAtPartner:ScanContainerAtPartnerResponse,
	ScanContainer:CustomerReusables,
	CancelContainerPickup:CustomerReusables,
	UpdateContainerPickup:ReusablesPickup,
	BookContainerPickup:CustomerReusables,
	/** Assign a shipment to be fulfilled at the given washing location */
	AssignReusableShipment:ReusableShipment,
	/** Mark a shipment as complete and schedule the order for it */
	CompleteReusableShipment:ReusableShipment,
	createRestaurant:Restaurant,
	updateRestaurant:Restaurant,
	setFulfillmentMethods:Restaurant,
	setAcceptsScheduledOrders:Restaurant,
	updateRestaurantHeaderImage:Restaurant,
	setBusy:Restaurant,
	setOnline:boolean,
	setOwner:boolean,
	markItemsUnavailable:boolean,
	createPartnerTag:PartnerTag,
	updatePartnerTag:PartnerTag,
	deletePartnerTag:boolean,
	PartnerTabletSync:PartnerTablet,
	PartnerTabletAction:PartnerTablet,
	addItem:Item[],
	addCategory:Menu,
	reorderCategory:MenuCategory,
	reorderModifierGroups:Menu,
	reorderCategories:Menu,
	addModifierGroup:Menu,
	importMenu:boolean,
	updateCategoryAsRestaurant:MenuCategory,
	createItemUpdateRequest:ItemUpdateRequest,
	updateItemUpdateRequest:ItemUpdateRequest,
	updateRestaurantAdmin:RestaurantAdmin,
	createSuperAdmin:RestaurantAdmin,
	updateTabletAccountPassword:boolean,
	createZone:Zone,
	boostZone:Boost,
	updateZone:Zone,
	setActivePolygon:Zone,
	endBoost:Boost,
	createZonePolygon:ZonePolygon,
	createRider:Rider,
	updateRiderLocation:Rider,
	unthrottleRider:Rider,
	sendMessageToRider:boolean,
	updateRider:Rider,
	requestNotification:boolean,
	endRiderOnlineSession:RiderOnlineSession,
	deleteRiderOnlineSession:boolean,
	createRiderApplication:RiderApplication,
	deleteRiderApplication:boolean,
	RiderLiveUIValidate:RiderLiveView,
	RiderLiveUIAction:RiderLiveView,
	RiderLiveUISetting:RiderLiveView,
	PayLeasePayment:LeasePayment,
	sendChatMessage:ChatMessage,
	login:AuthDTO,
	/** Exchange an OAuth token for ecoeats auth tokens */
	loginOAuth:AuthDTO,
	loginPhone:AuthDTO,
	setupTwoFactorAuthentication:TwoFactorSetup,
	confirmTwoFactorAuthentication:boolean,
	refreshToken:AuthDTO,
	logout:boolean,
	register:AuthDTO,
	resetPasswordRequest:boolean,
	resetPasswordWithToken:boolean,
	requestPhoneNumberVerification:boolean,
	verifyPhoneNumberCode:boolean,
	setFirebaseCloudMessagingToken:boolean,
	requestConfirmationEmail:boolean,
	confirmEmailWithToken:boolean,
	UserUpdateProfilePicture:User,
	createDiscount:Discount,
	updateDiscount:Discount,
	createRiderReferralDiscount:RiderReferralDiscount,
	updateAddress:Address,
	createAddress:Address,
	deleteAddress:boolean,
	makeBooking:Booking,
	cancelBooking:Booking,
	updateBooking:Booking,
	decideBooking:Booking,
	createBookingSlot:BookingSlot,
	updateBookingSlot:BookingSlot,
	deleteBookingSlot:boolean,
	autoCreateBookingSlots:boolean,
	AutoBookingSlotsConfigList:AutoBookingSlotsConfigList,
	createAutoBookingConfig:AutoBookingSlotsConfig,
	deleteAutoBookingSlot:AutoBookingSlotsConfig,
	createAutoBookingSlot:AutoBookingSlot,
	createCity:City,
	exportOrders:string,
	/** Finds the invoice for the arguments, creates one if it does not exist */
	riderInvoice:RiderInvoice,
	payRiderInvoice:RiderInvoice,
	payRiderInvoices:RiderInvoicesJob,
	PartnerInvoiceForPeriod:PartnerInvoice,
	payPartnerInvoice:PartnerInvoice,
	updatePartnerPaymentDetails:PartnerPaymentDetails,
	createRiderAdjustment:RiderAdjustment,
	updateRiderAdjustment:RiderAdjustment,
	createPartnerAdjustment:PartnerAdjustment,
	updatePartnerAdjustment:PartnerAdjustment,
	uploadPromotionImage:Promotion,
	createPromotion:Promotion,
	updatePromotion:Promotion,
	estimateB2BJob:B2BJobEstimateResponse,
	executeB2BJob:B2BDeliveryJob,
	cancelB2BJob:B2BDeliveryJob,
	createIncident:Incident,
	updateIncident:Incident,
	uploadImageAsset:ImageAsset,
	UploadVideoAsset:UploadMediaJob,
	UploadMediaComplete:VideoAsset,
	AdminPageView:AdminPageView,
	createMerchantSitePage:MerchantSitePage,
	updateMerchantSitePage:MerchantSitePage,
	createMerchantSiteDomain:MerchantSiteDomain
}

export type OAuthConfiguration = {
	__typename?: "OAuthConfiguration",
	apple:OAuthConfigurationApple,
	google:OAuthConfigurationGoogle,
	facebook:OAuthConfigurationFacebook
}

export type OAuthConfigurationApple = {
	__typename?: "OAuthConfigurationApple",
	clientId:string,
	redirectURI:string
}

export type OAuthConfigurationFacebook = {
	__typename?: "OAuthConfigurationFacebook",
	appId:string
}

export type OAuthConfigurationGoogle = {
	__typename?: "OAuthConfigurationGoogle",
	clientId:string,
	redirectURI:string
}

export type OpeningTime = {
	__typename?: "OpeningTime",
	id:string,
	open:string,
	close:string,
	daysOfWeek:DayOfWeek[],
	restaurant?:Restaurant,
	menuCategory?:MenuCategory,
	zone?:Zone
}

export type OpeningTimeInput = {
		id?:string,
	open:string,
	close:string,
	daysOfWeek?:string[]
}

export type Order = {
	__typename?: "Order",
	id:string,
	/** A string to indentify the order at the restaurant */
	number:string,
	fulfilledBy:OrderFulfilledBy,
	placedAt:Date,
	partnerAcknowlegedAt?:Date,
	scheduledFor?:Date,
	initialPickupAt?:Date,
	pickupAt?:Date,
	pickupDelayedByPartner:boolean,
	pickedDelayedByAdminAt?:Date,
	orderMarkedReadyAt?:Date,
	status:string,
	/** Whether this order has been viewed by the restaurant */
	opened:boolean,
	allergyNotes?:string,
	internalNote?:string,
	riderEarnings:number,
	baseRiderEarnings:number,
	deliveryEstimate:number,
	deliveryEstimateMax:number,
	estimatedArrivalTime?:Date,
	estimatedArrivalTimeDelta?:number,
	stripeFee:number,
	fulfillmentMethod:FulfillmentMethod,
	ourAdjustment:number,
	ourAdjustmentJustification?:string,
	partnerAdjustment:number,
	partnerAdjustmentJustification?:string,
	riderEarningsAdjustment:number,
	riderEarningsAdjustmentJustification?:string,
	riderTipAdjustment:number,
	riderTipAdjustmentJustification?:string,
	orderType:OrderType,
	/** The number of size points the items of this order sum to */
	sizePoints:number,
	/** Number of child orders needed to complete this order */
	numberOfChildOrders:number,
	/** The child orders for this object. Only populated when isSplit = true and orderType = PARENT */
	childOrders:Order[],
	/** The parent order for this order. Only populated when isSplit = true and orderType = CHILD */
	parentOrderId?:string,
	/** The parent order for this order. Only populated when isSplit = true and orderType = CHILD */
	parentOrder:Order,
	customerLastRemindedToPickUp?:Date,
	isBeingOfferedToRider:boolean,
	lastOfferedToRider?:Date,
	isRiderAtRestaurant:boolean,
	riderArrivedAtRestaurantTime?:Date,
	riderPickedUpTime?:Date,
	riderArrivedAtCustomerTime?:Date,
	riderDroppedOffTime?:Date,
	pickupBlocked:boolean,
	longWaitReportedAt?:Date,
	riderRating?:number,
	partnerRating?:number,
	amountRefunded:number,
	cancellationReason?:OrderCancellationReason,
	basket:Basket,
	customer:Customer,
	rider?:Rider,
	addressId:string,
	address?:Address,
	restaurant:Restaurant,
	offers:OrderOffer[],
	b2bDeliveryJob?:B2BDeliveryJob,
	b2bDeliveryJobId?:string,
	aggregateOrder:AggregateOrder,
	unreadMessageCount:number,
	canTipRider:boolean,
	/** Information about the reusables used for this order. */
	reusables?:ReusablesInfo,
	/** A polyline representing the directions that either the rider or customer needs to follow. 
      This is not to be used for displaying to riders in app, but rather as a decorative element 
      to show customers when tracking the order. */
	directions?:string,
	reusablesPickupAggregate?:ReusablesPickupAggregate,
	riderReport?:OrderRiderRating,
	reusableShipment?:ReusableShipment
}

export type OrderAdjustInput = {
		ourAdjustment:number,
	partnerAdjustment:number,
	riderEarningsAdjustment:number,
	riderTipAdjustment:number,
	ourAdjustmentJustification?:string,
	partnerAdjustmentJustification?:string,
	riderEarningsAdjustmentJustification?:string,
	riderTipAdjustmentJustification?:string
}

export type OrderAlert = {
	__typename?: "OrderAlert",
	id:string,
	type:OrderAlertType,
	created:Date,
	resolved?:Date,
	resolvedBy?:RestaurantAdmin,
	riderId?:string,
	rider?:Rider,
	orderId:string,
	order:Order,
	zoneId:string,
	zone:Zone
}

export type OrderAlertList = {
	__typename?: "OrderAlertList",
	items:OrderAlert[],
	total:number,
	hasMore:boolean
}

export enum OrderAlertType {
	RIDER_LATE_TO_RESTAURANT = "RIDER_LATE_TO_RESTAURANT",
	RIDER_COMPLETED_TOO_FAST = "RIDER_COMPLETED_TOO_FAST",
	RIDER_LATE_TO_CUSTOMER = "RIDER_LATE_TO_CUSTOMER",
	PARTNER_NOT_RESPONDING = "PARTNER_NOT_RESPONDING",
	ORDER_NOT_ASSIGNED = "ORDER_NOT_ASSIGNED"
}

export enum OrderCancellationReason {
	PARTNER_CLOSED = "PARTNER_CLOSED",
	PARTNER_DID_NOT_RESPOND = "PARTNER_DID_NOT_RESPOND",
	LOW_RIDER_SUPPLY = "LOW_RIDER_SUPPLY",
	REJECTED_BY_PARTNER = "REJECTED_BY_PARTNER",
	CUSTOMER_REQUEST = "CUSTOMER_REQUEST"
}

export type OrderCreateInput = {
		/** The stripe payment method */
	paymentMethodId?:string,
	/** A tokenised payment method */
	paymentMethodToken?:string,
	/** The basket for the order */
	basketId:string
}

export type OrderEvent = {
	__typename?: "OrderEvent",
	id:string,
	timestamp:Date,
	targetId:string,
	from:JSON,
	to:JSON
}

export type OrderEventList = {
	__typename?: "OrderEventList",
	items:OrderEvent[],
	total:number,
	hasMore:boolean
}

export enum OrderFulfilledBy {
	ECOEATS = "ECOEATS",
	MERCHANT = "MERCHANT"
}

export enum Ordering {
	ASC = "ASC",
	DESC = "DESC"
}

/** An item in an order or a basket */
export type OrderItem = {
	__typename?: "OrderItem",
	/** A descriptor unique to this order item */
	id:string,
	added:Date,
	/** Fields copied from the item, */
	name:string,
	/** Fields copied from the item, */
	description?:string,
	quantity:number,
	/** Unit price of of OrderItem including modifiers */
	unitPrice:number,
	/** Unit weight of the menu item */
	unitWeight:number,
	/** Total price including modifiers and quantity */
	totalPrice:number,
	alcohol:boolean,
	modifierItems:OrderItemModifier[],
	menuItemId:string,
	basketId:string,
	basket:Basket
}

/** A modifier for an item in an order or basket. This should be flattend,
regardless of modifier groups appearing in the actual menu display */
export type OrderItemModifier = {
	__typename?: "OrderItemModifier",
	id:string,
	name:string,
	quantity:number,
	unitPrice:number,
	/** Unit weight of the menu item */
	unitWeight:number,
	menuItemId:string,
	modifierGroupId:string,
	/** The menu modifier group for which this is an order entry */
	modifierGroup:ModifierGroup,
	orderItemId:string,
	/** The parent OrderItem for which this is a modifier item of a modifier group */
	orderItem:OrderItem
}

export type OrderList = {
	__typename?: "OrderList",
	items:Order[],
	total:number,
	hasMore:boolean
}

export type OrderOffer = {
	__typename?: "OrderOffer",
	id:string,
	offered:Date,
	changed:Date,
	outcome:string,
	distance:number,
	riderId:string,
	rider:Rider,
	orderId:string,
	order:Order
}

export type OrderRatingInput = {
		riderRating:number,
	partnerRating:number
}

export type OrderRejectionInput = {
		restaurantId?:string,
	orderId:string,
	/** Items that are in the order but no longer in stock */
	outOfStockItems?:string[],
	reason?:OrderCancellationReason
}

export type OrderRescheduleInput = {
		skipPrepTime:boolean,
	split:boolean,
	isPartnerResponsible:boolean,
	includeItems:string[]
}

export type OrderRiderRating = {
	__typename?: "OrderRiderRating",
	positive:boolean,
	ratings:string[],
	note?:string,
	id:string,
	order:Order,
	rider:Rider
}

export type OrderRiderRatingList = {
	__typename?: "OrderRiderRatingList",
	items:OrderRiderRating[],
	total:number,
	hasMore:boolean
}

export enum OrderRiderRatingType {
	BAD_DELIVERY_AREA = "BAD_DELIVERY_AREA",
	DISTANCE_TOO_LONG = "DISTANCE_TOO_LONG",
	DIFFICULT_JOURNEY = "DIFFICULT_JOURNEY",
	MERCHANT_DIFFICULT_TO_FIND = "MERCHANT_DIFFICULT_TO_FIND",
	MERCHANT_WAIT_TOO_LONG = "MERCHANT_WAIT_TOO_LONG",
	CUSTOMER_DIFFICULT_TO_FIND = "CUSTOMER_DIFFICULT_TO_FIND",
	CUSTOMER_DIFFICULT_TO_CONTACT = "CUSTOMER_DIFFICULT_TO_CONTACT",
	ITEMS_DAMAGED_OR_MISSING = "ITEMS_DAMAGED_OR_MISSING",
	SAFETY_CONCERNS = "SAFETY_CONCERNS",
	CUSTOMER_RUDE = "CUSTOMER_RUDE",
	MERCHANT_RUDE = "MERCHANT_RUDE"
}

export enum OrderStatus {
	CANCELLED = "CANCELLED",
	AWAITING_RESTAURANT = "AWAITING_RESTAURANT",
	PREP = "PREP",
	AWAITING_RIDER = "AWAITING_RIDER",
	EN_ROUTE = "EN_ROUTE",
	AWAITING_CUSTOMER = "AWAITING_CUSTOMER",
	COMPLETE = "COMPLETE"
}

export enum OrderType {
	PARENT = "PARENT",
	CHILD = "CHILD"
}

export type PartnerAdjustment = {
	__typename?: "PartnerAdjustment",
	id:string,
	created:Date,
	forDate:Date,
	amount:number,
	explanation:string,
	partnerId:string,
	partner:Restaurant
}

export type PartnerAdjustmentCreateInput = {
		forDate:Date,
	amount:number,
	explanation:string,
	partnerId:string
}

export type PartnerAdjustmentList = {
	__typename?: "PartnerAdjustmentList",
	items:PartnerAdjustment[],
	total:number,
	hasMore:boolean
}

export type PartnerAdjustmentUpdateInput = {
		forDate?:Date,
	amount?:number,
	explanation?:string,
	partnerId?:string
}

export type PartnerAnalytics = {
	__typename?: "PartnerAnalytics",
	id:string,
	meta:PartnerAnalyticsMeta,
	orderCount:number,
	prepTimes:PartnerPrepTimeAnalytics[],
	waitTimes:PercentileValue[],
	layoutGroups:ChartLayoutGroup[]
}

export type PartnerAnalyticsMeta = {
	__typename?: "PartnerAnalyticsMeta",
	id:string,
	startDate:Date,
	dayOfWeek?:number,
	endDate:Date,
	timeRange?:string[]
}

export type PartnerCommissionPlan = {
	__typename?: "PartnerCommissionPlan",
	id:string,
	created:Date,
	ended?:Date,
	plan:CommissionPlan,
	rung:CommissionPlanRung,
	partner:Restaurant
}

export type PartnerHomePageItemGroup = {
	__typename?: "PartnerHomePageItemGroup",
	id:string,
	title:string,
	items:Item[]
}

export type PartnerHomePageMeta = {
	__typename?: "PartnerHomePageMeta",
	skip:number,
	take:number,
	lat?:number,
	lng?:number,
	zoneId?:string,
	zoneSlug?:string,
	/** Only return restaurants that support this fulfillment method */
	fulfillmentMethod?:FulfillmentMethod,
	/** An optional query field to allow for user searches */
	query?:string,
	/** Whether or not to include closed restaurants in the query. Clients should disclose that restaurants are closed. */
	includeClosed?:boolean,
	tags?:string[],
	sortBy?:string,
	order?:string,
	coreConfiguration:CoreConfigurations,
	time?:DeliveryTimeArgs
}

export type PartnerInvoice = {
	__typename?: "PartnerInvoice",
	id:string,
	/** Whether this invoice has been paid */
	paid:boolean,
	value:number,
	start:Date,
	end:Date,
	numberOfOrders:number,
	sales:number,
	adjustments:number,
	commission:number,
	purchases:number,
	vat:number,
	ref?:string,
	revolutTransactionId?:string,
	revolutTransactionLink?:string,
	partnerId:string,
	partner:Restaurant
}

export type PartnerInvoiceList = {
	__typename?: "PartnerInvoiceList",
	items:PartnerInvoice[],
	total:number,
	hasMore:boolean
}

export type PartnerPaymentDetails = {
	__typename?: "PartnerPaymentDetails",
	id:string,
	accountNo:string,
	sortCode:string,
	isCompany:boolean,
	individualName?:string,
	restaurant:Restaurant
}

export type PartnerPrepTimeAnalytics = {
	__typename?: "PartnerPrepTimeAnalytics",
	percentile:string,
	value:number
}

export type PartnerSelection = {
	__typename?: "PartnerSelection",
	name:string,
	partners:Restaurant[],
	type:PartnerSelectionType
}

export enum PartnerSelectionType {
	CAROUSEL = "CAROUSEL"
}

export type PartnerTablet = {
	__typename?: "PartnerTablet",
	id:string,
	created:Date,
	lastConnection:Date,
	serial:string,
	model:string,
	manufacturer:string,
	battery?:number,
	release?:string,
	batteryState?:string,
	ram?:number,
	usedRam?:number,
	volume?:number,
	wifiNetwork?:string,
	bssid?:string,
	routerMake?:string,
	wifiStrength?:number,
	width?:number,
	height?:number,
	ip?:string,
	hostname?:string,
	isp?:string,
	location?:GraphQLGeoJSONPoint,
	partnerId:string,
	partner:Restaurant,
	webRTCOfferDescription?:JSON,
	webRTCOfferReceiveDescription?:JSON,
	webRTCOfferReceiveCandidates?:JSON,
	webRTCOfferCandidates?:JSON,
	screenshots:ImageAsset[]
}

export type PartnerTabletAction = {
	__typename?: "PartnerTabletAction",
	id:PartnerTabletActionType,
	tabletId:string,
	payload?:JSON
}

export enum PartnerTabletActionType {
	TAKE_SCREENSHOT = "TAKE_SCREENSHOT",
	PLAY_SOUND = "PLAY_SOUND",
	RESTART_APP = "RESTART_APP",
	STREAM_VIDEO = "STREAM_VIDEO",
	TOUCH_SCREEN = "TOUCH_SCREEN"
}

export type PartnerTabletList = {
	__typename?: "PartnerTabletList",
	items:PartnerTablet[],
	total:number,
	hasMore:boolean
}

export type PartnerTabletSyncArgs = {
		serial:string,
	model:string,
	manufacturer:string,
	battery?:number,
	release?:string,
	batteryState?:string,
	ram?:number,
	usedRam?:number,
	volume?:number,
	wifiNetwork?:string,
	bssid?:string,
	routerMake?:string,
	wifiStrength?:number,
	width?:number,
	height?:number,
	ip?:string,
	hostname?:string,
	isp?:string,
	location?:GraphQLGeoJSONPointI,
	webRTCOfferDescription?:JSON,
	webRTCOfferReceiveDescription?:JSON,
	webRTCOfferReceiveCandidates?:JSON,
	webRTCOfferCandidates?:JSON
}

/** A tag that describes something this partner offers, like Pizza, or Flowers. */
export type PartnerTag = {
	__typename?: "PartnerTag",
	id:string,
	name:string,
	image?:string,
	type:PartnerTagTypes,
	partners:Restaurant[]
}

export type PartnerTagInput = {
		name:string,
	image:string,
	type:PartnerTagTypes
}

/** Meta-type of a tag */
export enum PartnerTagTypes {
	Food = "Food",
	Locale = "Locale",
	Service = "Service"
}

export type PaymentMethod = {
	__typename?: "PaymentMethod",
	id:string,
	stripeId:string,
	last4:string,
	brand:string,
	exp_month:number,
	exp_year:number
}

export type PercentileValue = {
	__typename?: "PercentileValue",
	percentile:string,
	value:number
}

export enum Permissions {
	CREATE_OWN_ORDER = "CREATE_OWN_ORDER",
	CANCEL_OWN_ORDER = "CANCEL_OWN_ORDER",
	UPDATE_ORDER_AS_RESTAURANT = "UPDATE_ORDER_AS_RESTAURANT",
	GET_OWN_ADDRESS = "GET_OWN_ADDRESS",
	CREATE_OWN_ADDRESS = "CREATE_OWN_ADDRESS",
	DELETE_OWN_ADDRESS = "DELETE_OWN_ADDRESS",
	GET_OWN_RESTAURANT = "GET_OWN_RESTAURANT",
	GET_RESTAURANT = "GET_RESTAURANT",
	CREATE_RESTAURANT = "CREATE_RESTAURANT",
	DELETE_RESTAURANT = "DELETE_RESTAURANT",
	UPDATE_OWN_RESTAURANT = "UPDATE_OWN_RESTAURANT",
	CAN_COLLECT_ORDERS = "CAN_COLLECT_ORDERS",
	CAN_UPDATE_ASSIGNED_ORDER = "CAN_UPDATE_ASSIGNED_ORDER",
	MANAGE_CITY = "MANAGE_CITY",
	AUDIT_EVENTS = "AUDIT_EVENTS",
	CAN_UPDATE_MENUS = "CAN_UPDATE_MENUS",
	UPDATE_ALL_PARTNERS = "UPDATE_ALL_PARTNERS",
	CAN_ONBOARD_RIDERS = "CAN_ONBOARD_RIDERS",
	CREATE_SUPER_ADMINS = "CREATE_SUPER_ADMINS",
	MANAGE_DISCOUNTS = "MANAGE_DISCOUNTS",
	MANAGE_PROMOTIONS = "MANAGE_PROMOTIONS",
	MANAGE_IMAGES = "MANAGE_IMAGES",
	MANAGE_MARKETING = "MANAGE_MARKETING",
	VIEW_ALL_ORDERS = "VIEW_ALL_ORDERS",
	UPDATE_ALL_ORDERS = "UPDATE_ALL_ORDERS",
	MANAGE_BOOSTS = "MANAGE_BOOSTS",
	MANAGE_INCIDENTS = "MANAGE_INCIDENTS",
	VIEW_ALL_CUSTOMERS = "VIEW_ALL_CUSTOMERS",
	UPDATE_ALL_CUSTOMERS = "UPDATE_ALL_CUSTOMERS",
	UPDATE_ALL_CHARITIES = "UPDATE_ALL_CHARITIES",
	VIEW_PARTNER_FINANCES = "VIEW_PARTNER_FINANCES",
	UPDATE_PARTNER_FINANCES = "UPDATE_PARTNER_FINANCES",
	VIEW_RIDER_FINANCES = "VIEW_RIDER_FINANCES",
	UPDATE_RIDER_FINANCES = "UPDATE_RIDER_FINANCES",
	MANAGE_BOOKING_SLOTS = "MANAGE_BOOKING_SLOTS",
	VIEW_ALL_RIDERS = "VIEW_ALL_RIDERS",
	UPDATE_ALL_RIDERS = "UPDATE_ALL_RIDERS",
	ACCESS_BUSINESS_API = "ACCESS_BUSINESS_API",
	MANAGE_SAAS = "MANAGE_SAAS",
	CAN_VIEW_RIDER_APPLICATION = "CAN_VIEW_RIDER_APPLICATION",
	CAN_UPDATE_RIDER_APPLICATION = "CAN_UPDATE_RIDER_APPLICATION",
	CAN_VIEW_LEASE_DETAILS = "CAN_VIEW_LEASE_DETAILS",
	CAN_UPDATE_LEASE_DETAILS = "CAN_UPDATE_LEASE_DETAILS"
}

export type PieChart = {
	__typename?: "PieChart",
	id:string,
	type:string,
	labels:string[],
	values:number[]
}

export type PlaceOrderResponse = {
	__typename?: "PlaceOrderResponse",
	id:string,
	stripeNextAction?:JSON,
	stripeClientSecret?:string,
	order?:Order
}

export type PresignedUploadURL = {
	__typename?: "PresignedUploadURL",
	url:string,
	method:string,
	startBytes:number,
	endBytes:number
}

export type Price = {
	__typename?: "Price",
	value:number,
	display:string
}

/** How to modify price based on selections */
export enum PriceStrategy {
	NO_EXTRA_PRICE = "NO_EXTRA_PRICE",
	ITEM_PRICE_SUM = "ITEM_PRICE_SUM"
}

export type Promotion = {
	__typename?: "Promotion",
	id:string,
	created:Date,
	modified:Date,
	start:Date,
	end:Date,
	name:string,
	title:string,
	body:string,
	color?:string,
	imageKey?:string,
	zones:Zone[],
	callToActionText?:string,
	enabled:boolean,
	targetType?:PromotionTarget,
	target?:string
}

export type PromotionList = {
	__typename?: "PromotionList",
	items:Promotion[],
	total:number,
	hasMore:boolean
}

export enum PromotionTarget {
	TAG = "TAG",
	URL = "URL",
	PARTNER = "PARTNER"
}

export type PurchaseCustomerWalletTopup = {
	__typename?: "PurchaseCustomerWalletTopup",
	id:string,
	amount:Price,
	paymentIntentId:string,
	paymentIntentSecret:string
}

export type Query = {
	__typename?: "Query",
	Configuration:Configuration,
	DynamicForm:DynamicForm,
	CustomerList:CustomerList,
	Customer:Customer,
	stripeTokens:StripeTokens,
	order?:Order,
	OrderList:OrderList,
	FlightPlanOfferList:FlightPlanOfferList,
	OrderAlertList:OrderAlertList,
	OrderAlert:OrderAlert,
	OrderRiderRatingList:OrderRiderRatingList,
	OrderEventList:OrderEventList,
	CustomerWalletTopupList:CustomerWalletTopupList,
	Item:Item,
	ItemList:ItemList,
	BasketList:BasketList,
	Basket:Basket,
	/** Find basket for current restaurant */
	basketForRestaurant:Basket,
	Charity:Charity,
	CharityList:CharityList,
	activeCharityCampaigns:CharityCampaign,
	CharityCampaign:CharityCampaign,
	CharityCampaignList:CharityCampaignList,
	ConsumerCharityCampaign:ConsumerCharityCampaignView,
	ReusableDropBox:ReusableDropBox,
	ReusableDropBoxList:ReusableDropBoxList,
	ReusableContainer:ReusableContainer,
	ReusableContainerList:ReusableContainerList,
	ReusableContainerType:ReusableContainerType,
	ReusableContainerTypeList:ReusableContainerTypeList,
	ScannedContainerList:ScannedContainerList,
	WashingLocation:WashingLocation,
	WashingLocationList:WashingLocationList,
	ReusablesPickup:ReusablesPickup,
	ReusablesPickupList:ReusablesPickupList,
	CustomerReusables:CustomerReusables,
	ReusableShipment:ReusableShipment,
	ReusableShipmentList:ReusableShipmentList,
	ReusablesPickupAggregate:ReusablesPickupAggregate,
	ReusablesPickupAggregateList:ReusablesPickupAggregateList,
	Restaurant:Restaurant,
	allRestaurants:Restaurant[],
	RestaurantList:RestaurantList,
	restaurantHeartbeat:boolean,
	activeOrders:Order[],
	PartnerTag:PartnerTag,
	allPartnerTags:PartnerTag[],
	PartnerTablet:PartnerTablet,
	PartnerTabletList:PartnerTabletList,
	ConsumerSearch:ConsumerSearchResult,
	Menu:Menu,
	allMenus:Menu[],
	ItemUpdateRequest:ItemUpdateRequest,
	ItemUpdateRequestList:ItemUpdateRequestList,
	RestaurantAdminList:RestaurantAdminList,
	RestaurantAdmin:RestaurantAdmin,
	tabletForRestaurant:RestaurantAdmin,
	ZoneList:ZoneList,
	zone:Zone,
	zones:Zone[],
	/** Find the zone at the given point */
	zoneAtPoint?:Zone,
	/** Upcoming boosts for riders to view in their planner */
	UpcomingBoosts:Boost[],
	ZoneEventList:ZoneEventList,
	ZonePolygonList:ZonePolygonList,
	CommissionPlanList:CommissionPlanList,
	CommissionPlan:CommissionPlan,
	Brand:Brand,
	BrandList:BrandList,
	PartnerHomepage:HomePage,
	Rider:Rider,
	RiderList:RiderList,
	validateVehicleRegistration:ValidateRegistrationResult,
	riderOnlineSession:RiderOnlineSession,
	RiderOnlineSessionList:RiderOnlineSessionList,
	RiderLiveUI:RiderLiveView,
	RiderLiveRoute?:RiderLiveRoute,
	Lease:LeaseView,
	LeaseList:LeaseList,
	LeaseVehicle:LeaseVehicle,
	LeaseVehicleList:LeaseVehicleList,
	ChatMessageList:ChatMessageList,
	OAuthConfiguration:OAuthConfiguration,
	me:UserUnionDTO,
	Discount:Discount,
	DiscountList:DiscountList,
	RiderReferralDiscount:RiderReferralDiscount,
	RiderReferralDiscountList:RiderReferralDiscountList,
	Address:Address,
	AddressList:AddressList,
	allAddresses:Address[],
	LocationSearch:LocationSearchResult[],
	LocationSearchById:LocationSearchResult,
	BookingMetricsRank?:JSON,
	BookingSlot:BookingSlot,
	BookingSlotList:BookingSlotList,
	BookingSlotsAvailableForRider:BookingSlotRiderViewList,
	countBookingsForSlot:number,
	AutoBookingSlotConfig:AutoBookingSlotsConfig,
	allAnalytics:AnalyticsDTO,
	PartnerAnalytics:PartnerAnalytics,
	CustomerAnalytics:CustomerAnalytics,
	city:City,
	allCities:City[],
	Team:TeamMember[],
	financialsForRestaurants:RestaurantFinancialsView[],
	financialsForRestaurant:RestaurantFinancialsView,
	salesForRestaurant:ItemSales[],
	financialsForRider:SpecificRiderFinancials,
	RiderInvoice:RiderInvoice,
	allRiderInvoices:RiderInvoiceList,
	PartnerInvoice:PartnerInvoice,
	/** Get a signed URL for the partner invoice */
	downloadPartnerInvoice:string,
	allPartnerInvoices:PartnerInvoiceList,
	PartnerPaymentDetails:PartnerPaymentDetails,
	RiderAdjustment:RiderAdjustment,
	RiderAdjustmentList:RiderAdjustmentList,
	PartnerAdjustment:PartnerAdjustment,
	PartnerAdjustmentList:PartnerAdjustmentList,
	FinancialOverview:FinancialOverview,
	PromotionList:PromotionList,
	Promotion:Promotion,
	B2BDeliveryJob:B2BDeliveryJob,
	B2BDeliveryJobList:B2BDeliveryJobList,
	IncidentList:IncidentList,
	Incident:Incident,
	ImageAsset:ImageAsset,
	ImageAssetList:ImageAssetList,
	VideoAsset:VideoAsset,
	VideoAssetList:VideoAssetList,
	MarketingCampaignList:MarketingCampaignList,
	MerchantSite:MerchantSite,
	MerchantSiteList:MerchantSiteList,
	MerchantSitePage:MerchantSitePage,
	/** Retrieve a list of all files stored. */
	FileList:FileList
}

export type RegisterPayload = {
		email:string,
	name:string,
	password:string,
	phoneNumber?:string,
	marketing:boolean
}

export type Restaurant = {
	__typename?: "Restaurant",
	id:string,
	configuration:CoreConfigurations,
	name:string,
	slug:string,
	branch?:string,
	priceCategory?:string,
	blurhash?:string,
	headerImageKey?:string,
	ratingStars:number,
	ratingCount:number,
	phoneNumber:string,
	allergyWarnings?:string,
	acceptsAllergyNotes:boolean,
	autoAccept:boolean,
	commission?:number,
	commissionCollection?:number,
	onboardingStatus:RestaurantOnboardingStatus,
	fulfillmentMethods:FulfillmentMethod[],
	busyStatus:RestaurantBusyStatus,
	online:boolean,
	baseDeliveryTime:number,
	basePrepOrderThreshold:number,
	basePrepTime:number,
	basePrepTimeDelta:number,
	/** The amount to increase prep time by when restaurant is busy. */
	incrementPrepTime:number,
	currentPrepTime:number,
	/** Whether this partner accepts pre-orders (ie before they are open) */
	acceptsPreOrders:boolean,
	/** Whether this partner accepts scheduled orders (ie when they are already open) */
	acceptsScheduledOrders:boolean,
	deliveryTimes:FulfillmentDayTimes[],
	largeOrderSize:number,
	minimumOrderSize:number,
	minimumOrderValue:JSON,
	deliveryTippingEnabled:boolean,
	lastOnline:Date,
	minimumBasket:number,
	assignmentBias:AssignmentBias,
	exclusive:boolean,
	maxDeliveryDistanceMetres:number,
	isReusablesEnabled:boolean,
	openingTimes:OpeningTime[],
	menuId?:string,
	menu:Menu,
	zoneId:string,
	zone:Zone,
	owner?:RestaurantAdmin,
	hygiene?:Hygiene,
	hygieneId?:string,
	tags:PartnerTag[],
	commissionPlans:PartnerCommissionPlan[],
	activeCommissionPlan?:PartnerCommissionPlan,
	/** Fulfillment times for this partner */
	fulfillmentTimes:FulfillmentDayTimes[],
	/** The current delivery time (in minutes) */
	currentDeliveryTime:number,
	/** The current delivery time delta */
	currentDeliveryTimeDelta:number,
	distance?:string,
	/** The display header image */
	headerImage:string,
	/** Whether this restaurant is open or not */
	open?:boolean,
	/** Whether this restaurant is on vacation or not */
	isOnVacation:boolean,
	/** Whether this restaurant is coming soon */
	comingSoon?:boolean,
	external?:ExternalCompetitor,
	invoices:PartnerInvoice[],
	address:Address,
	brand?:Brand,
	headerVideo?:VideoAsset,
	washingLocation?:WashingLocation,
	nextOpeningTime?:Date,
	description:string
}

export type RestaurantAdmin = {
	__typename?: "RestaurantAdmin",
	id:string,
	name:string,
	email:string,
	created:Date,
	phoneNumber?:string,
	isPhoneNumberVerified:boolean,
	emailConfirmationTokenIssued?:Date,
	emailConfirmed:boolean,
	roles:string[],
	profileImageKey?:string,
	/** The restaurant that this user administers */
	administeredRestaurant?:Restaurant,
	/** The restaurants that this user administers */
	restaurants:Restaurant[],
	linkedCustomerAccount?:Customer,
	b2bJobs:B2BDeliveryJob[]
}

export type RestaurantAdminList = {
	__typename?: "RestaurantAdminList",
	items:RestaurantAdmin[],
	total:number,
	hasMore:boolean
}

export type RestaurantAdminUpdateInput = {
		roles?:Permissions[]
}

export enum RestaurantBusyStatus {
	QUIET = "QUIET",
	MODERATE = "MODERATE",
	BUSY = "BUSY"
}

export type RestaurantCreateInput = {
		name:string,
	address:AddressCreateInput,
	description:string,
	phoneNumber:string,
	zoneId:string,
	notes?:string,
	ownerEmail:string,
	ownerName:string
}

export type RestaurantFinancialsView = {
	__typename?: "RestaurantFinancialsView",
	restaurantId:string,
	restaurantSalesTotal:number,
	deliveryFeeTotal:number,
	/** The total price taken off of the order from discounts */
	discountsTotal:number,
	accountCreditTotal:number,
	smallOrderFeeTotal:number,
	overallTotal:number,
	riderEarnings:number,
	riderTips:number,
	numberOfOrders:number,
	commissionPercent:number,
	taxPercent:number,
	commissionBeforeAddedTax:number,
	taxFromCommission:number,
	commissionTotal:number,
	toPayRestaurant:number,
	ourProfit:number,
	amountRefunded:number,
	adjustmentsTotal:number,
	stripeFeeTotal:number,
	donationToRestaurantTotal:number,
	donationToCharityTotal:number,
	purchasesFromUsTotal:number,
	restaurant:Restaurant
}

export type RestaurantList = {
	__typename?: "RestaurantList",
	items:Restaurant[],
	total:number,
	hasMore:boolean
}

export enum RestaurantOnboardingStatus {
	ONBOARDING = "ONBOARDING",
	COMING_SOON = "COMING_SOON",
	LIVE = "LIVE"
}

/** Updates to the restaurant */
export type RestaurantUpdateInput = {
		name?:string,
	description?:string,
	onboardingStatus?:RestaurantOnboardingStatus,
	openingTimes?:OpeningTimeInput[],
	busyStatus?:RestaurantBusyStatus,
	fulfillmentMethods?:FulfillmentMethod[],
	exclusive?:boolean,
	basePrepTime?:number,
	incrementPrepTime?:number,
	baseDeliveryTime?:number,
	ratingStars?:number,
	ratingCount?:number,
	commission?:number,
	commissionCollection?:number,
	minimumBasket?:number,
	hygieneRatingId?:string,
	allergyWarnings?:string,
	phoneNumber?:string,
	tags?:string[],
	external?:ExternalCompetitorInput,
	acceptsScheduledOrders?:boolean,
	autoAccept?:boolean,
	commissionPlanId?:string,
	commissionPlanRungId?:string,
	configuration?:CoreConfigurations,
	brandId?:string,
	headerVideoId?:string,
	isReusablesEnabled?:boolean
}

export type ReusableContainer = {
	__typename?: "ReusableContainer",
	id:string,
	createdAt:Date,
	type:ReusableContainerType,
	uris:string[],
	aliases:ContainerCode[]
}

export type ReusableContainerList = {
	__typename?: "ReusableContainerList",
	items:ReusableContainer[],
	total:number,
	hasMore:boolean
}

export type ReusableContainerType = {
	__typename?: "ReusableContainerType",
	id:string,
	name:string,
	depth:number,
	length:number,
	width:number,
	unitPrice:number,
	image?:ImageAsset,
	/** Whether ecoeats has stock of these containers */
	inStock:boolean
}

export type ReusableContainerTypeList = {
	__typename?: "ReusableContainerTypeList",
	items:ReusableContainerType[],
	total:number,
	hasMore:boolean
}

export type ReusableDropBox = {
	__typename?: "ReusableDropBox",
	id:string,
	name:string,
	notes?:string,
	status:DropBoxStatus,
	depth:number,
	length:number,
	width:number,
	address:Address
}

export type ReusableDropBoxList = {
	__typename?: "ReusableDropBoxList",
	items:ReusableDropBox[],
	total:number,
	hasMore:boolean
}

export type ReusablePickupTracking = {
	__typename?: "ReusablePickupTracking",
	/** The current position of the rider */
	coordinates?:Coordinates,
	/** The earliest we expect the rider to arrive */
	etaEarliest:Date,
	/** The latest we expect the rider to arrive */
	etaLatest:Date,
	/** How many stops away the rider is */
	stopsAway?:number
}

export type ReusableShipment = {
	__typename?: "ReusableShipment",
	id:string,
	createdAt:Date,
	status:ReusableShipmentStatus,
	scheduledFor:Date,
	estimatedArrivalTime?:Date,
	washingLocation?:WashingLocation,
	partner:Restaurant,
	containers:ShipmentContainer[],
	orders:Order[],
	activeOrder?:Order,
	canScheduleOrder:boolean
}

export type ReusableShipmentList = {
	__typename?: "ReusableShipmentList",
	items:ReusableShipment[],
	total:number,
	hasMore:boolean
}

export enum ReusableShipmentStatus {
	PENDING = "PENDING",
	PREPARING = "PREPARING",
	EN_ROUTE = "EN_ROUTE",
	COMPLETE = "COMPLETE"
}

export type ReusablesInfo = {
	__typename?: "ReusablesInfo",
	id:string,
	deposit:number,
	fee:number,
	predictionCount?:number,
	partnerCount?:number,
	riderCount?:number,
	customerCount?:number
}

export type ReusablesPickup = {
	__typename?: "ReusablesPickup",
	id:string,
	type:ReusablesPickupType,
	status:ReusablesPickupStatus,
	refundedDeposit:number,
	pickupTimeEarliest:Date,
	pickupTimeLatest:Date,
	customerImageKey?:string,
	customerCount:number,
	riderCount?:number,
	address:Address,
	customer:Customer,
	aggregate?:ReusablesPickupAggregate,
	canReschedule:boolean,
	formattedQuantity:string,
	tracking:ReusablePickupTracking
}

export type ReusablesPickupAggregate = {
	__typename?: "ReusablesPickupAggregate",
	id:string,
	createdAt:Date,
	pickups:ReusablesPickup[],
	washingLocation:WashingLocation,
	orders:Order[]
}

export type ReusablesPickupAggregateList = {
	__typename?: "ReusablesPickupAggregateList",
	items:ReusablesPickupAggregate[],
	total:number,
	hasMore:boolean
}

export type ReusablesPickupList = {
	__typename?: "ReusablesPickupList",
	items:ReusablesPickup[],
	total:number,
	hasMore:boolean
}

export type ReusablesPickupScheduleTime = {
	__typename?: "ReusablesPickupScheduleTime",
	slot:TimeSlot,
	depositReturn:Price
}

export enum ReusablesPickupStatus {
	SCHEDULED = "SCHEDULED",
	IN_PROGRESS = "IN_PROGRESS",
	RIDER_AT_CUSTOMER = "RIDER_AT_CUSTOMER",
	COMPLETE = "COMPLETE"
}

export enum ReusablesPickupType {
	CUSTOMER = "CUSTOMER",
	DROP_BOX = "DROP_BOX"
}

export type Rider = {
	__typename?: "Rider",
	id:string,
	name:string,
	email:string,
	created:Date,
	phoneNumber?:string,
	isPhoneNumberVerified:boolean,
	emailConfirmationTokenIssued?:Date,
	emailConfirmed:boolean,
	roles:string[],
	profileImageKey?:string,
	application?:RiderApplication,
	lastOnline?:Date,
	online:boolean,
	throttledUntil?:Date,
	position?:GraphQLGeoJSONPoint,
	note?:string,
	activationStatus:string,
	vehicle:RiderVehicle,
	carRegistration?:string,
	activeFlightPlan?:DeliveryFlightPlan,
	zone:Zone,
	orders:Order[],
	invoices:RiderInvoice[],
	bookings:BookingList,
	vehicleDetails?:RiderVehicleDetails,
	activeBooking?:Booking,
	accountNo?:string,
	sortCode?:string,
	stripeSetupIntentId?:string,
	statistics?:RiderStatistics,
	offers:OrderOffer[],
	address:Address,
	shippingAddress:Address
}

export enum RiderActivationStatus {
	APPLIED = "APPLIED",
	AWAITING_GEAR = "AWAITING_GEAR",
	AWAITING_RTW = "AWAITING_RTW",
	AWAITING_CONTRACT = "AWAITING_CONTRACT",
	REJECTED = "REJECTED",
	SUSPENDED = "SUSPENDED",
	APPROVED = "APPROVED"
}

export enum RiderAddressType {
	RESTAURANT = "RESTAURANT",
	CUSTOMER = "CUSTOMER",
	BUSINESS = "BUSINESS"
}

export type RiderAddressView = {
	__typename?: "RiderAddressView",
	id:string,
	title:string,
	type:RiderAddressType,
	formatted:string,
	instructions?:string,
	phoneNumber:string,
	coordinates:Coordinates,
	instructionImageKey?:string
}

export type RiderAdjustment = {
	__typename?: "RiderAdjustment",
	id:string,
	created:Date,
	forDate:Date,
	amount:number,
	explanation:string,
	riderId:string,
	rider:Rider
}

export type RiderAdjustmentCreateInput = {
		forDate:Date,
	amount:number,
	explanation:string,
	riderId:string
}

export type RiderAdjustmentList = {
	__typename?: "RiderAdjustmentList",
	items:RiderAdjustment[],
	total:number,
	hasMore:boolean
}

export type RiderAdjustmentUpdateInput = {
		forDate?:Date,
	amount?:number,
	explanation?:string,
	riderId?:string
}

export type RiderApplication = {
	__typename?: "RiderApplication",
	id:string,
	created:Date,
	updated:Date,
	documentType:IdentityDocumentType,
	compareFaces?:JSON,
	rekognitionLabels?:JSON,
	imageUrl:string
}

export type RiderCreateInput = {
		zoneId:string,
	name:string,
	email:string,
	password:string,
	accountNo?:string,
	sortCode?:string,
	phoneNumber:string,
	address:AddressCreateInput,
	vehicle:RiderVehicle,
	carRegistration?:string,
	sessionId:string,
	code:string
}

export type RiderInvoice = {
	__typename?: "RiderInvoice",
	id:string,
	/** Whether this invoice has been paid */
	paid:boolean,
	value:number,
	start:Date,
	end:Date,
	orders:number,
	bookingExtra:number,
	referralExtra:number,
	leaseDeduction:number,
	tips:number,
	earnings:number,
	adjustments:number,
	revolutTransactionId?:string,
	revolutTransactionLink?:string,
	riderId:string,
	rider:Rider,
	url:string
}

export type RiderInvoiceList = {
	__typename?: "RiderInvoiceList",
	items:RiderInvoice[],
	total:number,
	hasMore:boolean
}

export type RiderInvoicesJob = {
	__typename?: "RiderInvoicesJob",
	id:string,
	count:number
}

export type RiderJobView = {
	__typename?: "RiderJobView",
	feeFormatted:string,
	feeModifiedExplanation?:string,
	orders:RiderOrderView[],
	origin:RiderAddressView,
	currentDestination:RiderAddressView,
	directions?:RiderLiveRoute
}

export type RiderList = {
	__typename?: "RiderList",
	items:Rider[],
	total:number,
	hasMore:boolean
}

export type RiderLiveRoute = {
	__typename?: "RiderLiveRoute",
	id:string,
	steps:RiderLiveRouteStep[],
	geometry:string,
	distance:number,
	duration:number
}

export type RiderLiveRouteStep = {
	__typename?: "RiderLiveRouteStep",
	id:string,
	geometry:string
}

export type RiderLiveView = {
	__typename?: "RiderLiveView",
	id:string,
	action?:RiderUIAction,
	layoutType:RiderUILayoutType,
	banners:RiderUIBanner[],
	job?:RiderJobView,
	support:RiderSupportView,
	offer?:RiderOfferView,
	settings:RiderSettingsView[]
}

export type RiderOfferView = {
	__typename?: "RiderOfferView",
	id:string,
	offeredAt:Date,
	offeredUntil:Date,
	feeFormatted:string,
	origins:RiderAddressView[],
	destinations:RiderAddressView[]
}

export type RiderOnlineSession = {
	__typename?: "RiderOnlineSession",
	id:string,
	riderId:string,
	rider:Rider,
	updated:Date,
	start:Date,
	end?:Date,
	path:GraphQLGeoJSONLineString,
	initialPoint?:GraphQLGeoJSONPoint
}

export type RiderOnlineSessionList = {
	__typename?: "RiderOnlineSessionList",
	items:RiderOnlineSession[],
	total:number,
	hasMore:boolean
}

export type RiderOrderItemView = {
	__typename?: "RiderOrderItemView",
	id:string,
	name:string,
	quantity:number
}

export type RiderOrderSupportView = {
	__typename?: "RiderOrderSupportView",
	actions:RiderUIAction[]
}

export type RiderOrderView = {
	__typename?: "RiderOrderView",
	id:string,
	number:string,
	isReadyToCollect:boolean,
	estimatedReadyAt?:Date,
	tipFormatted?:string,
	numberOfItems:number,
	items:RiderOrderItemView[],
	origin:RiderAddressView,
	destination:RiderAddressView,
	support:RiderOrderSupportView
}

export type RiderReferralDiscount = {
	__typename?: "RiderReferralDiscount",
	id:string,
	riderId:string,
	discountId:string,
	associationDate:Date,
	discount:Discount,
	rider:Rider
}

export type RiderReferralDiscountList = {
	__typename?: "RiderReferralDiscountList",
	items:RiderReferralDiscount[],
	total:number,
	hasMore:boolean
}

export type RiderSettingsView = {
	__typename?: "RiderSettingsView",
	id:string,
	initialValue:JSON,
	schema:JSON
}

export type RiderSlotStats = {
	__typename?: "RiderSlotStats",
	id:string,
	riderId:string,
	bookingId:string,
	bookingSlotId:string,
	slotType:BookingSlotType,
	slotStart:Date,
	slotEnd:Date,
	ordersAccepted:number,
	ordersOffered:number,
	maxMissedOrders:number,
	sessionSpans:string[],
	attended:BookingStatus,
	onlinePercentage:number,
	minAttendancePercentage:number,
	guaranteedRiderEarnings:number,
	orderRiderEarnings:number,
	riderOwedExtra:number
}

export type RiderStatistics = {
	__typename?: "RiderStatistics",
	riderId:string,
	acceptedOrders:number,
	rejectedOrders:number,
	timedoutOrders:number,
	averageRating?:number
}

export type RiderStatusUpdate = {
		location?:GraphQLGeoJSONPointInput,
	online:boolean,
	device?:string,
	os?:string
}

export type RiderSupportView = {
	__typename?: "RiderSupportView",
	supportURL:string,
	supportPhoneNumber?:string
}

export type RiderUIAction = {
	__typename?: "RiderUIAction",
	id:RiderUIActions,
	maxDistanceInMetres?:number,
	allowMaxDistanceOverride?:boolean,
	validationPrompt?:RiderUIActionValidationPrompt,
	areYouSure?:string,
	orderId?:string,
	disabled?:boolean,
	color?:RiderUIActionColour,
	title?:string,
	explanation?:string,
	text:string,
	extraPayload?:JSON
}

export enum RiderUIActionColour {
	INFO = "INFO",
	PRIMARY = "PRIMARY",
	ERROR = "ERROR",
	WARNING = "WARNING"
}

export enum RiderUIActions {
	ACCEPT_OFFER = "ACCEPT_OFFER",
	REJECT_OFFER = "REJECT_OFFER",
	ARRIVE_AT_PARTNER = "ARRIVE_AT_PARTNER",
	COLLECT_ORDER = "COLLECT_ORDER",
	ARRIVE_AT_CUSTOMER = "ARRIVE_AT_CUSTOMER",
	DROP_OFF_ORDER = "DROP_OFF_ORDER",
	REMOVE_FROM_ORDER = "REMOVE_FROM_ORDER",
	REPORT_LONG_WAIT = "REPORT_LONG_WAIT",
	SEND_NOTIFICATION_TO_CUSTOMER = "SEND_NOTIFICATION_TO_CUSTOMER",
	CUSTOMER_HAS_NO_ID = "CUSTOMER_HAS_NO_ID",
	LAUNCH_CUSTOMER_CHAT = "LAUNCH_CUSTOMER_CHAT"
}

export type RiderUIActionValidationPrompt = {
	__typename?: "RiderUIActionValidationPrompt",
	id:string,
	title:string,
	canBeTemporarilyDismissed:boolean,
	validateAge?:RiderUIAgeValidation,
	validateReusables?:RiderUIReusablesCountValidation,
	rateOrderPrompt?:RiderUIRateOrderValidation,
	buttons:RiderUIButton[]
}

export type RiderUIAgeValidation = {
	__typename?: "RiderUIAgeValidation",
	customerDOBShouldBeLessThanOrEqualTo:Date
}

export type RiderUIBanner = {
	__typename?: "RiderUIBanner",
	id:string,
	isDismissible:boolean,
	title:string,
	message?:string,
	color:RiderUIActionColour
}

export type RiderUIButton = {
	__typename?: "RiderUIButton",
	type:RiderUIButtonType,
	text:string,
	color:RiderUIActionColour
}

export enum RiderUIButtonType {
	CANCEL = "CANCEL",
	CONFIRM = "CONFIRM"
}

export enum RiderUILayoutType {
	MAP_WITH_ACTION_BAR = "MAP_WITH_ACTION_BAR",
	MODAL_WITH_MAP = "MODAL_WITH_MAP",
	PAGE = "PAGE"
}

export type RiderUIOption = {
	__typename?: "RiderUIOption",
	value:string,
	label:string
}

export type RiderUIRateOrderValidation = {
	__typename?: "RiderUIRateOrderValidation",
	options:RiderUIOption[]
}

export type RiderUIReusablesCountValidation = {
	__typename?: "RiderUIReusablesCountValidation",
	customerCountWas:number
}

export type RiderUpdateInput = {
		name?:string,
	activationStatus?:RiderActivationStatus,
	vehicle?:RiderVehicle,
	phoneNumber?:string,
	zoneId?:string,
	note?:string,
	accountNo?:string,
	sortCode?:string,
	carRegistration?:string,
	shippingAddressId?:string
}

export enum RiderVehicle {
	BIKE = "BIKE",
	CAR = "CAR"
}

export type RiderVehicleDetails = {
	__typename?: "RiderVehicleDetails",
	id:string,
	updated:Date,
	details:JSON,
	rider:Rider
}

export type ScanContainerAtPartnerResponse = {
	__typename?: "ScanContainerAtPartnerResponse",
	success:boolean,
	recognisedContainer:ReusableContainer
}

export type ScannedContainer = {
	__typename?: "ScannedContainer",
	id:number,
	containerId:string,
	scannedAt:Date,
	imageKey?:string,
	pickupId?:string,
	customerId?:string,
	washingLocationId?:string,
	shipmentContainerId?:string,
	container:ReusableContainer,
	washingLocation?:WashingLocation
}

export type ScannedContainerList = {
	__typename?: "ScannedContainerList",
	items:ScannedContainer[],
	total:number,
	hasMore:boolean
}

export type Series = {
	__typename?: "Series",
	label:string,
	x_values:string[],
	y_values:number[]
}

export type ShipmentContainer = {
	__typename?: "ShipmentContainer",
	quantity:number,
	containerType:ReusableContainerType,
	containersScannedSoFar:number
}

export type SpecificRiderFinancials = {
	__typename?: "SpecificRiderFinancials",
	startDate:Date,
	endDate:Date,
	id:string,
	riderEarningsSum:number,
	adjustments:number,
	extraFromSlots:number,
	extraFromReferrals:number,
	riderTips:number,
	totalPayout:number,
	numberOfOrders:number,
	bookingStats:RiderSlotStats[],
	rider:Rider
}

export type StatChart = {
	__typename?: "StatChart",
	id:string,
	type:string,
	label:string,
	value:string
}

export type StripeTokens = {
	__typename?: "StripeTokens",
	addPaymentMethod:string,
	payOnce:string
}

export type Subscription = {
	__typename?: "Subscription",
	LiveOrders?:Order[],
	OrderAlerts:OrderAlert,
	PartnerTabletActions:PartnerTabletAction,
	AdminPageViews:AdminPageView
}

export type TableChart = {
	__typename?: "TableChart",
	id:string,
	type:string,
	title:string,
	columns:TableColumn[],
	data:TableRow[]
}

export type TableColumn = {
	__typename?: "TableColumn",
	id:string,
	title:string
}

export type TableRecord = {
	__typename?: "TableRecord",
	dataIndex:string,
	formatted?:string,
	value:JSON
}

export type TableRow = {
	__typename?: "TableRow",
	id:string,
	records:TableRecord[]
}

export type TeamMember = {
	__typename?: "TeamMember",
	id:string,
	name:string,
	title:string,
	description?:string,
	imageKey?:string
}

export type TimeSlot = {
	__typename?: "TimeSlot",
	start:Date,
	end:Date
}

export type TwoFactorSetup = {
	__typename?: "TwoFactorSetup",
	uri:string
}

export enum UIThemeColour {
	PRIMARY = "PRIMARY",
	WARNING = "WARNING",
	ERROR = "ERROR",
	INFO = "INFO"
}

export type UnifiedLocationInput = {
		latLng?:GraphQLGeoJSONPointInput,
	zoneId?:string,
	geohash?:string
}

export type UnifiedLocationType = {
	__typename?: "UnifiedLocationType",
	latLng?:GraphQLGeoJSONPoint,
	zoneId?:string,
	geohash?:string
}

export type UpdateBookingArgs = {
		status?:BookingStatus
}

export type UpdateBookingSlotArgs = {
		startTime?:Date,
	endTime?:Date,
	guaranteedHourlyPay?:number,
	slotType?:BookingSlotType,
	capHourlyPay?:boolean,
	carOnly?:boolean,
	maxRiders?:number,
	autoIncreaseRiderPercentage?:boolean,
	percentage?:number
}

export type UpdateIncidentInput = {
		severity:IncidentSeverity,
	riderId?:string,
	orderId?:string,
	partnerId?:string,
	customerId?:string,
	atFault:UserType,
	description:string,
	imageIds:string[]
}

export type UpdateMenuCategoryAsRestaurantInput = {
		times?:OpeningTimeInput[],
	topLevel?:boolean
}

export type UpdateOrderInput = {
		internalNote?:string,
	addressId?:string,
	partnerReusablesCount?:number
}

export type UpdatePartnerPaymentDetailsInput = {
		accountNo:string,
	sortCode:string,
	isCompany?:boolean,
	individualName?:string
}

export type UpdatePromotionInput = {
		start?:Date,
	end?:Date,
	name?:string,
	title?:string,
	zoneIds?:string[],
	enabled?:boolean,
	body?:string,
	color?:string,
	targetType?:PromotionTarget,
	callToActionText?:string,
	target?:string
}

/** The `Upload` scalar type represents a file upload. */
export type Upload = any

export type UploadMediaJob = {
	__typename?: "UploadMediaJob",
	id:string,
	uploadId:string,
	key:string,
	urls:PresignedUploadURL[]
}

export type UploadMediaPart = {
		etag:string,
	partNumber:number
}

export type User = {
	__typename?: "User",
	id:string,
	name:string,
	email:string,
	created:Date,
	phoneNumber?:string,
	isPhoneNumberVerified:boolean,
	emailConfirmationTokenIssued?:Date,
	emailConfirmed:boolean,
	roles:string[],
	profileImageKey?:string
}

/** The type of this user */
export enum UserType {
	customer = "customer",
	rider = "rider",
	restaurant = "restaurant"
}

export type UserUnionDTO = {
	__union:Customer | RestaurantAdmin | Rider;
	__resolve:{
		['...on Customer']: Customer;
		['...on RestaurantAdmin']: RestaurantAdmin;
		['...on Rider']: Rider;
	}
}

export type UserView = {
	__typename?: "UserView",
	id:string,
	type:UserType,
	name:string,
	email:string,
	avatarUrl:string
}

export type ValidateRegistrationResult = {
	__typename?: "ValidateRegistrationResult",
	canRide:boolean,
	reason?:string,
	registration:string
}

export type VideoAsset = {
	__typename?: "VideoAsset",
	id:string,
	created:Date,
	updated:Date,
	title:string,
	key:string,
	width:number,
	height:number,
	fileSize:number,
	altText?:string,
	mimeType:string,
	uploadedBy:RestaurantAdmin,
	url:string
}

export type VideoAssetList = {
	__typename?: "VideoAssetList",
	items:VideoAsset[],
	total:number,
	hasMore:boolean
}

export type WashingLocation = {
	__typename?: "WashingLocation",
	id:string,
	name:string,
	notes?:string,
	status:string,
	hasUnattendedDropoff:boolean,
	address:Address,
	partner:Restaurant,
	/** The current list of ingested containers not scheduled */
	currentIngestedContainers:ScannedContainer[],
	/** A list of shipments that this washing location could fulfill. */
	availableShipments:ReusableShipment[]
}

export type WashingLocationList = {
	__typename?: "WashingLocationList",
	items:WashingLocation[],
	total:number,
	hasMore:boolean
}

export type Zone = {
	__typename?: "Zone",
	id:string,
	name:string,
	slug:string,
	polygon:GraphQLGeoJSONPolygon,
	baseDeliveryFee:number,
	feePerKM:number,
	baseRiderEarnings:number,
	riderEarningsPerKM:number,
	timeZone:string,
	onboardingStatus:ZoneOnboardingStatus,
	busyWeight:number,
	busyStatus:ZoneBusyStatus,
	minDeliveryDistanceMetres:number,
	standardDeliveryDistanceMetres:number,
	currentDeliveryDistanceMetres:number,
	hardLimitDeliveryDistanceMetres:number,
	autoBookingSlotConfigId?:string,
	autoBookingSlotConfig?:AutoBookingSlotsConfig,
	weather?:ZoneWeatherData,
	riders:Rider[],
	restaurants:Restaurant[],
	city:City,
	boosts:Boost[],
	bookingSlots:BookingSlot[],
	currentBoost?:Boost,
	openingTimes:OpeningTime[],
	activePolygonId?:string,
	liveView:ZoneLiveView
}

export enum ZoneBusyStatus {
	QUIET = "QUIET",
	MODERATE = "MODERATE",
	BUSY = "BUSY"
}

export type ZoneCreateInput = {
		name:string,
	polygon:GraphQLGeoJSONPolygonInput,
	cityId?:string,
	feePerKM?:number,
	baseDeliveryFee?:number
}

export type ZoneEvent = {
	__typename?: "ZoneEvent",
	id:string,
	timestamp:Date,
	operation:string,
	zoneId:string,
	from:JSON,
	to:JSON
}

export type ZoneEventList = {
	__typename?: "ZoneEventList",
	items:ZoneEvent[],
	total:number,
	hasMore:boolean
}

export type ZoneList = {
	__typename?: "ZoneList",
	items:Zone[],
	total:number,
	hasMore:boolean
}

export type ZoneLiveView = {
	__typename?: "ZoneLiveView",
	activeRiders:number,
	unassignedRiders:number,
	riderAcceptRate:number,
	ridersFreeSoon:number
}

export enum ZoneOnboardingStatus {
	ONBOARDING = "ONBOARDING",
	COMING_SOON = "COMING_SOON",
	TEMPORARILY_DISABLED = "TEMPORARILY_DISABLED",
	LIVE = "LIVE"
}

export type ZonePolygon = {
	__typename?: "ZonePolygon",
	id:string,
	created:Date,
	updated:Date,
	maxCycleTime:number,
	maxDriveTime:number,
	polygon:GraphQLGeoJSONPolygon
}

export type ZonePolygonInput = {
		zoneId:string,
	polygon:GraphQLGeoJSONPolygonInput
}

export type ZonePolygonList = {
	__typename?: "ZonePolygonList",
	items:ZonePolygon[],
	total:number,
	hasMore:boolean
}

export type ZoneUpdateInput = {
		feePerKM?:number,
	baseDeliveryFee?:number,
	riderEarningsPerKM?:number,
	baseRiderEarnings?:number,
	polygon?:GraphQLGeoJSONPolygonInput,
	onboardingStatus?:ZoneOnboardingStatus,
	busyStatus?:ZoneBusyStatus,
	autoBookingSlotConfigId?:string,
	openingTimes?:OpeningTimeInput[],
	minDeliveryDistanceMetres?:number,
	standardDeliveryDistanceMetres?:number,
	currentDeliveryDistanceMetres?:number,
	hardLimitDeliveryDistanceMetres?:number
}

export type ZoneWeatherData = {
	__typename?: "ZoneWeatherData",
	updated:Date,
	result:JSON
}

export const AllTypesProps  = {
	AddressCreateInput:{
		flat_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		address_line_1:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		postcode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		phone_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		instructions:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		town:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coordinates:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AddressUpdateInput:{
		flat_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		address_line_1:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		postcode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		instructions:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		town:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coordinates:{
			type:"GraphQLGeoJSONPointInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AssignmentBias: "enum",
	AutoBookingSlotInput:{
		dayOfTheWeek:{
			type:"DayOfWeek",
			array:false,
			arrayRequired:false,
			required:true
		},
		startTime:{
			type:"HourMinuteTimeString",
			array:false,
			arrayRequired:false,
			required:true
		},
		endTime:{
			type:"HourMinuteTimeString",
			array:false,
			arrayRequired:false,
			required:true
		},
		guaranteedHourlyPay:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		slotType:{
			type:"BookingSlotType",
			array:false,
			arrayRequired:false,
			required:true
		},
		maxRiders:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		percentage:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		autoIncreaseRiderPercentage:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		carOnly:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		configId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AutoBookingSlotsConfigInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	B2BDeliverySizes: "enum",
	BasketAddDiscountInput:{
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethod:{
			type:"FulfillmentMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"DeliveryTimeInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		useSynchronousPayments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BasketAddItemInput:{
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethod:{
			type:"FulfillmentMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"DeliveryTimeInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		useSynchronousPayments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		item:{
			type:"BasketItemInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BasketCheckOutInput:{
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		location:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethod:{
			type:"FulfillmentMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"DeliveryTimeInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		useSynchronousPayments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		tableNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BasketEditItemInput:{
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethod:{
			type:"FulfillmentMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"DeliveryTimeInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		useSynchronousPayments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		itemId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		quantity:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BasketIdentifierInfo:{
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethod:{
			type:"FulfillmentMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"DeliveryTimeInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		useSynchronousPayments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BasketIncrementDecrementItemInput:{
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethod:{
			type:"FulfillmentMethod",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"DeliveryTimeInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		useSynchronousPayments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		itemId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BasketItemInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		quantity:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		modifierGroups:{
			type:"BasketModifierGroupInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	BasketModifierGroupInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		modifierItems:{
			type:"BasketItemInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	BookingSlot:{
		bookings:{
			includeCancelled:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	BookingSlotInput:{
		dayOfTheWeek:{
			type:"DayOfWeek",
			array:false,
			arrayRequired:false,
			required:true
		},
		startTime:{
			type:"HourMinuteTimeString",
			array:false,
			arrayRequired:false,
			required:true
		},
		endTime:{
			type:"HourMinuteTimeString",
			array:false,
			arrayRequired:false,
			required:true
		},
		guaranteedHourlyPay:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		slotType:{
			type:"BookingSlotType",
			array:false,
			arrayRequired:false,
			required:true
		},
		maxRiders:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		percentage:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		autoIncreaseRiderPercentage:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		carOnly:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BookingSlotRiderView:{
		bookings:{
			includeCancelled:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	BookingSlotType: "enum",
	BookingStatus: "enum",
	BoostInput:{
		start:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		end:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"BoostType",
			array:false,
			arrayRequired:false,
			required:true
		},
		multiplier:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		zoneId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		fixedIncrease:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BoostType: "enum",
	CharityCampaignCreateArgs:{
		charityId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		endDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		goal:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		goalPrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		goalSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		costPerGoalUnit:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		campaignLogoId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CharityCampaignUpdateArgs:{
		charityId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		goal:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		goalPrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		goalSuffix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		costPerGoalUnit:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaignLogoId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CharityCreateArgs:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		logoId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CharityUpdateArgs:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		logoId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ChatMessageInput:{
		orderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		viewableBy:{
			type:"UserType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CityCreateInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		zones:{
			type:"ZoneCreateInput",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	Configuration:{
		coreSettings:{
			id:{
				type:"CoreConfigurations",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Coordinates: "String",
	CoreConfigurations: "enum",
	CreateIncidentInput:{
		severity:{
			type:"IncidentSeverity",
			array:false,
			arrayRequired:false,
			required:true
		},
		riderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partnerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		atFault:{
			type:"UserType",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	CreatePromotionInput:{
		start:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		end:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		zoneIds:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		targetType:{
			type:"PromotionTarget",
			array:false,
			arrayRequired:false,
			required:false
		},
		callToActionText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		target:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CustomerReusables:{
		slots:{
			count:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			addressId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	CustomerUpdateInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Date: "String",
	DayOfWeek: "enum",
	DeliveryJobInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduledFor:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		destinationId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		originId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		destination:{
			type:"AddressCreateInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		origin:{
			type:"AddressCreateInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		size:{
			type:"B2BDeliverySizes",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	DeliveryTimeDay: "enum",
	DeliveryTimeInput:{
		day:{
			type:"DeliveryTimeDay",
			array:false,
			arrayRequired:false,
			required:true
		},
		time:{
			type:"HourMinuteTimeString",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Discount:{
		orders:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	DiscountCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		validFrom:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		validUntil:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"DiscountType",
			array:false,
			arrayRequired:false,
			required:true
		},
		value:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxUsesGlobally:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		maxUsesPerCustomer:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		minimumTotalPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		referralAmount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DiscountType: "enum",
	DiscountUpdateInput:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		validFrom:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		validUntil:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"DiscountType",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxUsesGlobally:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxUsesPerCustomer:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		referralAmount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DropBoxStatus: "enum",
	DynamicFormType: "enum",
	ExternalCompetitorInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	FinancialOverview:{
		partners:{
			restaurantIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			}
		}
	},
	FulfillmentMethod: "enum",
	GraphQLGeoJSONPointI:{
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coordinates:{
			type:"Coordinates",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	GraphQLGeoJSONPointInput:{
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coordinates:{
			type:"Coordinates",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	GraphQLGeoJSONPolygonInput:{
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		coordinates:{
			type:"Coordinates",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	HourMinuteTimeString: "String",
	HygieneAuthorities: "enum",
	IdentityDocumentType: "enum",
	ImageAsset:{
		url:{
			width:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:true
			},
			height:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			gravity:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IncidentSeverity: "enum",
	ItemCreateInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ingredients:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unitPrice:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		alcohol:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popular:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerImageUpload:{
			type:"Upload",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortOrder:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		modifierIds:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		approved:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		available:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		published:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		vegan:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		vegetarian:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		weight:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		tax:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		categoryId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		heat:{
			type:"ItemHeatType",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableFor:{
			type:"FulfillmentMethod",
			array:true,
			arrayRequired:false,
			required:true
		},
		containerTypeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ItemHeatType: "enum",
	ItemUpdateAsRestaurantInput:{
		unitPrice:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		alcohol:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popular:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortOrder:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		modifierIds:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		available:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		published:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		vegan:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		vegetarian:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		weight:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		tax:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		categoryId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heat:{
			type:"ItemHeatType",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableFor:{
			type:"FulfillmentMethod",
			array:true,
			arrayRequired:false,
			required:true
		},
		containerTypeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ItemUpdateInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ingredients:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unitPrice:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		alcohol:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		popular:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerImageUpload:{
			type:"Upload",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortOrder:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		modifierIds:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		approved:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		available:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		published:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		vegan:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		vegetarian:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		weight:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		tax:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		categoryId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heat:{
			type:"ItemHeatType",
			array:false,
			arrayRequired:false,
			required:false
		},
		unavailableFor:{
			type:"FulfillmentMethod",
			array:true,
			arrayRequired:false,
			required:true
		},
		containerTypeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ItemUpdateRequestInput:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		itemId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		partnerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ItemUpdateRequestStatus: "enum",
	JSON: "String",
	LeasePaymentStatus: "enum",
	LeaseVehicleStatus: "enum",
	LoginNumberPayload:{
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		userType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LoginOAuthProviders: "enum",
	LoginPayload:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		userType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		twoFactorCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MarketingCampaignStatus: "enum",
	MenuCategoryCreateInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortOrder:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		topLevel:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		times:{
			type:"OpeningTimeInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	MenuModifierGroupCreateInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		maxOptions:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		minOptions:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		maxPerOption:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		instruction:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortOrder:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		optionIds:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		priceStrategy:{
			type:"PriceStrategy",
			array:false,
			arrayRequired:false,
			required:true
		},
		cascades:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		approved:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MerchantSitePageCreateInput:{
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		layout:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:true
		},
		siteId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MerchantSitePageUpdateInput:{
		slug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		layout:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MerchantSiteStatus: "enum",
	Mutation:{
		submitDynamicForm:{
			entityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateCustomer:{
			customer:{
				type:"CustomerUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteCustomer:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addPaymentMethod:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addPaymentMethodWithCard:{
			cardToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addVoucherCode:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removePaymentMethod:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addAddress:{
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			address:{
				type:"AddressCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		placeOrder:{
			order:{
				type:"OrderCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateOrder:{
			data:{
				type:"UpdateOrderInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rateOrder:{
			rating:{
				type:"OrderRatingInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderTipRider:{
			amount:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		adjustOrder:{
			data:{
				type:"OrderAdjustInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		acceptOrder:{
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		acceptOrderAsRestaurant:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		completeOrderAsRestaurant:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rejectOrderAsRestaurant:{
			rejectOrder:{
				type:"OrderRejectionInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		arriveForOwnOrder:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		pickUpOwnOrder:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delayPickup:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		blockPickup:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderRemoveRiderAsAdmin:{
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderCompleteAsAdmin:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderBringForward:{
			amount:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rescheduleOrder:{
			options:{
				type:"OrderRescheduleInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resolveOrderAlert:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PurchaseWalletTopup:{
			amount:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setAvailability:{
			available:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setItemAvailability:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			published:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			available:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateItemHeaderImage:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeItemHeaderImage:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateItem:{
			item:{
				type:"ItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteItem:{
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			itemId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateItemAsRestaurant:{
			item:{
				type:"ItemUpdateAsRestaurantInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketApplyDiscount:{
			input:{
				type:"BasketAddDiscountInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketAddItem:{
			input:{
				type:"BasketAddItemInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketIncrementItem:{
			input:{
				type:"BasketIncrementDecrementItemInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketDecrementItem:{
			input:{
				type:"BasketIncrementDecrementItemInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketEditItem:{
			input:{
				type:"BasketEditItemInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketIncrementOrDecrementTip:{
			increment:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketSetTip:{
			tip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketSetNotes:{
			notes:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketSetInstructions:{
			instructions:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketCheckOut:{
			input:{
				type:"BasketCheckOutInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketSetSingleDelivery:{
			singleDelivery:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketSetReusables:{
			pickupTime:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			enabled:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketDonationAdd:{
			donationOptionId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketDonationRemove:{
			donationOptionId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			info:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateCharity:{
			args:{
				type:"CharityUpdateArgs",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createCharity:{
			args:{
				type:"CharityCreateArgs",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createCharityCampaign:{
			args:{
				type:"CharityCampaignCreateArgs",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateCharityCampaign:{
			args:{
				type:"CharityCampaignUpdateArgs",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SwapContainerCode:{
			to:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			from:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ScanContainerAtPartner:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:false
			},
			shipmentId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ScanContainer:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CancelContainerPickup:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UpdateContainerPickup:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:false
			},
			count:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			addressId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		BookContainerPickup:{
			count:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			addressId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			slot:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AssignReusableShipment:{
			washingLocationId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CompleteReusableShipment:{
			override:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			washingLocationId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createRestaurant:{
			restaurant:{
				type:"RestaurantCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateRestaurant:{
			restaurant:{
				type:"RestaurantUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setFulfillmentMethods:{
			fulfillmentMethods:{
				type:"FulfillmentMethod",
				array:true,
				arrayRequired:true,
				required:true
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		setAcceptsScheduledOrders:{
			acceptsScheduledOrders:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateRestaurantHeaderImage:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setBusy:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			status:{
				type:"RestaurantBusyStatus",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setOnline:{
			online:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setOwner:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		markItemsUnavailable:{
			items:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPartnerTag:{
			tag:{
				type:"PartnerTagInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePartnerTag:{
			tag:{
				type:"PartnerTagInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deletePartnerTag:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PartnerTabletSync:{
			screenshot:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			tablet:{
				type:"PartnerTabletSyncArgs",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PartnerTabletAction:{
			payload:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			action:{
				type:"PartnerTabletActionType",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addItem:{
			item:{
				type:"ItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addCategory:{
			category:{
				type:"MenuCategoryCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		reorderCategory:{
			itemIds:{
				type:"String",
				array:true,
				arrayRequired:true,
				required:true
			},
			categoryId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		reorderModifierGroups:{
			modifierGroupIds:{
				type:"String",
				array:true,
				arrayRequired:true,
				required:true
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		reorderCategories:{
			categoryIds:{
				type:"String",
				array:true,
				arrayRequired:true,
				required:true
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		addModifierGroup:{
			modifierGroup:{
				type:"MenuModifierGroupCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		importMenu:{
			url:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateCategoryAsRestaurant:{
			category:{
				type:"UpdateMenuCategoryAsRestaurantInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createItemUpdateRequest:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:false
			},
			item:{
				type:"ItemUpdateRequestInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateItemUpdateRequest:{
			status:{
				type:"ItemUpdateRequestStatus",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateRestaurantAdmin:{
			admin:{
				type:"RestaurantAdminUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSuperAdmin:{
			permissions:{
				type:"Permissions",
				array:true,
				arrayRequired:false,
				required:true
			},
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateTabletAccountPassword:{
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createZone:{
			zone:{
				type:"ZoneCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		boostZone:{
			boost:{
				type:"BoostInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateZone:{
			zone:{
				type:"ZoneUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setActivePolygon:{
			polygonId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		endBoost:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createZonePolygon:{
			polygon:{
				type:"ZonePolygonInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createRider:{
			rider:{
				type:"RiderCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateRiderLocation:{
			rider:{
				type:"RiderStatusUpdate",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unthrottleRider:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		sendMessageToRider:{
			message:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateRider:{
			rider:{
				type:"RiderUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		endRiderOnlineSession:{
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteRiderOnlineSession:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createRiderApplication:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			},
			documentType:{
				type:"IdentityDocumentType",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteRiderApplication:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderLiveUIValidate:{
			value:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			validationId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderLiveUIAction:{
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			action:{
				type:"RiderUIActions",
				array:false,
				arrayRequired:false,
				required:true
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderLiveUISetting:{
			value:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:true
			},
			settingId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PayLeasePayment:{
			manual:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		sendChatMessage:{
			message:{
				type:"ChatMessageInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		login:{
			login:{
				type:"LoginPayload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		loginOAuth:{
			provider:{
				type:"LoginOAuthProviders",
				array:false,
				arrayRequired:false,
				required:false
			},
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		loginPhone:{
			login:{
				type:"LoginNumberPayload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setupTwoFactorAuthentication:{
			login:{
				type:"LoginPayload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		confirmTwoFactorAuthentication:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			login:{
				type:"LoginPayload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		refreshToken:{
			refreshToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		register:{
			register:{
				type:"RegisterPayload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resetPasswordRequest:{
			type:{
				type:"UserType",
				array:false,
				arrayRequired:false,
				required:true
			},
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resetPasswordWithToken:{
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		requestPhoneNumberVerification:{
			sessionId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			phoneNumber:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		verifyPhoneNumberCode:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			sessionId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			phoneNumber:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		setFirebaseCloudMessagingToken:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		confirmEmailWithToken:{
			token:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserUpdateProfilePicture:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createDiscount:{
			discount:{
				type:"DiscountCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateDiscount:{
			discount:{
				type:"DiscountUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createRiderReferralDiscount:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateAddress:{
			address:{
				type:"AddressUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAddress:{
			address:{
				type:"AddressCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAddress:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		makeBooking:{
			bookingSlotId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		cancelBooking:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateBooking:{
			updateArgs:{
				type:"UpdateBookingArgs",
				array:false,
				arrayRequired:false,
				required:true
			},
			bookingId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		decideBooking:{
			bookingId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createBookingSlot:{
			slot:{
				type:"BookingSlotInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateBookingSlot:{
			slot:{
				type:"UpdateBookingSlotArgs",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteBookingSlot:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AutoBookingSlotsConfigList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createAutoBookingConfig:{
			input:{
				type:"AutoBookingSlotsConfigInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAutoBookingSlot:{
			slotId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			configId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAutoBookingSlot:{
			input:{
				type:"AutoBookingSlotInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createCity:{
			city:{
				type:"CityCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		exportOrders:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			restaurantIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		riderInvoice:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			end:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		payRiderInvoice:{
			manual:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		payRiderInvoices:{
			end:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PartnerInvoiceForPeriod:{
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			end:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		payPartnerInvoice:{
			manual:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePartnerPaymentDetails:{
			details:{
				type:"UpdatePartnerPaymentDetailsInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createRiderAdjustment:{
			adjustment:{
				type:"RiderAdjustmentCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateRiderAdjustment:{
			input:{
				type:"RiderAdjustmentUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPartnerAdjustment:{
			adjustment:{
				type:"PartnerAdjustmentCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePartnerAdjustment:{
			input:{
				type:"PartnerAdjustmentUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		uploadPromotionImage:{
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPromotion:{
			promotion:{
				type:"CreatePromotionInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePromotion:{
			promotion:{
				type:"UpdatePromotionInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		estimateB2BJob:{
			job:{
				type:"DeliveryJobInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		executeB2BJob:{
			paymentMethodId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		cancelB2BJob:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIncident:{
			incident:{
				type:"CreateIncidentInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateIncident:{
			incident:{
				type:"UpdateIncidentInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		uploadImageAsset:{
			title:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			altText:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			image:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UploadVideoAsset:{
			title:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			altText:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			mimeType:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			size:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UploadMediaComplete:{
			parts:{
				type:"UploadMediaPart",
				array:true,
				arrayRequired:true,
				required:true
			},
			key:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			uploadId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AdminPageView:{
			url:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createMerchantSitePage:{
			page:{
				type:"MerchantSitePageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateMerchantSitePage:{
			page:{
				type:"MerchantSitePageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createMerchantSiteDomain:{
			domain:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			siteId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	OpeningTimeInput:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		open:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		close:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		daysOfWeek:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	OrderAdjustInput:{
		ourAdjustment:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		partnerAdjustment:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		riderEarningsAdjustment:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		riderTipAdjustment:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		ourAdjustmentJustification:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partnerAdjustmentJustification:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		riderEarningsAdjustmentJustification:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		riderTipAdjustmentJustification:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	OrderAlertType: "enum",
	OrderCancellationReason: "enum",
	OrderCreateInput:{
		paymentMethodId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		paymentMethodToken:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		basketId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	OrderFulfilledBy: "enum",
	Ordering: "enum",
	OrderRatingInput:{
		riderRating:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		partnerRating:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	OrderRejectionInput:{
		restaurantId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		outOfStockItems:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		reason:{
			type:"OrderCancellationReason",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	OrderRescheduleInput:{
		skipPrepTime:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		split:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		isPartnerResponsible:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		includeItems:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	OrderRiderRatingType: "enum",
	OrderStatus: "enum",
	OrderType: "enum",
	PartnerAdjustmentCreateInput:{
		forDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		explanation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		partnerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	PartnerAdjustmentUpdateInput:{
		forDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		explanation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partnerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PartnerSelectionType: "enum",
	PartnerTabletActionType: "enum",
	PartnerTabletSyncArgs:{
		serial:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		model:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		manufacturer:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		battery:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		release:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		batteryState:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ram:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		usedRam:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		volume:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiNetwork:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bssid:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		routerMake:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		wifiStrength:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		ip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hostname:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isp:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		location:{
			type:"GraphQLGeoJSONPointI",
			array:false,
			arrayRequired:false,
			required:false
		},
		webRTCOfferDescription:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		webRTCOfferReceiveDescription:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		webRTCOfferReceiveCandidates:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		webRTCOfferCandidates:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PartnerTagInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		image:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		type:{
			type:"PartnerTagTypes",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	PartnerTagTypes: "enum",
	Permissions: "enum",
	PriceStrategy: "enum",
	PromotionTarget: "enum",
	Query:{
		DynamicForm:{
			entityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CustomerList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Customer:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		order:{
			isBasket:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			status:{
				type:"OrderStatus",
				array:true,
				arrayRequired:false,
				required:true
			},
			fulfillmentMethods:{
				type:"FulfillmentMethod",
				array:true,
				arrayRequired:false,
				required:true
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			end:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			collapseAggregateOrders:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		FlightPlanOfferList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeCompleted:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderAlertList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeResolved:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		OrderAlert:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		OrderRiderRatingList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			ratings:{
				type:"OrderRiderRatingType",
				array:true,
				arrayRequired:false,
				required:true
			}
		},
		OrderEventList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerWalletTopupList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Item:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ItemList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			approved:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BasketList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			modifiedInLast:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			complete:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Basket:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		basketForRestaurant:{
			input:{
				type:"BasketIdentifierInfo",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Charity:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CharityList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CharityCampaign:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CharityCampaignList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			charityId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			minimumProgress:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConsumerCharityCampaign:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusableDropBox:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusableDropBoxList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			coordinates:{
				type:"Coordinates",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReusableContainer:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusableContainerList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReusableContainerType:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusableContainerTypeList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ScannedContainerList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			washingLocationId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			shipmentId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			pickupId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			containerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WashingLocation:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		WashingLocationList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			coordinates:{
				type:"Coordinates",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReusablesPickup:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusablesPickupList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			aggregateId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerReusables:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReusableShipment:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusableShipmentList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			washingLocationId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ReusablesPickupAggregate:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ReusablesPickupAggregateList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Restaurant:{
			slug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		allRestaurants:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			lat:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			lng:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			fulfillmentMethod:{
				type:"FulfillmentMethod",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeClosed:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			tags:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			order:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			coreConfiguration:{
				type:"CoreConfigurations",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RestaurantList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			lat:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			lng:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			fulfillmentMethod:{
				type:"FulfillmentMethod",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeClosed:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			tags:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			order:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			coreConfiguration:{
				type:"CoreConfigurations",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		restaurantHeartbeat:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			online:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		activeOrders:{
			online:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerTag:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		allPartnerTags:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerTablet:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PartnerTabletList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConsumerSearch:{
			location:{
				type:"UnifiedLocationInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Menu:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		allMenus:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ItemUpdateRequest:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ItemUpdateRequestList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			itemId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			statuses:{
				type:"ItemUpdateRequestStatus",
				array:true,
				arrayRequired:true,
				required:true
			}
		},
		RestaurantAdminList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RestaurantAdmin:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		tabletForRestaurant:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ZoneList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		zone:{
			slug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		zones:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			onboardingStatus:{
				type:"ZoneOnboardingStatus",
				array:true,
				arrayRequired:false,
				required:true
			}
		},
		zoneAtPoint:{
			lat:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:true
			},
			lng:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ZoneEventList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			fields:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			}
		},
		ZonePolygonList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CommissionPlanList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CommissionPlan:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Brand:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		BrandList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerHomepage:{
			day:{
				type:"DeliveryTimeDay",
				array:false,
				arrayRequired:false,
				required:true
			},
			time:{
				type:"HourMinuteTimeString",
				array:false,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			lat:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			lng:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			fulfillmentMethod:{
				type:"FulfillmentMethod",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeClosed:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			tags:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			order:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			coreConfiguration:{
				type:"CoreConfigurations",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Rider:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			activationStatus:{
				type:"RiderActivationStatus",
				array:false,
				arrayRequired:false,
				required:false
			},
			online:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateVehicleRegistration:{
			reg:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		riderOnlineSession:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderOnlineSessionList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RiderLiveUI:{
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RiderLiveRoute:{
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Lease:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		LeaseList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			vehicleId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			active:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LeaseVehicle:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		LeaseVehicleList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ChatMessageList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			isReading:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Discount:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DiscountList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			validFrom:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			validUntil:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderReferralDiscount:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderReferralDiscountList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			discountId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			validFrom:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			validUntil:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Address:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AddressList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LocationSearch:{
			location:{
				type:"UnifiedLocationInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		LocationSearchById:{
			provider:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		BookingMetricsRank:{
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		BookingSlot:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		BookingSlotList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BookingSlotsAvailableForRider:{
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		countBookingsForSlot:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AutoBookingSlotConfig:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		allAnalytics:{
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			end:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerAnalytics:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			dayOfWeek:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			timeRange:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			}
		},
		CustomerAnalytics:{
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		city:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		allCities:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		financialsForRestaurants:{
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		financialsForRestaurant:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			restaurantIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		salesForRestaurant:{
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			restaurantIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		financialsForRider:{
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RiderInvoice:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		allRiderInvoices:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			paid:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerInvoice:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		downloadPartnerInvoice:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		allPartnerInvoices:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			paid:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerPaymentDetails:{
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderAdjustment:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RiderAdjustmentList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerAdjustment:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PartnerAdjustmentList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		FinancialOverview:{
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			endDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			}
		},
		PromotionList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			start:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		Promotion:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		B2BDeliveryJob:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		B2BDeliveryJobList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		IncidentList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		Incident:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ImageAsset:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ImageAssetList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		VideoAsset:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		VideoAssetList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MarketingCampaignList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			startDate:{
				type:"Date",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MerchantSite:{
			domain:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MerchantSiteList:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			ownerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MerchantSitePage:{
			url:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		FileList:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	RegisterPayload:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		marketing:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	RestaurantAdminUpdateInput:{
		roles:{
			type:"Permissions",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	RestaurantBusyStatus: "enum",
	RestaurantCreateInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		address:{
			type:"AddressCreateInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		zoneId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ownerEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		ownerName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	RestaurantOnboardingStatus: "enum",
	RestaurantUpdateInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		onboardingStatus:{
			type:"RestaurantOnboardingStatus",
			array:false,
			arrayRequired:false,
			required:false
		},
		openingTimes:{
			type:"OpeningTimeInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		busyStatus:{
			type:"RestaurantBusyStatus",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfillmentMethods:{
			type:"FulfillmentMethod",
			array:true,
			arrayRequired:false,
			required:true
		},
		exclusive:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		basePrepTime:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		incrementPrepTime:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		baseDeliveryTime:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		ratingStars:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		ratingCount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		commission:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		commissionCollection:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		minimumBasket:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		hygieneRatingId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		allergyWarnings:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tags:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		external:{
			type:"ExternalCompetitorInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		acceptsScheduledOrders:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		autoAccept:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		commissionPlanId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		commissionPlanRungId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		configuration:{
			type:"CoreConfigurations",
			array:false,
			arrayRequired:false,
			required:false
		},
		brandId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerVideoId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isReusablesEnabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReusableShipmentStatus: "enum",
	ReusablesPickupStatus: "enum",
	ReusablesPickupType: "enum",
	Rider:{
		orders:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		bookings:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	RiderActivationStatus: "enum",
	RiderAddressType: "enum",
	RiderAdjustmentCreateInput:{
		forDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:true
		},
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		},
		explanation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		riderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	RiderAdjustmentUpdateInput:{
		forDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		explanation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		riderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RiderCreateInput:{
		zoneId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		accountNo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		address:{
			type:"AddressCreateInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		vehicle:{
			type:"RiderVehicle",
			array:false,
			arrayRequired:false,
			required:true
		},
		carRegistration:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sessionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	RiderStatusUpdate:{
		location:{
			type:"GraphQLGeoJSONPointInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		online:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		device:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		os:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RiderUIActionColour: "enum",
	RiderUIActions: "enum",
	RiderUIButtonType: "enum",
	RiderUILayoutType: "enum",
	RiderUpdateInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		activationStatus:{
			type:"RiderActivationStatus",
			array:false,
			arrayRequired:false,
			required:false
		},
		vehicle:{
			type:"RiderVehicle",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zoneId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		accountNo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sortCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		carRegistration:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingAddressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RiderVehicle: "enum",
	Subscription:{
		LiveOrders:{
			customerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			status:{
				type:"OrderStatus",
				array:true,
				arrayRequired:false,
				required:true
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			restaurantId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		OrderAlerts:{
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			order:{
				type:"Ordering",
				array:false,
				arrayRequired:false,
				required:true
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeResolved:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			zoneIds:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			orderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			riderId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerTabletActions:{
			partnerId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			tabletId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AdminPageViews:{
			url:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	UIThemeColour: "enum",
	UnifiedLocationInput:{
		latLng:{
			type:"GraphQLGeoJSONPointInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		zoneId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		geohash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdateBookingArgs:{
		status:{
			type:"BookingStatus",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdateBookingSlotArgs:{
		startTime:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endTime:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		guaranteedHourlyPay:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		slotType:{
			type:"BookingSlotType",
			array:false,
			arrayRequired:false,
			required:false
		},
		capHourlyPay:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		carOnly:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		maxRiders:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		autoIncreaseRiderPercentage:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdateIncidentInput:{
		severity:{
			type:"IncidentSeverity",
			array:false,
			arrayRequired:false,
			required:true
		},
		riderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partnerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		atFault:{
			type:"UserType",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		imageIds:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	UpdateMenuCategoryAsRestaurantInput:{
		times:{
			type:"OpeningTimeInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		topLevel:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdateOrderInput:{
		internalNote:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		addressId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partnerReusablesCount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdatePartnerPaymentDetailsInput:{
		accountNo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		sortCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		isCompany:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		individualName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UpdatePromotionInput:{
		start:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zoneIds:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		enabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		targetType:{
			type:"PromotionTarget",
			array:false,
			arrayRequired:false,
			required:false
		},
		callToActionText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		target:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Upload: "String",
	UploadMediaPart:{
		etag:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		partNumber:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	UserType: "enum",
	Zone:{
		restaurants:{
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			take:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			lat:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			lng:{
				type:"Float",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			zoneSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			fulfillmentMethod:{
				type:"FulfillmentMethod",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			includeClosed:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			},
			tags:{
				type:"String",
				array:true,
				arrayRequired:false,
				required:true
			},
			sortBy:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			order:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			coreConfiguration:{
				type:"CoreConfigurations",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ZoneBusyStatus: "enum",
	ZoneCreateInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		polygon:{
			type:"GraphQLGeoJSONPolygonInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		cityId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		feePerKM:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		baseDeliveryFee:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ZoneOnboardingStatus: "enum",
	ZonePolygonInput:{
		zoneId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		polygon:{
			type:"GraphQLGeoJSONPolygonInput",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ZoneUpdateInput:{
		feePerKM:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		baseDeliveryFee:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		riderEarningsPerKM:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		baseRiderEarnings:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		polygon:{
			type:"GraphQLGeoJSONPolygonInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		onboardingStatus:{
			type:"ZoneOnboardingStatus",
			array:false,
			arrayRequired:false,
			required:false
		},
		busyStatus:{
			type:"ZoneBusyStatus",
			array:false,
			arrayRequired:false,
			required:false
		},
		autoBookingSlotConfigId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		openingTimes:{
			type:"OpeningTimeInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		minDeliveryDistanceMetres:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		standardDeliveryDistanceMetres:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentDeliveryDistanceMetres:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		hardLimitDeliveryDistanceMetres:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes  = {
	Address:{
		id:"String",
		nickname:"String",
		flat_number:"String",
		address_line_1:"String",
		town:"String",
		postcode:"String",
		country:"String",
		phone_number:"String",
		instructions:"String",
		coordinates:"GraphQLGeoJSONPoint",
		imageKey:"String"
	},
	AddressList:{
		items:"Address",
		total:"Int",
		hasMore:"Boolean"
	},
	AdminPageView:{
		url:"String",
		users:"UserView"
	},
	AggregateBasket:{
		id:"String",
		isSingleDelivery:"Boolean",
		allowSwitchSingleDelivery:"Boolean",
		baskets:"Basket",
		feeExplanations:"BasketFeeExplanation",
		totalPrice:"Price",
		subtotal:"Price",
		fulfillmentTimes:"FulfillmentDayTimes",
		fulfillmentMethods:"FulfillmentMethod"
	},
	AggregateOrder:{
		id:"String",
		status:"OrderStatus",
		orders:"Order"
	},
	AnalyticsDTO:{
		totalOrders:"Float",
		totalRestaurants:"Float",
		customers:"Float"
	},
	AuthDTO:{
		user:"User",
		expiresIn:"String",
		accessToken:"String",
		refreshToken:"String"
	},
	AutoBookingSlot:{
		id:"String",
		dayOfTheWeek:"DayOfWeek",
		startTime:"HourMinuteTimeString",
		endTime:"HourMinuteTimeString",
		guaranteedHourlyPay:"Int",
		slotType:"BookingSlotType",
		maxRiders:"Float",
		percentage:"Float",
		autoIncreaseRiderPercentage:"Boolean",
		carOnly:"Boolean",
		configId:"String",
		config:"AutoBookingSlotsConfig"
	},
	AutoBookingSlotsConfig:{
		id:"String",
		name:"String",
		slots:"AutoBookingSlot",
		zones:"Zone"
	},
	AutoBookingSlotsConfigList:{
		items:"AutoBookingSlotsConfig",
		total:"Int",
		hasMore:"Boolean"
	},
	B2BDeliveryJob:{
		id:"String",
		created:"Date",
		placed:"Date",
		price:"Float",
		distanceDuration:"DistanceModel",
		scheduledFor:"Date",
		destination:"Address",
		origin:"Address",
		size:"B2BDeliverySizes",
		placedById:"String",
		placedBy:"RestaurantAdmin",
		order:"Order"
	},
	B2BDeliveryJobList:{
		items:"B2BDeliveryJob",
		total:"Int",
		hasMore:"Boolean"
	},
	B2BJobEstimateResponse:{
		job:"B2BDeliveryJob",
		scheduledTimes:"DeliveryTimes",
		estimatedArrivalMins:"Float",
		blockedReason:"String"
	},
	BarChart:{
		id:"String",
		type:"String",
		series:"Series"
	},
	Basket:{
		id:"String",
		modified:"Date",
		checkedOutAt:"Date",
		scheduledDelivery:"String",
		fulfillmentMethod:"FulfillmentMethod",
		targetDeliveryFromNow:"Float",
		restaurantId:"String",
		sessionId:"String",
		totalPriceBeforeDiscount:"Float",
		totalPrice:"Float",
		riderTip:"Float",
		allergyNotes:"String",
		tableNumber:"String",
		subtotal:"Float",
		smallOrderFee:"Float",
		deliveryFee:"Float",
		location:"GraphQLGeoJSONPoint",
		distanceDuration:"JSON",
		alcohol:"Boolean",
		numberOfItems:"Int",
		weight:"Int",
		instructions:"String",
		checkoutBlockedReason:"String",
		scheduledFor:"DeliveryTimeArgs",
		created:"Date",
		paymentIntentSecret:"String",
		paymentIntentId:"String",
		complete:"Boolean",
		donationTotal:"Float",
		items:"OrderItem",
		address:"Address",
		customer:"Customer",
		restaurant:"Restaurant",
		discount:"Discount",
		aggregateBasket:"AggregateBasket",
		donationView:"BasketDonationView",
		feeExplanations:"BasketFeeExplanation",
		fulfillmentMethods:"FulfillmentMethod",
		fulfillmentTimes:"FulfillmentDayTimes",
		reusables:"BasketReusablesOptions"
	},
	BasketDonationOptionView:{
		id:"String",
		primaryText:"String",
		secondaryText:"String",
		isSelected:"Boolean"
	},
	BasketDonationToggleView:{
		id:"String",
		toggleText:"String",
		toggleFormattedValue:"String",
		toggled:"Boolean"
	},
	BasketDonationView:{
		campaign:"ConsumerCharityCampaignView",
		donationOptions:"BasketDonationOptionView",
		donationOptionsMutuallyExclusive:"Boolean",
		donationToggleInfo:"BasketDonationToggleView"
	},
	BasketFeeExplanation:{
		title:"String",
		explanation:"String",
		feeFormatted:"String",
		color:"UIThemeColour"
	},
	BasketList:{
		items:"Basket",
		total:"Int",
		hasMore:"Boolean"
	},
	BasketReusablesOptions:{
		isEnabled:"Boolean",
		enableText:"String",
		explanation:"String",
		disableText:"String",
		pickupScheduleTimes:"TimeSlot",
		pickupScheduledTime:"TimeSlot",
		imageKey:"String"
	},
	Booking:{
		id:"String",
		createdAt:"Date",
		riderId:"String",
		rider:"Rider",
		bookingSlotId:"String",
		bookingSlot:"BookingSlot",
		status:"BookingStatus",
		attendancePercentage:"Float",
		numOfferedOrders:"Float",
		numAcceptedOrders:"Float",
		riderEarnings:"Float",
		sessionSpans:"String",
		lateCancellation:"Boolean",
		stats:"RiderSlotStats"
	},
	BookingList:{
		items:"Booking",
		total:"Int",
		hasMore:"Boolean"
	},
	BookingSlot:{
		id:"String",
		zoneId:"String",
		zone:"Zone",
		startTime:"Date",
		endTime:"Date",
		guaranteedHourlyPay:"Int",
		slotType:"BookingSlotType",
		capHourlyPay:"Boolean",
		carOnly:"Boolean",
		maxRiders:"Float",
		autoIncreaseRiderPercentage:"Boolean",
		percentage:"Float",
		bookings:"Booking"
	},
	BookingSlotList:{
		items:"BookingSlot",
		total:"Int",
		hasMore:"Boolean"
	},
	BookingSlotRiderView:{
		id:"String",
		zoneId:"String",
		zone:"Zone",
		startTime:"Date",
		endTime:"Date",
		guaranteedHourlyPay:"Int",
		slotType:"BookingSlotType",
		capHourlyPay:"Boolean",
		carOnly:"Boolean",
		maxRiders:"Float",
		autoIncreaseRiderPercentage:"Boolean",
		percentage:"Float",
		bookings:"Booking",
		hasBooked:"Boolean",
		bookingId:"String"
	},
	BookingSlotRiderViewList:{
		items:"BookingSlotRiderView",
		total:"Int",
		hasMore:"Boolean"
	},
	Boost:{
		id:"String",
		type:"BoostType",
		start:"Date",
		end:"Date",
		multiplier:"Float",
		fixedIncrease:"Int",
		notified:"Boolean",
		zoneId:"String",
		zone:"Zone"
	},
	Brand:{
		id:"String",
		slug:"String",
		name:"String",
		description:"String",
		logo:"ImageAsset",
		owners:"BrandOwner"
	},
	BrandList:{
		items:"Brand",
		total:"Int",
		hasMore:"Boolean"
	},
	BrandOwner:{
		id:"String",
		created:"Date"
	},
	CampaignMapMarker:{
		id:"String",
		center:"Coordinates"
	},
	CampaignMapView:{
		id:"String",
		features:"JSON",
		center:"Coordinates",
		markers:"CampaignMapMarker"
	},
	Charity:{
		id:"String",
		name:"String",
		logo:"ImageAsset",
		logoId:"String",
		campaigns:"CharityCampaign"
	},
	CharityCampaign:{
		id:"String",
		charityId:"String",
		startDate:"Date",
		endDate:"Date",
		goal:"Float",
		progress:"Float",
		title:"String",
		description:"String",
		goalPrefix:"String",
		goalSuffix:"String",
		costPerGoalUnit:"Float",
		campaignLogo:"ImageAsset",
		campaignLogoId:"String",
		charity:"Charity"
	},
	CharityCampaignFinancials:{
		id:"String",
		donationTotal:"Float",
		campaign:"CharityCampaign"
	},
	CharityCampaignList:{
		items:"CharityCampaign",
		total:"Int",
		hasMore:"Boolean"
	},
	CharityFinancials:{
		id:"String",
		donationTotal:"Float",
		campaigns:"CharityCampaignFinancials",
		charity:"Charity"
	},
	CharityList:{
		items:"Charity",
		total:"Int",
		hasMore:"Boolean"
	},
	Chart:{
		"...on PieChart":"PieChart",
		"...on LineChart":"LineChart",
		"...on BarChart":"BarChart",
		"...on TableChart":"TableChart",
		"...on StatChart":"StatChart"
	},
	ChartLayoutGroup:{
		id:"String",
		title:"String",
		description:"String",
		charts:"Chart"
	},
	ChatMessage:{
		id:"String",
		text:"String",
		sent:"Date",
		read:"Boolean",
		sender:"MessageParticipant",
		viewableBy:"UserType",
		order:"Order"
	},
	ChatMessageList:{
		items:"ChatMessage",
		total:"Int",
		hasMore:"Boolean",
		unreadCount:"Float"
	},
	City:{
		id:"String",
		name:"String",
		zones:"Zone"
	},
	CommissionPlan:{
		id:"String",
		slug:"String",
		description:"String",
		rungs:"CommissionPlanRung",
		partnerPlans:"PartnerCommissionPlan"
	},
	CommissionPlanList:{
		items:"CommissionPlan",
		total:"Int",
		hasMore:"Boolean"
	},
	CommissionPlanRung:{
		id:"String",
		name:"String",
		description:"String",
		fixedBase:"Int",
		percentage:"Int",
		percentageCollection:"Float",
		percentageTableService:"Float",
		deliveryFeePerKMDiff:"Float",
		deliveryFeeBaseDiff:"Float"
	},
	Configuration:{
		cdnURL:"String",
		imgProxyURL:"String",
		env:"String",
		coreSettings:"CoreSettings"
	},
	ConsumerCharityCampaignView:{
		charityId:"String",
		charityName:"String",
		charityLogo:"ImageAsset",
		id:"String",
		campaignTitle:"String",
		campaignLogo:"ImageAsset",
		startDate:"Date",
		endDate:"Date",
		goal:"Float",
		progress:"Float",
		goalFormatted:"String",
		progressFormatted:"String",
		percentageProgress:"Float",
		description:"String",
		map:"CampaignMapView"
	},
	ConsumerSearchItemResult:{
		relevance:"Float",
		item:"Item"
	},
	ConsumerSearchPartnerResult:{
		relevance:"Float",
		item:"Restaurant"
	},
	ConsumerSearchResult:{
		query:"String",
		location:"UnifiedLocationType",
		partners:"ConsumerSearchPartnerResult",
		items:"ConsumerSearchItemResult",
		tags:"ConsumerSearchTagResult"
	},
	ConsumerSearchTagResult:{
		relevance:"Float",
		item:"PartnerTag"
	},
	ContainerCode:{
		id:"String",
		createdAt:"Date"
	},
	CoreSettings:{
		id:"CoreConfigurations",
		displayName:"String",
		enableMultiPartnerOrders:"Boolean",
		url:"String",
		fulfillmentMethods:"FulfillmentMethod",
		fulfillmentTimeInterval:"Float",
		theme:"CoreSettingsTheme"
	},
	CoreSettingsTheme:{
		colors:"CoreSettingsThemeColors",
		font:"String"
	},
	CoreSettingsThemeColors:{
		PRIMARY:"String",
		SECONDARY:"String",
		TEXT:"String",
		DARK:"String"
	},
	Customer:{
		id:"String",
		name:"String",
		email:"String",
		created:"Date",
		phoneNumber:"String",
		isPhoneNumberVerified:"Boolean",
		emailConfirmationTokenIssued:"Date",
		emailConfirmed:"Boolean",
		roles:"String",
		profileImageKey:"String",
		marketing:"Boolean",
		referralLink:"String",
		referredBy:"Customer",
		referrals:"Customer",
		stripeCustomerId:"String",
		addresses:"Address",
		orders:"Order",
		paymentMethods:"PaymentMethod",
		orderStatistics:"CustomerOrderStatistics",
		accountCredit:"Int",
		vouchers:"CustomerVoucher",
		topups:"CustomerWalletTopup"
	},
	CustomerAnalytics:{
		id:"String",
		layoutGroups:"ChartLayoutGroup"
	},
	CustomerList:{
		items:"Customer",
		total:"Int",
		hasMore:"Boolean"
	},
	CustomerOrderStatistics:{
		metres:"Float",
		emissionsSaved:"Float",
		lastUpdated:"Date"
	},
	CustomerReusables:{
		id:"String",
		containersNotReturned:"Float",
		containersClaimedReturned:"Float",
		containersValidatedReturned:"Float",
		containersScheduled:"Float",
		containersScannedButNotScheduled:"Float",
		maxContainersSchedulable:"Int",
		totalDeposits:"Int",
		slots:"ReusablesPickupScheduleTime",
		pickups:"ReusablesPickup",
		activePickup:"ReusablesPickup"
	},
	CustomerVoucher:{
		id:"String",
		created:"Date",
		used:"Boolean",
		discount:"Discount"
	},
	CustomerWalletTopup:{
		id:"String",
		createdAt:"Date",
		note:"String",
		amount:"Int",
		paymentIntentId:"String",
		customer:"Customer"
	},
	CustomerWalletTopupList:{
		items:"CustomerWalletTopup",
		total:"Int",
		hasMore:"Boolean"
	},
	DeliveryFlightPlan:{
		id:"String",
		createdAt:"Date",
		completedAt:"Date",
		route:"JSON",
		riderId:"String",
		legs:"DeliveryFlightPlanLeg",
		activeOffer:"FlightPlanOffer"
	},
	DeliveryFlightPlanLeg:{
		id:"String",
		legOrder:"Float",
		order:"Order",
		address:"Address"
	},
	DeliveryTimeArgs:{
		day:"DeliveryTimeDay",
		time:"HourMinuteTimeString"
	},
	DeliveryTimes:{
		date:"String",
		slots:"Date"
	},
	Discount:{
		id:"String",
		code:"String",
		description:"String",
		value:"Float",
		minimumTotalPrice:"Float",
		type:"DiscountType",
		enabled:"Boolean",
		validFrom:"Date",
		validUntil:"Date",
		maxUsesGlobally:"Float",
		maxUsesPerCustomer:"Float",
		referralAmount:"Float",
		orders:"OrderList",
		riderReferral:"RiderReferralDiscount"
	},
	DiscountList:{
		items:"Discount",
		total:"Int",
		hasMore:"Boolean"
	},
	DistanceModel:{
		metres:"Float",
		seconds:"Float"
	},
	DynamicForm:{
		id:"String",
		type:"DynamicFormType",
		title:"String",
		pollInterval:"Int",
		canSubmit:"Boolean",
		submitText:"String",
		description:"String",
		initialValues:"JSON",
		schema:"JSON"
	},
	DynamicFormResult:{
		success:"Boolean",
		successMessage:"String",
		form:"DynamicForm"
	},
	ExternalCompetitor:{
		name:"String",
		id:"String",
		updated:"Date",
		raw:"JSON"
	},
	File:{
		id:"String",
		createdAt:"Date",
		type:"String",
		title:"String",
		size:"Int",
		url:"String"
	},
	FileList:{
		items:"File",
		total:"Int",
		hasMore:"Boolean"
	},
	FinancialOverview:{
		meta:"FinancialOverviewArgsMeta",
		partners:"RestaurantFinancialsView",
		riders:"SpecificRiderFinancials",
		charities:"CharityFinancials"
	},
	FinancialOverviewArgsMeta:{
		startDate:"Date",
		endDate:"Date",
		zoneIds:"String"
	},
	FlightPlanOffer:{
		id:"String",
		offered:"Date",
		changed:"Date",
		outcome:"String",
		fee:"Float",
		sneaky:"Boolean",
		context:"JSON",
		legs:"DeliveryFlightPlanLeg",
		orders:"Order",
		riderId:"String",
		rider:"Rider",
		flightPlanId:"String",
		flightPlan:"DeliveryFlightPlan"
	},
	FlightPlanOfferList:{
		items:"FlightPlanOffer",
		total:"Int",
		hasMore:"Boolean"
	},
	FulfillmentDayTimes:{
		day:"DeliveryTimeDay",
		times:"HourMinuteTimeString"
	},
	GenericAuditView:{
		id:"String",
		timestamp:"Date",
		targetId:"String",
		from:"JSON",
		to:"JSON"
	},
	GraphQLGeoJSONLineString:{
		type:"String",
		coordinates:"Coordinates"
	},
	GraphQLGeoJSONPoint:{
		type:"String",
		coordinates:"Coordinates"
	},
	GraphQLGeoJSONPolygon:{
		type:"String",
		coordinates:"Coordinates"
	},
	HomePage:{
		meta:"PartnerHomePageMeta",
		promotions:"HomepagePromotion",
		selections:"PartnerSelection",
		partners:"Restaurant",
		tags:"PartnerTag",
		zone:"Zone",
		fulfillmentTimes:"FulfillmentDayTimes",
		itemGroups:"PartnerHomePageItemGroup"
	},
	HomepagePromotion:{
		id:"String",
		body:"String",
		title:"String",
		color:"String",
		imageKey:"String",
		callToActionText:"String",
		target:"String",
		targetType:"PromotionTarget"
	},
	Hygiene:{
		id:"String",
		updated:"Date",
		rating:"String",
		authority:"HygieneAuthorities",
		authorityRatingId:"String",
		authorityRatingLink:"String",
		authorityRatingImage:"String",
		restaurant:"Restaurant"
	},
	ImageAsset:{
		id:"String",
		created:"Date",
		updated:"Date",
		title:"String",
		key:"String",
		width:"Int",
		height:"Int",
		fileSize:"Int",
		altText:"String",
		mimeType:"String",
		url:"String",
		uploadedBy:"RestaurantAdmin"
	},
	ImageAssetList:{
		items:"ImageAsset",
		total:"Int",
		hasMore:"Boolean"
	},
	Incident:{
		id:"String",
		created:"Date",
		number:"Float",
		severity:"IncidentSeverity",
		riderId:"String",
		orderId:"String",
		partnerId:"String",
		customerId:"String",
		atFault:"UserType",
		description:"String",
		customer:"Customer",
		order:"Order",
		rider:"Rider",
		partner:"Restaurant",
		audit:"GenericAuditView",
		images:"ImageAsset"
	},
	IncidentList:{
		items:"Incident",
		total:"Int",
		hasMore:"Boolean"
	},
	Item:{
		id:"String",
		isTemplate:"Boolean",
		name:"String",
		description:"String",
		ingredients:"String",
		unitPrice:"Float",
		maxPerOrder:"Float",
		emissions:"Float",
		alcohol:"Boolean",
		vegan:"Boolean",
		vegetarian:"Boolean",
		weight:"Float",
		unavailableFor:"FulfillmentMethod",
		slow:"Boolean",
		approved:"Boolean",
		tax:"Float",
		heat:"ItemHeatType",
		published:"Boolean",
		popular:"Boolean",
		headerImageKey:"String",
		blurhash:"String",
		sortOrder:"Float",
		available:"Boolean",
		deletedAt:"Date",
		categoryId:"String",
		category:"MenuCategory",
		modifiers:"ModifierGroup",
		optionFor:"ModifierGroup",
		containerTypeId:"String",
		price:"Price",
		headerImage:"String",
		squareImage:"String",
		menu:"Menu",
		containerType:"ReusableContainerType"
	},
	ItemList:{
		items:"Item",
		total:"Int",
		hasMore:"Boolean"
	},
	ItemSales:{
		item:"Item",
		numberSold:"Int",
		totalValue:"Int"
	},
	ItemUpdateRequest:{
		id:"String",
		created:"Date",
		status:"ItemUpdateRequestStatus",
		name:"String",
		description:"String",
		imageKey:"String",
		item:"Item",
		partner:"Restaurant"
	},
	ItemUpdateRequestList:{
		items:"ItemUpdateRequest",
		total:"Int",
		hasMore:"Boolean"
	},
	Lease:{
		id:"String",
		start:"Date",
		end:"Date",
		initialMileage:"Float",
		weeklyMileageAllowance:"Float",
		excessiveMileageCharge:"Float",
		totalDeposit:"Float",
		initialDeposit:"Float",
		depositGraceWeeks:"Float",
		numberOfDepositWeeks:"Float",
		firstDaysFree:"Float",
		pricePerDay:"Float",
		vehicleId:"String",
		vehicle:"LeaseVehicle",
		riderId:"String",
		rider:"Rider"
	},
	LeaseList:{
		items:"LeaseView",
		total:"Int",
		hasMore:"Boolean"
	},
	LeasePayment:{
		id:"String",
		created:"Date",
		type:"String",
		dueAt:"Date",
		paidAt:"Date",
		leaseId:"String",
		lease:"Lease",
		value:"Float",
		stripePaymentIntentId:"String",
		status:"LeasePaymentStatus"
	},
	LeaseVehicle:{
		id:"String",
		created:"Date",
		note:"String",
		registration:"String",
		status:"LeaseVehicleStatus",
		dvlaInfo:"JSON",
		leases:"Lease"
	},
	LeaseVehicleList:{
		items:"LeaseVehicle",
		total:"Int",
		hasMore:"Boolean"
	},
	LeaseView:{
		id:"String",
		start:"Date",
		end:"Date",
		initialMileage:"Float",
		weeklyMileageAllowance:"Float",
		excessiveMileageCharge:"Float",
		totalDeposit:"Float",
		initialDeposit:"Float",
		depositGraceWeeks:"Float",
		numberOfDepositWeeks:"Float",
		firstDaysFree:"Float",
		pricePerDay:"Float",
		vehicleId:"String",
		vehicle:"LeaseVehicle",
		riderId:"String",
		rider:"Rider",
		totalPaid:"Float",
		outstandingBalance:"Float",
		outstandingDeposit:"Float",
		nextDepositAmount:"Float",
		nextDepositDate:"Date",
		nextWeeklyAmount:"Float",
		nextWeeklyDate:"Date",
		lastDepositPayment:"Date",
		lastWeeklyPayment:"Date",
		payments:"LeasePayment"
	},
	LineChart:{
		id:"String",
		type:"String",
		series:"Series"
	},
	LocationSearchResult:{
		id:"String",
		text:"String",
		provider:"String",
		addressLine1:"String",
		postcode:"String",
		coordinates:"Coordinates"
	},
	MarketingCampaign:{
		id:"String",
		name:"String",
		status:"MarketingCampaignStatus",
		sends:"Int",
		scheduledFor:"Date"
	},
	MarketingCampaignList:{
		items:"MarketingCampaign",
		total:"Int",
		hasMore:"Boolean"
	},
	Menu:{
		id:"String",
		isTemplate:"Boolean",
		name:"String",
		items:"Item",
		modifierGroups:"ModifierGroup",
		categories:"MenuCategory",
		restaurant:"Restaurant"
	},
	MenuCategory:{
		id:"String",
		name:"String",
		description:"String",
		sortOrder:"Float",
		topLevel:"Boolean",
		items:"Item",
		times:"OpeningTime",
		menu:"Menu"
	},
	MerchantSite:{
		id:"String",
		name:"String",
		description:"String",
		status:"MerchantSiteStatus",
		domains:"MerchantSiteDomain",
		pages:"MerchantSitePage",
		screenshot:"ImageAsset"
	},
	MerchantSiteDomain:{
		id:"String",
		createdAt:"Date",
		domain:"String",
		isVerified:"Boolean",
		dns:"String"
	},
	MerchantSiteList:{
		items:"MerchantSite",
		total:"Int",
		hasMore:"Boolean"
	},
	MerchantSitePage:{
		id:"String",
		slug:"String",
		title:"String",
		layout:"JSON",
		screenshot:"ImageAsset",
		site:"MerchantSite"
	},
	MessageParticipant:{
		id:"String",
		type:"UserType",
		name:"String",
		email:"String",
		avatarUrl:"String"
	},
	ModifierGroup:{
		id:"String",
		name:"String",
		instruction:"String",
		maxOptions:"Float",
		minOptions:"Float",
		maxPerOption:"Float",
		cascades:"Boolean",
		sortOrder:"Float",
		priceStrategy:"PriceStrategy",
		options:"Item",
		optionFor:"Item",
		menuId:"String"
	},
	Mutation:{
		submitDynamicForm:"DynamicFormResult",
		updateCustomer:"Customer",
		deleteCustomer:"Boolean",
		addPaymentMethod:"Boolean",
		addPaymentMethodWithCard:"Boolean",
		addVoucherCode:"Customer",
		removePaymentMethod:"Boolean",
		addAddress:"Address",
		placeOrder:"PlaceOrderResponse",
		updateOrder:"Order",
		rateOrder:"Boolean",
		OrderTipRider:"Order",
		adjustOrder:"Order",
		acceptOrder:"Order",
		acceptOrderAsRestaurant:"Boolean",
		completeOrderAsRestaurant:"Boolean",
		rejectOrderAsRestaurant:"Boolean",
		arriveForOwnOrder:"Boolean",
		pickUpOwnOrder:"Boolean",
		delayPickup:"Order",
		blockPickup:"Order",
		OrderRemoveRiderAsAdmin:"Order",
		OrderCompleteAsAdmin:"Order",
		OrderBringForward:"Order",
		rescheduleOrder:"Order",
		resolveOrderAlert:"OrderAlert",
		PurchaseWalletTopup:"PurchaseCustomerWalletTopup",
		setAvailability:"Item",
		setItemAvailability:"Item",
		updateItemHeaderImage:"Item",
		removeItemHeaderImage:"Item",
		updateItem:"Item",
		deleteItem:"Item",
		updateItemAsRestaurant:"Item",
		basketApplyDiscount:"Basket",
		basketAddItem:"Basket",
		basketIncrementItem:"Basket",
		basketDecrementItem:"Basket",
		basketEditItem:"Basket",
		basketIncrementOrDecrementTip:"Basket",
		basketSetTip:"Basket",
		basketSetNotes:"Boolean",
		basketSetInstructions:"Basket",
		basketCheckOut:"Basket",
		basketSetSingleDelivery:"Basket",
		basketSetReusables:"Basket",
		basketDonationAdd:"Basket",
		basketDonationRemove:"Basket",
		updateCharity:"Charity",
		createCharity:"Charity",
		customerCharityUIView:"ConsumerCharityCampaignView",
		createCharityCampaign:"CharityCampaign",
		updateCharityCampaign:"CharityCampaign",
		SwapContainerCode:"ReusableContainer",
		ScanContainerAtPartner:"ScanContainerAtPartnerResponse",
		ScanContainer:"CustomerReusables",
		CancelContainerPickup:"CustomerReusables",
		UpdateContainerPickup:"ReusablesPickup",
		BookContainerPickup:"CustomerReusables",
		AssignReusableShipment:"ReusableShipment",
		CompleteReusableShipment:"ReusableShipment",
		createRestaurant:"Restaurant",
		updateRestaurant:"Restaurant",
		setFulfillmentMethods:"Restaurant",
		setAcceptsScheduledOrders:"Restaurant",
		updateRestaurantHeaderImage:"Restaurant",
		setBusy:"Restaurant",
		setOnline:"Boolean",
		setOwner:"Boolean",
		markItemsUnavailable:"Boolean",
		createPartnerTag:"PartnerTag",
		updatePartnerTag:"PartnerTag",
		deletePartnerTag:"Boolean",
		PartnerTabletSync:"PartnerTablet",
		PartnerTabletAction:"PartnerTablet",
		addItem:"Item",
		addCategory:"Menu",
		reorderCategory:"MenuCategory",
		reorderModifierGroups:"Menu",
		reorderCategories:"Menu",
		addModifierGroup:"Menu",
		importMenu:"Boolean",
		updateCategoryAsRestaurant:"MenuCategory",
		createItemUpdateRequest:"ItemUpdateRequest",
		updateItemUpdateRequest:"ItemUpdateRequest",
		updateRestaurantAdmin:"RestaurantAdmin",
		createSuperAdmin:"RestaurantAdmin",
		updateTabletAccountPassword:"Boolean",
		createZone:"Zone",
		boostZone:"Boost",
		updateZone:"Zone",
		setActivePolygon:"Zone",
		endBoost:"Boost",
		createZonePolygon:"ZonePolygon",
		createRider:"Rider",
		updateRiderLocation:"Rider",
		unthrottleRider:"Rider",
		sendMessageToRider:"Boolean",
		updateRider:"Rider",
		requestNotification:"Boolean",
		endRiderOnlineSession:"RiderOnlineSession",
		deleteRiderOnlineSession:"Boolean",
		createRiderApplication:"RiderApplication",
		deleteRiderApplication:"Boolean",
		RiderLiveUIValidate:"RiderLiveView",
		RiderLiveUIAction:"RiderLiveView",
		RiderLiveUISetting:"RiderLiveView",
		PayLeasePayment:"LeasePayment",
		sendChatMessage:"ChatMessage",
		login:"AuthDTO",
		loginOAuth:"AuthDTO",
		loginPhone:"AuthDTO",
		setupTwoFactorAuthentication:"TwoFactorSetup",
		confirmTwoFactorAuthentication:"Boolean",
		refreshToken:"AuthDTO",
		logout:"Boolean",
		register:"AuthDTO",
		resetPasswordRequest:"Boolean",
		resetPasswordWithToken:"Boolean",
		requestPhoneNumberVerification:"Boolean",
		verifyPhoneNumberCode:"Boolean",
		setFirebaseCloudMessagingToken:"Boolean",
		requestConfirmationEmail:"Boolean",
		confirmEmailWithToken:"Boolean",
		UserUpdateProfilePicture:"User",
		createDiscount:"Discount",
		updateDiscount:"Discount",
		createRiderReferralDiscount:"RiderReferralDiscount",
		updateAddress:"Address",
		createAddress:"Address",
		deleteAddress:"Boolean",
		makeBooking:"Booking",
		cancelBooking:"Booking",
		updateBooking:"Booking",
		decideBooking:"Booking",
		createBookingSlot:"BookingSlot",
		updateBookingSlot:"BookingSlot",
		deleteBookingSlot:"Boolean",
		autoCreateBookingSlots:"Boolean",
		AutoBookingSlotsConfigList:"AutoBookingSlotsConfigList",
		createAutoBookingConfig:"AutoBookingSlotsConfig",
		deleteAutoBookingSlot:"AutoBookingSlotsConfig",
		createAutoBookingSlot:"AutoBookingSlot",
		createCity:"City",
		exportOrders:"String",
		riderInvoice:"RiderInvoice",
		payRiderInvoice:"RiderInvoice",
		payRiderInvoices:"RiderInvoicesJob",
		PartnerInvoiceForPeriod:"PartnerInvoice",
		payPartnerInvoice:"PartnerInvoice",
		updatePartnerPaymentDetails:"PartnerPaymentDetails",
		createRiderAdjustment:"RiderAdjustment",
		updateRiderAdjustment:"RiderAdjustment",
		createPartnerAdjustment:"PartnerAdjustment",
		updatePartnerAdjustment:"PartnerAdjustment",
		uploadPromotionImage:"Promotion",
		createPromotion:"Promotion",
		updatePromotion:"Promotion",
		estimateB2BJob:"B2BJobEstimateResponse",
		executeB2BJob:"B2BDeliveryJob",
		cancelB2BJob:"B2BDeliveryJob",
		createIncident:"Incident",
		updateIncident:"Incident",
		uploadImageAsset:"ImageAsset",
		UploadVideoAsset:"UploadMediaJob",
		UploadMediaComplete:"VideoAsset",
		AdminPageView:"AdminPageView",
		createMerchantSitePage:"MerchantSitePage",
		updateMerchantSitePage:"MerchantSitePage",
		createMerchantSiteDomain:"MerchantSiteDomain"
	},
	OAuthConfiguration:{
		apple:"OAuthConfigurationApple",
		google:"OAuthConfigurationGoogle",
		facebook:"OAuthConfigurationFacebook"
	},
	OAuthConfigurationApple:{
		clientId:"String",
		redirectURI:"String"
	},
	OAuthConfigurationFacebook:{
		appId:"String"
	},
	OAuthConfigurationGoogle:{
		clientId:"String",
		redirectURI:"String"
	},
	OpeningTime:{
		id:"String",
		open:"String",
		close:"String",
		daysOfWeek:"DayOfWeek",
		restaurant:"Restaurant",
		menuCategory:"MenuCategory",
		zone:"Zone"
	},
	Order:{
		id:"String",
		number:"String",
		fulfilledBy:"OrderFulfilledBy",
		placedAt:"Date",
		partnerAcknowlegedAt:"Date",
		scheduledFor:"Date",
		initialPickupAt:"Date",
		pickupAt:"Date",
		pickupDelayedByPartner:"Boolean",
		pickedDelayedByAdminAt:"Date",
		orderMarkedReadyAt:"Date",
		status:"String",
		opened:"Boolean",
		allergyNotes:"String",
		internalNote:"String",
		riderEarnings:"Float",
		baseRiderEarnings:"Float",
		deliveryEstimate:"Float",
		deliveryEstimateMax:"Float",
		estimatedArrivalTime:"Date",
		estimatedArrivalTimeDelta:"Float",
		stripeFee:"Float",
		fulfillmentMethod:"FulfillmentMethod",
		ourAdjustment:"Int",
		ourAdjustmentJustification:"String",
		partnerAdjustment:"Int",
		partnerAdjustmentJustification:"String",
		riderEarningsAdjustment:"Int",
		riderEarningsAdjustmentJustification:"String",
		riderTipAdjustment:"Int",
		riderTipAdjustmentJustification:"String",
		orderType:"OrderType",
		sizePoints:"Float",
		numberOfChildOrders:"Float",
		childOrders:"Order",
		parentOrderId:"String",
		parentOrder:"Order",
		customerLastRemindedToPickUp:"Date",
		isBeingOfferedToRider:"Boolean",
		lastOfferedToRider:"Date",
		isRiderAtRestaurant:"Boolean",
		riderArrivedAtRestaurantTime:"Date",
		riderPickedUpTime:"Date",
		riderArrivedAtCustomerTime:"Date",
		riderDroppedOffTime:"Date",
		pickupBlocked:"Boolean",
		longWaitReportedAt:"Date",
		riderRating:"Int",
		partnerRating:"Int",
		amountRefunded:"Float",
		cancellationReason:"OrderCancellationReason",
		basket:"Basket",
		customer:"Customer",
		rider:"Rider",
		addressId:"String",
		address:"Address",
		restaurant:"Restaurant",
		offers:"OrderOffer",
		b2bDeliveryJob:"B2BDeliveryJob",
		b2bDeliveryJobId:"String",
		aggregateOrder:"AggregateOrder",
		unreadMessageCount:"Int",
		canTipRider:"Boolean",
		reusables:"ReusablesInfo",
		directions:"String",
		reusablesPickupAggregate:"ReusablesPickupAggregate",
		riderReport:"OrderRiderRating",
		reusableShipment:"ReusableShipment"
	},
	OrderAlert:{
		id:"String",
		type:"OrderAlertType",
		created:"Date",
		resolved:"Date",
		resolvedBy:"RestaurantAdmin",
		riderId:"String",
		rider:"Rider",
		orderId:"String",
		order:"Order",
		zoneId:"String",
		zone:"Zone"
	},
	OrderAlertList:{
		items:"OrderAlert",
		total:"Int",
		hasMore:"Boolean"
	},
	OrderEvent:{
		id:"String",
		timestamp:"Date",
		targetId:"String",
		from:"JSON",
		to:"JSON"
	},
	OrderEventList:{
		items:"OrderEvent",
		total:"Int",
		hasMore:"Boolean"
	},
	OrderItem:{
		id:"String",
		added:"Date",
		name:"String",
		description:"String",
		quantity:"Float",
		unitPrice:"Float",
		unitWeight:"Float",
		totalPrice:"Float",
		alcohol:"Boolean",
		modifierItems:"OrderItemModifier",
		menuItemId:"String",
		basketId:"String",
		basket:"Basket"
	},
	OrderItemModifier:{
		id:"String",
		name:"String",
		quantity:"Float",
		unitPrice:"Float",
		unitWeight:"Float",
		menuItemId:"String",
		modifierGroupId:"String",
		modifierGroup:"ModifierGroup",
		orderItemId:"String",
		orderItem:"OrderItem"
	},
	OrderList:{
		items:"Order",
		total:"Int",
		hasMore:"Boolean"
	},
	OrderOffer:{
		id:"String",
		offered:"Date",
		changed:"Date",
		outcome:"String",
		distance:"Float",
		riderId:"String",
		rider:"Rider",
		orderId:"String",
		order:"Order"
	},
	OrderRiderRating:{
		positive:"Boolean",
		ratings:"String",
		note:"String",
		id:"String",
		order:"Order",
		rider:"Rider"
	},
	OrderRiderRatingList:{
		items:"OrderRiderRating",
		total:"Int",
		hasMore:"Boolean"
	},
	PartnerAdjustment:{
		id:"String",
		created:"Date",
		forDate:"Date",
		amount:"Float",
		explanation:"String",
		partnerId:"String",
		partner:"Restaurant"
	},
	PartnerAdjustmentList:{
		items:"PartnerAdjustment",
		total:"Int",
		hasMore:"Boolean"
	},
	PartnerAnalytics:{
		id:"String",
		meta:"PartnerAnalyticsMeta",
		orderCount:"Float",
		prepTimes:"PartnerPrepTimeAnalytics",
		waitTimes:"PercentileValue",
		layoutGroups:"ChartLayoutGroup"
	},
	PartnerAnalyticsMeta:{
		id:"String",
		startDate:"Date",
		dayOfWeek:"Float",
		endDate:"Date",
		timeRange:"String"
	},
	PartnerCommissionPlan:{
		id:"String",
		created:"Date",
		ended:"Date",
		plan:"CommissionPlan",
		rung:"CommissionPlanRung",
		partner:"Restaurant"
	},
	PartnerHomePageItemGroup:{
		id:"String",
		title:"String",
		items:"Item"
	},
	PartnerHomePageMeta:{
		skip:"Int",
		take:"Int",
		lat:"Float",
		lng:"Float",
		zoneId:"String",
		zoneSlug:"String",
		fulfillmentMethod:"FulfillmentMethod",
		query:"String",
		includeClosed:"Boolean",
		tags:"String",
		sortBy:"String",
		order:"String",
		coreConfiguration:"CoreConfigurations",
		time:"DeliveryTimeArgs"
	},
	PartnerInvoice:{
		id:"String",
		paid:"Boolean",
		value:"Float",
		start:"Date",
		end:"Date",
		numberOfOrders:"Float",
		sales:"Float",
		adjustments:"Float",
		commission:"Float",
		purchases:"Float",
		vat:"Float",
		ref:"String",
		revolutTransactionId:"String",
		revolutTransactionLink:"String",
		partnerId:"String",
		partner:"Restaurant"
	},
	PartnerInvoiceList:{
		items:"PartnerInvoice",
		total:"Int",
		hasMore:"Boolean"
	},
	PartnerPaymentDetails:{
		id:"String",
		accountNo:"String",
		sortCode:"String",
		isCompany:"Boolean",
		individualName:"String",
		restaurant:"Restaurant"
	},
	PartnerPrepTimeAnalytics:{
		percentile:"String",
		value:"Float"
	},
	PartnerSelection:{
		name:"String",
		partners:"Restaurant",
		type:"PartnerSelectionType"
	},
	PartnerTablet:{
		id:"String",
		created:"Date",
		lastConnection:"Date",
		serial:"String",
		model:"String",
		manufacturer:"String",
		battery:"Float",
		release:"String",
		batteryState:"String",
		ram:"Float",
		usedRam:"Float",
		volume:"Float",
		wifiNetwork:"String",
		bssid:"String",
		routerMake:"String",
		wifiStrength:"Float",
		width:"Float",
		height:"Float",
		ip:"String",
		hostname:"String",
		isp:"String",
		location:"GraphQLGeoJSONPoint",
		partnerId:"String",
		partner:"Restaurant",
		webRTCOfferDescription:"JSON",
		webRTCOfferReceiveDescription:"JSON",
		webRTCOfferReceiveCandidates:"JSON",
		webRTCOfferCandidates:"JSON",
		screenshots:"ImageAsset"
	},
	PartnerTabletAction:{
		id:"PartnerTabletActionType",
		tabletId:"String",
		payload:"JSON"
	},
	PartnerTabletList:{
		items:"PartnerTablet",
		total:"Int",
		hasMore:"Boolean"
	},
	PartnerTag:{
		id:"String",
		name:"String",
		image:"String",
		type:"PartnerTagTypes",
		partners:"Restaurant"
	},
	PaymentMethod:{
		id:"String",
		stripeId:"String",
		last4:"String",
		brand:"String",
		exp_month:"Float",
		exp_year:"Float"
	},
	PercentileValue:{
		percentile:"String",
		value:"Float"
	},
	PieChart:{
		id:"String",
		type:"String",
		labels:"String",
		values:"Float"
	},
	PlaceOrderResponse:{
		id:"String",
		stripeNextAction:"JSON",
		stripeClientSecret:"String",
		order:"Order"
	},
	PresignedUploadURL:{
		url:"String",
		method:"String",
		startBytes:"Int",
		endBytes:"Int"
	},
	Price:{
		value:"Float",
		display:"String"
	},
	Promotion:{
		id:"String",
		created:"Date",
		modified:"Date",
		start:"Date",
		end:"Date",
		name:"String",
		title:"String",
		body:"String",
		color:"String",
		imageKey:"String",
		zones:"Zone",
		callToActionText:"String",
		enabled:"Boolean",
		targetType:"PromotionTarget",
		target:"String"
	},
	PromotionList:{
		items:"Promotion",
		total:"Int",
		hasMore:"Boolean"
	},
	PurchaseCustomerWalletTopup:{
		id:"String",
		amount:"Price",
		paymentIntentId:"String",
		paymentIntentSecret:"String"
	},
	Query:{
		Configuration:"Configuration",
		DynamicForm:"DynamicForm",
		CustomerList:"CustomerList",
		Customer:"Customer",
		stripeTokens:"StripeTokens",
		order:"Order",
		OrderList:"OrderList",
		FlightPlanOfferList:"FlightPlanOfferList",
		OrderAlertList:"OrderAlertList",
		OrderAlert:"OrderAlert",
		OrderRiderRatingList:"OrderRiderRatingList",
		OrderEventList:"OrderEventList",
		CustomerWalletTopupList:"CustomerWalletTopupList",
		Item:"Item",
		ItemList:"ItemList",
		BasketList:"BasketList",
		Basket:"Basket",
		basketForRestaurant:"Basket",
		Charity:"Charity",
		CharityList:"CharityList",
		activeCharityCampaigns:"CharityCampaign",
		CharityCampaign:"CharityCampaign",
		CharityCampaignList:"CharityCampaignList",
		ConsumerCharityCampaign:"ConsumerCharityCampaignView",
		ReusableDropBox:"ReusableDropBox",
		ReusableDropBoxList:"ReusableDropBoxList",
		ReusableContainer:"ReusableContainer",
		ReusableContainerList:"ReusableContainerList",
		ReusableContainerType:"ReusableContainerType",
		ReusableContainerTypeList:"ReusableContainerTypeList",
		ScannedContainerList:"ScannedContainerList",
		WashingLocation:"WashingLocation",
		WashingLocationList:"WashingLocationList",
		ReusablesPickup:"ReusablesPickup",
		ReusablesPickupList:"ReusablesPickupList",
		CustomerReusables:"CustomerReusables",
		ReusableShipment:"ReusableShipment",
		ReusableShipmentList:"ReusableShipmentList",
		ReusablesPickupAggregate:"ReusablesPickupAggregate",
		ReusablesPickupAggregateList:"ReusablesPickupAggregateList",
		Restaurant:"Restaurant",
		allRestaurants:"Restaurant",
		RestaurantList:"RestaurantList",
		restaurantHeartbeat:"Boolean",
		activeOrders:"Order",
		PartnerTag:"PartnerTag",
		allPartnerTags:"PartnerTag",
		PartnerTablet:"PartnerTablet",
		PartnerTabletList:"PartnerTabletList",
		ConsumerSearch:"ConsumerSearchResult",
		Menu:"Menu",
		allMenus:"Menu",
		ItemUpdateRequest:"ItemUpdateRequest",
		ItemUpdateRequestList:"ItemUpdateRequestList",
		RestaurantAdminList:"RestaurantAdminList",
		RestaurantAdmin:"RestaurantAdmin",
		tabletForRestaurant:"RestaurantAdmin",
		ZoneList:"ZoneList",
		zone:"Zone",
		zones:"Zone",
		zoneAtPoint:"Zone",
		UpcomingBoosts:"Boost",
		ZoneEventList:"ZoneEventList",
		ZonePolygonList:"ZonePolygonList",
		CommissionPlanList:"CommissionPlanList",
		CommissionPlan:"CommissionPlan",
		Brand:"Brand",
		BrandList:"BrandList",
		PartnerHomepage:"HomePage",
		Rider:"Rider",
		RiderList:"RiderList",
		validateVehicleRegistration:"ValidateRegistrationResult",
		riderOnlineSession:"RiderOnlineSession",
		RiderOnlineSessionList:"RiderOnlineSessionList",
		RiderLiveUI:"RiderLiveView",
		RiderLiveRoute:"RiderLiveRoute",
		Lease:"LeaseView",
		LeaseList:"LeaseList",
		LeaseVehicle:"LeaseVehicle",
		LeaseVehicleList:"LeaseVehicleList",
		ChatMessageList:"ChatMessageList",
		OAuthConfiguration:"OAuthConfiguration",
		me:"UserUnionDTO",
		Discount:"Discount",
		DiscountList:"DiscountList",
		RiderReferralDiscount:"RiderReferralDiscount",
		RiderReferralDiscountList:"RiderReferralDiscountList",
		Address:"Address",
		AddressList:"AddressList",
		allAddresses:"Address",
		LocationSearch:"LocationSearchResult",
		LocationSearchById:"LocationSearchResult",
		BookingMetricsRank:"JSON",
		BookingSlot:"BookingSlot",
		BookingSlotList:"BookingSlotList",
		BookingSlotsAvailableForRider:"BookingSlotRiderViewList",
		countBookingsForSlot:"Int",
		AutoBookingSlotConfig:"AutoBookingSlotsConfig",
		allAnalytics:"AnalyticsDTO",
		PartnerAnalytics:"PartnerAnalytics",
		CustomerAnalytics:"CustomerAnalytics",
		city:"City",
		allCities:"City",
		Team:"TeamMember",
		financialsForRestaurants:"RestaurantFinancialsView",
		financialsForRestaurant:"RestaurantFinancialsView",
		salesForRestaurant:"ItemSales",
		financialsForRider:"SpecificRiderFinancials",
		RiderInvoice:"RiderInvoice",
		allRiderInvoices:"RiderInvoiceList",
		PartnerInvoice:"PartnerInvoice",
		downloadPartnerInvoice:"String",
		allPartnerInvoices:"PartnerInvoiceList",
		PartnerPaymentDetails:"PartnerPaymentDetails",
		RiderAdjustment:"RiderAdjustment",
		RiderAdjustmentList:"RiderAdjustmentList",
		PartnerAdjustment:"PartnerAdjustment",
		PartnerAdjustmentList:"PartnerAdjustmentList",
		FinancialOverview:"FinancialOverview",
		PromotionList:"PromotionList",
		Promotion:"Promotion",
		B2BDeliveryJob:"B2BDeliveryJob",
		B2BDeliveryJobList:"B2BDeliveryJobList",
		IncidentList:"IncidentList",
		Incident:"Incident",
		ImageAsset:"ImageAsset",
		ImageAssetList:"ImageAssetList",
		VideoAsset:"VideoAsset",
		VideoAssetList:"VideoAssetList",
		MarketingCampaignList:"MarketingCampaignList",
		MerchantSite:"MerchantSite",
		MerchantSiteList:"MerchantSiteList",
		MerchantSitePage:"MerchantSitePage",
		FileList:"FileList"
	},
	Restaurant:{
		id:"String",
		configuration:"CoreConfigurations",
		name:"String",
		slug:"String",
		branch:"String",
		priceCategory:"String",
		blurhash:"String",
		headerImageKey:"String",
		ratingStars:"Float",
		ratingCount:"Float",
		phoneNumber:"String",
		allergyWarnings:"String",
		acceptsAllergyNotes:"Boolean",
		autoAccept:"Boolean",
		commission:"Float",
		commissionCollection:"Float",
		onboardingStatus:"RestaurantOnboardingStatus",
		fulfillmentMethods:"FulfillmentMethod",
		busyStatus:"RestaurantBusyStatus",
		online:"Boolean",
		baseDeliveryTime:"Float",
		basePrepOrderThreshold:"Float",
		basePrepTime:"Float",
		basePrepTimeDelta:"Float",
		incrementPrepTime:"Float",
		currentPrepTime:"Float",
		acceptsPreOrders:"Boolean",
		acceptsScheduledOrders:"Boolean",
		deliveryTimes:"FulfillmentDayTimes",
		largeOrderSize:"Float",
		minimumOrderSize:"Float",
		minimumOrderValue:"JSON",
		deliveryTippingEnabled:"Boolean",
		lastOnline:"Date",
		minimumBasket:"Float",
		assignmentBias:"AssignmentBias",
		exclusive:"Boolean",
		maxDeliveryDistanceMetres:"Float",
		isReusablesEnabled:"Boolean",
		openingTimes:"OpeningTime",
		menuId:"String",
		menu:"Menu",
		zoneId:"String",
		zone:"Zone",
		owner:"RestaurantAdmin",
		hygiene:"Hygiene",
		hygieneId:"String",
		tags:"PartnerTag",
		commissionPlans:"PartnerCommissionPlan",
		activeCommissionPlan:"PartnerCommissionPlan",
		fulfillmentTimes:"FulfillmentDayTimes",
		currentDeliveryTime:"Float",
		currentDeliveryTimeDelta:"Float",
		distance:"String",
		headerImage:"String",
		open:"Boolean",
		isOnVacation:"Boolean",
		comingSoon:"Boolean",
		external:"ExternalCompetitor",
		invoices:"PartnerInvoice",
		address:"Address",
		brand:"Brand",
		headerVideo:"VideoAsset",
		washingLocation:"WashingLocation",
		nextOpeningTime:"Date",
		description:"String"
	},
	RestaurantAdmin:{
		id:"String",
		name:"String",
		email:"String",
		created:"Date",
		phoneNumber:"String",
		isPhoneNumberVerified:"Boolean",
		emailConfirmationTokenIssued:"Date",
		emailConfirmed:"Boolean",
		roles:"String",
		profileImageKey:"String",
		administeredRestaurant:"Restaurant",
		restaurants:"Restaurant",
		linkedCustomerAccount:"Customer",
		b2bJobs:"B2BDeliveryJob"
	},
	RestaurantAdminList:{
		items:"RestaurantAdmin",
		total:"Int",
		hasMore:"Boolean"
	},
	RestaurantFinancialsView:{
		restaurantId:"String",
		restaurantSalesTotal:"Float",
		deliveryFeeTotal:"Float",
		discountsTotal:"Float",
		accountCreditTotal:"Float",
		smallOrderFeeTotal:"Float",
		overallTotal:"Float",
		riderEarnings:"Float",
		riderTips:"Float",
		numberOfOrders:"Float",
		commissionPercent:"Float",
		taxPercent:"Float",
		commissionBeforeAddedTax:"Float",
		taxFromCommission:"Float",
		commissionTotal:"Float",
		toPayRestaurant:"Float",
		ourProfit:"Float",
		amountRefunded:"Float",
		adjustmentsTotal:"Float",
		stripeFeeTotal:"Float",
		donationToRestaurantTotal:"Float",
		donationToCharityTotal:"Float",
		purchasesFromUsTotal:"Float",
		restaurant:"Restaurant"
	},
	RestaurantList:{
		items:"Restaurant",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusableContainer:{
		id:"String",
		createdAt:"Date",
		type:"ReusableContainerType",
		uris:"String",
		aliases:"ContainerCode"
	},
	ReusableContainerList:{
		items:"ReusableContainer",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusableContainerType:{
		id:"String",
		name:"String",
		depth:"Float",
		length:"Float",
		width:"Float",
		unitPrice:"Float",
		image:"ImageAsset",
		inStock:"Boolean"
	},
	ReusableContainerTypeList:{
		items:"ReusableContainerType",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusableDropBox:{
		id:"String",
		name:"String",
		notes:"String",
		status:"DropBoxStatus",
		depth:"Float",
		length:"Float",
		width:"Float",
		address:"Address"
	},
	ReusableDropBoxList:{
		items:"ReusableDropBox",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusablePickupTracking:{
		coordinates:"Coordinates",
		etaEarliest:"Date",
		etaLatest:"Date",
		stopsAway:"Float"
	},
	ReusableShipment:{
		id:"String",
		createdAt:"Date",
		status:"ReusableShipmentStatus",
		scheduledFor:"Date",
		estimatedArrivalTime:"Date",
		washingLocation:"WashingLocation",
		partner:"Restaurant",
		containers:"ShipmentContainer",
		orders:"Order",
		activeOrder:"Order",
		canScheduleOrder:"Boolean"
	},
	ReusableShipmentList:{
		items:"ReusableShipment",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusablesInfo:{
		id:"String",
		deposit:"Int",
		fee:"Int",
		predictionCount:"Float",
		partnerCount:"Float",
		riderCount:"Float",
		customerCount:"Float"
	},
	ReusablesPickup:{
		id:"String",
		type:"ReusablesPickupType",
		status:"ReusablesPickupStatus",
		refundedDeposit:"Int",
		pickupTimeEarliest:"Date",
		pickupTimeLatest:"Date",
		customerImageKey:"String",
		customerCount:"Int",
		riderCount:"Int",
		address:"Address",
		customer:"Customer",
		aggregate:"ReusablesPickupAggregate",
		canReschedule:"Boolean",
		formattedQuantity:"String",
		tracking:"ReusablePickupTracking"
	},
	ReusablesPickupAggregate:{
		id:"String",
		createdAt:"Date",
		pickups:"ReusablesPickup",
		washingLocation:"WashingLocation",
		orders:"Order"
	},
	ReusablesPickupAggregateList:{
		items:"ReusablesPickupAggregate",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusablesPickupList:{
		items:"ReusablesPickup",
		total:"Int",
		hasMore:"Boolean"
	},
	ReusablesPickupScheduleTime:{
		slot:"TimeSlot",
		depositReturn:"Price"
	},
	Rider:{
		id:"String",
		name:"String",
		email:"String",
		created:"Date",
		phoneNumber:"String",
		isPhoneNumberVerified:"Boolean",
		emailConfirmationTokenIssued:"Date",
		emailConfirmed:"Boolean",
		roles:"String",
		profileImageKey:"String",
		application:"RiderApplication",
		lastOnline:"Date",
		online:"Boolean",
		throttledUntil:"Date",
		position:"GraphQLGeoJSONPoint",
		note:"String",
		activationStatus:"String",
		vehicle:"RiderVehicle",
		carRegistration:"String",
		activeFlightPlan:"DeliveryFlightPlan",
		zone:"Zone",
		orders:"Order",
		invoices:"RiderInvoice",
		bookings:"BookingList",
		vehicleDetails:"RiderVehicleDetails",
		activeBooking:"Booking",
		accountNo:"String",
		sortCode:"String",
		stripeSetupIntentId:"String",
		statistics:"RiderStatistics",
		offers:"OrderOffer",
		address:"Address",
		shippingAddress:"Address"
	},
	RiderAddressView:{
		id:"String",
		title:"String",
		type:"RiderAddressType",
		formatted:"String",
		instructions:"String",
		phoneNumber:"String",
		coordinates:"Coordinates",
		instructionImageKey:"String"
	},
	RiderAdjustment:{
		id:"String",
		created:"Date",
		forDate:"Date",
		amount:"Float",
		explanation:"String",
		riderId:"String",
		rider:"Rider"
	},
	RiderAdjustmentList:{
		items:"RiderAdjustment",
		total:"Int",
		hasMore:"Boolean"
	},
	RiderApplication:{
		id:"String",
		created:"Date",
		updated:"Date",
		documentType:"IdentityDocumentType",
		compareFaces:"JSON",
		rekognitionLabels:"JSON",
		imageUrl:"String"
	},
	RiderInvoice:{
		id:"String",
		paid:"Boolean",
		value:"Float",
		start:"Date",
		end:"Date",
		orders:"Float",
		bookingExtra:"Float",
		referralExtra:"Float",
		leaseDeduction:"Float",
		tips:"Float",
		earnings:"Float",
		adjustments:"Float",
		revolutTransactionId:"String",
		revolutTransactionLink:"String",
		riderId:"String",
		rider:"Rider",
		url:"String"
	},
	RiderInvoiceList:{
		items:"RiderInvoice",
		total:"Int",
		hasMore:"Boolean"
	},
	RiderInvoicesJob:{
		id:"String",
		count:"Float"
	},
	RiderJobView:{
		feeFormatted:"String",
		feeModifiedExplanation:"String",
		orders:"RiderOrderView",
		origin:"RiderAddressView",
		currentDestination:"RiderAddressView",
		directions:"RiderLiveRoute"
	},
	RiderList:{
		items:"Rider",
		total:"Int",
		hasMore:"Boolean"
	},
	RiderLiveRoute:{
		id:"String",
		steps:"RiderLiveRouteStep",
		geometry:"String",
		distance:"Float",
		duration:"Float"
	},
	RiderLiveRouteStep:{
		id:"String",
		geometry:"String"
	},
	RiderLiveView:{
		id:"String",
		action:"RiderUIAction",
		layoutType:"RiderUILayoutType",
		banners:"RiderUIBanner",
		job:"RiderJobView",
		support:"RiderSupportView",
		offer:"RiderOfferView",
		settings:"RiderSettingsView"
	},
	RiderOfferView:{
		id:"String",
		offeredAt:"Date",
		offeredUntil:"Date",
		feeFormatted:"String",
		origins:"RiderAddressView",
		destinations:"RiderAddressView"
	},
	RiderOnlineSession:{
		id:"String",
		riderId:"String",
		rider:"Rider",
		updated:"Date",
		start:"Date",
		end:"Date",
		path:"GraphQLGeoJSONLineString",
		initialPoint:"GraphQLGeoJSONPoint"
	},
	RiderOnlineSessionList:{
		items:"RiderOnlineSession",
		total:"Int",
		hasMore:"Boolean"
	},
	RiderOrderItemView:{
		id:"String",
		name:"String",
		quantity:"Float"
	},
	RiderOrderSupportView:{
		actions:"RiderUIAction"
	},
	RiderOrderView:{
		id:"String",
		number:"String",
		isReadyToCollect:"Boolean",
		estimatedReadyAt:"Date",
		tipFormatted:"String",
		numberOfItems:"Float",
		items:"RiderOrderItemView",
		origin:"RiderAddressView",
		destination:"RiderAddressView",
		support:"RiderOrderSupportView"
	},
	RiderReferralDiscount:{
		id:"String",
		riderId:"String",
		discountId:"String",
		associationDate:"Date",
		discount:"Discount",
		rider:"Rider"
	},
	RiderReferralDiscountList:{
		items:"RiderReferralDiscount",
		total:"Int",
		hasMore:"Boolean"
	},
	RiderSettingsView:{
		id:"String",
		initialValue:"JSON",
		schema:"JSON"
	},
	RiderSlotStats:{
		id:"String",
		riderId:"String",
		bookingId:"String",
		bookingSlotId:"String",
		slotType:"BookingSlotType",
		slotStart:"Date",
		slotEnd:"Date",
		ordersAccepted:"Float",
		ordersOffered:"Float",
		maxMissedOrders:"Float",
		sessionSpans:"String",
		attended:"BookingStatus",
		onlinePercentage:"Float",
		minAttendancePercentage:"Float",
		guaranteedRiderEarnings:"Float",
		orderRiderEarnings:"Float",
		riderOwedExtra:"Float"
	},
	RiderStatistics:{
		riderId:"String",
		acceptedOrders:"Float",
		rejectedOrders:"Float",
		timedoutOrders:"Float",
		averageRating:"Float"
	},
	RiderSupportView:{
		supportURL:"String",
		supportPhoneNumber:"String"
	},
	RiderUIAction:{
		id:"RiderUIActions",
		maxDistanceInMetres:"Float",
		allowMaxDistanceOverride:"Boolean",
		validationPrompt:"RiderUIActionValidationPrompt",
		areYouSure:"String",
		orderId:"String",
		disabled:"Boolean",
		color:"RiderUIActionColour",
		title:"String",
		explanation:"String",
		text:"String",
		extraPayload:"JSON"
	},
	RiderUIActionValidationPrompt:{
		id:"String",
		title:"String",
		canBeTemporarilyDismissed:"Boolean",
		validateAge:"RiderUIAgeValidation",
		validateReusables:"RiderUIReusablesCountValidation",
		rateOrderPrompt:"RiderUIRateOrderValidation",
		buttons:"RiderUIButton"
	},
	RiderUIAgeValidation:{
		customerDOBShouldBeLessThanOrEqualTo:"Date"
	},
	RiderUIBanner:{
		id:"String",
		isDismissible:"Boolean",
		title:"String",
		message:"String",
		color:"RiderUIActionColour"
	},
	RiderUIButton:{
		type:"RiderUIButtonType",
		text:"String",
		color:"RiderUIActionColour"
	},
	RiderUIOption:{
		value:"String",
		label:"String"
	},
	RiderUIRateOrderValidation:{
		options:"RiderUIOption"
	},
	RiderUIReusablesCountValidation:{
		customerCountWas:"Int"
	},
	RiderVehicleDetails:{
		id:"String",
		updated:"Date",
		details:"JSON",
		rider:"Rider"
	},
	ScanContainerAtPartnerResponse:{
		success:"Boolean",
		recognisedContainer:"ReusableContainer"
	},
	ScannedContainer:{
		id:"Int",
		containerId:"String",
		scannedAt:"Date",
		imageKey:"String",
		pickupId:"String",
		customerId:"String",
		washingLocationId:"String",
		shipmentContainerId:"String",
		container:"ReusableContainer",
		washingLocation:"WashingLocation"
	},
	ScannedContainerList:{
		items:"ScannedContainer",
		total:"Int",
		hasMore:"Boolean"
	},
	Series:{
		label:"String",
		x_values:"String",
		y_values:"Float"
	},
	ShipmentContainer:{
		quantity:"Float",
		containerType:"ReusableContainerType",
		containersScannedSoFar:"Int"
	},
	SpecificRiderFinancials:{
		startDate:"Date",
		endDate:"Date",
		id:"String",
		riderEarningsSum:"Float",
		adjustments:"Float",
		extraFromSlots:"Float",
		extraFromReferrals:"Float",
		riderTips:"Float",
		totalPayout:"Float",
		numberOfOrders:"Float",
		bookingStats:"RiderSlotStats",
		rider:"Rider"
	},
	StatChart:{
		id:"String",
		type:"String",
		label:"String",
		value:"String"
	},
	StripeTokens:{
		addPaymentMethod:"String",
		payOnce:"String"
	},
	Subscription:{
		LiveOrders:"Order",
		OrderAlerts:"OrderAlert",
		PartnerTabletActions:"PartnerTabletAction",
		AdminPageViews:"AdminPageView"
	},
	TableChart:{
		id:"String",
		type:"String",
		title:"String",
		columns:"TableColumn",
		data:"TableRow"
	},
	TableColumn:{
		id:"String",
		title:"String"
	},
	TableRecord:{
		dataIndex:"String",
		formatted:"String",
		value:"JSON"
	},
	TableRow:{
		id:"String",
		records:"TableRecord"
	},
	TeamMember:{
		id:"String",
		name:"String",
		title:"String",
		description:"String",
		imageKey:"String"
	},
	TimeSlot:{
		start:"Date",
		end:"Date"
	},
	TwoFactorSetup:{
		uri:"String"
	},
	UnifiedLocationType:{
		latLng:"GraphQLGeoJSONPoint",
		zoneId:"String",
		geohash:"String"
	},
	UploadMediaJob:{
		id:"String",
		uploadId:"String",
		key:"String",
		urls:"PresignedUploadURL"
	},
	User:{
		id:"String",
		name:"String",
		email:"String",
		created:"Date",
		phoneNumber:"String",
		isPhoneNumberVerified:"Boolean",
		emailConfirmationTokenIssued:"Date",
		emailConfirmed:"Boolean",
		roles:"String",
		profileImageKey:"String"
	},
	UserUnionDTO:{
		"...on Customer":"Customer",
		"...on RestaurantAdmin":"RestaurantAdmin",
		"...on Rider":"Rider"
	},
	UserView:{
		id:"String",
		type:"UserType",
		name:"String",
		email:"String",
		avatarUrl:"String"
	},
	ValidateRegistrationResult:{
		canRide:"Boolean",
		reason:"String",
		registration:"String"
	},
	VideoAsset:{
		id:"String",
		created:"Date",
		updated:"Date",
		title:"String",
		key:"String",
		width:"Int",
		height:"Int",
		fileSize:"Int",
		altText:"String",
		mimeType:"String",
		uploadedBy:"RestaurantAdmin",
		url:"String"
	},
	VideoAssetList:{
		items:"VideoAsset",
		total:"Int",
		hasMore:"Boolean"
	},
	WashingLocation:{
		id:"String",
		name:"String",
		notes:"String",
		status:"String",
		hasUnattendedDropoff:"Boolean",
		address:"Address",
		partner:"Restaurant",
		currentIngestedContainers:"ScannedContainer",
		availableShipments:"ReusableShipment"
	},
	WashingLocationList:{
		items:"WashingLocation",
		total:"Int",
		hasMore:"Boolean"
	},
	Zone:{
		id:"String",
		name:"String",
		slug:"String",
		polygon:"GraphQLGeoJSONPolygon",
		baseDeliveryFee:"Float",
		feePerKM:"Float",
		baseRiderEarnings:"Float",
		riderEarningsPerKM:"Float",
		timeZone:"String",
		onboardingStatus:"ZoneOnboardingStatus",
		busyWeight:"Float",
		busyStatus:"ZoneBusyStatus",
		minDeliveryDistanceMetres:"Float",
		standardDeliveryDistanceMetres:"Float",
		currentDeliveryDistanceMetres:"Float",
		hardLimitDeliveryDistanceMetres:"Float",
		autoBookingSlotConfigId:"String",
		autoBookingSlotConfig:"AutoBookingSlotsConfig",
		weather:"ZoneWeatherData",
		riders:"Rider",
		restaurants:"Restaurant",
		city:"City",
		boosts:"Boost",
		bookingSlots:"BookingSlot",
		currentBoost:"Boost",
		openingTimes:"OpeningTime",
		activePolygonId:"String",
		liveView:"ZoneLiveView"
	},
	ZoneEvent:{
		id:"String",
		timestamp:"Date",
		operation:"String",
		zoneId:"String",
		from:"JSON",
		to:"JSON"
	},
	ZoneEventList:{
		items:"ZoneEvent",
		total:"Int",
		hasMore:"Boolean"
	},
	ZoneList:{
		items:"Zone",
		total:"Int",
		hasMore:"Boolean"
	},
	ZoneLiveView:{
		activeRiders:"Float",
		unassignedRiders:"Float",
		riderAcceptRate:"Float",
		ridersFreeSoon:"Int"
	},
	ZonePolygon:{
		id:"String",
		created:"Date",
		updated:"Date",
		maxCycleTime:"Float",
		maxDriveTime:"Float",
		polygon:"GraphQLGeoJSONPolygon"
	},
	ZonePolygonList:{
		items:"ZonePolygon",
		total:"Int",
		hasMore:"Boolean"
	},
	ZoneWeatherData:{
		updated:"Date",
		result:"JSON"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }



export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>,
subscription: ((o: any, variables) =>
    fullChainConstruct(fn)('subscription', 'Subscription')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Subscription"],Subscription>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>,
subscription: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('subscription', 'Subscription')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Subscription"],Subscription>
});
export const Zeus = {
  query: (o:ValueTypes["Query"]) => queryConstruct('query', 'Query')(o),
mutation: (o:ValueTypes["Mutation"]) => queryConstruct('mutation', 'Mutation')(o),
subscription: (o:ValueTypes["Subscription"]) => queryConstruct('subscription', 'Subscription')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Query"],
  Query
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Mutation"],
  Mutation
>,
subscription: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Subscription"],
  Subscription
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>(),
subscription: ZeusSelect<ValueTypes["Subscription"]>()
};
  

export const Gql = Chain('http://localhost:3000/graphql')